import React from 'react';

import {
  Container,
  Row,
} from 'reactstrap';

import {
  withRouter
} from 'react-router-dom';


import {DeepURLOnboardingWrapper} from 'common/LandingWrapper.js';

import 'pages/user/Landing.css';

class DeepURLLanding extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };

  }

  componentDidMount() {
    console.log('this.props.postLoginURL ' + window.location.pathname)
    this.props.setPostLoginURL( window.location.pathname );
  }

  render() {
    return (
      <DeepURLOnboardingWrapper {...this.props}>
      <Container className="landingCard mt-4">
        <Row className="justify-content-center pb-1">
          {"Please sign in to continue."}
        </Row>

      </Container>
      </DeepURLOnboardingWrapper>
    )
  }

}


export default withRouter(DeepURLLanding);
