import React from 'react';

import {
  Container,
  Row,
  Col,
  Button
} from 'reactstrap';

import {
  Link
} from 'react-router-dom';

import pickTeam from 'img/pickteam.png';
import FontAwesome from 'react-fontawesome';

import { get_next_league_event } from 'utils/api.js';

import './Rules.css';

export class SimpleHowToPlay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.getNextLeagueEvent = this.getNextLeagueEvent.bind(this);
  }
  componentDidMount() {
    this.getNextLeagueEvent();
    const { league } = this.props.match.params;
    if (league) {
      const paramLeague = parseInt(league, 10);
      if (this.props.activeLeague !== paramLeague) {
        this.props.selectLeague(paramLeague);
      }
    }
  }
  getNextLeagueEvent() {
    const { league } = this.props.match.params;
    if (league) {
      get_next_league_event(league, ({success, error, nextEvent}) => {
        this.setState({
          success,
          nextEvent
        });
      }, (error) => {
        console.log(error);
        this.setState({success: false});
      })
    }
  }
  render() {
    const leageid = this.props.activeLeague;
    const eventid = this.state.nextEvent ? this.state.nextEvent.eventid : null;
    return (
      <Container>
        <div className="rulesContent">
          <div className="rulesContentTitle">How to Play</div>
          <p>
            Playing Majors Challenge fantasy golf is simple and familiar.
            Your team of 12 golfers competes against the other teams in your league.
            The leaderboard is simple stroke play golf.
          </p>
          <p>
            To get started:
          </p>
          <Row>
            <Col xs="1" className="text-right">
              <div className="ruleStepNumberCircle">1</div>
            </Col>
            <Col xs="11">
              <h5>Choose your team of 12 golfers</h5>
              <p>Golfers are segmented into four groups, by their world rank.</p>
            </Col>
            <Col md={{size: 6, offset: 1}} className="d-none d-md-block">
              <img src={pickTeam} style={{height: "200px"}} alt="Pick team."/>
            </Col>
          </Row>
          <Row>
            <Col xs="1" className="text-right">
              <div className="ruleStepNumberCircle">2</div>
            </Col>
            <Col>
              <h5>Pick one alternate for each group</h5>
              <p>An alternate is only used if your starter withdraws from the event before it starts.</p>
            </Col>
          </Row>
          <Row>
            <Col xs="1" className="text-right">
              <div className="ruleStepNumberCircle">3</div>
            </Col>
            <Col>
              <h5>Save Your Team</h5>
              <p>You'll be asked to enter a name for your team.</p>
            </Col>
          </Row>
          <Row>
            <Col xs="1" className="text-right">
              <div className="ruleStepNumberCircle">4</div>
            </Col>
            <Col>
              <h5>Follow your friends</h5>
              <p>Easily track how your friends are doing by tagging them in the&nbsp;
              <Link to={`/members/${leageid}`}>
                <span>Members</span>
              </Link> section. Choose the  &nbsp;
              <Button className="px-1 py-0" style={{lineHeight: "1em", boxShadow: "none" }}
                outline={true} color="primary">
                <span style={{fontSize: ".7em", paddingBottom:'2px'}}>
                  <FontAwesome name="users" />
                  <span style={{marginLeft:'2px'}}>Filter</span>
                </span>
              </Button>
               &nbsp; button to collapse the Leaderboard into only the teams you care about.</p>
            </Col>
          </Row>
          <Row>
            <Col xs="1" className="text-right">
              <div className="ruleStepNumberCircle">5</div>
            </Col>
            <Col>
              <h5>Follow the action live</h5>
              <p>The leaderboard is basic stroke play. Add up your golfers scores and compare it to the competition.</p>
            </Col>
          </Row>

            { eventid && (
              this.state.nextEvent.status === 'open' ?
              <Link to={`/events/${eventid}`}>
                <Button color="primary" size="lg">Enter your Team</Button>
              </Link>
              :
              <Link to={`/events/${eventid}`}>
                <Button color="secondary" size="lg">Preview the Next Event</Button>
              </Link>
            )
            }
          <Row className="pt-5">
            <Col>
              <h5>What about Cut Golfers?</h5>
              <p>If one of your golfers gets cut or withdraws, then you’ll receive the worst 4 round score in place of that golfer.</p>
              <p>This goes for all cut golfers in your lineup.</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5>Tie Breaker Rules</h5>
              <p>Ties are settled based on league rules. See Event Rules for an explanation.</p>
            </Col>
          </Row>

          <Row>
            <Col>
              <h5>Good luck!</h5>
            </Col>
          </Row>

        </div>
      </Container>
    )
  }
}
