import React, {Component} from 'react';

import { Link } from 'react-router-dom'

import {
  Navbar,
  Row,
  Col,
  Container,
  NavbarBrand,
} from 'reactstrap';

import FontAwesome from 'react-fontawesome';

import LoginModal from 'common/LoginModal';
import brand from 'img/brand.png';

import 'common/LandingFooter.css';

class LandingFooter extends Component {
  render() {
    return (
      <div>
        <Navbar className="d-none d-md-block landingFooter" light expand color="faded" >
          <Container className="d-block">
            <Row style={{width: "100%"}}>
              <Col md="3">
                <NavbarBrand href="/">
                  <img src={brand} alt="Majors Challenge brand" style={{height: "42px"}}/>
                </NavbarBrand>
                <div className="d-block pt-4">
                  <a href="https://www.facebook.com/majorschallenge" target="_blank" rel="noopener noreferrer" className="landingFooter_link m-3">
                    <FontAwesome name="facebook-official" size="2x"/>
                  </a>
                  <a href="https://www.twitter.com/challengemajors" target="_blank" rel="noopener noreferrer" className="landingFooter_link m-3">
                    <FontAwesome name="twitter" size="2x"/>
                  </a>
                </div>
              </Col>
              <Col md="3">
                <div className="landingFooter_heading">
                  Company
                </div>
                <Link className="d-block landingFooter_link" to="/terms">Terms of Use</Link>
                <Link className="d-block landingFooter_link" to="/privacy">Privacy Policy</Link>
              </Col>
              <Col md="3">
                <div className="landingFooter_heading">
                  Support
                </div>
                <Link className="d-block landingFooter_link" to="/rules">FAQ</Link>
                <Link className="d-block landingFooter_link" to="/rules">Rules</Link>
                <a className="d-block landingFooter_link" href="mailto:support@majorschallenge.com">Technical Issues</a>
              </Col>
              <Col md="3">
                <div className="landingFooter_heading">
                  Contact
                </div>
                <a className="d-block landingFooter_link" href="mailto:support@majorschallenge.com">Feedback</a>
                <LoginModal loginLink disallowSignup {...this.props} />
              </Col>
            </Row>
            <Row className="pt-3 pl-3">
              <p className="landingFooter_copyright">
                Ⓒ 2020. Majors Challenge. All Rights Reserved
              </p>
            </Row>
          </Container>
        </Navbar>
        <Navbar className="d-flex d-md-none justify-content-center landingFooterMobile" light expand color="faded" >
          <Link className="m-2 m-sm-3 landingFooter_link" to="/privacy">Privacy Policy</Link>
          <Link className="m-2 m-sm-3 landingFooter_link" to="/terms">Terms of Use</Link>
          <Link className="m-2 m-sm-3 landingFooter_link" to="/rules">Rules</Link>
          <a className="m-2 m-sm-3 landingFooter_link" href="mailto:support@majorschallenge.com">Support</a>
        </Navbar>
      </div>
    )
  }
}

export default LandingFooter;
