import React from 'react';

import FontAwesome from 'react-fontawesome';

import {
  login_facebook
} from 'utils/api.js';

import 'common/Auth.css';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'common/ConfirmAlert.css';

export default class FacebookAuth extends React.Component {
  constructor(props) {
    super(props);
    this.checkAuthStatus = this.checkAuthStatus.bind(this);
    this.onAuthSuccess = this.onAuthSuccess.bind(this);
  }
  componentDidMount() {
    (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {return;}
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));

    window.fbAsyncInit = function() {

      window.FB.init({
        appId      : '310825116940127',
        cookie     : false,
        xfbml      : true,
        status     : false,
        version    : 'v2.10'
      });
    };
  }
  checkAuthStatus() {
    window.FB.getLoginStatus((response) => {
      console.log(response);
      if (response.status !== 'connected') {
        //window.FB.login(this.onAuthSuccess, {scope: 'public_profile, email, user_friends',return_scopes: true});
        window.FB.login(this.onAuthSuccess, {scope: 'email',return_scopes: true});
      } else {
        this.onAuthSuccess(response);
      }
    });
  }
  onAuthSuccess(resp) {
    console.log(resp);
    const onSuccessRedirect = this.props.onSuccessRedirect;
    const onError = this.props.onError;
    const authResponse = resp.authResponse;
    if (resp.status === 'connected') {
      login_facebook(authResponse.userID, authResponse.accessToken,
        (response) => {
          if (response.data.success) {
            onSuccessRedirect(response.data.me);
          } else {
            confirmAlert({
              title: 'Error',
              message: response.data.msg,
              buttons: [
                {
                  label: 'OK',
                  onClick: () => { }
                }
              ]
            });
            //onError();
          }
        }, (error) => {
          onError();
      })
    } else {
      onError();
    }
  }
  render() {
    return (
      <div className="facebookAuthLoginButton" onClick={this.checkAuthStatus}>
        <FontAwesome name="facebook-official" className="facebookAuthLoginIcon"/>
        <span className="facebookAuthLoginIconText">{'Log in with Facebook'}</span>
      </div>
    )
  }
}
