import React from 'react';

import {
  Container,
} from 'reactstrap';

import {OnboardingWrapper} from 'common/LandingWrapper.js';
import LoginModal from 'common/LoginModal'


import {activation} from 'utils/api.js';

export default class Activation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activated: null,
      passedEmailL: '',
    }
  }
  componentDidMount() {
    const code = this.props.match.params.activation_code;
    console.log(code);
    activation(code, (response) => {
      this.setState({activated: true, passedEmail: response.email});
    }, (error) => {
      this.setState({activated: false});
    });
  }
  render() {
    let message = "Checking your activation code..."
    if (this.state.activated === true) {
      message = "Your account has been activated."
    } else if (this.state.activated === false) {
      message = "Your activation code was not valid."
    }
    return (
      <OnboardingWrapper {...this.props}>
        <Container className="text-center landingCard mt-4">
          <h5>
            {message}
          </h5>
          {this.state.activated === true &&
            <LoginModal loginButtonActivation {...this.props} passedEmail={this.state.passedEmail}/>
          }
        </Container>
      </OnboardingWrapper>
    )
  }
}
