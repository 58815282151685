import React from 'react';

import {
  Container,
  Row,
  Col,
  Button,
} from 'reactstrap';

import {
  Link
} from 'react-router-dom';

import LandingWrapper from 'common/LandingWrapper.js';
import FontAwesome from 'react-fontawesome';

import pickTeam from 'img/pickteam.png';

import './Rules.css';

const RulesContent = (props) => (
  <div className="rulesContent">
    <div className="rulesContentTitle">How to Play</div>
    <Row className="pt-2">
      <Col xs="1" className="text-right">
        <div className="ruleStepNumberCircle">1</div>
      </Col>
      <Col>
        <h5>Join a friend's league or start your own</h5>
        <p>Become the commissioner of your own league or join a friend's.</p>
        <ul>
          <li>Ask a friend to send you an invite to join his or her league</li>
          <li>The commissioner will choose the events your league will participate in during the PGA season</li>
        </ul>
      </Col>
    </Row>
    <Row>
      <Col xs="1" className="text-right">
        <div className="ruleStepNumberCircle">2</div>
      </Col>
      <Col xs="11">
        <h5>Choose your team of 12 golfers</h5>
        <p>Golfers are segmented into four groups, by their world rank.</p>
      </Col>
      <Col md={{size: 6, offset: 1}} className="d-none d-md-block">
        <img src={pickTeam} style={{height: "200px"}} alt="Pick team."/>
      </Col>
    </Row>
    <Row>
      <Col xs="1" className="text-right">
        <div className="ruleStepNumberCircle">3</div>
      </Col>
      <Col>
        <h5>Pick one alternate for each group</h5>
        <p>Your alternate picks will only be used if one of your starters withdraws from the event.</p>
      </Col>
    </Row>
    <Row>
      <Col xs="1" className="text-right">
        <div className="ruleStepNumberCircle">4</div>
      </Col>
      <Col>
        <h5>Follow your friends</h5>
        <p>Easily track how your friends are doing by tagging them in the&nbsp;
        <Link to={`/members/`}>
          <span>Members</span>
        </Link> section. Choose the  &nbsp;
        <Button className="px-1 py-0" style={{lineHeight: "1em", boxShadow: "none" }}
          outline={true} color="primary">
          <span style={{fontSize: ".7em", paddingBottom:'2px'}}>
            <FontAwesome name="users" />
            <span style={{marginLeft:'2px'}}>Filter</span>
          </span>
        </Button>
         &nbsp; button to collapse the Leaderboard into only the teams you care about.</p>
      </Col>
    </Row>
    <Row>
      <Col xs="1" className="text-right">
        <div className="ruleStepNumberCircle">5</div>
      </Col>
      <Col>
        <h5>Invite your friends</h5>
        <p>Fantasy contests are week-to-week with no season long obligation. We expect most leagues to participate during the four golf majors (Masters, US Open, British Open, PGA Championship) and Players Championship (unofficial fifth major), but fantasy tournaments will be available all season long.</p>
      </Col>
    </Row>

    <Row>
      <Col>
        <h5>Scoring</h5>
        <p>During the tournament, the Majors Challenge leaderboard updates in real-time with the PGA. A teams position on the leaderboard is based on the sum of its golfer scores. Your commissioner may choose to count all 12 golfers in the calculation or opt to drop the worst performing scores. The number of scores to drop is determined by your commissioner.</p>
        <p style={{textDecoration: "underline"}}>Cut, Withdraw and MDF Rules:</p>
        <ul>
          <li>If one of your golfers withdraws from the tournament before his first tee time then that golfer will be replaced with an alternate.</li>
          <li>If a golfer withdraws after start of play the result is based on league rules determined by your commissioner.
            <ul>
              <li>Option 1: All WD turn into a CUT</li>
              <li>Option 2: Golfers who WD in Round 1 or 2 receive an alternate. After Round 2 WD turn into a CUT</li>
            </ul>
          </li>
          <li>If more than one golfer in a group withdraws before start of play then your team will receive an alternate for the first WD and a CUT for any additional WD.</li>
          <li>You will receive the worst four round PGA score in place of every golfer on your squad that:
            <ul>
              <li>is CUT or is MDF</li>
              <li>Withdraws after start of play</li>
              <li>is Disqualified</li>
            </ul>
          </li>
        </ul>
      </Col>
    </Row>
    <Row>
      <Col>
        <h5>Entry and Purse</h5>
        <p>The entry fee and purse for your league depends on how your commissioner sets it up.</p>
      </Col>
    </Row>
    <Row>
      <Col md="6">
        <h6>Fixed Entry</h6>
        <p>Fixed Entry leagues charge the same amount for each team entered into a golf pool. The total amount collected is the purse for that tournament.</p>
        <p>As an example, if your league had 100 teams with an entry fee of 10, the purse would be fixed at 1000. Your commissioner sets how many winning places share the purse and at what percentages.</p>
      </Col>
      <Col md="6">
        <h6>Stroke Play (Variable Purse)</h6>
        <p>Stroke play is an exciting and unique way to play fantasy golf. Your loss is a variable amount that depends on how well your team performs compared to the best team in your league. Think of it as stroke play for fantasy golf.</p>
        <ul>
          <li>If your team is 10 shots off the lead then you would owe 10 points into the tournament purse. The further back in the field you fall, the more you'd owe in to the purse.</li>
          <li>Every team in your league contributes their share to the purse</li>
          <li>Your commissioner will select a stroke multiplier that either reduces or increases the purse by that factor. The default factor is set to 1, so if you were 20 strokes behind the leader then you'd owe 20 into the purse. If your commissioner set the multiplier to 0.5, then you'd only owe 10 for the same loss.</li>
          <li>If you enter multiple teams, you are responsible for the loss on each team. This can add up quickly when choosing a value larger than 1.</li>
        </ul>
        <p>The purse will continue to grow throughout the tournament, particularly over the weekend as teams with many cut golfers see their score balloon.</p>
      </Col>
    </Row>
    <Row>
      <Col md="12">
        <h6>Winnings</h6>
        <p>Your commissioner sets how many winning places share the purse and at what percentages. Payouts can be configured differently for each tournament. We recommend the variable point pool. You'll love it.</p>
      </Col>
    </Row>
    <Row>
      <Col>
        <h5>Tie Breakers</h5>
        <p>In the event two teams have the same score then tie breaker rules are in effect. Ties are first broken by looking at the cut count, where the roster with the fewest number of cuts wins. Any golfer that does not finish the 4th round of an event is counted toward your cut counts. Your commissioner will choose which rule applies to your league if both the score and cut count are the same:</p>
        <h6>Rule 1 - Split Points (with cuts)</h6>
        <p>This rule splits the purse across all ties. Assume your league rewarded 200 to second place and 100 to third place. If these teams tied, then the two places would split 300 or 150 each.</p>
        <h6>Rule 2 - Split Points (without cuts)</h6>
        <p>This rule is the same as Rule 1, but ties are split across teams having the same score regardless of cut count. With this rule a team with 2 cuts can still tie a team with 1 cut.</p>
        <h6>Rule 3 - Match Cards</h6>
        <p>The team containing the lowest score for a single golfer breaks the tie. For example, if Team A and Team B have the same score and cut count, while Team B's best golfer shot -5 and Team A's best golfer shot -3, then Team B places ahead of Team A. In the event that Team A and B both have best golfers with the same score, then each team's second best golfer will determine the tie breaker. This continues until the tie has broken. If both teams have identical golfer scores, then they split the purse as described in Rule 1.</p>
      </Col>
    </Row>
    <Row className="pt-2 text-center">
      <Col>
        <p>
          <Link className="" to="/games">{'<< Back to All Games'}</Link>
        </p>
      </Col>
    </Row>
  </div>
);

const WhiteLabelRulesContent = (props) => (
  <div className="rulesContent">
    <div className="rulesContentTitle">How to Play</div>
    <p>
      <h6>Welcome to the CDGA.org Golf Pool</h6>
    </p>
    <p>
      To get started:
    </p>
    <Row>
      <Col xs="1" className="text-right">
        <div className="ruleStepNumberCircle">1</div>
      </Col>
      <Col xs="11">
        <h5>Enter a Team of 12 golfers.</h5>
        <p>Golfers are segmented into four groups, by their world rank.</p>
      </Col>
      <Col md={{size: 6, offset: 1}} className="d-none d-md-block">
        <img src={pickTeam} style={{height: "200px"}} alt="Pick team."/>
      </Col>
    </Row>
    <Row>
      <Col xs="1" className="text-right">
        <div className="ruleStepNumberCircle">2</div>
      </Col>
      <Col>
        <h5>Pick one alternate for each group</h5>
        <p>An alternate is only used if your starter withdraws from the event before it starts.</p>
      </Col>
    </Row>
    <Row>
      <Col xs="1" className="text-right">
        <div className="ruleStepNumberCircle">3</div>
      </Col>
      <Col>
        <h5>Save Your Team</h5>
        <p>You'll be asked to enter a name for your team.</p>
      </Col>
    </Row>
    <Row>
      <Col xs="1" className="text-right">
        <div className="ruleStepNumberCircle">4</div>
      </Col>
      <Col>
        <h5>Follow your friends</h5>
        <p>Easily track how your friends are doing by tagging them in the&nbsp;
        <Link to={`/members/`}>
          <span>Members</span>
        </Link> section. Choose the  &nbsp;
        <Button className="px-1 py-0" style={{lineHeight: "1em", boxShadow: "none" }}
          outline={true} color="primary">
          <span style={{fontSize: ".7em", paddingBottom:'2px'}}>
            <FontAwesome name="users" />
            <span style={{marginLeft:'2px'}}>Filter</span>
          </span>
        </Button>
         &nbsp; button to collapse the Leaderboard into only the teams you care about.</p>
      </Col>
    </Row>
    <Row>
      <Col xs="1" className="text-right">
        <div className="ruleStepNumberCircle">5</div>
      </Col>
      <Col>
        <h5>Follow the action live</h5>
        <p>The leaderboard is basic stroke play. Add up your golfers scores and compare it to the competition.</p>
      </Col>
    </Row>
    <Row className="mt-4">
      <Col>
        <h5>Scoring</h5>
        <p>During the tournament, the Majors Challenge leaderboard updates in real-time with the PGA. A teams position on the leaderboard is based on the sum of its golfer scores.</p>
        <p style={{textDecoration: "underline"}}>Cut, Withdraw and MDF Rules:</p>
        <ul>
          <li>If one of your golfers withdraws from the tournament before his first tee time then that golfer will be replaced with an alternate.</li>
          <li>If a golfer withdraws after start of play then that golfer is treated the same as if he were CUT.</li>
          <li>If more than one golfer in a group withdraws before start of play then your team will receive an alternate for the first WD and a CUT for any additional WD.</li>
          <li>You will receive the worst four round PGA score in place of every golfer on your squad that:
            <ul>
              <li>is CUT or is MDF</li>
              <li>Withdraws after start of play</li>
              <li>is Disqualified</li>
            </ul>
          </li>
        </ul>
      </Col>
    </Row>
    <Row className="mt-4">
      <Col>
        <h5>Prizes</h5>
        <p>Prizes to be listed here</p>
      </Col>
    </Row>



  </div>
);


export class LandingRules extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <LandingWrapper {...this.props}>
        <RulesContent />
      </LandingWrapper>
    )
  }
}

export const AuthedRules = (props) => (
  <Container style={{paddingTop:"4rem", paddingBottom:"2rem"}}>
    <RulesContent />
  </Container>
);

export const AuthedWhiteLabelRules = (props) => (
  <div style={{paddingTop:"4rem", paddingBottom:"2rem", backgroundColor:"rgba(236, 240, 241, 0.5)"}}>
    <Container>
    <WhiteLabelRulesContent />
    </Container>
  </div>
);
