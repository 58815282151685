import React from 'react';

import {
  Button,
  Col,
  Container,
  Row,
} from 'reactstrap';

import {
  withRouter
} from 'react-router-dom';

import FontAwesome from 'react-fontawesome';

import { check_league, add_self_member} from 'utils/api';

import 'pages/user/JoinLeague.css';
import 'pages/user/Signup.css';

class AuthedJoinLeague extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      leagueslug: null,
      leagueid: null,
      leagueName: "",
      leagueType: "",
      commissioner: "",
      commissionerId: "",
      email: '',
      validLeague: null,
      status: null,
      error: null,
      leaguePwd: "",
      password: '',
      firstname: '',
      lastname: '',
      defaultAuthPassed: false,

    };


    this.checkLeague = this.checkLeague.bind(this);
    this.handleLeagueAuthSubmit = this.handleLeagueAuthSubmit.bind(this);

    this.handleChange = this.handleChange.bind(this);

    this.renderLeagueAuthForm = this.renderLeagueAuthForm.bind(this);

    this.handleMembership = this.handleMembership.bind(this);
  }

  componentDidMount() {
    //this.props.setPostLoginURL( this.props.postLoginURL );
    if (this.props.match.params.leagueslug || this.props.leagueslug){
      this.checkLeague();
    }
  }

  checkLeague() {
    let leagueslug = this.props.match.params.leagueslug;
    if (!leagueslug){
      leagueslug = this.state.leagueslug || this.props.leagueslug;
    }
    check_league(
      leagueslug,
      null,
    ({success, reason, leagueName, leagueType, commissioner, leagueid, commissionerId}) => {
      if (!success && this.props.match.params.leagueslug && reason === 'Invalid League ID'){
        this.props.history.push("/");
        return;
      }
      this.setState({
        validLeague: success,
        error: reason,
        leagueName,
        leagueType,
        commissioner,
        leagueid,
        commissionerId,
      });

      if (success && leagueid !== null){
        if ('already member' === reason){
          this.props.history.push('/league/'+leagueid);
          return;
        }
        this.setState({defaultAuthPassed:true,});
      }
    }, (error) => {
      console.log(error);
      this.props.history.push("/");
    });
  }

  handleLeagueAuthSubmit(event){
    event.preventDefault();
    event.stopPropagation();

    let leagueslug = this.props.match.params.leagueslug;
    if (!leagueslug){
      leagueslug = this.state.leagueslug;
    }

    this.setState({status: false});
    check_league(
      leagueslug,
      this.state.leaguePwd,
    ({success, reason, leagueName, leagueType, commissioner, leagueid, commissionerId}) => {
      this.setState({
        error: reason,
        leagueName,
        leagueType,
        commissioner,
        leagueid,
        commissionerId,
        status: null,
      });
      if (success && leagueid !== null){
        this.handleMembership();
      }
    }, (error) => {
      console.log(error);
      this.props.history.push("/");
    });
  }



  handleMembership(event){
    this.setState({status: false});

    add_self_member(this.state.leagueid, this.state.commissionerId, this.state.firstname, this.state.lastname, this.state.email, this.state.password,
      ({success, reason, me}) => {

        if (success){
          this.props.updateMe(me);
          this.props.history.push('/league/'+this.state.leagueid)

          //this.props.history.push('/');
          //this.props.loggedInCallback(me, this.props.history);
        }
        this.setState({
          error: reason,
          status: null,
        });

      }, (error) => {

      }
    )
  }

  handleChange(event) {
    this.setState({[event.target.name]: event.target.value});
  }

  renderDeflautAuthPassedForm(){
    return(
      <div>
        <Row className="justify-content-center text-right p-4">
          <Col xs="10" sm="9" md="8" lg="6" xl="5">
              <div justify-content-center>
                <div>
                  <Button color="success" onClick={this.handleMembership} block> Join Now </Button>
                </div>
              </div>
          </Col>
        </Row>
        {this.state.commissioner &&
          <Row noGutters className="justify-content-center">
            <Col xs="10" sm="9" md="9" lg="9" xl="8">
              <div className="mt-3 text-center">
                Commissioner:
                <a href={"mailto:"+this.state.commissioner}>
                  <span style={{whiteSpace: "nowrap", fontSize: "0.85rem", paddingLeft:'10px'}}>
                    <FontAwesome name="envelope" className="mr-2" />
                    <span>{this.state.commissioner}</span>
                  </span>
                </a>
              </div>
            </Col>
          </Row>
        }
      </div>
    )
  }

  renderLeagueAuthForm(){
    return (
      <div>
        <form onSubmit={this.handleLeagueAuthSubmit}>
          {!this.props.match.params.leagueslug &&
          <Row noGutters className="justify-content-center">
            <Col xs="10" sm="9" md="9" lg="9" xl="8">
              <label className="joinContent_signuplabel">
                <input type="text" name="leagueslug" value={this.state.leagueslug}
                  className="joinContent_signupinput" onChange={this.handleChange}
                  placeholder="League ID"/>
              </label>
            </Col>
          </Row>
          }
          <Row noGutters className="justify-content-center">
            <Col xs="10" sm="9" md="9" lg="9" xl="8">
              <label className="joinContent_signuplabel">
                <input type="password" name="leaguePwd" value={this.state.leaguePwd}
                  className="joinContent_signupinput" onChange={this.handleChange}
                  placeholder="Password"/>
              </label>
            </Col>
          </Row>
          <input type="submit" value="Authenticate" hidden />
        </form>
        <Row className="justify-content-center text-right p-4">
          <Col xs="10" sm="9" md="8" lg="6" xl="5">
            {
              this.state.error &&
                <div style={{color: "red", textAlign: "center", paddingBottom: "1rem"}}>{this.state.error}</div>
            }
            {
              this.state.status === null ?
                <div justify-content-center>
                  <div>
                    <Button color="success" onClick={this.handleLeagueAuthSubmit} block> Let Me In </Button>
                  </div>
                </div>
              :
              <Button color="success" disabled block>
                <FontAwesome name="spinner" spin />
              </Button>
            }
          </Col>
        </Row>
        {this.state.commissioner &&
          <Row noGutters className="justify-content-center">
            <Col xs="10" sm="9" md="9" lg="9" xl="8">
              <div className="mt-3 text-center">
                Commissioner:
                <a href={"mailto:"+this.state.commissioner}>
                  <span style={{whiteSpace: "nowrap", fontSize: "0.85rem", paddingLeft:'10px'}}>
                    <FontAwesome name="envelope" className="mr-2" />
                    <span>{this.state.commissioner}</span>
                  </span>
                </a>
              </div>
            </Col>
          </Row>
        }
      </div>
    )
  }

  render() {
    if (this.state.validLeague === null && this.props.match.params.leagueslug) {
      return null;
    }

    let clsName = "col-lg-auto pt-0 mt-lg-2";
    if (this.props.match.params.leagueslug){
      //clsName = "col-lg-auto pt-0 pt-lg-5 mt-lg-5";
      clsName = "col-lg-auto pt-0 pt-5 mt-5";
    }
    return (

      <div className={clsName} >
        <Container fluid className="joinContainer">
          <Row noGutters>
            <Col xs="12" sm="10" md="9" lg="8" xl="6" className="joinContent mx-auto">
              {this.props.match.params.leagueslug &&
                <div className="joinContent_title">Welcome to the league</div>
              }
              {!this.props.match.params.leagueslug &&
                <div className="joinContent_title">Looking for a friend's pool? Ask them to invite you or enter credentials below</div>
              }
              <h2 className="joinContent_leaguename">{this.state.leagueName}</h2>
              {
                this.state.leagueid === null ? this.renderLeagueAuthForm(): (this.state.defaultAuthPassed?this.renderDeflautAuthPassedForm():'')
              }
            </Col>
          </Row>
        </Container>
      </div>

    );
  }
}

export default withRouter(AuthedJoinLeague);
