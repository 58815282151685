import React from 'react';

import {
  Row, Col,
  ListGroup, ListGroupItem,
  ButtonGroup, Input,
  InputGroup, InputGroupText, InputGroupAddon,
} from 'reactstrap';


import FontAwesome from 'react-fontawesome';

import {
  ContentWrapper
} from 'common/ProfileComponents.js';

//import GooglePayButton from "@google-pay/button-react";

import ImgCreditCards from 'img/v-mc-d-amex-small.gif';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'common/ConfirmAlert.css';

import AuthnetPay from 'common/AuthnetPay.js';
//import GPay from 'common/GPay.js';
//import ApplePay from 'common/ApplePay.js';
//import ActumACHPay from 'common/ActumACHPay.js';


import 'pages/user/JoinLeague.css';


export default class EntryFeeDepositFund extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: props.entryFee,
      otheramount: "",
      loading: false,
      showPaymentOptions: true,
      showCardInputForm: false,
      status: null,
      saveSuccess: null,
      error: null,
      stateDropdownOpen: false,
      message:null,
      gpayReturnMsg:'',
      showApplePay: false,
      userVerified: false,
      payStatus: null,
      payMsg: null,

    }


    this.toggleShowCardInputForm = this.toggleShowCardInputForm.bind(this);
    this.validateDepositAmount = this.validateDepositAmount.bind(this);
    this.handleOtherAmountChange = this.handleOtherAmountChange.bind(this);
    this.setPaymentStatus = this.setPaymentStatus.bind(this);

  }
  componentDidMount() {

  }

  handleOtherAmountChange(event) {
    const value = event.target.value.replace(/[^\d]/,'');

    if(parseInt(value) !== 0) {
      this.setState({[event.target.name]: value,
        amount:event.target.value, message: null, submitted: false, payStatus:null,});
    }

  }

  validateDepositAmount(){
    let validationMsg = '';
    if (this.state.amount === "" ){
      this.setState({amount:this.props.entryFee});
    }
    if (this.state.amount !== "" && parseInt(this.state.amount) <=0 ){
      validationMsg = 'The minimum donation is $' + this.props.entryFee;
    }
    /*if (this.state.amount !== "" && parseInt(this.state.amount)< this.props.entryFee ){
      validationMsg = 'The minimum donation is $' + this.props.entryFee;
    }*/
    if (this.state.amount !== "" && parseInt(this.state.amount)> 10000 ){
      validationMsg = 'The maximum donation is $10,000';
    }

    if (validationMsg !== ''){
      confirmAlert({
        title: 'Alert',
        message: validationMsg,
        buttons: [
          {
            label: 'OK',
            onClick: () => { }
          }
        ]
      });
      return false;
    }

    return true;
  }

  toggleShowCardInputForm(){
    if (!this.validateDepositAmount()){return;}
    if (!this.state.showPaymentOptions){
      this.props.toggleMovedToPaymentScreen();
    }
    this.setState({showPaymentOptions:!this.state.showPaymentOptions,
                   showCardInputForm:!this.state.showCardInputForm,
                   error: null,
                 });
  }


  setPaymentStatus(payStatus, payMsg, userLedgerId){
    this.setState({payStatus, payMsg, showPaymentOptions: true, showCardInputForm: false});
    if (payStatus === 'success' && this.props.depositFundSuccess){
      this.props.depositFundSuccess(userLedgerId);
    }
  }


  renderPaymentOptions() {
    if (!this.state.showPaymentOptions){
      return '';
    }


    return (
      <div className="">
        <div className="mb-2" style={{fontSize: "1.1em", fontWeight: 600}}>
          Donation Amount: ${this.props.entryFee}
        </div>
        <Row className="justify-content-between creditBalanceRow">
          <Col className="d-flex justify-content-start" style={{overflow:'auto'}}>
            <div className="pt-1">Donate another amount </div>
            <ButtonGroup size="sm">
              <div class="btn-group" style={{marginLeft:'10px'}}>
                <InputGroup style={{flexWrap:'nowrap'}}>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>$</InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder="Other" value={this.state.otheramount} name="otheramount"
                  onChange={this.handleOtherAmountChange}
                  style={{width:'75px', marginLeft:'0px !important'}}
                  className={this.state.otheramount===''?'':'active'}/>
                </InputGroup>
              </div>
            </ButtonGroup>
          </Col>
        </Row>
        <Row className="my-2">
          <Col  className="">
            <div className="text-danger">Choose a deposit method</div>
          </Col>
        </Row>
        <Row className="justify-content-centerx">
          <Col  className="">
            <ListGroup style={{width:'100%', border:'1px solid rgba(0, 0, 0, 0.125)'}}>
              <ListGroupItem action tag="a" href="#" onClick={()=> this.props.handlePrePaymentSteps(this.toggleShowCardInputForm)} style={{border:'none'}}>
                <div style={{width: "100%"}} className="d-flex justify-content-between">
                  Debit/ Credit Card
                  <img src={ImgCreditCards} height="22" alt="Authorize.Net"
                  style={{marginLeft: 'auto',paddingRight:'10px'}} border="0" />
                  <FontAwesome name="angle-right" size="2x" style={{color:'green', marginTop:'-5px'}}/>
                </div>
              </ListGroupItem>
              {/*<ActumACHPay amount={this.state.amount} setPaymentStatus={this.setPaymentStatus} leagueEventId={this.props.leagueEventId}
                entryId={this.props.entryId} updateMe={this.props.updateMe}
                handlePrePaymentSteps={this.props.handlePrePaymentSteps}/>*/}
              {/*<GPay amount={this.state.amount} setPaymentStatus={this.setPaymentStatus} leagueEventId={this.props.leagueEventId} updateMe={this.props.updateMe}/>*/}
              {/*<ApplePay amount={this.state.amount} setPaymentStatus={this.setPaymentStatus}
              leagueEventId={this.props.leagueEventId}
              entryId={this.props.entryId} updateMe={this.props.updateMe} handlePrePaymentSteps={this.props.handlePrePaymentSteps}/>
              */}
            </ListGroup>
          </Col>
        </Row>
        <Row className="mt-4 mb-3 ml-2">
          {/*<Col style={{maxWidth:'10px'}}>
            <Input type="checkbox" value="checkbox" disabled checked={true}></Input>
          </Col>*/}
          <Col className="pl-0">
            <div style={{fontSize:".80em"}} >
              {"You are making this donation with the understand that no prize, cash or otherwise, is awarded by Majors Challenge to any participant in this golf pool. Proceeds from this golf pool will be made as a charitable donation to "}<b>{this.props.charityName}</b>{" or any such predetermined similar charity at the sole discretion of Majors Challenge, in Majors Challenge's own name. By entering, you consent to share your user profile with the charity organizers and allow them to contact you directly through email or other means. Donations do not increase your chance of winning.Donations are tax deductible to the extent allowable by law."}
            </div>
          </Col>
        </Row>

        {(this.state.payMsg && this.state.payStatus === 'error') &&
        <Row className=" text-center py-1">
          <Col>
            <div style={{color: "red", textAlign: "center", paddingBottom: "1rem"}}>{this.state.payMsg}</div>
          </Col>
        </Row>
        }

        {(this.state.payMsg && this.state.payStatus === 'success') &&
        <Row className=" text-center py-1">
          <Col >
            <div style={{color: "green", textAlign: "center", paddingBottom: "1rem"}}>
              <span style={{fontWeight:'bold'}}>${this.state.amount}</span> has been added to your account.
            </div>
          </Col>
        </Row>
        }

      </div>
    )
  }

  renderCardInputForm() {
    if (!this.state.showCardInputForm){
      return '';
    }

    return <AuthnetPay amount={this.state.amount} leagueId={this.props.leagueId} leagueEventId={this.props.leagueEventId} entryId={this.props.entryId}
      goBack={this.toggleShowCardInputForm} setPaymentStatus={this.setPaymentStatus}/>
  }


  render() {

    return (
      <div>
        <ContentWrapper>
          {this.renderPaymentOptions()}
          {this.renderCardInputForm()}
        </ContentWrapper>
      </div>
    )
  }
}
