import React from 'react';

import {
  Link
} from 'react-router-dom';

import {
  Alert
} from 'reactstrap';

const LeagueBalanceWarning = (props) => {
  return (
    <Alert color="danger">
      <div style={{fontSize:".85rem"}}>
        <b>Warning:</b> Your credit balance is low. &nbsp;
        <Link to={"/commissioner/" + props.activeLeague + "/balance"}>Purchase more credits</Link> to prevent disruptions in your league.
      </div>
    </Alert>
  )
}

export default LeagueBalanceWarning;
