import React from 'react';

import {
  Container,
  Row,
  Col,
  Button,
} from 'reactstrap';

import {
  Link,
  //useHistory,
} from 'react-router-dom';

import LandingWrapper from 'common/LandingWrapper.js';


import oneAndDonePic from 'img/one-done.png';
import oneAndDoneSSPic from 'img/season_earnings.png';
import seasonDraftPic from 'img/season_draft.png';
import seasonDraftSSPic from 'img/season_draft_ss.png';
import cupStandingsPic from 'img/cup_standings.png';
import weeklyLeaderboardPic from 'img/weekly_leaderboard.png';
import linestarPic from 'img/linestar.png';

import { isMobPortrait, } from 'utils/responsive.js';

import './Games.css';

const GamesContent = (props) => {
  const imgHeight = isMobPortrait()?"160px":"350px";
  const imgHeight1 = isMobPortrait()?"130px":"305px";
  const lineSepWidth = isMobPortrait()?"350px":"600px";
  return (
  <div className="gamesContent">
    <div className="gamesContentTitle">Majors Challenge Game Formats</div>
    <div className="gamesContentSubTitle">Weekly Golf Pools</div>
    <Row className="pt-2 pb-0">
      <Col>
        <p>
          In a weekly golf pool, participants select a team of 12 golfers playing in the PGA TOUR event that week. Golfers are divided into four tiers based on their world golf ranking. Your fantasy score is calculated by adding up the scores of each golfer on your team. Should any of your golfers be cut from weekend play, a penalty may be applied to your overall score. The entry fee, payout structure and other rules are determined by the league commissioner.
          &nbsp;<Link className="" to="/rules">Click here </Link>for full rules.
        </p>
      </Col>
    </Row>
    <Row className="pb-2 text-center">
      <Col className="">
        <img src={weeklyLeaderboardPic} style={{height: imgHeight}} alt="One and Done."/>
      </Col>
    </Row>
    <Row className="pt-2">
      <Col xs="12">
        <p>Game Options:</p>
        <ul>
          <li className="mb-2">
            <span className="font-weight-bold">Fixed Entry - </span>
            <span>The league commissioner establishes the entry fee, with the weekly purse determined by multiplying the number of teams by the entry amount. </span>
          </li>
          <li>
            <span className="font-weight-bold">Stroke Play - </span>
            <span>A team’s loss is determined by the number of strokes it trails behind the leading team on the fantasy leaderboard. The weekly purse fluctuates, contingent on the winner's performance relative to the rest of the fantasy field. </span>
          </li>
        </ul>
        <p>Weekly tournaments are available for all stroke play PGA TOUR events and select LPGA tournaments. Each pool starts with team selection on Monday and concludes on Sunday with a winner. </p>
      </Col>

    </Row>
    {props.auth &&
    <div className="text-center">
      <Link to={"/createleague"} replace style={{color: "inherit", textDecoration: "none", cursor: "inherit"}} className="ml-auto">
        <Button  size="md" color="primary">
            CREATE A LEAGUE
        </Button>
      </Link>
    </div>
    }
    <Row className="pb-2 mt-4 text-center">
      <Col className=" text-center">
        <img src={linestarPic} style={{width:lineSepWidth}} alt="."/>
      </Col>
    </Row>
    <div className="gamesContentSubTitle">Season Long Games</div>
    <Row className="pt-2">
      <Col>
        <h5>Majors Challenge Cup</h5>
        <p>
        The Majors Challenge Cup is a season-long leaderboard that aggregates team scores from each weekly tournament in your fantasy season. Think of this contest as the fantasy version of the FedEx Cup. Depending on rules, the season champion is determined by either the team having the lowest score or most points through the last event in your fantasy season.
        </p>
      </Col>
    </Row>
    <Row className="pb-3 mt-0 text-center">
      <Col className="">
        <img src={cupStandingsPic} style={{height: imgHeight}} alt="One and Done."/>
      </Col>
    </Row>
    <Row className="pt-2">
      <Col>
        <h5>Tournament of Champions</h5>
        <p>
        The Tournament of Champions is a season-ending golf pool where participants earn entry by placing at the top of the leaderboard in a weekly tournament. The league commissioner selects how many places gain entry into the TOC each week, and the PGA TOUR event to serve as the Tournament of Champions. The winner of this event is crowned the season champion.
        </p>
      </Col>
    </Row>

    <Row className="pb-2 mt-1 text-center">
      <Col className=" text-center">
        <img src={linestarPic} style={{width:lineSepWidth}} alt="."/>
      </Col>
    </Row>

    <div className="gamesContentSubTitle">Other Formats</div>
    <Row className="pt-2">
      <Col>
        <h5>One-And-Done</h5>
        <p>
        The One-And-Done game is a season long competition where participants select a new PGA TOUR player each week. The money that player earns goes to your season long total. Once a golfer is chosen, they cannot be picked again. The player with the highest total earnings at the end of the season is the winner. This game takes patience and strategy, knowing when to pick the best golfers and when and where to find value each week.
        &nbsp;<Link className="" to="/onedonerules">Click here </Link>for full rules.
        </p>
      </Col>
    </Row>
    <Row className="pt-2 pb-3 text-center">
      <Col className="">
        <img src={oneAndDoneSSPic} style={{height: imgHeight1}} alt="One and Done."/>
      </Col>
    </Row>
    <Row className="pt-2">
      <Col>
        <h5>Season Draft</h5>
        <p>
        Season Draft is a season-long competition where participants draft a team of five golfers and one alternate. Drafts are held offline and typically follow a snake-draft format. The money each golfer earns goes to that team’s season long total. Teams can substitute their alternate for an active golfer once per season. The team that earns the most money at the end of the season is the winner.
        &nbsp;<Link className="" to="/seasondraftrules">Click here </Link>for full rules.
        </p>
      </Col>
    </Row>
    <Row className="py-2 text-center">
      <Col className="">
        <img src={seasonDraftSSPic} style={{height: imgHeight}} alt="Season Draft."/>
      </Col>
    </Row>

    <Row className="pb-1 mt-4 text-center">
      <Col className=" text-center">
        <img src={linestarPic} style={{width:lineSepWidth}} alt="."/>
      </Col>
    </Row>
    {props.auth &&
    <div className="text-center mt-4">
      <Link to={"/createleague"} replace style={{color: "inherit", textDecoration: "none", cursor: "inherit"}} className="ml-auto">
        <Button  size="md" color="primary">
            CREATE A LEAGUE
        </Button>
      </Link>
    </div>
    }
  </div>);
}

export class GamesUnauth extends React.Component {

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <LandingWrapper {...this.props}>
        <GamesContent auth={false}  />
      </LandingWrapper>
    )
  }
}

export const GamesAuth = (props) => (
  <Container style={{paddingTop:"4rem", paddingBottom:"2rem"}}>
    <GamesContent auth={true}/>
  </Container>
);


const OneAndDoneGameRuleContent = (props) => {
  //const navigate = useHistory();
	const goBack = () => {
		props.history.goBack();
	}
  return(
  <div className="gamesContent">
      <Row className="pt-2 text-center">
        <Col className="">
          <img src={oneAndDonePic} style={{height: "200px"}} alt="One and Done."/>
        </Col>
      </Row>
      <div className="gamesContentSubTitle">One-And-Done Rules</div>
      <Row className="pt-2">
        <Col>
        <ol>
          <li className="mb-2">
            Your league commissioner will choose which PGA TOUR events are included in your One-And-Done fantasy golf season.
          </li>
          <li className="mb-2">
            Your league commissioner will set the number of golfers to select each week.
          </li>
          <li className="mb-2">
            Each week during your season you will select a golfer playing in the PGA TOUR event scheduled for that week. Once the PGA tournament field is released, your picks can be entered. This is usually on Monday each week.
          </li>
          <li className="mb-2">
            If your league requires multiple selections, then you will need to choose additional golfers each week. You cannot choose the same golfer multiple times.
          </li>
          <li className="mb-2">
            After selecting a golfer, you cannot choose that player again for the rest of the season. However, if your selected golfer withdraws from a tournament before teeing off, that golfer remains available to pick again later in the season.
          </li>
          <li className="mb-2">
            Golfer selections lock at the posted first tee time for the PGA tournament. This is usually on Thursday morning.
          </li>
          <li className="mb-2">
            Golfer selections are final and cannot be changed after the start of the tournament. There are no substitutions for golfers who do not play, withdraw, are cut, or are disqualified.
          </li>
          <li className="mb-2">
            Each week, the earnings from your selected golfers contribute to your season total.
          </li>
          <li className="mb-2">
            While there is no penalty for forgetting to make your picks, you will not receive any earnings for that week.
          </li>
          <li className="mb-0">
            The season winner is determined by the person with the highest total earnings over the entire season.
          </li>
        </ol>
        </Col>
      </Row>
      {((!props.embedIn || props.embedIn !== 'COMMISS')) &&
      <Row className="pt-2 text-center">
        <Col>
          <p>
            <Link className="" to="/games" onClick={goBack}>{'<< All Games '} </Link>
          </p>
        </Col>
      </Row>
      }
      {!props.auth &&
      <div className="text-center">
        <Link to={"/createleague"} replace style={{color: "inherit", textDecoration: "none", cursor: "inherit"}} className="ml-auto">
          <Button  size="md" color="primary">
              CREATE A LEAGUE
          </Button>
        </Link>
      </div>
      }
  </div>);
}

export class OneAndDoneGameRuleUnauth extends React.Component {

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <LandingWrapper {...this.props}>
        <OneAndDoneGameRuleContent auth={false}/>
      </LandingWrapper>
    )
  }
}

export const OneAndDoneGameRuleAuth = (props) => (
  <Container style={{paddingTop:"4rem", paddingBottom:"2rem"}}>
    <OneAndDoneGameRuleContent {...props} auth={true}/>
  </Container>
);

const SeasonDraftGameRuleContent = (props) => (
  <div className="gamesContent">

      <Row className="pt-2 text-center">
        <Col className="">
          <img src={seasonDraftPic} style={{height: "200px"}} alt="Season Draft."/>
        </Col>
      </Row>
      <div className="gamesContentSubTitle">Season Draft Rules</div>
      <Row className="pt-2">
        <Col>
        <ol>
          <li className="mb-2">
            The season draft requires that you conduct an offline draft of fantasy golf teams, typically using a “snake draft” format. While there is no strict limit on the number of teams in your draft, having too many teams can hinder competitiveness, especially for those at the bottom of the draft order.
          </li>
          <li className="mb-2">
            During your draft each team selects a team of five active golfers and one alternate. While golfers on the LIV tour can be chosen, only PGA TOUR events are included in the Season Draft season.
          </li>
          <li className="mb-2">
            Once your draft is complete, your commission will enter teams and golfers into your Majors Challenge league.
          </li>
          <li className="mb-2">
            Team selections are final and cannot be changed once entered.
          </li>
          <li className="mb-2">
            Your league commissioner will choose which PGA TOUR events are included in your Season Draft fantasy golf season. LIV golf events are not allowed.
          </li>
          <li className="mb-2">
            Each week, the earnings from the golfers on your team contribute to your overall season total.
          </li>
          <li className="mb-2">
            Once per year you can request a substitution of your alternate for an active golfer. To process the alternate, submit a request to your commissioner, specifying the final tournament for the active golfer you wish to replace. Any earnings accumulated by the replaced golfer will remain in your team's total, but only up until his last tournament. After that event, your alternate will start contributing to your total.
          </li>
          <li className="mb-2">
            The season winner is determined by the team with the highest total earnings over the entire season.
          </li>
        </ol>
        </Col>
      </Row>
      {(!props.embedIn || props.embedIn !== 'COMMISS') &&
      <Row className="pt-2 text-center">
        <Col>
          <p>
            <Link className="" to="/games">{'<< All Games'}</Link>
          </p>
        </Col>
      </Row>
      }
      {!props.auth &&
      <div className="text-center">
        <Link to={"/createleague"} replace style={{color: "inherit", textDecoration: "none", cursor: "inherit"}} className="ml-auto">
          <Button  size="md" color="primary">
              CREATE A LEAGUE
          </Button>
        </Link>
      </div>
      }
  </div>);

export class SeasonDraftGameRuleUnauth extends React.Component {

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <LandingWrapper {...this.props}>
        <SeasonDraftGameRuleContent auth={false}/>
      </LandingWrapper>
    )
  }
}

export const SeasonDraftGameRuleAuth = (props) => (
  <Container style={{paddingTop:"4rem", paddingBottom:"2rem"}}>
    <SeasonDraftGameRuleContent {...props} auth={true}/>
  </Container>
);
