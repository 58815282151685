import React from 'react';

import {
  Container,
  Row,
  Col,
} from 'reactstrap';

import {
  withRouter
} from 'react-router-dom';

import {OnboardingWrapper} from 'common/LandingWrapper.js';
import CreateCharityLeague from 'pages/user/CreateCharityLeague';

import {logout} from 'utils/api';



class UnauthedCreateCharityLeague extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    };

  }

  componentDidMount() {
      let postLoginURL = this.props.postLoginURL || '';
      console.log('postLoginURL :' + postLoginURL);
      if (postLoginURL === '/'){
        logout((response) => {
            this.props.loggedOutCallback();
          }, (error) => {
            console.log(error);
            console.log("Error occurred trying to logout!");
        });
      }else if (postLoginURL !== '/createcharityleague'){
        this.props.setPostLoginURL('/createcharityleague');
      }

  }



  render() {

    return (
      <OnboardingWrapper {...this.props}>
        <Container fluid className="joinContainer">
          <Row noGutters>
            <Col className="mx-auto">
              <CreateCharityLeague {...this.props} {...this.params} authed={false}/>
            </Col>
          </Row>
        </Container>
      </OnboardingWrapper>
    );
  }
}

export default withRouter(UnauthedCreateCharityLeague);
