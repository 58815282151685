import React from 'react';
import {
  Row,
  Button,
  Card,
  Input,
} from 'reactstrap';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'common/ConfirmAlert.css';

import {
  MobileHeader,
  ContentWrapper
} from 'common/CommissionerComponents.js';



import {
  get_charity_league,
  update_charity_league,
} from 'utils/api.js';

export default class FoundationAgreement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        consent_name: '',
        db_consent_name: '',
    };
  }

  componentDidMount() {
    this.getCharityLeague(this.props.activeLeague);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.activeLeague === nextProps.activeLeague){return;}
    this.getCharityLeague(nextProps.activeLeague);
  }


  handleChange: Function = (event) => {
    this.setState({[event.target.name]: event.target.value});
  }

  getCharityLeague: Function = (league) => {
    get_charity_league(league,
    ({success, error, charity}) => {
      if (success) {
        this.setState({db_consent_name:charity.consent_name, ...charity});
      } else {
        console.log(error);
      }
    }, (error) => {
      console.log(error);
    });
  }

  saveCharityLeague: Function = (fldNm) => {
    if (!this.state.consent_name || this.state.consent_name.trim() === ''){
      confirmAlert({
        title: 'Alert',
        message: 'Please provide an authorized representative name.',
        buttons: [{label: 'OK',onClick: () => {}}]
      });
      return;
    }
    update_charity_league(this.state.charityid,fldNm, this.state.consent_name,
    ({success, error, charity}) => {
      if (success) {
        this.setState({db_consent_name:charity.consent_name, consent_name:charity.consent_name});

      } else {
        console.log(error);
      }
    }, (error) => {
      console.log(error);
    });
  }

  render() {
    return (
      <div>
        <MobileHeader to="Foundation Agreement" leagueid={this.props.activeLeague} />
        <ContentWrapper>
          <div className="mb-2" style={{fontSize: "1.75em", fontWeight: 300}}>
            {'Foundation Agency Agreement'}
          </div>

          <div className="foundationContent mx-3" >
            <Row className="pb-2 pl-1">
              <p>Majors Challenge, a website and business entity managed by Roxi Fantasy Gaming, LLC, enables 501(c)(3) non-profit organizations to host fundraisers through fantasy golf pools during PGA TOUR tournaments. To use the Majors Challenge platform, foundations must hold 501(c)(3) status.</p>
            </Row>
            <Row className="py-2 pl-1" style={{fontSize: "1rem", overflow:"auto", backgroundColor:"white"}}>
              <div style={{maxHeight: "200px"}}>
                <p><h5>AGENCY AGREEMENT</h5></p>
                <p>This AGENCY AGREEMENT (“Agreement”) is entered into between a 501(c)3 registered non-profit organization ("Foundation") and MAJORS CHALLENGE, a New Jersey limited liability company ("Agent") with a principal place of business at 95 W. Main St Suite 5-171, Chester NJ 07930. Foundation and Agent are each a “Party” and collectively the “Parties”. This Agreement will be effective as of the date at which the Foundation indicates its acceptance during the registration process on the Agent's website. </p>
                <p>WHEREAS, Foundation needs the assistance of Agent to collect and administer charitable donations made to Foundation, as well as to distribute such charitable donations as directed by the individuals and/or entities making such charitable donations ("Donor”) subject to the terms herein.</p>
                <p>WHEREAS, Foundation wishes to appoint Agent as its exclusive agent to represent Foundation with respect to the Services; </p>
                <p>WHEREAS, Agent accepts the appointment to serve as Foundation’s non-exclusive agent with respect to the Services, subject to the terms and conditions of this Agreement; </p>
                <p>NOW, THEREFORE, in consideration of the promises and mutual covenants contained herein, the Parties intending to be legally bound hereby agree to the following terms:</p>
                <ul>
                  <li>Engagement. The Foundation hereby engages the Agent, and the Agent hereby agrees to provide Services to the Foundation, upon the terms and conditions set forth in this Agreement.</li>
                  <li>Acceptance. The Agent reserves the right to accept or reject the Foundation's request to run a fundraiser at its sole discretion. The Foundation may be turned down for any reason and without explanation.</li>
                  <li>Appointment, Scope, and Responsibilities of Agent: The Foundation hereby appoints the Agent to perform the Services on its behalf, which include collecting donations from Donors through their participation in a golf pool hosted on the Agent's website. Upon registration, the Foundation will be provided with a unique web address to promote its golf pool. Donors participating in the Foundations golf pool may contribute funds to the Foundation in conjunction with their entry. </li>
                  <li>Responsibilities and Liabilities. The Foundation assumes the entire responsibility and liability for running a fundraiser golf pool on the Agent's platform, and shall indemnify, defend, and forever hold harmless the Agent and its agents, employees, officers, and directors from and against all claims, damages, losses, costs, and expenses, including reasonable attorney's fees and costs arising out of, in connection with, or resulting from operating the golf pool.</li>
                  <li>Collection and Distribution of Donations. The Foundation agrees to allow the Agent to collect donations on its behalf in connection with the golf pool. Donations are paid by Donors to the Agent. Upon completion of the golf tournament associated with the fundraiser, a donation will be made to the Foundation in the name of the Agent in the amount equal to 90% of donations successfully cleared through the Agent's credit card agency or bank.</li>
                  <li>Compensation. Agent shall receive payment of 10% of the total donation amount as compensation for the Services detailed herein. Agent is responsible for paying all of Agent’s taxes including but not limited to United States Federal and any and all State Income Tax, Social Security Tax, Workman’s Compensation Tax, Federal Insurance Contribution Act, Federal Unemployment Tax Act, and all other payroll taxes and all State and/or local taxes. Neither Federal, nor State, nor local income tax of any kind shall be withheld or paid by Foundation on behalf of Agent or the employees of Agent. Foundation will receive an IRS 1099 statement and related tax statements and will be required to file corporate tax returns and to pay taxes in accordance with all provisions of applicable Federal and State law.</li>
                  <li>Relationship Created. Agent will perform all Services strictly as an independent contractor and will not be considered an employee of Company. Nothing in this Agreement shall be interpreted or construed as creating, establishing, continuing, or reestablishing any employer-employee. Agent shall be solely responsible for supervising its own activities.</li>
                  <li>Representation and Benefactor. The Agent and its agents, employees, officers, and directors do not advocate for and are not a representative or benefactor of the Foundation.</li>
                  <li>Success or Failure of Fundraiser. The Agent does not participate in and is not responsible for the success or failure of any fundraiser. The Foundation understands that there is no guarantee any money will be raised as a result of their fundraising on the Agent's platform.</li>
                  <li>User Accounts. Any and all user accounts having already existed or newly created in relation to the Foundation's golf pool belong exclusively to the Agent.</li>
                  <li>Prizes and Free-to-Play Option. If the Foundation intends to reward participants with prizes or offer any benefit to play in or win the golf pool, a free-to-play option must be available that does not require a donation. Making a donation does not increase a participant's chance of winning, and winning entries may result from non-donors.</li>
                  <li>Trademarks/Intellectual Property. Foundation shall not have the right to use the Marks other than for advertising only in performance of this Agreement and shall not use any confusingly similar trademarks or service marks in connection with any goods or services. Foundation shall at all times use each of the Marks in its proper trademark sense, acknowledging that it is the registered trademark of Agent and shall provide appropriate (as determined by Agent) trademark notices with respect thereto. Foundation shall comply with all guidelines issued by Agent from time to time for use of the Marks. Marks means the name Majors Challenge and includes but is not limited to the stylized design logo, which are the registered trademarks of Agent, and any other trademarks, whether or not registered, which Agent may use in connection with Products (or any other Products of Agent) from time to time.</li>
                  <li>Reporting and Tax Receipts. Foundation may receive donations through the Agent website and will be provided with the name, email address and amount donated to allow the Foundation to provide tax receipts to Donors. Agent does not issue tax receipts, nor does it represent or warrant that donations will be tax deductible under the law. Agent does not provide legal or tax advice, and nothing contained herein should be construed as such.</li>
                  <li>Notices. Any notice, demand, or request required or permitted to be given hereunder shall be in writing and shall be deemed effective on personal delivery or seventy-two (72) hours after having been deposited in the United States mail, postage prepaid, registered or certified, and addressed to the addressee, at his or its main office, as set forth in the Preamble above. Any Party may change his or her address for purposes of this Agreement by written notice given in accordance herewith.</li>
                  <li>Financial Information. Agent will require billing and payment information such as credit and debit card information when a Donor makes a donation in connection with a charity fundraiser. However, all payment transactions entered into on the Website are processed by a third-party Payment Processor, currently authorize.net. Donors will provide payment information to the Payment Processor, and that Payment Processor’s Privacy Policy and Terms and Conditions of Use will govern use and disclosure of that information. We encourage Users to carefully read the Payment Processor’s Privacy Policy and Terms and Conditions of Use which can be accessed from its website. We do not use or store financial information. Agent strictly disclaims any and all liability regarding a breach of any financial information.</li>
                  <li>Limitation of Liability. a. Disclaimer. EXCEPT AS EXPRESSLY PROVIDED IN THIS AGREEMENT, AGENT MAKES NO WARRANTY, EXPRESSED OR IMPLIED, WITH RESPECT TO THIS AGREEMENT, THE PRODUCTS OR SERVICES RENDERED HEREUNDER, INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTY OF MERCHANTABILITY OR FITNESS FOR ANY PARTICULAR PURPOSE. b. Total Liability. Agent’s liability hereunder for damages derived or caused from the negligence of the Agent for any claim arising out of this Agreement or the brokerage of any Products or performance of any Services hereunder shall not exceed the total amount paid to Agent for those Products or Services defined in this Agreement. c. Indemnification. Agent shall have no liability to Foundation for Products brokered or procured under this Agreement. Agent shall have no obligation or liability to perform any duty other than the Services described in this Agreement.</li>
                  <li>Legal Consultation. It is advised that the Foundation consult with its own attorney before running its first golf pool fundraiser.</li>
                  <li>Term and Termination. This Agreement shall commence on the date at which Foundation indicates its acceptance and shall continue until terminated by either party upon written notice to the other party.</li>
                  <li>Governing Law and Jurisdiction. This Agreement shall be construed and enforced in accordance with the laws of the State of New Jersey. Any judicial proceeding brought against any of the Parties to this Agreement on account of any dispute arising out of this Agreement, or any matter related hereto, shall be brought in the state courts of County of Morris and the State of New Jersey, and, by execution and delivery of this Agreement, each of the parties to this Agreement submits and consents to the exclusive jurisdiction of such courts, and irrevocably agrees to be bound by any judgment rendered thereby in connection with this Agreement. If either Party shall institute an action against the other Party for breach of this contract, the unsuccessful Party shall pay court costs and reasonable attorney's fees to the successful Party.</li>
                  <li>Captions. Captions and headings used herein are for convenience only and shall not be used in construing or interpreting this Agreement.</li>
                  <li>No Third-Party Beneficiaries. Each of the provisions of this Agreement is for the sole and exclusive benefit of the Parties hereto, respectively, as their interests appear, and shall not be deemed for the benefit of any other person.</li>
                  <li>Validity. The failure of any Party to enforce at any time any of the provisions of this Agreement shall in no way be deemed a waiver of any such provision, nor in any way affect the validity of this Agreement or any part thereof.</li>
                  <li>Successors and Assigns. This Agreement shall be binding and inure to the benefit of the successors and assigns of the Parties hereto.</li>
                  <li>Force Majeure. Neither Party shall be liable or responsible to the other Party nor be deemed to have defaulted under or breached the Agreement for failure or delay in fulfilling or performing any obligation under this Agreement if the delay or failure is caused by (a) governmental action, laws, orders, directions, regulations, or requests, or (b) as a result of events, such as war, acts of public enemies, terrorism, fires, floods, pandemic, endemic, acts of God or any causes beyond the reasonable control of such Party. The delaying Party shall be entitled to a reasonable extension of time for the fulfillment of such obligations The Parties shall mutually seek a resolution of the delay or the failure to perform.</li>
                  <li>Entire Agreement. This Agreement constitutes the entire agreement between the parties hereto with respect to the subject matter hereof and supersedes all prior agreements, understandings, negotiations, and discussions, whether oral or written, between the parties relating to the subject matter hereof.</li>
                  <li>Severability. Any provision of this Agreement that is prohibited or unenforceable shall not invalidate any of the other provisions hereof and such other provisions shall remain in full force and effect.</li>
                  <li>Survival. Any provision of this Agreement that expressly or by implication is intended to survive termination, regardless of the date, cause or manner of such termination, and including but not limited to rights of action accruing prior to termination and payment obligations, will survive such termination and will continue in full force and effect. </li>
                  <li>Construction. This Agreement shall not be construed against the Party preparing it, but shall be construed as if it was jointly prepared and negotiated by the Parties and all persons and entities affected hereby. Any ambiguity or uncertainty shall not be interpreted against any person or entity. Neither Party was coerced, under undue influence, nor in any way forced to enter into this Agreement and both Parties had the opportunity to have this Agreement reviewed by counsel of their own choosing. </li>
                  <li>Legitimacy and Confirmation of 501(c)(3) Status. Foundation agrees to submit all required information to Agent, including but not limited to tax ID and 501(c3) Determination Form to allow Agent to verify Foundation’s tax status, and certifies this information to be accurate and representative of their legal tax status. If at any time Agent’s 501c3 status changes, Agent is responsible for informing Foundation before receiving future donations. </li>

                </ul>
              </div>
            </Row>
            <Row className="pt-3">
              <p>By signing this agreement you attest to operate your golf pool in accordance with all local, state and federal laws and consent to all the terms and conditions in this agreement. Furthermore, you attest to have read, understand and agree to comply with our Terms of Service and Privacy Policy.</p>
            </Row>

            {this.state.db_consent_name &&
              <Row>
                <strong>Authorized Representative Name: </strong>&nbsp;&nbsp;{this.state.consent_name}
              </Row>
            }
            {!this.state.db_consent_name &&
            <Row>
              <Input type="text" placeholder="Authorized Representative Name" name="consent_name"
                value={this.state.consent_name} onChange={this.handleChange} style={{width:'350px'}}/>
            </Row>
            }
            <Row>
            <div className="my-2 mx-1">
              <div className="mb-1"><strong>{this.state.name}</strong></div>
              <div style={{fontSize:'0.9em'}}>{this.state.contact_name}</div>
              <div style={{fontSize:'0.9em'}}>{this.state.contact_email}</div>
              <div style={{fontSize:'0.9em'}}>{this.state.contact_ph_no}</div>
              <div style={{fontSize:'0.9em'}}>{this.state.address}</div>
              <div style={{fontSize:'0.9em'}}>Tax ID: {this.state.tax_id}</div>
            </div>
            </Row>
            {!this.state.db_consent_name &&
            <Row className="py-2">
              <Button color="primary"  className="mx-1" onClick={()=>this.saveCharityLeague('consent_name')} >
                {'SUBMIT CONSENT'}
              </Button>
            </Row>
            }
          </div>
        </ContentWrapper>
      </div>
    )
  }
}
