import React from 'react';
import {
  Row,
  Col,
  Table,
} from 'reactstrap';

import FontAwesome from 'react-fontawesome';

import { get_golfer_scorecard } from 'utils/api.js';

import 'common/GolferModal.css';

export default class GolferScoreCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      golferid: props.golferid,
      eventid: props.eventid,
      loaded: false,
      scorecards: null,
    };

    this.fetchGolferScoreCard = this.fetchGolferScoreCard.bind(this);
  }
  componentDidMount() {
    this.fetchGolferScoreCard();
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.golferid !== this.state.golferid ||
       nextProps.eventid !== this.state.eventid) {
      this.setState({
        golferid: nextProps.golferid,
        eventid: nextProps.eventid,
        loaded: false,
        scorecards: null,
      }, () => this.fetchGolferScoreCard());
    }
  }
  fetchGolferScoreCard() {
    if (!this.state.golferid) {
      return;
    }
    get_golfer_scorecard(this.state.golferid, this.state.eventid, (scorecards) => {
      //alert(scorecards);
      this.setState({scorecards: scorecards, loaded: true});
    }, (error) => {
      this.setState({loaded: true, error: 'Scorecard Information for the chosen golfer is currently unavailable.'});
    });
  }

  renderWithoutChosenGolfer() {
    return (

        <div className="golferModal text-center py-2">
          <FontAwesome name="spinner" size="4x" spin />
        </div>

    )
  }
  renderGolferUnavailable() {
    return (

        <div className="golferModal text-center py-5">
          {this.state.error}
        </div>

    )
  }


  render() {
    let r1Data = [];
    if (this.state.scorecards && this.state.scorecards.r1){
      r1Data = this.state.scorecards.r1;
    }
    let r2Data = [];
    if (this.state.scorecards && this.state.scorecards.r2){
      r2Data = this.state.scorecards.r2;
    }
    let r3Data = [];
    if (this.state.scorecards && this.state.scorecards.r3){
      r3Data = this.state.scorecards.r3;
    }
    let r4Data = [];
    if (this.state.scorecards && this.state.scorecards.r4){
      r4Data = this.state.scorecards.r4;
    }
    let scoreClassMap ={
      '1':'birdie',
      '2':'eagle',
      '3':'eagle',
      '4':'eagle',
      '5':'eagle',
      '6':'eagle',
      '7':'eagle',
      '8':'eagle',
      '-1':'bogey',
      '-2':'bogeyDouble',
      '-3':'bogeyDouble',
      '-4':'bogeyDouble',
      '-5':'bogeyDouble',
      '-6':'bogeyDouble',
      '-7':'bogeyDouble',
      '-8':'bogeyDouble',
    };
    var rows = [];
    rows.push({
              "number": "Hole",
              "par": "Par",
              "yardage": "",
              "r1": "R1",
              "r2": "R2",
              "r3": "R3",
              "r4": "R4",
              "className": "",
              "r1ClassName": "",
              "r2ClassName": "",
              "r3ClassName": "",
              "r4ClassName": "",
            });

    for (var i = 0; i < r1Data.length; i++) {
        let r1 = (r1Data[i].strokes===0?'':r1Data[i].strokes),
        r2 = (r2Data[i] ? (r2Data[i].strokes===0?'':r2Data[i].strokes):'-'),
        r3 = (r3Data[i] ? (r3Data[i].strokes===0?'':r3Data[i].strokes):'-'),
        r4 = (r4Data[i] ? (r4Data[i].strokes===0?'':r4Data[i].strokes):'-');
        rows.push({
                  "number": r1Data[i].number,
                  "par": r1Data[i].par,
                  "yardage": r1Data[i].yardage,
                  "r1": r1,
                  "r2": r2,
                  "r3": r3,
                  "r4": r4,
                  "className": "",
                  "r1ClassName": (r1==='' || r1==='-')?'':scoreClassMap[parseInt(r1Data[i].par)-parseInt(r1)],
                  "r2ClassName": (r2==='' || r2==='-')?'':scoreClassMap[parseInt(r1Data[i].par)-parseInt(r2)],
                  "r3ClassName": (r3==='' || r3==='-')?'':scoreClassMap[parseInt(r1Data[i].par)-parseInt(r3)],
                  "r4ClassName": (r4==='' || r4==='-')?'':scoreClassMap[parseInt(r1Data[i].par)-parseInt(r4)],
                });
        if (i===8 || i===17){
          let a=0, b=0, c=0, d=0, e=0;
          let x = (i===8?1:11);
          for (var j = x; j < rows.length; j++) {
            a = a + (parseInt(rows[j].par)||0);
            b = b + (parseInt(rows[j].r1)||0);
            c = c + (parseInt(rows[j].r2)||0);
            d = d + (parseInt(rows[j].r3)||0);
            e = e + (parseInt(rows[j].r4)||0);
          }
          rows.push({
                    "number": (i===8?"OUT":"IN"),
                    "par": a,
                    "yardage": "",
                    "r1": b,
                    "r2": c,
                    "r3": d,
                    "r4": e,
                    "className": "scoreColSpcl"
                  });
          if (i===17){
            rows.push({
                      "number": "TOT",
                      "par": (rows[10]?((parseInt(rows[10].par)||0) + (parseInt(rows[20].par)||0)):'-'),
                      "yardage": "",
                      "r1": (rows[10]?((parseInt(rows[10].r1)||0) + (parseInt(rows[20].r1)||0)):'-'),
                      "r2": (rows[10]?((parseInt(rows[10].r2)||0) + (parseInt(rows[20].r2)||0)):'-'),
                      "r3": (rows[10]?((parseInt(rows[10].r3)||0) + (parseInt(rows[20].r3)||0)):'-'),
                      "r4": (rows[10]?((parseInt(rows[10].r4)||0) + (parseInt(rows[20].r4)||0)):'-'),
                      "className": "scoreColSpcl"
                    });
          }
        }
    }

    const holeRow = rows.map((item, index) => {
      return (<td class="scoreCardHoleRow">{item.number}</td>)
    });

    const parRow = rows.map((item, index) => {
        return (<td className={item.className}>{item.par}</td>)
    });

    const score1Row = rows.map((item, index) => {
        const cls = item.className + ' ' + item.r1ClassName;
        return (<td className={cls}>{item.r1}</td>);
    });

    const score2Row = rows.map((item, index) => {
        const cls = item.className + ' ' + item.r2ClassName;
        return (<td className={cls}>{item.r2}</td>)
    });

    const score3Row = rows.map((item, index) => {
        const cls = item.className + ' ' + item.r3ClassName;
        return (<td className={cls}>{item.r3}</td>)
    });

    const score4Row = rows.map((item, index) => {
        const cls = item.className + ' ' + item.r4ClassName;
        return (<td className={cls}>{item.r4}</td>)
    });

    return (
        <div class="scoreCardContainer">
        {this.state.scorecards && this.state.scorecards.r1 &&
          <Row noGutters className="py-1">
            <Col xs="7" className="d-flex justify-content-start">
              <div class="scoreCardHeader">Score Card</div>
            </Col>
            <Col xs="5" className="d-flex justify-content-end">
              <Table className="scoreCardLegendTable">
              <tbody>
                <tr>
                  <td className="eagle"></td>
                  <td>Eagle</td>
                  <td className="birdie"></td>
                  <td>Birdie</td>
                  <td className="bogey"></td>
                  <td>Bogey</td>
                  <td className="bogeyDouble"></td>
                  <td>Double Bogey+</td>
                </tr>
              </tbody>
              </Table>
            </Col>
          </Row>
          }
          {this.state.scorecards && this.state.scorecards.r1 &&
            <Table className="scoreCardTable mb-1" striped>
            <tbody>
              <tr>{holeRow}</tr>
              <tr>{parRow}</tr>
              <tr className="scoreCardScoreRow">{score1Row}</tr>
              <tr className="scoreCardScoreRow">{score2Row}</tr>
              <tr className="scoreCardScoreRow">{score3Row}</tr>
              <tr className="scoreCardScoreRow">{score4Row}</tr>
            </tbody>
            </Table>
          }
      </div>
    )
  }
}
