import React from 'react';
import Select from 'react-select';

import {
  Alert,
  Badge,
  Card,
  Collapse,
  Row, Col,
  Input,
} from 'reactstrap';

import { NotMobilePortrait, MobilePortrait } from 'utils/responsive.js';

import {
  NavLink
} from 'react-router-dom';

import FontAwesome from 'react-fontawesome';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'common/ConfirmAlert.css';

import { Bootstrap2Toggle } from 'react-bootstrap-toggle';
import "utils/bootstrap2-toggle.css";

import {
  MobileHeader,
  ContentWrapper
} from 'common/CommissionerComponents.js';


import { get_toc_data, league_in_toc, event_in_toc, set_toc_fld_value,} from 'utils/api.js';

export default class Toc extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      toc: false,
      howToOpen: false,
    };


  }

  componentDidMount() {
    if (this.props.activeLeague === null) { return; }

    this.fetchTocData(this.props.activeLeague);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.activeLeague === nextProps.activeLeague) { return; }

    this.fetchTocData(nextProps.activeLeague);
  }


  fetchTocData = (league) => {
    if (!league) { return; }

    get_toc_data(league,
    ({success, toc, numQualifiers, msg, events, finalLeagueEventId, error}) => {
      if (success) {
        if (events){
          events.map((x) => x.actionsOpen = false);
        }
        this.setState({toc, howToOpen: !toc, msg, numQualifiers, events, finalLeagueEventId, });
      } else {
        console.log(error);
      }
    }, (error) => {
      console.log(error);
    });
  }


  handleTocEnroll = (toc) => {
    if (toc === this.state.toc) { return; }

    const leaguein_toc = () => {
      const league = this.props.activeLeague;
      league_in_toc(league, toc,
        (success, error) => {
          if (success) {
            this.fetchTocData(league);
          } else {
            console.log(error);
          }
      }, (error) => {
        console.log(error);
      });
    }

    if (!toc){
      confirmAlert({
        title: 'Confirmation',
        message: 'Are you sure you want to cancel the Tournament of Champions season?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => { leaguein_toc();}
          },
          {
            label: 'Cancel',
            onClick: () => { }
          }
        ]
      });
    }else{
      leaguein_toc();
    }
  }

  toggleEventRowActions = (eventid) =>{
    let events = this.state.events;
    if (!events) { return; }
    const mtchEvent = events.find(({id}) => id === eventid);
    mtchEvent.actionsOpen = !mtchEvent.actionsOpen;
    this.setState({
      events:events,
    })
  }


  handleEventToggle = (eventid, toc) => {
    if (!this.state.events) { return; }
    if (this.state.events.find(({id, toc:currToc}) => id === eventid && toc === currToc)) { return; }

    const league = this.props.activeLeague;

    event_in_toc(league, eventid, toc,
      (success, error) => {
        if (success) {
          this.fetchTocData(league);
        } else {
          console.log(error);
        }
    }, (error) => {
      console.log(error);
    });
  }

  toggleHowTo = () => {
    this.setState({
      howToOpen: !this.state.howToOpen,
    })
  }


  handleInputValueChange = (event) => {
    //this.setState({[event.target.name]: event.target.value});
    this.saveTocFieldValue(event.target.name,event.target.value);
  }


  saveTocFieldValue = (fldNm, fldVal) => {
    this.setState({[fldNm]: fldVal});
    set_toc_fld_value(this.props.activeLeague, fldNm, fldVal,
    ({success, error}) => {
      if (success) {

      } else {
        console.log(error);
      }
    }, (error) => {
      console.log(error);
    });
  }


  render() {
    return (
      <div className="commissionerMembers">
        <MobileHeader to="Tournament of Champions" leagueid={this.props.activeLeague} />
        <ContentWrapper>
          <TocHeader />
          <TocSubheader howToOpen={this.state.howToOpen} toggle={this.toggleHowTo}/>

          <TocParticipation {...this.state} leagueid={this.props.activeLeague}
            handleTocEnroll={this.handleTocEnroll}  handleInputValueChange={this.handleInputValueChange}
            saveTocFieldValue={this.saveTocFieldValue}
            />




        </ContentWrapper>

      </div>
    );
  }
}

const TocHeader = () =>
  <div className="d-flex mb-4">
    <div className="d-none d-lg-block mr-auto" style={{fontSize: "1.75em", fontWeight: 300}}>Tournament of Champions</div>
  </div>

const TocSubheader = (props) =>
  <Alert color="success" className="my-4">
    <div className="d-flex justify-content-between py-1 font-weight-bold" onClick={props.toggle}>
      Tournament of Champions
      <FontAwesome size="lg" name={props.howToOpen ? "angle-up" : "angle-down"} />
    </div>
    <Collapse isOpen={props.howToOpen}>
      <span style={{"fontSize": "0.80rem", whiteSpace: "pre-line"}}>
      The Tournament of Champions is a season-long format where the top finishers in each tournament qualify to play in a season ending championship. If a player qualifies in multiple tournaments then they would receive multiple entries into the championship. For some leagues this may work better than The Cup because someone only playing in a late season event can still qualify for the championship tournament.
      </span>
    </Collapse>
  </Alert>

const TocParticipation = (props) =>{
  let selectedValue = null;
  let eventOptions = [];
  if (props.events){
    props.events.forEach((event, index) => {
      const {name, dates, course, major, status} = event;
      let option = { value: event.id,
                    label:
                      <div>
                          <div style={{color: "#555", fontWeight:700}}>
                            <span className="text-nowrap">{name}</span>
                            { major &&
                              <Badge color="success" style={{marginLeft: "0.5rem"}}>Major</Badge>
                            }
                            { status === "open" &&
                              <Badge color="warning" style={{marginLeft: "0.5rem"}}> Open </Badge>
                            }
                            { ["round1", "round2", "round3", "round4"].indexOf(status) !== -1 &&
                              <Badge color="info" style={{marginLeft: "0.5rem"}}> Live </Badge>
                            }
                          </div>
                          <div style={{color: "#aaa", fontSize: ".75em"}}>{dates} &bull; {course}</div>
                      </div>
                    };
      if (props.finalLeagueEventId && event.id === props.finalLeagueEventId){
        selectedValue = option;
      }
      eventOptions.push(option);
    });
  }

  let finalEventCls = props.finalLeagueEventId ? 'text-nowrap mt-4': 'text-nowrap mt-2';

  return(
  <Card body className="p-2 my-4">
    <div className="pl-2 py-2 d-flex align-items-center border-bottom">

        <div className="col-sm-8 pt-1">
          {'Participate in the Tournament of Champions?'}
        </div>
        <div className="col-sm-4 d-flex justify-content-end">
          <Bootstrap2Toggle
            onClick={(state)=>{props.handleTocEnroll(state)}}
            on={'YES'}
            off={'NO'}
            size="sm"
            offstyle="danger"
            onstyle="success"
            active={props.toc}
          />
        </div>

    </div>
    {props.toc &&
        <div className="pl-4 pt-1">
          <Row noGutters className="py-1 mr-1">
            <Col xs="8" className="">
              <div className="text-nowrap mt-2" style={{fontSize:'0.95rem'}}>Number of Qualifiers Per Tournament</div>
              <div style={{fontSize: "0.75em", color: "#888"}} className="">
                {'How many teams per tournament should qualify for the season ending Tournament of Champions?'}
              </div>
            </Col>
            <Col xs="4" className="d-flex justify-content-end">
              <Input placeholder="1" value={props.numQualifiers} name="numQualifiers" style={{maxWidth: '80px'}}
              onChange={props.handleInputValueChange} type="number"/>
            </Col>
          </Row>
          <MobilePortrait>
          <>
            <Row noGutters className="py-1 mr-1">
              <Col className="">
                <div className="text-nowrap mt-2" style={{fontSize:'0.95rem'}}>Championship Tournament</div>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex justify-content-start">
                <div style={{width:'100%'}}>
                  <Select className="no-border my-2" isClearable isSearchable options={eventOptions}
                  value={selectedValue}
                  placeholder = {"Final event"}
                  onChange={(selectedOption) => {
                      console.log(selectedOption);
                      if (selectedOption){
                        props.saveTocFieldValue('finalLeagueEventId', selectedOption.value);
                      }else{
                        props.saveTocFieldValue('finalLeagueEventId', null);
                      }
                    }
                  } />
                </div>
              </Col>
            </Row>
            </>

          </MobilePortrait>

          <NotMobilePortrait>
            <Row noGutters className="py-1 mr-1">
              <Col xs="8" className="">
                <div className={finalEventCls} style={{fontSize:'0.95rem'}}>Championship Tournament</div>
              </Col>
              <Col xs="4" className="d-flex justify-content-end">
                <div style={{width:'95%'}}>
                <Select className="no-border my-2" isClearable isSearchable options={eventOptions}
                value={selectedValue}
                placeholder = {"Final event"}
                onChange={(selectedOption) => {
                    console.log(selectedOption);
                    if (selectedOption){
                      props.saveTocFieldValue('finalLeagueEventId', selectedOption.value);
                    }else{
                      props.saveTocFieldValue('finalLeagueEventId', null);
                    }
                  }
                } />
                </div>
              </Col>
            </Row>
          </NotMobilePortrait>

          <Row noGutters className="py-1 mr-1">
            <Col xs="12" className="">
              <div className="mb-2" style={{fontSize: ".95rem"}}>
                {'Tournament of Champions Announcement'}
              </div>
              <Input type="textarea" placeholder="Message to display." style={{fontSize:'.8rem'}} name="msg"
                value={props.msg} onChange={props.handleInputValueChange} rows="6"/>
              <div style={{fontSize: "0.75em", color: "#888"}} className="my-2">
                {''}
              </div>
            </Col>
          </Row>

         <MoveToTournaments leagueid={props.leagueid}/>
        </div>
    }
  </Card>
  );
}

const MoveToTournaments = (props) =>
  <Card body className="p-0 mb-4 mt-0" style={{border:"0px solid",backgroundColor:"inherit"}}>
    <div className="pl-2 d-flex align-items-center" style={{fontSize: '0.9em'}}>
    <span>Add more events from </span>&nbsp;
    <NavLink
      to={"/commissioner/" + props.leagueid + "/events"}
      exact={true}
      className="pt-2 pb-2"
      activeClassName="active">
      <div>Tournaments</div>
    </NavLink>
    </div>
  </Card>
