import React from 'react';

import {
  Button,
  Card, CardHeader, CardBody,
  Collapse,
  Table,Row, Col,
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';

import {Link} from 'react-router-dom';

import FontAwesome from 'react-fontawesome';
import numeral from 'numeral';

import { get_moneyleaders } from 'utils/api.js';

import 'pages/user/Leaderboard.css';

export default class MoneyLeaderboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: null,
      seasonOptions: [],
      moneyLeaders: [],
      hideThis: false,
      season: 0,
      seasonDropdownOpen: false,
      showAll: (props.showAll?props.showAll:false),
    };
    this.fetchMoneyLeaderboardsData = this.fetchMoneyLeaderboardsData.bind(this);
    this.toggleSeasonDropdown = this.toggleSeasonDropdown.bind(this);
    this.toggleShowAll = this.toggleShowAll.bind(this);
    this.setSeason = this.setSeason.bind(this);

  }


  fetchMoneyLeaderboardsData(league, season) {
      get_moneyleaders(league, season,
        ({success, error, ...data}) => {

          if (!success) {
            this.setState({loading: false, error});
            return;
          }
          data.moneyLeaders.sort((a,b) => {
              return b.winningAmt - a.winningAmt;
          });

          this.setState({hideThis:data.hideThis,
                        season:data.season,
                        seasonOptions:data.seasonOptions,
                        moneyLeaders:data.moneyLeaders,
                        loading: false,
                        error: null,});

        },
        (error) => {
          console.log('error getting leaderboard: ' + error);
          this.setState({ loading: false, error: 'The leaderboard failed to load, possibly due to a network problem. Please refresh the page to reload the leaderboard.' });
        }
      )
  }

  componentDidMount() {
    let paramLeague = (this.props.match ? parseInt(this.props.match.params.league, 10):this.props.activeLeague);

    if (this.props.activeLeague !== paramLeague) {
      this.props.selectLeague(paramLeague);
      //this.fetchMoneyLeaderboardsData(paramLeague, 0);
    } else {
      this.fetchMoneyLeaderboardsData(this.props.activeLeague, 0);
    }

  }

  componentWillReceiveProps(nextProps) {
      if (nextProps.activeLeague !== this.props.activeLeague) {
        //this.setState({showAll:false,season:0});
        this.fetchMoneyLeaderboardsData(nextProps.activeLeague,0)
      }
  }

  toggleSeasonDropdown() {
    this.setState({
      seasonDropdownOpen: !this.state.seasonDropdownOpen,
      //showAll:false
    });
  }

  toggleShowAll() {
    this.setState({
      showAll: true
    });
  }

  setSeason(season){
    this.setState({season:season});
    this.fetchMoneyLeaderboardsData(this.props.activeLeague, season);
  }

  render() {
    if (this.state.loading) {
      return null;
    }
    if (this.state.error) {
      return null;
    }
    if (this.state.hideThis) {
      return null;
    }

    let divStyle = {paddingTop:(this.props.showAll?'60px':'0px')};
    return (
        <div className="" style={divStyle}>
          <Row noGutters className="pt-4 d-flex justify-content-center">
            <Col lg="7">
            <MoneyLeader activeLeague = {this.props.activeLeague}
                        data = {this.state.moneyLeaders}
                        season = {this.state.season}
                        setSeason = {this.setSeason}
                        seasonOptions = {this.state.seasonOptions}
                        seasonDropdownOpen={this.state.seasonDropdownOpen}
                        toggleSeasonDropdown={this.toggleSeasonDropdown}
                        showAll={this.state.showAll}
                        toggleShowAll={this.toggleShowAll}/>
            </Col>
          </Row>
      </div>
    );
  }
}


const MoneyLeader = (props) => {

  const moneyLeaderRows = [];
  const maxRowsAllow = (props.showAll?99999:3);
  props.data.forEach((item, index) => {
    if (index<maxRowsAllow){
      moneyLeaderRows.push(<MoneyLeaderRow item={item} key={index} index={index} showAll={props.showAll}/>);
    }
  });


  const seasons = props.seasonOptions.map((data) =>
    <DropdownItem key={data} onClick={() => {props.setSeason(data)}}>
      {data}
    </DropdownItem>
  )

  return (
    <Card className="my-4">
      <CardHeader className="d-flex justify-content-between">
        <div className="d-flex justify-content-start">
          Money Leaders
        </div>
        <div className="d-flex justify-content-end">
          {props.showAll === true &&
            <Dropdown isOpen={props.seasonDropdownOpen}
              toggle={() => props.toggleSeasonDropdown()} size="sm">
              <DropdownToggle caret className="golferModalOptionRow_dropdowntoggle">
                {props.season}
              </DropdownToggle>
              <DropdownMenu>
                {seasons}
              </DropdownMenu>
            </Dropdown>
          }
          {props.showAll === false &&
            <div className="d-flex justify-content-end">
              <Link to={"/moneyleaders/"+props.activeLeague} style={{color: "inherit", textDecoration: "none", cursor: "inherit"}} className="ml-auto">
                <Button size="sm" color="primary">
                    All Results
                </Button>
              </Link>
            </div>
          }
          {/*<Button size="sm" color="primary" className="ml-3" onClick={() => props.toggleShowAll()}>
              All Results
          </Button>
          */}
        </div>
      </CardHeader>
      <CardBody className="p-0">
          <Table responsive className="mb-0">
            <thead>
              <tr style={{backgroundColor: "#fafafa", color: "gray", "fontSize": "0.85rem"}}>
                <th className="text-center">POS</th>
                <th className="text-left">User</th>
                <th className="text-center">Wins</th>
                <th className="text-center">Won</th>
                {props.showAll && <th className="text-center">&nbsp;</th>}
              </tr>
            </thead>
            <tbody style={{fontSize: ".9em"}}>
              {moneyLeaderRows}
            </tbody>
          </Table>
      </CardBody>
    </Card>
  )
}

class MoneyLeaderRow extends React.Component {
    constructor(props) {
      super(props);
      this.state = { collapsed: true };
      this.toggle = this.toggle.bind(this);
    }
    toggle() {
      this.setState({collapsed: !this.state.collapsed});
    }
    render() {
      const item = this.props.item;
      const index = this.props.index;

      const winning = (w) => {
        const recolor = w > 0 ? {color: "#27ae60"} : {};
        return  <span style={recolor}>{ numeral(w).format('0,0[.]00') }</span>
      }

      let bodyrowclass = "completedEventRowBody";
      if (!this.state.collapsed) {
        bodyrowclass += " expanded";
      }



      return (
        <>
        <tr key={index} onClick={this.toggle} className={"cupTeamRowBody" + (item.usersRow ? ' personal' : '')}>
          <td className="text-center"><span className={''}>{index+1}</span></td>
          <td className="cupTeamRowBase text-left">{item.owner}</td>
          <td className="cupTeamRowBase text-center">{item.teamsCnt}</td>
          <td className="cupTeamRowBase text-center">{winning(item.winningAmt)}</td>
          {this.props.showAll &&
            <td className="align-middle"><FontAwesome size="lg" name={this.state.collapsed ? "angle-down" : "angle-up"} /></td>
          }
        </tr>
        {this.props.showAll &&
          <tr className={bodyrowclass}>
            <td colSpan="12" style={{padding: 0, borderTop: "none"}}>
              <Collapse isOpen={!this.state.collapsed} className="completedEntryCollapse">
                <MoneyLeaderEntries {...this.props.item}/>
              </Collapse>
            </td>
          </tr>
        }
        </>
      )
    }
}

const MoneyLeaderEntries = (props) => {
  props.entryDetails.sort((a, b)=>{
    return new Date(b.event_date).getTime() - new Date(a.event_date).getTime();
  });

  const rows = props.entryDetails.map((entry, index) => {

    const winning = (w) => {
      const recolor = w > 0 ? {color: "#27ae60"} : {};
      return  <span style={recolor}>{ numeral(w).format('0,0[.]00') }</span>
    }

    const rank = (rank, winner) => {
      let classname = "mcLeaderboardTeamRowRank";

      if (winner) {
        classname += " winner"
      }

      return <span className={classname}>
        {rank}
      </span>
    }

    const toParColor = (entry.toPar !== null && entry.toPar < 0) ? "#e74c3c" : "#515356";
    const toParContent = entry.toPar === 0 ? "E" :
                    entry.toPar === null ? "-" :
                    entry.toPar > 0 ? "+" + entry.toPar :
                    entry.toPar;

    return (
      <tr key={entry.entry_id} className="completedEntryRow">
        <td className="text-center">{rank(entry.place, true)}</td>
        <td className="">{entry.event}</td>
        <td className="">{entry.team}</td>
        <td className="cupTeamRowBase text-center">{<span style={{color: toParColor}}>{toParContent}</span>}</td>
        <td className="cupTeamRowBase text-center">{winning(entry.winning)}</td>
        <td className="cupTeamRowBase text-center">&nbsp;</td>
      </tr>
    )
  });

  return (
    <Table className="completedEntriesTable">
      <thead>
        <tr className="completedEntriesTableHeader">
          <th className="text-center">POS</th>
          <th className="">Event</th>
          <th className="">Team</th>
          <th className="text-center text-nowrap">To Par</th>
          <th className="text-center">Won</th>
          <th className="text-center">&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        {rows}
      </tbody>
    </Table>
  )
}
