import React from 'react';

import {
  Container,
} from 'reactstrap';

import {OnboardingWrapper} from 'common/LandingWrapper.js';
import LoginModal from 'common/LoginModal'


import {verify_mc_auth_token, logout} from 'utils/api.js';

export default class LoginFromExternal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: null,
    }
  }
  componentDidMount() {
    const application = this.props.match.params.application;
    const token = this.props.match.params.token;

    verify_mc_auth_token(application, token, (response) => {
      if (response.success){
        if (this.props.me){
          this.props.updateMe(response.me);
          this.props.history.push('/league/' + response.def_league);
        }else{
          this.props.setPostLoginURL('/league/' + response.def_league);
          this.props.loggedInCallback(response.me, this.props.history);
          setTimeout(() => {
              this.props.history.push('/league/' + response.def_league);
          }, 100);


        }
      }else{
        logout((response) => {
            this.props.loggedOutCallback();
            this.props.history.push("/");
          }, (error) => {
            console.log(error);
            console.log("Error occurred trying to logout!");
        })
      }
        //this.props.history.push('/');
        //this.props.loggedInCallback(me, this.props.history);

    }, (error) => {
      logout((response) => {
          this.props.loggedOutCallback();
          this.props.history.push("/");
        }, (error) => {
          console.log(error);
          console.log("Error occurred trying to logout!");
      })
    });
  }
  render() {
    let message = "Login in progress…"

    return (
      <OnboardingWrapper {...this.props}>
        <Container className="text-center landingCard mt-4">
          <h5>
            {message}
          </h5>
        </Container>
      </OnboardingWrapper>
    )
  }
}
