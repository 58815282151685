import React from 'react';
import {
  Button,
  Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';

import { ReactMultiEmail, isEmail } from 'react-multi-email';
import 'react-multi-email/style.css';
import FontAwesome from 'react-fontawesome';

import { CopyToClipboard } from 'react-copy-to-clipboard';

import {
  invite_to_league
} from 'utils/api.js';

import 'common/McModal.css'
import 'common/InviteModal.css';


export default class InviteMultipleModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      emails: [''],
      valid: [''],
      note: '',
      can_send: false,
      message: null,
      error: null,
      maxEmailsCnt: 50,
      isEmailInvalid:false,
      isEmailCurrent:false,
      isEmailInvited:false,
      isEmailMaxReached:false,
      submitInProgress:false,
    };

    this.toggle = this.toggle.bind(this);
    this.handleNote = this.handleNote.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
      emails: [],
      valid: [],
      note: '',
      message: null,
      error: null,
      maxEmailsCnt: 50,
      isEmailInvalid:false,
      isEmailCurrent:false,
      isEmailInvited:false,
      isEmailMaxReached:false,
      submitInProgress:false,
    });
  }

  handleNote(event) {
    this.setState({note: event.target.value});
  }

  handleSubmit(event) {
    this.setState({can_send:false, submitInProgress:true});
    let emails = this.state.emails.filter((email) => {return email !== ''});
    invite_to_league(this.props.league, emails, this.state.note,
      (response) => {
        this.setState({submitInProgress:false});
        if (response.data.success) {
          this.setState({message: "Invites Sent!",
                        isEmailInvalid:false,
                        isEmailCurrent:false,
                        isEmailInvited:false,
                      });
          return;
        }

        if (response.data.error) {
          this.setState({error: response.data.error});
        }

        if (response.data.valid || response.data.current || response.data.invalid || response.data.invited) {
          let valid = this.state.valid;
          let isEmailInvalid = false;
          let isEmailCurrent = false;
          let isEmailInvited = false;
          this.state.emails.forEach((email, index) => {
            if (response.data.valid.indexOf(email) > -1) {
              valid[email] = 'valid';
            } else if (response.data.invalid.indexOf(email) > -1) {
              valid[email] = 'invalid';
              isEmailInvalid = true;
            } else if (response.data.current.indexOf(email) > -1) {
              valid[email] = 'current';
              isEmailCurrent = true;
            } else if (response.data.invited.indexOf(email) > -1) {
              valid[email] = 'invited';
              isEmailInvited = true;
            }
          });
          this.setState({valid: valid,
                        isEmailInvalid:isEmailInvalid,
                        isEmailCurrent:isEmailCurrent,
                        isEmailInvited:isEmailInvited,
                        can_send:true,
                      });
        }
      }, (error) => {
        this.setState({error: 'An unexpected error occurred. Please try again later.'});
    })
    event.preventDefault();
  }

  render() {

    const msg ="Add up to " + this.state.maxEmailsCnt + " emails. Separate each with a comma, space or semi-colon."

    const multiEmails = (
        <>
          <ReactMultiEmail
            placeholder={msg}
            emails={this.state.emails}
            onChange={(_emails: string[]) => {
              _emails = [...new Set(_emails)];
              let can_send = _emails.length > 0;
              //alert(_emails.length + '-' + this.state.maxEmailsCnt);
              if (_emails.length < this.state.maxEmailsCnt){
                this.setState({ emails: _emails, can_send:can_send,
                    isEmailMaxReached:(_emails.length === this.state.maxEmailsCnt?true:false)});
              }else{
                for (var i=_emails.length-1;i>=this.state.maxEmailsCnt;i--){
                  _emails.splice(i, 1);
                };
                this.setState({ emails: _emails, can_send:can_send, isEmailMaxReached:true });
              }
            }}
            validateEmail={email => {
              return isEmail(email); // return boolean
            }}
            removeEmail={index => {
              alert(index);
            }}
            getLabel={(
              email: string,
              index: number,
              removeEmail:(index:number) => void,
            ) => {
              let valid = this.state.valid[email];
              let clsName = valid === 'valid'   ? "validBar" :
                            valid === 'invalid' ? "invalidBar" :
                            valid === 'invited' ? "invitedBar":
                            valid === 'current' ? "currentBar" :
                            "";
              return (
                <div data-tag key={index} style={{width:"auto"}}>
                  <span className={clsName}>&nbsp;</span>
                  {email}
                  <span data-tag-handle onClick={() => removeEmail(index)}>
                    ×
                  </span>
                </div>
              );
            }}
          />
          <div>
            {
              this.state.isEmailMaxReached === true &&
              <div className="legend">
                <span className="invalidBar">&nbsp;</span>
                  Maximum number of allowable emails reached.
              </div>
            }
            {
              this.state.isEmailInvited === true &&
              <div className="legend">
                <span className="invitedBar">&nbsp;</span>
                This email has already been invited to join the league in the last 12 hours.
              </div>
            }
            {
              this.state.isEmailCurrent === true &&
              <div className="legend">
                <span className="currentBar">&nbsp;</span>
                This email belongs to a current league member.
              </div>
            }
          </div>
        </>
      );

    let clsNm = "btn-sm btn btn-outline-success";
    if (this.props.embedIn && this.props.embedIn === 'Invite'){
      clsNm = "btn-sm btn btn-success";
    }
    return (
      <Button size="sm" color="" className={clsNm} onClick={this.toggle} >Invite
        <Modal isOpen={this.state.modal}  className="inviteModal">
          <ModalHeader toggle={this.toggle} className="mcModalHeader">Invite Members</ModalHeader>
          <ModalBody>
            { this.props.leagueInfo && this.props.leagueInfo.clubSlug &&
              <div className="pt-3 multiEmails" style={{fontSize: ".8rem", color: "#28a745"}}>
                <p>
                  <b>Tip:</b> You can skip this dialog by sending a personal email with your web address:
                  <span className="mr-2" style={{fontWeight:'bold'}}> {window.location.host + '/club/'+this.props.leagueInfo.clubSlug} </span>
                  <CopyToClipboard text={window.location.origin + '/club/'+this.props.leagueInfo.clubSlug}>
                    <Button color="success" size="sm" outline><FontAwesome name="copy" /> </Button>
                  </CopyToClipboard>
                </p>
              </div>
            }
            <form onSubmit={this.handleSubmit}>
              <div className="pt-3 multiEmails">
                <label>
                    Recipients:
                </label>
                { multiEmails }
              </div>
              <div className="pt-4">
                <label>
                  Note:
                </label>
                <div>
                  <textarea rows="3" maxLength="1000" className="mt-1" placeholder="(optional)" value={this.state.note} onChange={this.handleNote} />
                </div>
              </div>


            </form>
          </ModalBody>
          <ModalFooter>
            {
              this.state.error && <div style={{color: "red", textAlign: "left", paddingBottom: "1rem"}}>{this.state.error}</div>
            }
            <Button color="primary" size="sm" onClick={this.toggle} >
              Cancel
            </Button>
            {
              this.state.message ? <div style={{textAlign: "left", paddingBottom: "1rem"}}>{this.state.message}</div> :
              <Button color="primary" size="sm" onClick={this.handleSubmit} disabled={!this.state.can_send}>
                {this.state.submitInProgress === true && <FontAwesome name="spinner" className="mr-2" spin />}
                Send Invites
              </Button>
            }

          </ModalFooter>
        </Modal>
      </Button>
    );
  }
}
