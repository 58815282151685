import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, Row, Col } from 'reactstrap';

import FlashMessage from 'react-flash-message';
import { Bootstrap2Toggle } from 'react-bootstrap-toggle';
import "utils/bootstrap2-toggle.css";
import "utils/flashMessage.css";

import 'common/McModal.css'
import 'common/LoginModal.css';

import {
  add_member
} from 'utils/api.js';

export default class AddMemberModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      firstname: '',
      lastname: '',
      username: '',
      altCommis: false,
      email: '',
      password: '',
      reset: false,
      error: null,
      message: null
    };

    this.toggle = this.toggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.setAlternateCommissioner = this.setAlternateCommissioner.bind(this);

  }


  toggle(event) {
    this.setState({
      modal: !this.state.modal,
      firstname: '',
      lastname: '',
      username: '',
      altCommis: false,
      email: '',
      password: '',
      reset: false,
      error: null,
      message: null
    });

    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }


  }

  setAlternateCommissioner(commissionerind){
    this.setState({ altCommis:commissionerind });
  }

  handleChange(event) {
    this.setState({[event.target.name]: event.target.value});
  }

  handleSubmit(event) {
    add_member(this.props.leagueid,
        this.state.username, this.state.email, this.state.firstname,
        this.state.lastname, this.state.password,this.state.altCommis,
      (response) => {
        if (response.data.success) {
          this.setState({modal:false, message: response.data.message});
          this.props.fetchMembers(this.props.leagueid,response.data.memberid);
        } else {
          this.setState({error: response.data.reason});
        }
      }, (error) => {
        this.setState({error: "Something wrong happened."});
    })
    event.preventDefault();
  }



  render() {


    return (
      <>
      {!!this.state.message &&
        <FlashMessage duration={3000} >
          <div className="flash-message flash-success">
            {this.state.message}
          </div>
        </FlashMessage>
      }
      <Button outline color="success" onClick={this.toggle} size="sm" className='ml-3 btn-sm' > Add Member
        <Modal isOpen={this.state.modal} toggle={this.toggle} className="loginModal" autoFocus={false}>
          <ModalHeader toggle={this.toggle} className="mcModalHeader">{'Add Member'}</ModalHeader>
          <ModalBody>
            <form onSubmit={this.handleSubmit} autocomplete="on">
              <div>
                <label>
                  <div>
                    First Name
                  </div>
                  <input type="text" name='firstname'  autoFocus={true} onChange={this.handleChange}  className="form-control" />
                </label>
              </div>
              <div>
                <label>
                  <div>
                    Last Name
                  </div>
                  <input type="text" name='lastname' value={this.state.lastname} onChange={this.handleChange}  className="form-control" />
                </label>
              </div>
              <div>
                <label>
                  <div>
                    Email
                  </div>
                  <input type="text" name='email' value={this.state.email} autocomplete="off" onChange={this.handleChange}  className="form-control" />
                </label>
              </div>
              {
                (this.props.leagueInfo && this.props.leagueInfo.leagueType === 'PROMO')  &&
                <div>
                  <label>
                    <div>
                      Username
                    </div>
                    <input type="text" name='username' value={this.state.username} autocomplete="off" onChange={this.handleChange}  className="form-control" />
                  </label>
                </div>
               }
              <div>
                <label>
                  <div>
                    Password
                  </div>
                  <input type="password" name='password' autoComplete="new-password" value={this.state.password} onChange={this.handleChange}  className="form-control" />
                </label>
              </div>
              {
              (this.props.leagueInfo && this.props.leagueInfo.amILeagueOwner === true) &&
              <Row className="profileFormRow pb-3 pb-sm-0">
                <Col sm="5" className="profileFormRow_label">
                  {'Alternate Commissioner:'}
                </Col>
                <Col sm="5"  className="pl-2">
                    <div className="col-sm-6 text-left pl-2">
                        <Bootstrap2Toggle
                          onClick={(state)=>{this.setAlternateCommissioner(state)}}
                          on={'YES'}
                          off={'NO'}
                          size="sm"
                          offstyle="secondary"
                          onstyle="secondary"
                          active={this.state.altCommis}
                        />
                    </div>
                </Col>
              </Row>
              }
              <input type="submit" value="Sign In" hidden />
              <div className="d-flex justify-content-between align-items-center pt-3">
                {
                  this.state.error && <div style={{color: "red"}} className="pr-2">{this.state.error}</div>
                }
              </div>
              <div className="d-flex justify-content-center pt-3">
                <button type="submit" className="btn btn-primary" onClick={this.handleSubmit}>
                  Add Member
                </button>
              </div>


            </form>
          </ModalBody>
        </Modal>
      </Button>
      </>
    );
  }
}
