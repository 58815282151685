import React from 'react';

import { Link } from 'react-router-dom';

import {
  Row, Col
} from 'reactstrap';

import {
  MobileHeader,
  ContentWrapper
} from 'common/ProfileComponents.js';


const ProfileLegal = (props) => {
  return (
    <div>
      <MobileHeader to="Leagues" />
      <ContentWrapper>
        <h5 className="profileContentTitle">Copyright © 2020 Roxi Fantasy Gaming, LLC</h5>
        <Row className="profileFormRow pb-3 pb-sm-0">
          <Col sm="12" lg="9" className="profileLegal">
            <p className="pt-4">
              Majors Challenge is a privately run site owned and operated by Roxi Fantasy Gaming, LLC
              and is in no way affiliated with the PGA or PGA Tour.
            </p>
            <p>
              By using this site you agree to our <Link to="/terms">Terms of Service</Link> and <Link to="/privacy">Privacy Policy</Link>.
              Use of this site is intended for entertainment purposes only.
            </p>
          </Col>
        </Row>
      </ContentWrapper>
    </div>
  )
};

export default ProfileLegal;
