import React from 'react';
import {
  Badge,
  Button, ButtonDropdown, ButtonGroup,
  Card,
  DropdownToggle, DropdownMenu, DropdownItem,
  Table,
} from 'reactstrap';

import FontAwesome from 'react-fontawesome';

import {
  Link,
  withRouter,
} from 'react-router-dom';

import { CopyToClipboard } from 'react-copy-to-clipboard';

import numeral from 'numeral';

import InviteMultipleModal from 'common/InviteMultipleModal';
import AddMemberModal from 'common/AddMemberModal';

import {
  MobileHeader,
  ContentWrapper
} from 'common/CommissionerComponents.js';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'common/ConfirmAlert.css';

import CsvDownloader from 'react-csv-downloader';

import { get_members, set_member_status, resend_invitation_emails, start_password_reset } from 'utils/api.js';
//import { isDesktop } from 'utils/responsive.js';

class Members extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: 'active',
      filters: ['all', 'active', 'inactive', 'banned', 'invited', 'new'],
    };

    this.fetchMembers = this.fetchMembers.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.resendInvitation = this.resendInvitation.bind(this);
  }

  componentDidMount() {
    if (this.props.activeLeague === null) {
      return
    }

    this.fetchMembers(this.props.activeLeague);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.activeLeague === nextProps.activeLeague) {
      return
    }

    this.fetchMembers(nextProps.activeLeague);
  }

  fetchMembers(league) {
    if (!league) {
      return;
    }
    get_members(league,
    ({success, members, leagueInfo, error}) => {
      if (success) {
        this.setState({members, leagueInfo});
      } else {
        console.log(error);
      }
    }, (error) => {
      console.log(error);
    });
  }
  handleFilter(filter) {
    if (this.state.filter === filter) {
      return;
    }

    this.setState({filter});
  }

  emailCopyConfirmation(cnt){
    confirmAlert({
      title: 'Email',
      message: cnt + ' Emails Copied to Clipboard',
      buttons: [
        {
          label: 'OK',
          onClick: () => { }
        }
      ]
    });
  }

  resendInvitation(member){
    if (member){
      resend_invitation_emails(this.props.activeLeague,member,
      ({success, inviteSentCnt, error}) => {
        if (success) {
          confirmAlert({
            title: 'Invite',
            message: (member?'Invite sent.':(inviteSentCnt===1 ? inviteSentCnt + ' invite resent.' : inviteSentCnt + ' invites resent.')),
            buttons: [
              {
                label: 'OK',
                onClick: () => { }
              }
            ]
          });
        } else {
          console.log(error);
        }
      }, (error) => {
        console.log(error);
      });
      return;
    }

    confirmAlert({
      title: 'Invite',
      message: 'Resend An Invite To Everyone?',
      buttons: [
        {
          label: 'OK',
          onClick: () => {
            resend_invitation_emails(this.props.activeLeague,member,
            ({success, inviteSentCnt, error}) => {
              if (success) {
                confirmAlert({
                  title: 'Invite',
                  message: (member?'Invite sent.':(inviteSentCnt===1 ? inviteSentCnt + ' invite resent.' : inviteSentCnt + ' invites resent.')),
                  buttons: [
                    {
                      label: 'OK',
                      onClick: () => { }
                    }
                  ]
                });
              } else {
                console.log(error);
              }
            }, (error) => {
              console.log(error);
            });

          }
        },
        {
          label: 'Cancel',
          onClick: () => { }
        }
      ]
    });

  }


  render() {
    let csvExprtDatas = [];
    let csvExprtFileName = null;
    if (!!this.state.leagueInfo){
      csvExprtFileName = this.state.filter.toUpperCase() + " Memebers for " + this.state.leagueInfo.leaguename;
    }

    const csvExprtColumns = [{
        id: 'fname',
        displayName: 'First Name'
      },{
          id: 'lname',
          displayName: 'Last Name'
      },{
          id: 'username',
          displayName: 'Username'
      },{
        id: 'email',
        displayName: 'Email'
      },{
        id: 'venmo',
        displayName: 'Venmo'
      },{
        id: 'status',
        displayName: 'Status'
      },{
        id: 'date_joined',
        displayName: 'Date Joined'
      }];


    let filteredEmails = "";
    let filteredMembers = [];
    let filteredEmailsCnt = 0;
    if (!!this.state.members) {
      filteredMembers = filterMembers(this.state.members, this.state.filter);

      filteredEmails = filteredMembers.map((member) => member.email) // List of emails
      filteredEmailsCnt = filteredEmails.length;
      filteredEmails = filteredEmails.join('; ') // join with separator recognized by standard email clients

      csvExprtDatas = filteredMembers;
    }

    const resendInviteButton = filteredEmails === "" ?
      <div><Button size="sm" color="secondary" outline disabled className="ml-3 ml-md-none text-nowrap">
        <FontAwesome name="envelope" />&nbsp;&nbsp;<FontAwesome name="angle-double-right" />
      </Button></div> :
      <div>
        <Button size="sm" color="success" outline className="ml-3 ml-md-none text-nowrap"
          onClick={()=>this.resendInvitation(null)}>
          <FontAwesome name="envelope" />&nbsp;&nbsp;<FontAwesome name="angle-double-right" />
        </Button>
      </div>


    //const copyEmailsButtonText = isDesktop() ? "Copy Emails" : "Copy Emails";
    const copyEmailsButton = filteredEmails === "" ?
      <div><Button size="sm" color="secondary" outline disabled className="ml-3 ml-md-none text-nowrap">
        <FontAwesome name="envelope" />&nbsp;&nbsp;<FontAwesome name="copy" />
      </Button></div> :
      <CopyToClipboard text={filteredEmails}>
        <div>
          <Button size="sm" color="success" outline className="ml-3 ml-md-none text-nowrap"
            onClick={()=>this.emailCopyConfirmation(filteredEmailsCnt)}>
            <FontAwesome name="envelope" />&nbsp;&nbsp;<FontAwesome name="copy" />
          </Button>
        </div>
      </CopyToClipboard>


    return (
      <div className="commissionerMembers">
        <MobileHeader to="Members" leagueid={this.props.activeLeague} />
        <ContentWrapper>
          <MembersHeader activeLeague={this.props.activeLeague} leagueInfo={this.state.leagueInfo}
          fetchMembers={this.fetchMembers}/>
          <Card body className="p-0">
            <div className="p-2 d-flex justify-content-between commissionerMembersHeader">
              <ButtonGroup>
                {this.state.filters.map((item) => (
                  <Button color="primary" outline onClick={() => this.handleFilter(item)} active={this.state.filter === item} key={item}>
                    <span className="text-uppercase text-nowrap" style={{fontSize: ".7em"}}>
                      {this.state.filter === item ? item + " (" + filteredMembers.length + ")" : item}
                    </span>
                  </Button>
                ))}
              </ButtonGroup>
              <div className="btn-group">
                {this.state.filter === 'invited' && resendInviteButton}
                {copyEmailsButton}
                <CsvDownloader
                  filename={csvExprtFileName}
                  separator=","
                  wrapColumnChar='"'
                  columns={csvExprtColumns}
                  datas={csvExprtDatas}>
                  {
                    csvExprtDatas.length > 0 ?
                    <Button size="sm" color="success" outline className="ml-3 ml-md-none text-nowrap">
                      <FontAwesome name="file-excel-o" />&nbsp;&nbsp;<FontAwesome name="download" />
                    </Button> :
                    <Button size="sm" color="secondary" outline disabled className="ml-3 ml-md-none text-nowrap">
                      <FontAwesome name="file-excel-o" />&nbsp;&nbsp;<FontAwesome name="download" />
                    </Button>
                  }

                </CsvDownloader>
              </div>
            </div>
            { !this.state.members ? undefined :
                <MembersTable
                  league={this.props.activeLeague}
                  members={this.state.members}
                  setMembers={(members) => this.setState({members})}
                  filter={this.state.filter}
                  resendInvitation={this.resendInvitation}
                />
            }
          </Card>
        </ContentWrapper>
      </div>
    );
  }
}

const MembersHeader = (props) => (
  <div className="d-flex pb-2 justify-content-between">
    <div className="d-none d-lg-block mr-auto" style={{fontSize: "1.5em", fontWeight: 300}}>Members</div>
    <InviteMultipleModal league={ props.activeLeague } leagueInfo={props.leagueInfo}/>
    <AddMemberModal leagueid={ props.activeLeague } leagueInfo={props.leagueInfo} fetchMembers={props.fetchMembers}/>
  </div>
)

const MembersTable = (props) => {
  let rows = null;
  if (props.members) {
    const filteredMembers = filterMembers(props.members, props.filter);

    rows = filteredMembers.map((member, index) =>
      <MembersRow member={member} key={index} setMembers={props.setMembers} league={props.league} resendInvitation={props.resendInvitation}/>
    );
  }

  return (
    <Table responsive>
      <thead>
        <tr style={{backgroundColor: "#f6f6f6", color: "#7c7c7c", "fontSize": "0.7rem", textTransform: "uppercase"}}>
          <th>Member</th>
          <th>Invited By</th>
          <th>Invites</th>
          <th>Notes</th>
          {/*<th>Balance</th>*/}
          <th>Tournaments</th>
          <th/>
        </tr>
      </thead>
      <tbody>
        {rows}
      </tbody>
    </Table>
  )
}

class MembersRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      actionsOpen: false,
    }
    this.toggleActions = this.toggleActions.bind(this);
    this.setStatus = this.setStatus.bind(this);
    this.markActive = this.markActive.bind(this);
    this.markInactive = this.markInactive.bind(this);
    this.ban = this.ban.bind(this);
    this.remove = this.remove.bind(this);
    this.sendAgain = this.sendAgain.bind(this);
  }
  toggleActions() {
    this.setState({ actionsOpen: !this.state.actionsOpen, });
  }
  setStatus(status) {
    set_member_status(this.props.league, this.props.member.id, status, true,
      ({success, members}) => {
        if (success) {
          this.props.setMembers(members);
        }
      }, (error) => {
        console.log('Error occurred setting status.')
      }
    )
  }
  markActive() {
    this.setStatus('active');
  }
  markInactive() {
    this.setStatus('inactive');
  }
  ban() {
    this.setStatus('banned');
  }
  remove() {
    this.setStatus('remove');
  }
  sendAgain(){
    this.props.resendInvitation(this.props.member.id);
  }

  resetPassword = ()=>{
    const sendPwd = () =>{
      start_password_reset(this.props.member.email,
        ({success}) => {
          let msg = '';
          if (success) {
            msg = 'Password reset sent.';
          } else {
            msg = 'Sorry, we did not find an account matching that email.';
          }
          confirmAlert({
            title: 'Password Reset',
            message: msg,
            buttons: [
              {
                label: 'OK',
                onClick: () => { }
              }
            ]
          });
        }, (error) => {
          confirmAlert({
            title: 'Error',
            message: 'An unexpected error occurred. Try again later.',
            buttons: [
              {
                label: 'OK',
                onClick: () => { }
              }
            ]
          });
      });
    }
    let name = (this.props.member.fname + ' ' + (this.props.member.lname||'')).trim();
    confirmAlert({
      title: 'Password Reset',
      message: 'Send password reset email to ' + name + ' at ' + this.props.member.email,
      buttons: [
        {
          label: 'Send',
          layoutClass:'button_success',
          onClick: () => { sendPwd();}
        },
        {
          label: 'Cancel',
          layoutClass: 'btn_outline_success',
          onClick: () => { }
        }
      ]
    });


  }

  render() {
    const member = this.props.member;
    let invites = <td style={{color: member.invites === 0 ? "#ccc" : "#1477D5"}} className="align-middle">{member.invites}</td>
    let notes = <td style={{color: member.notes === 0 ? "#ccc" : "#1477D5"}} className="align-middle">{member.notes}</td>
    let events = <td style={{color: member.event_count === 0 ? "#ccc" : "#1477D5"}} className="align-middle">{member.event_count}</td>

    let balanceValue = numeral(member.balance);
    let balanceStyle = {color:"#ccc"}; // Default for 0 balance
    if (balanceValue.value() !== 0) {
      balanceStyle = {fontWeight: "600", color: balanceValue.value() > 0 ? "#39CA74" : "#D63348"};
    }
    let plusSign = balanceValue.value() > 0 ? '+' : '';
    let balance = <td style={balanceStyle} className="align-middle">{plusSign + balanceValue.format('$0[.]00')}</td>

    return (
      <tr style={{fontSize: "0.9em"}}>
        <td className="py-1 align-middle">
          <Link to={makeMemberUrl(this.props.league, member.id)}>
            <span className="text-nowrap">
              {!!member.name ? member.name : member.email}
            </span>
            {isMemberNew(member) && <Badge color="success" className="mx-1">New</Badge>}
          </Link>
        </td>
        <td style={{color: "#777"}} className="text-nowrap align-middle">
          {!!member.invited_by_id && member.invited_by_id !== member.id ?
            <Link to={makeMemberUrl(this.props.league, member.invited_by_id)}>{member.invited_by}</Link>
            : '-'
          }
        </td>
        {invites}
        {notes}
        {/*balance*/}
        {events}
        <td className="align-middle">
          <ButtonDropdown isOpen={this.state.actionsOpen} toggle={this.toggleActions}>
            <DropdownToggle caret>
              Actions
            </DropdownToggle>
            {
              member.commissioner === false &&
              <DropdownMenu>
                {member.status === "active" && <DropdownItem onClick={this.markInactive}>Mark as Inactive</DropdownItem>}
                {(member.status === "inactive" || member.status === "banned") && <DropdownItem onClick={this.markActive}>Mark as Active</DropdownItem>}
                {member.status !== "invited" && <DropdownItem divider />}
                {member.status !== "invited" && member.status !== "banned" && <DropdownItem onClick={this.ban}>Ban from League</DropdownItem>}
                {member.status === "invited" && <DropdownItem onClick={this.sendAgain}>Send Again</DropdownItem>}
                {member.status === "invited" && <DropdownItem onClick={this.remove}>Remove</DropdownItem>}
                {member.email && <DropdownItem onClick={this.resetPassword}>Password Reset</DropdownItem>}
              </DropdownMenu>
            }
          </ButtonDropdown>
        </td>
      </tr>
    )
  }
}

const filterMembers = (members, filter) => {
  // 'all', 'active', 'inactive', 'banned', 'invited', 'new'
  let filterFunc = null;
  if (filter === 'all') {
    filterFunc = (member) => true;
  } else if (filter === 'active') {
    filterFunc = (member) => member.status === 'active';
  } else if (filter === 'inactive') {
    filterFunc = (member) => member.status === 'inactive';
  } else if (filter === 'banned') {
    filterFunc = (member) => member.status === 'banned';
  } else if (filter === 'invited') {
    filterFunc = (member) => member.status === 'invited';
  } else if (filter === 'new') {
    filterFunc = isMemberNew;
  }
  //RI#161 - Hide clubs@majorschallenge.com
  let ownfilterFunc = (member) => member.email !== 'clubs@majorschallenge.com';
  let mems = members.filter(ownfilterFunc);
  return mems.filter(filterFunc);
}

const isMemberNew = (member) => member.entry_count === 0 && member.status === 'active';

const makeMemberUrl = (leagueid, memberid) => "/commissioner/" + leagueid + "/member/" + memberid

export default withRouter(Members);
