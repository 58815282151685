import React from 'react';

import CommissionerClubBalance from 'pages/commissioner/ClubBalanceAuthnet';
import CommissionerBalance from 'pages/commissioner/BalanceAuthnet';


export default class CommissionerBalanceNav extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {

  }

  componentWillReceiveProps(nextProps) {
    if (this.props.activeLeague === nextProps.activeLeague) {
      return;
    }
  }

  render() {
    return (
      <>
      { this.props.club === true && <CommissionerClubBalance {...this.props} />}
      {this.props.club === false && <CommissionerBalance {...this.props} />}
      </>
    )
  }
}
