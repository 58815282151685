import React from 'react';
import {
  Row, Col,
  Button,
  Container,
  Card,
  Input,
} from 'reactstrap';
import NumberFormat from 'react-number-format';

import { withRouter } from 'react-router-dom';

import {
  create_charity_league, login,
  } from 'utils/api';

import 'pages/user/CreateLeague.css';

import FontAwesome from 'react-fontawesome';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'common/ConfirmAlert.css';

import { locationStates } from 'utils/constants';

class CreateCharityLeague extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 1,
      loading:false,
      foundation_name: "",
      foundation_url:'',
      foundation_slug:'',
      rep_name:"",
      rep_email:"",
      rep_ph_no:"",
      address_lines:"",
      city:"",
      state:"",
      postal:"",
      tax_id:"",
      determinationLetterData:"",
      determinationLetterFileName:"",
      determinationLetterFileExtn:"",
      leagueid:'',
    };

    this.navigationClick = this.navigationClick.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleFileInputChange = this.handleFileInputChange.bind(this);
    this.handleResetFileInput = this.handleResetFileInput.bind(this);
  }

  componentDidMount() {

  }

  navigationClick(step) {
    return () => {
      if (this.state.step !== step) {
        this.setState({step});
      }
    }
  }

  handleNext() {
    if (this.state.step ===4){
      this.moveToCommissionerPage();
      return;
    }
    let nextStep = this.state.step + 1;
    if (nextStep > 3) {
      let foundation_url = this.state.foundation_url.toLowerCase()
      let pattern = '^(https?:\\/\\/)?' + // protocol
        '((([a-zA-Z\\d]([a-zA-Z\\d-]{0,61}[a-zA-Z\\d])*\\.)+' + // sub-domain + domain name
        '[a-zA-Z]{2,13})' + // extension
        '|((\\d{1,3}\\.){3}\\d{1,3})' + // OR ip (v4) address
        '|localhost)' + // OR localhost
        '(\\:\\d{1,5})?' + // port
        '(\\/[a-zA-Z\\&\\d%_.~+-:@]*)*' + // path
        '(\\?[a-zA-Z\\&\\d%_.,~+-:@=;&]*)?' + // query string
        '(\\#[-a-zA-Z&\\d_]*)?$'; // fragment locator
      let regex = new RegExp(pattern);
      let isValid = regex.test(foundation_url);

      if (!isValid) {
        confirmAlert({
          title: 'Alert',
          message: 'Please enter a valid Foundation Website.',
          buttons: [
            {
              label: 'OK',
              onClick: () => {
              }
            }
          ]
        });

        return ;
      }

      this.setState({loading:true});
      create_charity_league(
        this.props.authed,
        this.state.foundation_name,
        this.state.foundation_url,
        this.state.foundation_slug,
        this.state.rep_name,
        this.state.rep_email,
        this.state.rep_ph_no,
        this.state.address_lines,
        this.state.city,
        this.state.state,
        this.state.postal,
        this.state.tax_id,
        this.state.determinationLetterData,
        this.state.determinationLetterFileExtn,
      ({success, error, league, me}) => {
        if (success) {
          if (this.props.authed){
            this.props.updateMe(me);
          }
          this.setState({step: nextStep, loading:false, leagueid:league});
        } else {
          this.setState({loading:false});
          if (error === 'Please login to create your fundraiser.'){
            error = <span> Please <a href={`/createcharityleague`} style={{cursor:'pointer'}}>Login</a>  to create your fundraiser.</span>;
          }
          confirmAlert({
            title: 'Existing Account',
            message: error,
            buttons: [
              {
                label: 'OK',
                onClick: () => {
                }
              }
            ]
          });
        }
      }, (error) => {
        console.log(error);
        this.setState({loading:false});
        alert("Error occurred while trying to create the league.");
      });
    } else {
      this.setState({step: nextStep});
    }
  }


  handleChange(event) {
    let val = event.target.value;
    if (['foundation_slug','foundation_url','rep_email'].indexOf(event.target.name) > -1){
      val = val.trim();
    }
    this.setState({[event.target.name]: val});
  }

  handleFileInputChange(event) {
    event.preventDefault();
    this.setState({error:null});

    var x = this;
    let reader = new FileReader();
    const fileName = event.target.files[0].name;
    const fileExtension = event.target.files[0].name.split('.').pop().toLowerCase();
    /*if (event.target.files[0].size > 300000){
      confirmAlert({
        title: 'Alert',
        message: 'File excceeds allowable file size of 300KB.',
        buttons: [
          {
            label: 'OK',
            onClick: () => {
            }
          }
        ]
      });
      return;
    }*/
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = function () {
        console.log(reader.result);
        console.log(reader.result.substr(reader.result.indexOf('base64')+7));
        x.setState({
          determinationLetterData: reader.result.indexOf('base64')>0?reader.result.substr(reader.result.indexOf('base64')+7):null,
          determinationLetterFileExtn: fileExtension,
          determinationLetterFileName: fileName,
        });
    };
    reader.onerror = function (error) {
        console.log('Error: ', error);
    };

  }

  handleResetFileInput(){
    document.getElementById('fileUpload').value = '';
    this.setState({
      determinationLetterData: '',
      determinationLetterFileExtn: '',
      determinationLetterFileName: '',
    });
  }

  createLeagueNotAllowed(){
    confirmAlert({
      title: 'Alert',
      message: 'Before creating a league please provide a valid email address.',
      buttons: [
        {
          label: 'OK',
          onClick: () => { this.props.history.push('/profile/settings'); }
        }
      ]
    });

  }

  moveToCommissionerPage = () =>{
    if (this.props.authed){
      this.props.history.push("commissioner/" + this.state.leagueid + "/settings");
    }else{
      login(this.state.rep_email, this.state.foundation_slug,
        (response) => {
          if (response.data.success) {
            this.props.history.push("commissioner/" + this.state.leagueid + "/settings");
            this.props.loggedInCallback(response.data.me, this.props.history);
            setTimeout(() => {
                this.props.history.push("commissioner/" + this.state.leagueid + "/settings");
            }, 100);

          } else {
            this.setState({status:null, error: "There was a problem logging you in."});
          }
        }, (error) => {
          this.setState({status:null, error: "Invalid email or password."});
      })
    }
  }

  render() {

    if (this.props.me && this.props.me.usr.useremail === ''){
      this.createLeagueNotAllowed();

      return (
        <Container fluid style={{backgroundColor:"rgba(236, 240, 241, 1)", minHeight: "100vh"}}>
        </Container>
      );
    }

    let bodyParams = {...this.state}
    bodyParams.navigationClick = this.navigationClick;
    bodyParams.handleNext = this.handleNext;
    bodyParams.handleChange = this.handleChange;
    bodyParams.handleFileInputChange = this.handleFileInputChange;
    bodyParams.handleResetFileInput = this.handleResetFileInput;


    return (
      <Container fluid style={{backgroundColor:"rgba(236, 240, 241, 1)", minHeight: "100vh"}}>
        <Container style={{paddingTop:"5.5rem", paddingBottom:"2rem"}}>
          <Card className="pb-4">
            <Title />
            <Body {...bodyParams} />
          </Card>
        </Container>
      </Container>
    );
  }

}


const Title = (props) =>
  <h2 className="text-center p-2 pt-4" style={{color:"#212121"}}>Start a Fundraiser</h2>

const Body = (props) => {
  let stepContent;
  switch (props.step) {
    default:
    case 1:
      stepContent = <Fundraiser {...props} />;
      break;
    case 2:
      stepContent = <Countact {...props} />;
      break;
    case 3:
      stepContent = <Foundation {...props} />;
      break;
  }

  return (
    <div>
      <div className="p-3">
        {props.step <4 &&
        <Col lg={{size:6, offset: 3}} md={{size:10, offset:1}}>
          <Steps step={props.step} navigationClick={props.navigationClick} />
          {stepContent}
          <Row className="pt-4">
            {props.loading === false ?
              <Button className="nextButton" color="success" size="lg" onClick={props.handleNext} block> {props.step===3?'Submit':'Next'} </Button> :
              <Button color="success" disabled block>
                <FontAwesome name="spinner" spin />
              </Button>
            }
          </Row>
        </Col>
        }
        {props.step === 4 &&
        <Col lg={{size:6, offset: 3}} md={{size:10, offset:1}}>
          <Row className="pt-4">
            <h5>THANK YOU!</h5>
            <div>Your application has been submitted successfully and is under review. Someone from our team will be in touch. Please visit the Commissioner Console to review and e-sign your Foundation Agreement.</div>
            <Button className="nextButton mt-4" color="success" size="lg" onClick={props.handleNext} block> Commissioner Console </Button>
          </Row>
        </Col>
        }
      </div>
    </div>
  )
}

const Steps = (props) =>
  <div className="d-flex justify-content-between text-center px-sm-1 px-md-2 px-lg-3 mb-4">
    <Step current={props.step} index={1} title="Welcome" navigationClick={props.navigationClick}/>
    <Step current={props.step} index={2} title="Contact" navigationClick={props.navigationClick} next={props.next}/>
    <Step current={props.step} index={3} title="Foundation" navigationClick={props.navigationClick} />
  </div>

const Step = (props) =>
  <div className={ "step d-flex align-items-center flex-column" + (props.current === props.index ? " activeStep" : "") }  onClick={props.navigationClick(props.index)}>
    <div className="stepIndex">{props.index}</div>
    <div className="stepTitle pt-2">{props.title}</div>
  </div>

const Fundraiser = (props) => {
  return (
    <div className="charityContent">
      <Row className="mt-2 pb-4" style={{fontSize: ".90rem", color: "#78909c"}}>
        Looking for a new way to raise money for your 501(c)(3) foundation?  When you run a golf pool we take care of all the hard work. All you need to do is invite your database of users to play. We will collect donations and gift the money back to your foundation. Get started today.
      </Row>
      <Row className="contentTitle pb-2">
        Foundation Name
      </Row>
      <Row className=" pb-1">
        <Input placeholder="Enter foundation name" value={props.foundation_name} name="foundation_name" onChange={props.handleChange} maxLength="60"/>
      </Row>
      <Row className=" pb-4" style={{fontSize: ".85rem", color: "#78909c"}}>
        This is the name checks will be written to.
      </Row>
      <Row className="contentTitle pb-2">
        Foundation Website
      </Row>
      <Row className=" pb-4">
        <Input placeholder="Enter foundation website" value={props.foundation_url} name="foundation_url" onChange={props.handleChange} maxLength="60"/>
      </Row>
      <Row className="contentTitle pb-2">
        Web Abbreviation
      </Row>
      <Row className=" pb-2">
        <Input placeholder="Web Abbreviation" value={props.foundation_slug} name="foundation_slug" onChange={props.handleChange} maxLength="30"/>
      </Row>
      <Row className=" pb-2" style={{fontSize: ".85rem", color: "#78909c"}}>
        Please abbreviate your foundation name here. This value will be used for your custom web link which is how you’ll invite people to your golf pool. For example, Acme Foundation might abbreviate to acme.
      </Row>
    </div>
  )
}

const Countact = (props) =>
  <div className="charityContent">
    <Row className="contentTitle pb-4">
      Please tell us the best contact at your foundation.
    </Row>
    <Row className="contentTitle pb-2">
      Contact Name
    </Row>
    <Row className="pb-4">
      <Input placeholder="Enter contact name" value={props.rep_name} name="rep_name" onChange={props.handleChange} maxLength="60"/>
    </Row>
    <Row className="contentTitle pb-2">
      Contact Email Address
    </Row>
    <Row className="pb-4" >
      <Input placeholder="Enter contact email" value={props.rep_email} name="rep_email" onChange={props.handleChange} maxLength="60"/>
    </Row>
    <Row className="contentTitle pb-2">
      Contact Phone Number
    </Row>
    <Row className="pb-1">
      <NumberFormat format="(###) ###-####" placeholder="Enter foundation phone" value={props.rep_ph_no}
      name="rep_ph_no" onChange={props.handleChange} className="form-control"/>
    </Row>
  </div>

const Foundation = (props) =>{
  const locationStateOptions = locationStates.map((item, index) =>
      <option value={item} key={index}>{item}</option>
  );
  return(
    <div className="charityContent">
      <Row className="contentTitle pb-4">
        Your foundation must be a 501(c)(3) organization.
      </Row>
      <Row className="contentTitle pb-2">
        Address
      </Row>
      <Row className="pb-1">
        <Input placeholder="Enter foundation address" value={props.address_lines} name="address_lines" onChange={props.handleChange} maxLength="100"/>
      </Row>
      <Row className=" pb-4" style={{fontSize: ".85rem", color: "#78909c"}}>
        This is the address where checks will be sent.
      </Row>
      <Row className="contentTitle pb-2">
        City
      </Row>
      <Row className="pb-4">
        <Input placeholder="Enter foundation city" value={props.city} name="city" onChange={props.handleChange} maxLength="60"/>
      </Row>
      <Row className="contentTitle pb-2">
        State
      </Row>
      <Row className="pb-4">
        <Input type="select"  value={props.state} name="state"
          onChange={props.handleChange}>
          <option value={''}>{'Select'}</option>
          {locationStateOptions}
        </Input>
      </Row>
      <Row className="contentTitle pb-2">
        Postal Code
      </Row>
      <Row className="pb-4">
        <NumberFormat format="#####" placeholder="Enter foundation Postal code" value={props.postal}
        name="postal" onChange={props.handleChange} className="form-control"/>
      </Row>
      <Row className="contentTitle pb-2">
        Tax ID Number
      </Row>
      <Row className="pb-4">
        <Input placeholder="Enter foundation Tax ID" value={props.tax_id} name="tax_id" onChange={props.handleChange} maxLength="15"/>
      </Row>
      <Row className="contentTitle pb-2 d-none">
        IRS Determination Letter
        <div className={"file btn btn-sm btn-primary file_upload_div ml-4 mt-n1" + (props.determinationLetterFileName ? " d-none" : "")}>
          <FontAwesome name="upload" />
          &nbsp;&nbsp;Upload <input type="file" name="image" accept=".doc, .docx, .pdf" id="fileUpload"
          className="file_upload_div_input" onChange={props.handleFileInputChange}/>
        </div>
        <div className="ml-2 mt-1" style={{fontSize:'0.7em', lineHeight:'15px', color:'darkblue'}}>{props.determinationLetterFileName}</div>
        {props.determinationLetterFileName &&
          <FontAwesome name="close" className="ml-2 mt-1" onClick={props.handleResetFileInput} style={{cursor:'pointer', lineHeight:'15px'}}/>
        }
      </Row>
    </div>
  );
}


export default withRouter(CreateCharityLeague);
