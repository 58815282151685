import React from 'react';

import {
  Button,
  Col,
  Container,
  Row,
} from 'reactstrap';

import {
  withRouter
} from 'react-router-dom';

import FontAwesome from 'react-fontawesome';

import {OnboardingWrapper} from 'common/LandingWrapper.js';
import LoginModal from 'common/LoginModal';
import FacebookAuth from 'common/FacebookAuth.js';
import GoogleAuth from 'common/GoogleAuthNew.js';

import { check_club, invite_self_to_club, login } from 'utils/api';

import 'pages/user/JoinLeague.css';
import 'pages/user/Signup.css';

class UnauthedJoinClub extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open_membership: null,
      league_name: "",
      league_type: "",
      commissioner: "",
      email: '',
      valid_club: null,
      status: null,
      error: null,
    };

    this.checkClub = this.checkClub.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.renderEmailForm = this.renderEmailForm.bind(this);
    this.renderPromoLoginForm = this.renderPromoLoginForm.bind(this);
    this.renderInviteSuccess = this.renderInviteSuccess.bind(this);
    this.renderInvitesClosed = this.renderInvitesClosed.bind(this);
    this.handleLoginSubmit = this.handleLoginSubmit.bind(this);

    this.onSuccessRedirect = this.onSuccessRedirect.bind(this);
    this.onFacebookError = this.onFacebookError.bind(this);
    this.onGoogleError = this.onGoogleError.bind(this);
  }

  componentDidMount() {
    this.props.setPostLoginURL( this.props.postLoginURL );
    this.checkClub();
  }

  checkClub() {
    check_club(
      this.props.match.params.clubname,
    ({success, reason, league_name, open_membership, league_type, commissioner}) => {
      this.setState({
        valid_club: success,
        error: reason,
        league_name,
        open_membership,
        league_type,
        commissioner,
      })
    }, (error) => {
      console.log(error);
      this.props.history.push("/");
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();

    this.setState({status: false});

    invite_self_to_club(
      this.props.match.params.clubname,
      this.state.email,
      ({success, reason}) => {
        if (success) {
          this.setState({submitted: true, status: true});
        } else {
          this.setState({error: reason, status: null});
        }
      },
      (error) => {
        console.log(error);
        this.setState({
          status: null,
          error: "We were unable to send you an invitation. Please verify the email address."
        });
    });
  }

  handleLoginSubmit(event){
    event.preventDefault();
    event.stopPropagation();

    this.setState({status: false});

    login(this.state.email, this.state.password,
      (response) => {
        if (response.data.success) {
          this.props.loggedInCallback(response.data.me, this.props.history);
        } else {
          this.setState({status:null, error: "There was a problem logging you in."});
        }
      }, (error) => {
        this.setState({status:null, error: "Invalid email or password."});
    })
  }

  handleChange(event) {
    this.setState({[event.target.name]: event.target.value});
  }

  onSuccessRedirect(me) {
    this.props.loggedInCallback(me, this.props.history);
  }

  onFacebookError() {
    this.setState({error: "There was a problem logging you in with Facebook."});
  }
  onGoogleError() {
    this.setState({error: "There was a problem logging you in with Google."});
  }

  renderEmailForm() {
    if (this.state.league_type === 'PROMO'){
      return this.renderPromoLoginForm();
    }

    return (
      <div>
        <div class="justify-content-center pb-3 row d-none d-md-flex ">
          <div class="">
              <FacebookAuth onSuccessRedirect={this.onSuccessRedirect} onError={this.onFacebookError}/>
          </div>
          <div class="ml-3">
              <GoogleAuth fakeProp={true} onSuccessRedirect={this.onSuccessRedirect} onError={this.onGoogleError} />
          </div>
        </div>
        <div class="justify-content-center pb-3 row d-flex d-md-none ">
          <div class="">
              <FacebookAuth onSuccessRedirect={this.onSuccessRedirect} onError={this.onFacebookError}/>
          </div>
        </div>
        <div class="justify-content-center pb-3 row d-flex d-md-none ">
          <div class="">
              <GoogleAuth fakeProp={true} onSuccessRedirect={this.onSuccessRedirect} onError={this.onGoogleError} />
          </div>
        </div>
        <div class="justify-content-center pb-3 row">
          <div class="" style={{borderBottom: "2px inset darkgray",width: "100px",marginBottom: "12px"}}></div>
          <div class="mx-3" style={{textAlign: "center", color: "#888"}}>
            {"OR"}
          </div>
          <div class="" style={{borderBottom: "2px inset darkgray",width: "100px",marginBottom: "12px"}}></div>
        </div>
        <div className="joinContent_invitedby">
          Enter your email address to receive an invite to this golf pool.
        </div>
        <form onSubmit={this.handleSubmit}>
          <Row noGutters className="justify-content-center">
            <Col xs="10" sm="9" md="9" lg="9" xl="8">
              <label className="joinContent_signuplabel">
                <input type="text" name="email" value={this.state.email} className="joinContent_signupinput" onChange={this.handleChange}  placeholder="Email"/>
              </label>
            </Col>
          </Row>
          <input type="submit" value="Signup" hidden />
        </form>
        <Row className="justify-content-center text-right p-4">
        <Col xs="10" sm="9" md="9" lg="9" xl="8">
            {
              this.state.error && <div style={{color: "red", textAlign: "left", paddingBottom: "1rem"}}>{this.state.error}</div>
            }
            {
              this.state.status === null ?
                <div justify-content-center>
                  <div>
                    <Button color="success" onClick={this.handleSubmit} block> Send </Button>
                  </div>
                  {/*<div className="mt-3 text-center">
                        <GoogleAuth  />
                    </div>*/}
                  <div className="mt-3 text-center">
                    Already a member?<br/><LoginModal {...this.props} loginLink disallowSignup /> first.
                  </div>
                </div>
              :
              <Button color="success" disabled block>
                <FontAwesome name="spinner" spin />
              </Button>
            }
          </Col>
        </Row>
      </div>
    )
  }

  renderPromoLoginForm() {
    return (
      <div>
        <form onSubmit={this.handleLoginSubmit}>
          <Row noGutters className="justify-content-center">
            <Col xs="10" sm="9" md="9" lg="9" xl="8">
              <label className="joinContent_signuplabel">
                <input type="text" name="email" value={this.state.email}
                  className="joinContent_signupinput" onChange={this.handleChange}
                  placeholder="User Name"/>
              </label>
            </Col>
          </Row>
          <Row noGutters className="justify-content-center">
            <Col xs="10" sm="9" md="9" lg="9" xl="8">
              <label className="joinContent_signuplabel">
                <input type="password" name="password" value={this.state.password}
                  className="joinContent_signupinput" onChange={this.handleChange}
                  placeholder="Password"/>
              </label>
            </Col>
          </Row>
          <input type="submit" value="Signup" hidden />
        </form>
        <Row className="justify-content-center text-right p-4">
          <Col xs="10" sm="9" md="8" lg="6" xl="5">
            {
              this.state.error &&
                <div style={{color: "red", textAlign: "center", paddingBottom: "1rem"}}>{this.state.error}</div>
            }
            {
              this.state.status === null ?
                <div justify-content-center>
                  <div>
                    <Button color="success" onClick={this.handleLoginSubmit} block> Login </Button>
                  </div>
                </div>
              :
              <Button color="success" disabled block>
                <FontAwesome name="spinner" spin />
              </Button>
            }
          </Col>
        </Row>
        <Row noGutters className="justify-content-center">
          <Col xs="10" sm="9" md="9" lg="9" xl="8">
            <div className="mt-3 text-center">
              Looking to join this golf pool? Contact: <br></br>
              <a href={"mailto:"+this.state.commissioner}>
                <span style={{whiteSpace: "nowrap", fontSize: "0.85rem"}}>
                  <FontAwesome name="envelope" className="mr-2" />
                  <span>{this.state.commissioner}</span>
                </span>
              </a>
            </div>
          </Col>
        </Row>
      </div>
    )
  }

  renderInviteSuccess() {
    return (
      <div className="joinContent_invitedby">
        <div className="pb-4">Your invitation has been sent. Click the link in your email.</div>
        <div>(It is safe to close this window.)</div>
      </div>
    )
  }
  renderInvitesClosed() {
    return (
      <div className="joinContent_invitedby">
        <div className="pb-2">This league is only available via private invitation.</div>
        <div>Please request an invitation from the league commissioner to join this league.</div>
        <div className="mt-3 text-center">
          Already a member?<br/>
          <LoginModal {...this.props} loginLink disallowSignup /> first.
        </div>
      </div>
    )
  }
  render() {
    if (this.state.valid_club === null) {
      return null;
    }
    return (
      <OnboardingWrapper {...this.props}>
        <Container fluid className="joinContainer">
          <Row noGutters>
            <Col xs="12" sm="10" md="9" lg="8" xl="6" className="joinContent mx-auto">
              <div className="joinContent_title">Welcome to the league</div>
              <h2 className="joinContent_leaguename">{this.state.league_name}</h2>
              {
                this.state.open_membership === true
                ? (this.state.status ? this.renderInviteSuccess() : this.renderEmailForm())
                : this.renderInvitesClosed()
              }
            </Col>
          </Row>
        </Container>
      </OnboardingWrapper>
    );
  }
}

export default withRouter(UnauthedJoinClub);
