import React from 'react';


import {
  Desktop,
  NotDesktop
} from 'utils/responsive.js';

import {
  DesktopAdmin,
  MobileAdmin,
} from 'pages/admin/AdminNav.js';



import 'pages/admin/Admin.css'
import 'common/McModal.css';

export default class Admin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      events: [],
      fieldid: null,
      alternatefieldid: null,
    };

  }


  render() {

    return (
      <div className="adminWrapper">
        <Desktop>
          <DesktopAdmin {...this.state} {...this.props} />
        </Desktop>
        <NotDesktop>
          <MobileAdmin {...this.state} {...this.props} />
        </NotDesktop>
      </div>
    );

  }
}
