import React from 'react';
import {
  Button,
  Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';

import {
  invite_to_league
} from 'utils/api.js';

import 'common/McModal.css'
import 'common/InviteModal.css';

export default class InviteModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      emails: [''],
      valid: [''],
      note: '',
      can_send: false,
      message: null,
      error: null,
    };

    this.toggle = this.toggle.bind(this);
    this.addEmail = this.addEmail.bind(this);
    this.handleEmail = this.handleEmail.bind(this);
    this.handleNote = this.handleNote.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
      emails: [''],
      valid: [''],
      note: '',
      message: null,
      error: null
    });
  }

  addEmail(event) {
    let emails = this.state.emails;
    emails.push('');
    let valid = this.state.valid;
    valid.push('');
    this.setState({
      emails: emails,
      valid: valid,
    });
  }

  handleEmail(event) {
    let emails = this.state.emails;
    emails[event.target.name] = event.target.value.trim();

    let can_send = this.state.emails.filter((email) => {return email !== ''}).length > 0;

    let valid = this.state.valid;
    valid[event.target.name] = '';

    this.setState({
      emails: emails,
      can_send: can_send,
      valid: valid,
    });
  }

  handleNote(event) {
    this.setState({note: event.target.value});
  }

  handleSubmit(event) {
    let emails = this.state.emails.filter((email) => {return email !== ''});
    invite_to_league(this.props.league, emails, this.state.note,
      (response) => {
        if (response.data.success) {
          this.setState({message: "Invites Sent!"});
          return;
        }

        if (response.data.error) {
          this.setState({error: response.data.error});
        }

        if (response.data.valid || response.data.current || response.data.invalid || response.data.invited) {
          let valid = this.state.valid;
          this.state.emails.forEach((email, index) => {
            if (response.data.valid.indexOf(email) > -1) {
              valid[index] = 'valid';
            } else if (response.data.invalid.indexOf(email) > -1) {
              valid[index] = 'invalid';
            } else if (response.data.current.indexOf(email) > -1) {
              valid[index] = 'current';
            } else if (response.data.invited.indexOf(email) > -1) {
              valid[index] = 'invited';
            }
          });
          this.setState({valid: valid});
        }
      }, (error) => {
        this.setState({error: 'An unexpected error occurred. Please try again later.'});
    })
    event.preventDefault();
  }

  render() {
    // this.state.valid[index]
    let emails = this.state.emails.map((email, index) => {
      let valid = this.state.valid[index];

      let feedback =
        valid === 'invalid' ?
          <div className="feedback">This is not a valid email address.</div> :
          valid === 'invited' ?
          <div className="feedback">This email has already been invited to join the league in the last 12 hours.</div> :
            valid === 'current' ?
              <div className="feedback">This email belongs to a current league member.</div> :
              undefined;


      return (
        <div key={index} className={valid}>
          <input type="text" className="mt-1 mb-1 email" name={index} value={email} onChange={this.handleEmail} placeholder='Email' />
          { feedback }
        </div>
      )
    });

    let addInvitee =
      this.state.emails.length < 10 &&
        <div className="addInvitee" onClick={this.addEmail}>Add Invitee</div>;

    return (
      <Button outline className="btn-sm" color="success" onClick={this.toggle} >Invite
        <Modal isOpen={this.state.modal} toggle={this.toggle} className="inviteModal">
          <ModalHeader toggle={this.toggle} className="mcModalHeader">Invite Members</ModalHeader>
          <ModalBody>
            <form onSubmit={this.handleSubmit}>
              <div className="pt-3">
                <label>
                    Recipients:
                </label>
                { emails }
              </div>
              { addInvitee }
              <div className="pt-4">
                <label>
                  Note:
                </label>
                <div>
                  <textarea rows="3" maxLength="1000" className="mt-1" placeholder="(optional)" value={this.state.note} onChange={this.handleNote} />
                </div>
              </div>
              <input type="submit" value="Invite" hidden />
            </form>
          </ModalBody>
          <ModalFooter>
            {
              this.state.error && <div style={{color: "red", textAlign: "left", paddingBottom: "1rem"}}>{this.state.error}</div>
            }
            {
              this.state.message ? <div style={{textAlign: "left", paddingBottom: "1rem"}}>{this.state.message}</div> :
              <Button color="primary" onClick={this.handleSubmit} disabled={!this.state.can_send}>Send Invites</Button>
            }
          </ModalFooter>
        </Modal>
      </Button>
    );
  }
}
