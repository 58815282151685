import React from 'react';

import {
  Button,
  Container,
  Card,
  Row, Col,
  Input,
} from 'reactstrap';

import {
  MobileHeader,
  ContentWrapper
} from 'common/AdminComponents.js';

import { locationStates } from 'utils/constants';

import {
  create_club, login,
} from 'utils/api';

import 'pages/admin/Admin.css'
import 'common/McModal.css';

export default class CreateClub extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 0,
      showConfirmation: false,
      events: [],
      clubName: "",
      slug: "",
      commissioner: "",
      allowInvites: true,
      entryFee: '',
      commissFirstNm: '',
      commissLastNm: '',
      addressLine: '',
      city: '',
      state: '',
      zip: '',
      maxEntries: '',
      payoutStructure: '3',
      league: null,
      userLoggedIn: false,
      usrEmail: '',
      usrPwd: '',
      me: null,
      referraluser: null,
      errorMsg: null,
      fldNm:'',
    };

    this.toggle = this.toggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleAllowInvitesRadio = this.handleAllowInvitesRadio.bind(this);
    this.navigationClick = this.navigationClick.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handleCommissConsole = this.handleCommissConsole.bind(this);

  }

  componentDidMount() {
    let referraluser = parseInt(this.props.match.params.referraluser, 10);
    console.log('referraluser : '+ referraluser);
    this.setState({referraluser:(referraluser?referraluser:null)});


  }

  toggle(event) {
    this.setState({
      showConfirmation: !this.state.showConfirmation,
    });

    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

  }

  render() {
    let bodyParams = {...this.state}
    bodyParams.navigationClick = this.navigationClick;
    bodyParams.handleChange = this.handleChange;
    bodyParams.handleAllowInvitesRadio = this.handleAllowInvitesRadio;
    bodyParams.handleNext = this.handleNext;
    bodyParams.toggle = this.toggle;
    bodyParams.handleCommissConsole = this.handleCommissConsole;

    return (
      <div>
        {this.props.authed &&
        <MobileHeader to="New Golf Club"/>
       }
        <ContentWrapper>
          <Container fluid style={{minHeight: "100vh"}} className="px-0">
            <Container className="px-0">
              <Card className="pb-4">
                {!this.state.showConfirmation && <Title {...bodyParams}/> }
                <Body {...bodyParams} />
              </Card>
            </Container>
          </Container>
        </ContentWrapper>
      </div>
    );
  }


  handleChange(event) {
    //alert(event.target.value);
    if (event.target.name === 'slug'){ //allow only char and number
      event.target.value = event.target.value.replace(/[^\w\s]/gi, '').replace(/ /g,'').toLowerCase();
    }

    if (event.target.name === 'entryFee' || event.target.name === 'maxEntries' ){
      event.target.value = event.target.value.replace(/[\D+]/g, '').replace(/ /g,'');
      //alert(event.target.value);
    }
    if (event.target.name === 'entryFee' && parseInt(event.target.value)>10000){ //Max Value 10000
      return;
    }
    this.setState({[event.target.name]: event.target.value});
  }

  handleAllowInvitesRadio(event) {
    let value = event.target.id === "yes";
    if (this.state.allowInvites === value) {
      return;
    }
    this.setState({allowInvites: value});
  }

  navigationClick(step) {
    return () => {
      if (this.state.step !== step) {
        this.setState({step});
      }
    }
  }

  handleCommissConsole(){
    if (this.state.userLoggedIn){
      this.props.history.push("/commissioner/" + this.state.league + "/settings");
      this.props.updateMe(this.state.me);
    }else{
      login(this.state.usrEmail, this.state.usrPwd,
        (response) => {
          if (response.data.success) {
            //this.onSuccessRedirect(response.data.me);
            this.props.setPostLoginURL("/commissioner/" + this.state.league + "/settings");
            this.props.loggedInCallback(response.data.me);

            setTimeout(() => {this.props.history.push("/commissioner/" + this.state.league + "/settings");},100);
          } else {
            alert("There was a problem logging you in.");
          }
        }, (error) => {
          alert("Invalid email or password.");
      })
    }
  }

  handleNext() {
    let nextStep = this.state.step + 1;
    this.setState({errorMsg:null, fldNm:''});
    if (nextStep > 3) {
      if (/^\w+([\.-\.+]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/.test(this.state.commissioner) === false &&
          /^\w+([\.-\.+]?\w+)*@\w+([\.-]?\w+)*(\.melbourne)+$/.test(this.state.commissioner) === false){
        this.setState({fldNm:"commissioner", errorMsg:"Please enter a valid Email address for your commissioner.", step:2});
        return false;
      }

      create_club(
        this.state.clubName,
        this.state.slug,
        this.state.commissioner,
        this.state.allowInvites,
        this.state.entryFee,
        this.state.commissFirstNm,
        this.state.commissLastNm,
        this.state.addressLine,
        this.state.city,
        this.state.state,
        this.state.zip,
        this.state.maxEntries,
        this.state.payoutStructure,
        this.state.referraluser,
      ({success, error, msgTab, fldNm, league, club, me}) => {
        if (success) {
          if (me){
            this.setState({userLoggedIn:true, me:me});
          }else{
            this.setState({userLoggedIn:false, usrEmail:this.state.commissioner, usrPwd: this.state.slug});
          }
          this.setState({clubName: '',slug: '',commissioner: '',allowInvites: false,entryFee: '',commissFirstNm:'',commissLastNm:'',
            addressLine: '', city: '', state: '', zip: '', maxEntries:'', payoutStructure: '3', showConfirmation: true,league});
          //alert('Club Id ' + club + ' and League Id :' + league + ' is created successfully');
          //this.props.updateMe(me);
          //this.props.history.push("/commissioner/"+league+'/events');
        } else {
          //alert(error);
          this.setState({errorMsg:error, step:msgTab, fldNm});
        }
      }, (error) => {
        console.log(error);
        alert("Error occurred while trying to create the Club.");
      });
    } else {
      this.setState({step: nextStep});
    }

  }

}


const Title = (props) =>{
  let titleTxt = 'Welcome';
  switch (props.step) {
    default:
    case 0:
      titleTxt = 'Welcome';
      break;
    case 1:
      titleTxt = 'Club Info';
      break;
    case 2:
      titleTxt = 'Contact Info';
      break;
    case 3:
      titleTxt = 'Contest Info';
      break;
  }
  return(
    <h2 className="text-center p-2 pt-4" style={{color:"#212121"}}>{titleTxt}</h2>
  );
}

const Body = (props) => {
  let stepContent;
  switch (props.step) {
    default:
    case 0:
      stepContent = <SectionWelcome {...props} />;
      break;
    case 1:
      stepContent = <SectionOne {...props} />;
      break;
    case 2:
      stepContent = <SectionTwo {...props} />;
      break;
    case 3:
      stepContent = <SectionThree {...props} />;
      break;
  }

  return (
    <div>
      {!props.showConfirmation &&
      <div className="p-3 createClubForm">
        <Col lg={{size:6, offset: 3}} md={{size:10, offset:1}} >
          {props.step > 0 &&
          <Steps step={props.step} navigationClick={props.navigationClick} />
          }
          {stepContent}
          <Row className="pt-4">
            <Button className="nextButton" color="success" size="lg" onClick={props.handleNext} block> {props.step === 3 ?'Submit':'Next'} </Button>
          </Row>
        </Col>
      </div>
      }
      {props.showConfirmation &&
      <div className="p-3 createClubForm">
        <Col xs="12">
          <Row className="profileFormRow pb-3 pb-sm-0 text-center">
            <Col  className="" style={{fontSize:'18px', fontWeight:'bold', color:'#28a745'}}>
              CONGRATULATIONS
            </Col>
          </Row>
          <Row className="profileFormRow pb-3 pb-sm-0">
            <Col  className="" style={{fontSize:'13px', fontWeight:'bold'}}>
              You all done! Check your inbox for an email with additional information.
            </Col>
          </Row>
          <Row className="profileFormRow pb-3 pb-sm-0">
            <Col  className="" style={{fontSize:'13px'}}>
            <li>Your <b>FREE TRIAL</b> begins now and includes your first Major tournament.</li>
            {!props.userLoggedIn && <li>Your initial login is <b>{props.usrEmail}</b> with password <b>{props.usrPwd}</b>.</li>}
            <li>Your facility is in charge of all collections and payouts.</li>
            <li>You are enrolled to play all the Majors plus The Players Championship. Please visit the Commissioner Console to review additional settings or revise your schedule.</li>
            <li>We’re here to help. Expect a follow-up from us shortly. Thank you!</li>

            </Col>
          </Row>
          <Row className="pt-4 text-center d-flex justify-content-center">
            <Col  className="text-center d-flex justify-content-center">
              <Button className="nextButton" color="success" style={{maxWidth:'200px'}}
                size="sm" onClick={props.handleCommissConsole} block> {'COMMISSIONER CONSOLE'} </Button>
            </Col>
          </Row>
          <Row className="profileFormRow pb-3 pb-sm-0">
            <Col  className="text-center " style={{fontSize:'13px'}}>
              Questions? Please reach us a
              <a className="" href="mailto:support@majorschallenge.com">
                <span> support@majorschallenge.com</span>
              </a>
              {'.'}
            </Col>
          </Row>
        </Col>
      </div>
      }

    </div>
  )
  }

const Steps = (props) =>
  <div className="d-flex justify-content-between text-center px-sm-1 px-md-2 px-lg-3 mb-4">
    <Step current={props.step} index={1} title="Club" navigationClick={props.navigationClick}/>
    <Step current={props.step} index={2} title="Contact" navigationClick={props.navigationClick} next={props.next} majors={props.majors} />
    <Step current={props.step} index={3} title="Contest" navigationClick={props.navigationClick} />
  </div>

const Step = (props) =>
  <div className={ "step d-flex align-items-center flex-column" + (props.current === props.index ? " activeStep" : "") }  onClick={props.navigationClick(props.index)}>
    <div className="stepIndex">{props.index}</div>
    <div className="stepTitle pt-2">{props.title}</div>
  </div>

const SectionWelcome = (props) => {

    return (
      <div>
        <Row>
          <p>
            Whether you already run golf pools for members or are looking for a new way to engage them, <b>Majors Challenge</b> can help create a buzz at your club all season long.
          </p>
          <p>
            As the premier platform for golf clubs, Majors Challenge brings the excitement of real-time fantasy golf while simplifying the process of administering a pool. Your members enjoy an easy-to-navigate online portal where they select their teams and follow the action. Your club gets an opportunity to uniquely engage members while even driving pro shop sales.
          </p>
          <p>
            Setting up your league is only takes a few seconds. Once you're done make sure to visit the Commissioner Console to fine tune any settings. We're always here to help.
          </p>
        </Row>
      </div>
    );
}


const SectionOne = (props) => {
    const locationStateOptions = locationStates.map((item) =>
        <option value={item}>{item}</option>
    );

    return (
      <div>
        <Row className="contentTitle pb-2">
          Club Name
        </Row>
        <Row>
          <Input placeholder="Enter Club name" value={props.clubName}
            name="clubName" onChange={props.handleChange} maxLength="30"/>
        </Row>
        {props.fldNm === 'clubName' &&
          <Row className="pt-2 pb-0" style={{fontSize: ".85rem", color: "red"}}>
            {props.errorMsg}
          </Row>
        }
        <Row className="pt-2 pb-2" style={{fontSize: ".85rem", color: "#78909c"}}>
          What is your club or facility name?
        </Row>

        <Row className="contentTitle py-2">
          State
        </Row>
        <Row>
          <Input type="select"  value={props.state} name="state"
            onChange={props.handleChange}>
            <option value={''}>{'Select'}</option>
            {locationStateOptions}
            <option value={'Canada'}>{'Canada'}</option>
            <option value={'Outside US'}>{'Outside US'}</option>
          </Input>
        </Row>
        {props.fldNm === 'state' &&
          <Row className="pt-2 pb-0" style={{fontSize: ".85rem", color: "red"}}>
            {props.errorMsg}
          </Row>
        }
        <Row style={{fontSize: ".9rem", color: "#78909c"}} className="pb-3">
          Tell us the state you’re located in.
        </Row>

        <Row className="contentTitle pb-2">
          Web Abbreviation
        </Row>
        <Row>
          <Input placeholder="acme" value={props.slug} name="slug" onChange={props.handleChange} maxLength="30"/>
        </Row>
        {props.fldNm === 'slug' &&
          <Row className="pt-2 pb-0" style={{fontSize: ".85rem", color: "red"}}>
            {props.errorMsg}
          </Row>
        }
        <Row className="pt-2 pb-2" style={{fontSize: ".85rem", color: "#78909c"}}>
          <p>Please abbreviate your facility name here. This value will be used for your custom web link which is how you’ll invite members to your golf pool. For example, Acme Golf Club might abbreviate to acme.</p>
          <p>
            {!props.slug && <span className="" style={{color: "#555"}}>{window.location.host + '/club/acme'}</span>}
            {props.slug && <span className="" style={{color: "#007bff"}}>{window.location.host + '/club/' + props.slug}</span>}
          </p>
        </Row>
      </div>
    )
  }


const SectionTwo = (props) => {


      return (
        <div>
          <Row className="contentTitle pb-2">
            Commissioner First Name
          </Row>
          <Row>
            <Input placeholder="First Name" value={props.commissFirstNm} name="commissFirstNm" onChange={props.handleChange} maxLength="30"/>
          </Row>
          {props.fldNm === 'commissFirstNm' &&
            <Row className="pt-2 pb-0" style={{fontSize: ".85rem", color: "red"}}>
              {props.errorMsg}
            </Row>
          }
          <Row className="pt-2 pb-2" style={{fontSize: ".85rem", color: "#78909c"}}>
            Who will oversee running your golf pool?  This person is called the commissioner.
          </Row>

          <Row className="contentTitle pb-2">
            Commissioner Last Name
          </Row>
          <Row>
            <Input placeholder="Last Name" value={props.commissLastNm} name="commissLastNm" onChange={props.handleChange} maxLength="30"/>
          </Row>
          {props.fldNm === 'commissLastNm' &&
            <Row className="pt-2 pb-0" style={{fontSize: ".85rem", color: "red"}}>
              {props.errorMsg}
            </Row>
          }
          <Row className="pt-2 pb-2" style={{fontSize: ".85rem", color: "#78909c"}}>
            Last name of your commissioner.
          </Row>

          <Row className="contentTitle pb-2">
            Commissioner Email
          </Row>
          <Row>
            <Input type="email" placeholder="clubpro@acmegc.com" value={props.commissioner} name="commissioner" onChange={props.handleChange} maxLength="75"/>
          </Row>
          {props.fldNm === 'commissioner' &&
            <Row className="pt-2 pb-0" style={{fontSize: ".85rem", color: "red"}}>
              {props.errorMsg}
            </Row>
          }
          <Row className="pt-2 pb-2" style={{fontSize: ".85rem", color: "#78909c"}}>
            Email address for your commissioner.
          </Row>
        </div>
      )
}

const SectionThree = (props) => {

      return (
        <div>
          <Row className="contentTitle pb-2">
            Entry Fee
          </Row>
          <Row>
            <Input type="text" placeholder="$20" value={props.entryFee} name="entryFee"
            onChange={props.handleChange} min="0" max="1000"/>
          </Row>
          {props.fldNm === 'entryFee' &&
            <Row className="pt-2 pb-0" style={{fontSize: ".85rem", color: "red"}}>
              {props.errorMsg}
            </Row>
          }
          <Row className="pt-2 pb-2" style={{fontSize: ".85rem", color: "#78909c"}}>
            How much will you charge a member to play? If this is a promotional or free contest then enter $0.
          </Row>

          <Row className="contentTitle pb-2">
            Maximum Entries
          </Row>
          <Row>
            <Input type="text" placeholder="unlimited" value={props.maxEntries} name="maxEntries"
            onChange={props.handleChange} min="0" max="1000"/>
          </Row>
          {props.fldNm === 'maxEntries' &&
            <Row className="pt-2 pb-0" style={{fontSize: ".85rem", color: "red"}}>
              {props.errorMsg}
            </Row>
          }
          <Row className="pt-2 pb-2" style={{fontSize: ".85rem", color: "#78909c"}}>
            Participants are responsible for an entry fee for each roster they generate in a tournament. You can restrict how many entries a participant may create here. Leave blank to keep as unlimited. For those running free tournaments consider setting this value to 1.
          </Row>

          <Row className="contentTitle pb-2">
            Payout Structure
          </Row>
          <Row>
            <Input type="select" value={props.payoutStructure} name="payoutStructure"
            onChange={props.handleChange}>
              <option value={'1'}>{'1'}</option>
              <option value={'2'}>{'2'}</option>
              <option value={'3'}>{'3'}</option>
              <option value={'4'}>{'4'}</option>
              <option value={'5'}>{'5'}</option>
            </Input>
          </Row>
          <Row className="pt-2 pb-2" style={{fontSize: ".85rem", color: "#78909c"}}>
            How many places would you like to declare a winner? This value and payout percentages can be changed in the Commissioner Console.
          </Row>
          <Row className="pt-2 pb-2" style={{fontSize: ".85rem", color: ""}}>
          {props.payoutStructure === '1' && <span className="ml-2" style={{color:'#007bff'}}>{'One winner to receive 100%'}</span>}
          {props.payoutStructure === '2' && <span className="ml-2" style={{color:'#007bff'}}><b>2 Winners: </b>{'60% / 40%'}</span>}
          {props.payoutStructure === '3' && <span className="ml-2" style={{color:'#007bff'}}><b>3 Winners: </b>{'60% / 30% / 10%'}</span>}
          {props.payoutStructure === '4' && <span className="ml-2" style={{color:'#007bff'}}><b>4 Winners: </b>{'50% / 25% / 15% / 10%'}</span>}
          {props.payoutStructure === '5' && <span className="ml-2" style={{color:'#007bff'}}><b>5 Winners:  </b>{'50% / 20% / 15% / 10% / 5% '}</span>}
          </Row>

        </div>
      )
}

const ClubCreateForm = (props) => {
  const locationStateOptions = locationStates.map((item) =>
      <option value={item}>{item}</option>
  );

    return (
      <div className="createClubForm">
        <Row className="contentTitle pb-2">
          Club Name
        </Row>
        <Row>
          <Input placeholder="Acme Golf Club" value={props.clubName} name="clubName"
          onChange={props.handleChange} maxLength="30"/>
        </Row>
        <Row className="contentTitle pb-2 pt-3">
          URL Extension
        </Row>
        <Row>
          <Input placeholder="acmegc" value={props.slug} name="slug"
          onChange={props.handleChange} maxLength="30"/>
        </Row>
        <Row className="contentTitle pb-2 pt-3">
          Commissioner Email
        </Row>
        <Row>
          <Input type="email" placeholder="clubpro@acmegc.com" value={props.commissioner} name="commissioner"
          onChange={props.handleChange} maxLength="75"/>
        </Row>
        <Row className="contentTitle pb-2 pt-3">
          Commissioner First Name
        </Row>
        <Row>
          <Input placeholder="First Name" value={props.commissFirstNm} name="commissFirstNm"
          onChange={props.handleChange} maxLength="30"/>
        </Row>
        <Row className="contentTitle pb-2 pt-3">
          Commissioner Last Name
        </Row>
        <Row>
          <Input placeholder="Last Name" value={props.commissLastNm} name="commissLastNm"
          onChange={props.handleChange} maxLength="30"/>
        </Row>

        <Row className="pb-0 pt-3">
          <Col sm="6" md="5" className="contentTitle" style={{paddingLeft:'0px'}}>
            Allow Member Invites
          </Col>
          <Col sm="6" md="5" className="d-flex">
          <div>
          <Input type="radio" name="allowInvites" id="yes" className="mr-3" value={!!props.allowInvites} checked={!!props.allowInvites} onClick={props.handleAllowInvitesRadio} />
          <span style={{color:"#555"}}> Yes </span>
          </div>
          <div className="ml-5">
          <Input type="radio" name="allowInvites" id="no" className="mr-3" value={!props.allowInvites} checked={!props.allowInvites} onClick={props.handleAllowInvitesRadio} />
          <span style={{color:"#555"}}> No </span>
          </div>
          </Col>

        </Row>
        <Row className="contentTitle pb-2 pt-3">
          Entry Fee
        </Row>
        <Row>
          <Input type="number" placeholder="$20" value={props.entryFee} name="entryFee"
          onChange={props.handleChange} min="0" max="1000"/>
        </Row>

        <Row className="contentTitle pb-2 pt-3">
          Located Street
        </Row>
        <Row>
          <Input placeholder="123 Main St" value={props.addressLine} name="addressLine"
          onChange={props.handleChange} maxLength="40"/>
        </Row>
        <Row className="contentTitle pb-2 pt-3">
          Located City
        </Row>
        <Row>
          <Input placeholder="New York" value={props.city} name="city"
          onChange={props.handleChange} maxLength="30"/>
        </Row>
        <Row className="contentTitle pb-2 pt-3">
          Located State
        </Row>
        <Row>
          <Input type="select"  value={props.state} name="state"
            onChange={props.handleChange}>
            <option value={''}>{'Select'}</option>
            {locationStateOptions}
          </Input>
        </Row>
        <Row className="contentTitle pb-2 pt-3">
          Located Zip
        </Row>
        <Row>
          <Input placeholder="12345" value={props.zip} name="zip"
          onChange={props.handleChange} type="number" maxLength="5"/>
        </Row>

      </div>
    )
  }
