import React from 'react';
import {
  Row,
  Col,
  Modal,
  ModalBody,
  Table,
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
  ButtonGroup, Button,
  Badge,
  Collapse,
} from 'reactstrap';

import FontAwesome from 'react-fontawesome';
import numeral from 'numeral';
import GolferScoreCard from 'common/GolferScoreCard.js';

import Out from 'img/out.gif';

import { get_golfer } from 'utils/api.js';
import { CURRENT_SEASON } from 'utils/constants';

import 'common/GolferModal.css';


export default class GolferModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      golferid: props.golferid,
      loaded: false,
      bio: null,
      tournaments: null,
      majors: null,
      season: CURRENT_SEASON+"",
      eventid: null,
      seasonDropdownOpen: false,
      tournamentDropdownOpen:false,
      selectedTab: "MAJORS",
      error: null,
      collapsed: true,
    };

    this.fetchGolfer = this.fetchGolfer.bind(this);
    this.renderWithoutChosenGolfer = this.renderWithoutChosenGolfer.bind(this);
    this.renderGolferUnavailable = this.renderGolferUnavailable.bind(this);
    this.renderChosenGolfer = this.renderChosenGolfer.bind(this);
    this.toggleSeasonDropdown = this.toggleSeasonDropdown.bind(this);
    this.toggleTournamentDropdown = this.toggleTournamentDropdown.bind(this);
  }
  componentDidMount() {
    this.fetchGolfer();
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.golferid !== this.state.golferid) {
      this.setState({
        golferid: nextProps.golferid,
        loaded: false,
        bio: null,
        tournaments: null,
        majors: null,
        live: null,
        error: null,
        season: CURRENT_SEASON+"",
        eventid: null,
        seasonDropdownOpen: false,
        tournamentDropdownOpen:false,
        collapsed:true,
        selectedTab: "MAJORS",
      }, () => this.fetchGolfer());
    }
  }
  fetchGolfer() {
    if (!this.state.golferid) {
      return;
    }
    get_golfer(this.state.golferid, (bio, tournaments, majors, live) => {
      this.setState({bio, tournaments, majors, live, loaded: true,
                    selectedTab: (live?"LIVE":"MAJORS")});
    }, (error) => {
      this.setState({loaded: true, error: 'Information for the chosen golfer is currently unavailable.'});
    });
  }
  toggleSeasonDropdown() {
    this.setState({
      seasonDropdownOpen: !this.state.seasonDropdownOpen
    });
  }
  toggleTournamentDropdown(){
    this.setState({
      tournamentDropdownOpen: !this.state.tournamentDropdownOpen
    });
  }
  renderWithoutChosenGolfer() {
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} className="golferModalContainer">
        <div className="golferModal text-center py-2">
          <FontAwesome name="spinner" size="4x" spin />
        </div>
      </Modal>
    )
  }
  renderGolferUnavailable() {
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} className="golferModalContainer">
        <div className="golferModal text-center py-5">
          {this.state.error}
        </div>
      </Modal>
    )
  }
  renderChosenGolfer() {
    const dataForChosenSeason = this.state.tournaments[this.state.season];
    const seasons = Object.keys(this.state.tournaments).reverse().map((season) =>
      <DropdownItem key={season} onClick={() => this.setState({season: season, eventid:null,eventname:''})}>
        {season}
      </DropdownItem>
    )

    /*let tournamentNames;
    let selectedTounament;
    if (dataForChosenSeason){
      if (!this.state.eventid)
        this.setState({eventid: dataForChosenSeason[0].eventid, eventname:dataForChosenSeason[0].name});

      selectedTounament = dataForChosenSeason.filter(item => item.eventid === this.state.eventid);
      tournamentNames = dataForChosenSeason.map((item) =>
        <DropdownItem key={item.eventid} onClick={() => this.setState({eventid: item.eventid, eventname:item.name})}>
          {item.name}
        </DropdownItem>
      );
    }*/

    let mainContent;
    if (dataForChosenSeason && this.state.selectedTab === "ALL") {
      mainContent = <GolferModalTournamentsTable tournaments={dataForChosenSeason} golferid={this.state.golferid}/>
    } else if (this.state.selectedTab === "ALL") {
      mainContent = <h5 style={{}}>No Data for this year.</h5>
    } else if (this.state.selectedTab === "MAJORS"){
      mainContent = <GolferModalMajorsTable majors={this.state.majors} />
    } else if (this.state.selectedTab === "LIVE"){
      mainContent = <GolferModalLiveTable golferid={this.state.golferid} live={this.state.live} />
    }

    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} className="golferModalContainer">
        <div className="golferModal">
          <GolferModalHeader bio={this.state.bio} toggle={this.props.toggle} />
          <ModalBody>
            <Row noGutters className="golferModalOptionRow">
              <Col xs="3" className="d-flex justify-content-start">
                {/*
                  this.state.showAllTournaments &&
                  <Dropdown isOpen={this.state.seasonDropdownOpen} toggle={this.toggleSeasonDropdown} size="sm">
                    <DropdownToggle caret className="golferModalOptionRow_dropdowntoggle">
                      {this.state.season}
                    </DropdownToggle>
                    <DropdownMenu>
                      {seasons}
                    </DropdownMenu>
                  </Dropdown>
                */}
              </Col>
              <Col xs="6" className="d-flex justify-content-center">
                <ButtonGroup size="sm">
                  <Button
                    onClick={() => this.setState({selectedTab: 'MAJORS'})}
                    active={this.state.selectedTab === 'MAJORS'}>
                    <span className="d-block d-sm-none">Majors</span>
                    <span className="d-none d-sm-block">Majors</span>
                  </Button>
                  <Button
                    onClick={() => this.setState({selectedTab: 'ALL'})}
                    active={this.state.selectedTab === 'ALL'}>
                    <span className="d-block d-sm-none">All Events</span>
                    <span className="d-none d-sm-block">All Tournaments</span>
                  </Button>
                  {
                    this.state.live &&
                    <Button
                      onClick={() => this.setState({selectedTab: 'LIVE'})}
                      active={this.state.selectedTab === 'LIVE'}>
                      <span className="d-block d-sm-none">Live</span>
                      <span className="d-none d-sm-block">Live</span>
                    </Button>
                  }
                </ButtonGroup>
              </Col>
              <Col xs="3" className="d-flex justify-content-end">
                {
                  /*this.state.showAllTournaments &&
                  <Dropdown isOpen={this.state.tournamentDropdownOpen} toggle={this.toggleTournamentDropdown} size="sm">
                    <DropdownToggle caret className="golferModalOptionRow_dropdowntoggle">
                      {this.state.eventname}
                    </DropdownToggle>
                    <DropdownMenu>
                      {tournamentNames}
                    </DropdownMenu>
                  </Dropdown>*/
                  this.state.selectedTab === 'ALL' &&
                  <Dropdown isOpen={this.state.seasonDropdownOpen} toggle={this.toggleSeasonDropdown} size="sm">
                    <DropdownToggle caret className="golferModalOptionRow_dropdowntoggle">
                      {this.state.season}
                    </DropdownToggle>
                    <DropdownMenu>
                      {seasons}
                    </DropdownMenu>
                  </Dropdown>
                }
              </Col>

            </Row>
            {mainContent}
          </ModalBody>
        </div>
      </Modal>
    )
  }
  render() {
    if (!this.state.loaded) {
      return this.renderWithoutChosenGolfer();
    } else if (this.state.error) {
      return this.renderGolferUnavailable();
    } else {
      return this.renderChosenGolfer();
    }
  }
}


class GolferModalHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {hasImg: true};
    this.imgNotFound = this.imgNotFound.bind(this);
  }
  imgNotFound() {
    this.setState({hasImg: false})
  }
  render() {
    const bio = this.props.bio;
    const toggle = this.props.toggle;
    const winnings = numeral(bio.winnings).format('$0,0');

    const rotowire = bio.rotowire &&
      <Button color="success" size="sm" className="mx-5"
        onClick={() => {window.open(bio.rotowire, '_blank');}}>
        <img src="https://media.majorschallenge.com/rwlogo-h100.png" width="58.4" height="20" alt="Rotowire News" />
      </Button>;

    return (
      <div>
        {/* <FontAwesome name="times" size="2x" className="golferModalHeader_close" onClick={toggle}/> */}
        <GolferModalClose onClick={toggle}/>
        <Row noGutters className="golferModalHeader pb-2 pb-sm-0">
          <GolferModalHeaderImg imgSrc={bio.image} golferName={bio.name} hasImg={this.state.hasImg} imgNotFound={this.imgNotFound}/>
          <Col className="golferModalHeader_content">
            <h3 className="golferModalHeader_name d-flex align-items-start">
              <span>{bio.name}</span>
              {rotowire}
            </h3>
            <span className="golferModalHeader_subtitle">{bio.country} &bull; {bio.age} years old</span>
            <Row noGutters className="golferModalHeaderDataRow">
              <GolferModalHeaderData label="season" value={bio.season} />
              <GolferModalHeaderData label="average" value={bio.average} />
              <GolferModalHeaderData label="cuts made" value={bio.cutsmade} />
              <GolferModalHeaderData label="1st" value={bio.first} small />
              <GolferModalHeaderData label="2nd" value={bio.second} small />
              <GolferModalHeaderData label="3rd" value={bio.third} small />
              <GolferModalHeaderData label="top 10" value={bio.top10} small />
              <GolferModalHeaderData label="top 25" value={bio.top25} small />
            </Row>
            <Row noGutters className="golferModalHeaderDataRow">
              <GolferModalHeaderData label="winnings" value={winnings} />
              <GolferModalHeaderData label="world rank" value={bio.rank} />
              <GolferModalHeaderData label="avg drive" value={bio.avgdrive} />
              <GolferModalHeaderData label="drive acc" value={bio.driveaccuracy} />
              <GolferModalHeaderData label="gir" value={bio.gir}  />
              <GolferModalHeaderData label="scrambling" value={bio.scrambling} />
            </Row>
          </Col>
        </Row>
        {bio.injury &&
        <Row noGutters className="golferModalInjuryHeader pb-2 pb-sm-0">
          <Col className="">
            <h7 className="my-2 d-flex align-items-start">
              <span className="golferModalInjuryHeader_label d-none">{'Status:'}</span>
              <span className="golferModalInjuryHeader_value text-nowrap d-none">{bio.injury.status||'?'}</span>

              <span className="golferModalInjuryHeader_label">{'Injury:'}</span>
              <span className="golferModalInjuryHeader_value text-nowrap">{bio.injury.type||'unknown'}</span>

              <span className="golferModalInjuryHeader_label d-none d-sm-block">{'Detail:'}</span>
              <span className="golferModalInjuryHeader_value text-nowrap d-none d-sm-block">{bio.injury.detail||'unknown'}</span>

              <span className="golferModalInjuryHeader_label text-nowrap d-none d-sm-block">{'Estimated Return:'}</span>
              <span className="golferModalInjuryHeader_value text-nowrap d-none d-sm-block">{bio.injury.returnDate}</span>

              <span className="golferModalInjuryHeader_label text-nowrap d-none d-mob-potrait">{'Est. Return:'}</span>
              <span className="golferModalInjuryHeader_value text-nowrap d-none d-mob-potrait">{bio.injury.returnDate}</span>

              {bio.injury.isWithdrawn === 1 &&
                  <Badge className="mx-2 text-white bg-danger" style={{marginTop:'1px'}} color="denger">OUT</Badge>
              }
            </h7>
          </Col>
        </Row>
        }
      </div>
    )
  }
}

const GolferModalClose = ({onClick}) => {
  return <span className="golferModalHeader_close" onClick={onClick}/>
}

const GolferModalHeaderImg = ({imgSrc, golferName, hasImg, imgNotFound}) => {
  if (hasImg) {
    return <img src={imgSrc} alt={golferName} className="d-none d-sm-block" onError={imgNotFound}/>
  } else {
    return <img src="https://media.majorschallenge.com/headshots/headshot.png" alt="headshot missing" className="d-none d-sm-block golferModalHeader_imgplaceholder" />
  }
}

const GolferModalHeaderData = ({label, value, small, large}) => {
  let cls;
  if (small) {
    cls = "golferModalHeaderDataCellSmall";
  } else if (large) {
    cls = "golferModalHeaderDataCellLarge";
  } else {
    cls = "golferModalHeaderDataCell";
  }
  return (
    <Col className={cls}>
      <div className="golferModalHeaderDataCell_value">{value}</div>
      <div className="golferModalHeaderDataCell_label pb-1">{label}</div>
    </Col>
  )
}

class GolferModalTournamentsTableRow extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = { collapsed: true};
  }

  toggle() {
    this.setState({ collapsed: !this.state.collapsed });
  }

  componentWillReceiveProps(nextProps) {
      this.setState({
        collapsed:true,
      });
  }

  render() {
    const item = this.props.tournamentRow;
    let badge = null;
    if (item.major === true) {
      badge = <Badge color="success">MAJOR</Badge>
    }
    return (
      <tbody>
        <tr key={this.props.index} onClick={this.toggle} className="golferModalTableRow">
          <td className="golferModalTableRow_leftcell">{item.date}</td>
          <td className="golferModalTableRow_leftcell">{item.name} {badge}</td>
          <td>{item.place}</td>
          <td>{item.r1}</td>
          <td>{item.r2}</td>
          <td>{item.r3}</td>
          <td>{item.r4}</td>
          <td>{!['CUT','WD','MDF'].includes(item.place) && item.total}</td>
          <td className="align-middle"><FontAwesome size="lg" name={this.state.collapsed ? "angle-down" : "angle-up"} /></td>
        </tr>
        <tr>
          <td colSpan="9" className="px-0" style={{padding: 0, borderTop:"none"}}>
            <Collapse isOpen={!this.state.collapsed}>
              <GolferScoreCard golferid={this.props.golferid} eventid={item.eventid}/>
            </Collapse>
          </td>
        </tr>
      </tbody>
    );

  }
}


class GolferModalTournamentsTable extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const tournaments = this.props.tournaments;
    const golferid = this.props.golferid;

    const rows = tournaments.map((item, index) =>
        <GolferModalTournamentsTableRow tournamentRow={item} golferid={golferid} index={index}/>
    );


    return (
      <div className="golferModalTableContainer">
        <Table className="golferModalTable" >
          <thead>
            <tr className="golferModalTableHeader">
              <th className="">Date</th>
              <th className="">Event</th>
              <th className="text-right">Place</th>
              <th className="text-right">R1</th>
              <th className="text-right">R2</th>
              <th className="text-right">R3</th>
              <th className="text-right">R4</th>
              <th className="text-right">Total</th>
              <th className="align-middle"></th>
            </tr>
          </thead>
            {rows}
        </Table>
      </div>
    )
  }
}

const GolferModalMajorsTable = ({majors}) => {
  const rows = Object.keys(majors).map((major) => {
    return (
      <GolferModalMajorsTableRow key={major} name={major} {...majors[major]} />
    )
  });

  return (
    <div className="golferModalTableContainer">
      <Table className="golferModalTable" >
        <thead>
          <tr className="golferModalTableHeader">
            <th className="">{'Event'}</th>
            <th className="golferModalTableHeader_centeritem">{'Entered'}</th>
            <th className="golferModalTableHeader_centeritem">{'Wins'}</th>
            <th className="golferModalTableHeader_centeritem">{"Top 10's"}</th>
            <th className="golferModalTableHeader_centeritem">{"Top 25's"}</th>
            <th className="golferModalTableHeader_centeritem">{'Cuts'}</th>
            <th className="golferModalTableHeader_centeritem">{'Earnings'}</th>
            <th></th>
          </tr>
        </thead>
        {rows}
      </Table>
    </div>
  )
}

class GolferModalMajorsTableRow extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = { collapsed: true};
  }

  toggle() {
    this.setState({ collapsed: !this.state.collapsed});
  }

  render() {
    const major = this.props.name;
    const overall = this.props.overall;
    return (
      <tbody>
        <tr className="golferModalTableRow" onClick={this.toggle}>
          <td className="golferModalTableRow_leftcell">{major}</td>
          <td className="golferModalTableRow_centercell">{overall.events}</td>
          <td className="golferModalTableRow_centercell">{overall.wins}</td>
          <td className="golferModalTableRow_centercell">{overall.top10s}</td>
          <td className="golferModalTableRow_centercell">{overall.top25s}</td>
          <td className="golferModalTableRow_centercell">{overall.cuts}</td>
          <td className="golferModalTableRow_centercell">{numeral(overall.earnings).format('$0,0')}</td>
          <td className="golferModalTableRow_centercell"><FontAwesome size="lg" name="angle-down" /></td>
        </tr>
        <tr>
          <td colSpan="12" style={{padding: 0, borderTop:"none"}}>
            <Collapse isOpen={!this.state.collapsed}>
              <GolferModalMajorsTableRowInnerTable history={this.props.history}/>
            </Collapse>
          </td>
        </tr>
      </tbody>
    )
  }
}

const GolferModalMajorsTableRowInnerTable = ({history}) => {
  const rows = history.map((item, index) => {
    return (
      <tr key={index} className="golferModalTableRow">
        <td className="golferModalTableRow_leftcell">{item.date}</td>
        <td className="golferModalTableRow_centercell">{item.place}</td>
        <td className="golferModalTableRow_centercell">{item.r1}</td>
        <td className="golferModalTableRow_centercell">{item.r2}</td>
        <td className="golferModalTableRow_centercell">{item.r3}</td>
        <td className="golferModalTableRow_centercell">{item.r4}</td>
        <td className="golferModalTableRow_centercell">{item.total}</td>
        <td className="golferModalTableRow_centercell">{numeral(item.winnings).format('$0,0')}</td>
      </tr>
    )
  });
  return (
    <Table className="golferModalTable" striped>
      <thead>
        <tr className="golferModalTableHeader">
          <th className="">Date</th>
          <th className="golferModalTableHeader_centeritem">Place</th>
          <th className="golferModalTableHeader_centeritem">R1</th>
          <th className="golferModalTableHeader_centeritem">R2</th>
          <th className="golferModalTableHeader_centeritem">R3</th>
          <th className="golferModalTableHeader_centeritem">R4</th>
          <th className="golferModalTableHeader_centeritem">Total</th>
          <th className="golferModalTableHeader_centeritem">Winnings</th>
        </tr>
      </thead>
      <tbody>
        {rows}
      </tbody>
    </Table>
  )
}

class GolferModalLiveTable extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {

    return (
      <div className="golferModalTableContainer">
        <div className="liveScoreCardHeader">{this.props.live.name}</div>
        <div class="liveScoreCard">
          <GolferScoreCard golferid={this.props.golferid} eventid={this.props.live.id}/>
        </div>
      </div>
    )
  }
}
