import React from 'react';
import {
  Badge,
  Card,
  Table,
  Tooltip,
  Button,
} from 'reactstrap';

import { NavLink } from 'react-router-dom';
import FontAwesome from 'react-fontawesome';
import numeral from 'numeral';

import EnterEditEventModal from 'pages/commissioner/EnterEditEventModal';
import {
  MobileHeader,
  ContentWrapper
} from 'common/CommissionerComponents.js';

import LeagueBalanceWarning from 'common/LeagueBalanceWarning';

import {
  get_events,
  hide_unhide_event,
} from 'utils/api.js';

export default class Events extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      viewFullUpcoming: false,
      costTooltip: false,
      teamTooltip: false,
    };

    this.fetchEvents = this.fetchEvents.bind(this);
    this.toggleViewFullUpcoming = this.toggleViewFullUpcoming.bind(this);
    this.toggleCostTooltip = this.toggleCostTooltip.bind(this);
    this.toggleTeamTooltip = this.toggleTeamTooltip.bind(this);
  }

  componentDidMount() {
    // TODO check if the Commissioner component knows we're on the events page, if not use callback

    // if (this.props.activeLeague !== paramLeague) {
    //   this.props.selectLeague(paramLeague);
    // }

    this.fetchEvents();
  }

  componentDidUpdate(prevProps, prevState) {
    // let paramLeague = parseInt(this.props.match.params.league, 10);

    // TODO check if the Commissioner component knows we're on the events page, if not use callback
    // if (this.props.activeLeague !== paramLeague) {
    //   this.props.selectLeague(paramLeague);
    // }

    if (prevProps.activeLeague === this.props.activeLeague) {
      return
    }

    this.fetchEvents();
  }

  fetchEvents() {
    if (!this.props.activeLeague) {
      return;
    }
    get_events(this.props.activeLeague,
    ({success, upcoming, past, error, club}) => {
      if (success) {
        this.setState({upcoming, past, club});
      } else {
        console.log(error);
      }
    }, (error) => {
      console.log(error);
    });
  }

  toggleViewFullUpcoming() {
    this.setState({ viewFullUpcoming: !this.state.viewFullUpcoming });
  }

  toggleCostTooltip() {
    this.setState({ costTooltip: !this.state.costTooltip });
  }

  toggleTeamTooltip() {
    this.setState({ teamTooltip: !this.state.teamTooltip });
  }

  hideUnhideEvent = (event) => {
    let past = this.state.past;
    let pastEvent = past.filter((e) => {return e.id === event.id})[0];
    pastEvent.hidden = !pastEvent.hidden;
    this.setState({past});

    hide_unhide_event(this.props.activeLeague, event.id,
    ({success, past, error}) => {
      if (success) {
        this.setState({past});
      } else {
        console.log(error);
      }
    }, (error) => {
      console.log(error);
    });
  }

  render() {
    let upcomingParams = {
      upcoming: this.state.upcoming,
      activeLeague: this.props.activeLeague,
      viewFullUpcoming: this.state.viewFullUpcoming,
      fetchEvents: this.fetchEvents,
      leaveEvent: this.leaveEvent,
      toggleViewFullUpcoming: this.toggleViewFullUpcoming,
      costTooltip: this.state.costTooltip,
      toggleCostTooltip: this.toggleCostTooltip,
      teamTooltip: this.state.teamTooltip,
      toggleTeamTooltip: this.toggleTeamTooltip,
      club: this.state.club,
      updateMeDefaultEvent: this.props.updateMeDefaultEvent,
      history:this.props.history,
    }

    return (
      <div style={{fontSize: ".85em"}}>
        <MobileHeader to="Tournaments" leagueid={this.props.activeLeague}/>
        <ContentWrapper>
          {this.props.balancewarning && <LeagueBalanceWarning {...this.props} />}
          <EventsHeader />
          { this.state.upcoming &&
              <UpcomingTable {...upcomingParams} />
          }
          { this.state.past && this.state.past.length>0 &&
              <PastTable past={this.state.past} hideUnhideEvent={this.hideUnhideEvent}/>
          }
        </ContentWrapper>
      </div>
    );
  }
}

const EventsHeader = () => (
  <>
  <div className="d-none d-lg-block">
    <div style={{fontSize: "1.75rem", fontWeight: 300}}>Tournaments</div>
  </div>
  <div className="d-block d-sm-none mb-2" style={{color: "#999", padding: "0"}}>
    {"On mobile devices, swipe left to reveal more content."}
  </div>
  <div style={{color: "#999", padding: "0"}}>
    {"Select which tournaments to run golf pools this year. These tournaments are not your season-long schedule. For setting up your season-long schedule, refer to those sections."}
  </div>

  </>
)

const UpcomingTable = (props) => {
  let rows = props.upcoming.map((event, index) => {
    let tournament =
      <td>
          <div style={{color: event.entered ? "#555" : "#ccc", fontWeight:700}}>
            <span className="text-nowrap">{event.name}</span>
            { event.tour === 'lpga' &&
              <Badge color="primary" style={{marginLeft: "0.5rem"}}>LPGA</Badge>
            }
            { event.major &&
              <Badge color="success" style={{marginLeft: "0.5rem"}}>Major</Badge>
            }
            { event.status === "open" &&
              <Badge color="warning" style={{marginLeft: "0.5rem"}}> Open </Badge>
            }
            { ["round1", "round2", "round3", "round4"].indexOf(event.status) !== -1 &&
              <Badge color="info" style={{marginLeft: "0.5rem"}}> Live </Badge>
            }
            { event.cup &&
              <Badge color="primary" style={{marginLeft: "0.5rem"}}> Cup </Badge>
            }
          </div>
          <div style={{color: event.entered ? "#aaa" : "#ccc", fontSize: ".75em"}}>{event.dates} &bull; {event.course}</div>
      </td>

    let cost =
      <td style={{color: event.entered ? "#555" : "#ccc"}} className="pt-3 text-center">
        {event.cost > 0 ? numeral(event.cost).format("$0[.]00") : 'Free'}
      </td>

    let teams =
      <td style={{color: "#555"}} className="pt-3 text-center">
        {event.entered ? event.teams : ''}
      </td>
    let pool =
      <td style={{color: "#106CD6", textTransform: "capitalize"}} className="pt-3 text-center">
        {event.entered ? event.pool : ''}
      </td>
    let factor =
      <td style={{color: "#106CD6"}} className="pt-3 text-center">
        {event.entered ? numeral(event.pool_factor).format(event.pool === "variable" ? "0.00" : "$0.00")+(event.pool === 'variable' ? 'x' : '') : ''}
      </td>

    let enterOrEdit =
      <td>
        <EnterEditEventModal league={props.activeLeague} event={event} fetchEvents={props.fetchEvents}
        updateMeDefaultEvent={props.updateMeDefaultEvent} history={props.history}/>
      </td>

    return (
        <tr key={index} >
          {tournament}
          {!props.club && cost}
          {teams}
          {pool}
          {factor}
          {enterOrEdit}
        </tr>
    )
  });

  return (
    <div className="pt-3">
      <div className="pb-2" style={{fontWeight: 600}}>Upcoming</div>
      <Card body className="p-0">
        <Table responsive>
          <thead>
            <tr style={{backgroundColor: "#f6f6f6", color: "#aaa", "fontSize": "0.7rem", textTransform: "uppercase"}}>
              <th>Tournament</th>
              {! props.club &&
                <th className="text-center text-nowrap">
                  Cost <a href="#costinfo" id="costInfo"><FontAwesome name="info-circle" /></a>
                  <Tooltip placement="top" isOpen={props.costTooltip} target="costInfo" toggle={props.toggleCostTooltip}>
                    Fee based tournaments deduct 1 credit from the league's balance for each team entered.
                  </Tooltip>
                </th>
              }
              <th className="text-center text-nowrap">
                Teams <a href="#teaminfo" id="teamInfo"><FontAwesome name="info-circle" /></a>
                <Tooltip placement="top" isOpen={props.teamTooltip} target="teamInfo" toggle={props.toggleTeamTooltip}>
                  You can restrict user invites by editing a single tournament or changing the value in League Settings.
                </Tooltip>
              </th>
              <th className="text-center text-nowrap">
                Point Pool
              </th>
              <th className="text-center text-nowrap">
                <span>Multiplier / Fee</span>
              </th>
              <th/>
            </tr>
          </thead>
          <tbody>
            {props.viewFullUpcoming ?
              rows :
              rows.splice(0,5)
            }
          </tbody>
        </Table>
        <div style={{color: "blue"}} className="ml-2 mb-1" onClick={() => {props.toggleViewFullUpcoming()}}>
          {props.viewFullUpcoming ?
            "Only show the next 5 tournaments" :
            "View Full Schedule"
          }
        </div>
      </Card>
    </div>
  )
}

const PastTable = (props) => {
  const rows = [];
  let lastSeason = null;
  props.past.forEach((event, index) => {
    if (event.season !== lastSeason) {
      rows.push(
        <tr key={'season'+event.season} style={{backgroundColor: "#dee2e6", color: "gray", "fontSize": "0.85rem"}}>
          <td style={{paddingTop: "0.25rem", paddingBottom: "0.25rem"}}>{event.season}</td>
          <td/><td/><td/><td/>
        </tr>
      );
      lastSeason = event.season;
    }

    let tournament =
      <td>
        <div>
          <NavLink to={"/events/"+event.id} style={{fontWeight: "700"}}>
            {event.name}
            {event.major && <Badge color="success" style={{marginLeft: "0.5rem"}}>Major</Badge>}
            {event.cup && <Badge color="primary" style={{marginLeft: "0.5rem"}}> Cup </Badge>}

          </NavLink>
        </div>

        <div style={{color: "#aaa", fontSize: ".75em"}}>{event.dates} &bull; {event.course}</div>
      </td>
    let teams =
      <td style={{color: "#555"}} className="pt-3 text-center">
        {event.teams}
      </td>
    let factor =
      <td style={{color: "#555"}} className="pt-3 text-center">
        {numeral(event.pool_factor).format(event.pool === "variable" ? "0.00" : "$0.00") + (event.pool === 'variable' ? 'x' : '')}
      </td>
    let points =
      <td style={{color: "#555"}} className="pt-3 text-center">
        {numeral(event.points).format("0,000[.]00")}
      </td>
    let excluded =
      <td style={{color: "#555"}} className="pt-3 text-center">
        ({event.excluded < 0 && '-'}{numeral(event.excluded).format("0[.]00")})
      </td>
    let action =
        <td style={{}} className="pt-3 text-center">
          <Button caret outline color="success" block style={{fontWeight: 600, fontSize: ".85em", textTransform:"uppercase"}} onClick={() => {props.hideUnhideEvent(event)}}> {event.hidden ?
            "Un-hide" :
            "Hide"
          } </Button>
          {/*
          <div style={{color: "blue"}} className="ml-2 mb-1" onClick={() => {props.hideUnhideEvent(event)}}>
            {event.hidden ?
              "Un-hide" :
              "Hide"
            }
          </div>*/}
        </td>

    rows.push(
        <tr key={index}>
          {tournament}
          {teams}
          {factor}
          {points}
          {/*excluded*/}
          {action}
        </tr>
    );
  });

  return (
    <div className="pt-4">
      <div className="pb-2" style={{fontWeight: 600}}>Past</div>
      <Card body className="p-0">
        <Table responsive>
          <thead>
            <tr style={{backgroundColor: "#f6f6f6", color: "#aaa", "fontSize": "0.7rem", textTransform: "uppercase"}}>
              <th>Tournament</th>
              <th className="text-center text-nowrap">Teams</th>
              <th className="text-center text-nowrap">Multiplier / Fee</th>
              <th className="text-center text-nowrap">Purse</th>
              {/*<th className="text-center text-nowrap">Excluded</th>*/}
              <th className="text-center text-nowrap">Action</th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </Table>
      </Card>
    </div>
  )
}
