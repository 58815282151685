import React from 'react';

import {
  Card,
  Button,
  Row, Col,
  Input,
} from 'reactstrap';

import styled from 'styled-components'
import { useTable } from "react-table";


import {
  MobileHeader,
  ContentWrapper
} from 'common/AdminComponents.js';

import {
  get_mc_user_list,
  add_remove_support_member
} from 'utils/api.js';


import 'common/McModal.css';
import CsvDownloader from 'react-csv-downloader';
import FontAwesome from 'react-fontawesome';


const Styles = styled.div`

  /* This is required to make the table full-width */
  display: block;
  max-width: 100%;
  /* This will make the table scrollable when it gets too small */
  .tableWrap {
    display: block;
    max-width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    border-bottom: 0px solid black;
  }
  table {
    /* Make sure the inner table is always as wide as needed */
    width: 100%;
    border-spacing: 0;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }

      :nth-child(even) {
          background-color: #f6f6f6;
      }
    }
    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 0px solid black;
      border-right: 0px solid black;
      /* The secret sauce */
      /* Each cell should grow equally */
      width: 1%;
      /* But "collapsed" cells should be as small as possible */
      &.collapse {
        width: 0.0000000001%;
      }
      :last-child {
        border-right: 0;
      }
    }
  }
  .pagination {
    padding: 0.5rem;
  }

`

export default class Support extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error:'',
      loading: false,
      fName:'',
      lName:'',
      email:'',
      leagueName:'',
      supportInd: '',
      userList: null,
      userListCols: [],
    };


  }

  componentDidMount() {

  }

  componentDidUpdate(prevProps, prevState, snapshot) {


  }

  getMCUserList = () => {
    this.setState({
      loading: true,
      error:'',
      supportInd: '',
    });
    get_mc_user_list(this.state.email, this.state.fName, this.state.lName, this.state.leagueName, '',
    ({success, userList, error}) => {
      if (!success){
        this.setState({
          loading:false,
          error
        });
        return;
      }
      // build result columns
      this.handleSearchResults(userList);

    }, (error) => {
      console.log(error);
    });
  }

  handleSearchResults = (userList) => {
    let userListCols = [];
    if (userList && userList.length >0){
      let cols = Object.getOwnPropertyNames(userList[0]);
      userListCols = cols.filter(a => ['user_id','usr_support_ind','usr_commissioner_ind'].indexOf(a) < 0).map(a => {

        if (a === "league_name") {
          return {
            'id': 'league_name',
            'Header': a,
            'accessor': d => <a href={"/commissioner/"+d.league_id}  >{d.league_name}</a>,
            'className': '',

          }
        }
        if (a === "league_id") {
          return {
            'id': 'league_id',
            'Header': "Action",
            'accessor': d => {
              if (!d.league_id){
                return <div className="" > - </div>
              }else if (d.league_status === 'inactive'){
                return <div className="" >Inactive League</div>
              }else if (d.usr_commissioner_ind === null){
                return <div className="renderComponent" onClick={()=>{this.joinQuitLeague(d.league_id, d.user_id, 'A')}} >Join League</div>
              }else if (d.usr_support_ind){
                return <div className="renderComponent" style={{color: 'red'}} onClick={()=>{this.joinQuitLeague(d.league_id, d.user_id, 'D')}} >Quit League </div>
              } else if (d.usr_commissioner_ind){
                return <div className="" >Already Commissioner</div>
              }else if (!d.usr_commissioner_ind){
                return <div className="renderComponent" onClick={()=>{this.joinQuitLeague(d.league_id, d.user_id, 'A')}} >Set Commissioner</div>
              }

            },
            'className': 'text-nowrap',

          }
        }

        //Boolean handling
        if (typeof userList[0][a] == "boolean") {
          return {
            'id': a,
            'Header': a,
            'accessor': a,
            'className': '',
            Cell: ({ cell: { value } }) => value ? "Yes" : "No"
          }
        }


        let dateformat = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/;
        let className = '';
        //alert(reportResult[0][a]);
        if(userList[0][a] && userList[0][a].toString().match(dateformat)){
          className = 'text-nowrap'
        }
        return {
          'id': a,
          'Header': a,
          'accessor': a,
          'className': className,
        }
      });
    }

    this.setState({
      loading: false,
      userListCols,
      userList,
    });
  }
  handleChange = (event) => {
    this.setState({[event.target.name]: event.target.value});
  }

  joinQuitLeague = (league_id, user_id, action) => {
    this.setState({
      loading: true,
      success: null,
      message: null,
    });
    add_remove_support_member(league_id, user_id, action,
    ({success, message}) => {
      this.setState({
        loading: false,
        success,
        message,
      });
      if (success && this.state.supportInd === ''){
        this.getMCUserList();
      }else if (success && this.state.supportInd === 'true'){
        this.getQuitLeagues();
      }
    }, (error) => {
      console.log(error);
      this.setState({
        loading: false,
        message:error,
      });
    });
  }

  resetSearchForm = () =>{
    this.setState({
      error:'',
      loading: false,
      fName:'',
      lName:'',
      email:'',
      leagueName:'',
      supportInd:'',
      message:'',
      success: null,
    });
  }

  getQuitLeagues = () => {
    this.setState({
      error:'',
      supportInd: 'true',
    });
    get_mc_user_list('','','','', 'true',
    ({success, userList, error}) => {
      if (!success){
        this.setState({
          loading:false,
          error
        });
        return;
      }
      // build result columns
      this.handleSearchResults(userList);

    }, (error) => {
      console.log(error);
    });
  }


  render() {
    /*this.state.userListCols.push(
    {
      id: 'league',
      Header: "Action",
      accessor: "league_id",
      Cell: ActionJoinLeagueCell,
      className: 'text-nowrap'
    });*/

    return (
      <div>
        <MobileHeader to="Reports"/>
        <ContentWrapper>
          <Card className="pb-4">
            <Title />
            <div>
              <div>
                <SearchForm {...this.state} handleChange={this.handleChange}
                  getMCUserList={this.getMCUserList} resetSearchForm ={this.resetSearchForm}
                  getQuitLeagues={this.getQuitLeagues}/>
              </div>
              {(this.state.success && this.state.message) &&
                <div className="text-danger font-weight-bold text-center my-2">
                  <span >{this.state.message}</span>
                </div>
              }
              {(!this.state.success && this.state.message) &&
                <div className="text-danger font-weight-bold text-center my-2">
                  <span >{this.state.message}</span>
                </div>
              }
              {(this.state.userList && this.state.userList.length > 0) &&
              <div>
                <ReactCustomTable columns={this.state.userListCols} data={this.state.userList} joinLeague={this.joinLeague}/>
              </div>
              }
              {(this.state.userList && this.state.userList.length === 0) &&
                <div className="text-danger font-weight-bold text-center my-2">
                  <span >{'No records found'}</span>
                </div>
              }
            </div>

            </Card>
        </ContentWrapper>
      </div>
    );
  }


}

const Title = (props) =>
  <h2 className="text-center p-2 pt-4" style={{color:"#212121"}}>Support</h2>

const SearchForm = (props) => {

  return (
    <div className="ml-3">
        <Row className="mb-3">
          <Col xs="10" md="5" className="mx-3">
            <Row className="contentTitle pb-2">
              First Name
            </Row>
            <Row>
              <Input placeholder="User First name" value={props.fName}
                name="fName" onChange={props.handleChange} maxLength="30"/>
            </Row>
          </Col>
          <Col xs="10" md="5" className="mx-3 mt-3 mt-md-0">
            <Row className="contentTitle pb-2">
              Last Name
            </Row>
            <Row>
              <Input placeholder="User Last name" value={props.lName}
                name="lName" onChange={props.handleChange} maxLength="30"/>
            </Row>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col xs="10" md="5" className="mx-3">
            <Row className="contentTitle pb-2">
              Email
            </Row>
            <Row>
              <Input placeholder="User Email" value={props.email}
                name="email" onChange={props.handleChange} />
            </Row>
          </Col>
          <Col xs="10" md="5" className="mx-3 mt-3 mt-md-0">
            <Row className="contentTitle pb-2">
              League Name
            </Row>
            <Row>
              <Input placeholder="League Name" value={props.leagueName}
                name="leagueName" onChange={props.handleChange} />
            </Row>
          </Col>
        </Row>


        <Row className="mb-2">
          <Col xs="10" md="6" className="mx-0">
            <div className="pb-2 text-secondary" style={{fontSize: '0.8rem'}}>
              {'Wildcard search with "*" before and/or after search criteria'}
            </div>
          </Col>
        </Row>
        {props.error &&
          <Row className="mb-3">
            <Col xs="10" md="6" className="mx-3">
              <Row className="pb-2" style={{color:'red'}}>
                {props.error}
              </Row>
            </Col>
          </Row>
        }

        <Row className="mb-3">
          <Col xs="4" md="2" className="mx-0">
            <div className="pb-2 d-flex">
              {props.loading ?
                <Button color="primary" disabled block><FontAwesome name="spinner" spin /></Button>
                :<Button className="nextButton" color="primary" size="md" onClick={props.getMCUserList} block> {'Search'} </Button>
              }
              <Button className="nextButton ml-3" color="primary" size="md" onClick={props.resetSearchForm} outline> {'Reset'} </Button>

            </div>
          </Col>
          <Col className="mx-0">
            <div className="pb-2 d-flex justify-content-end mr-3">
              <Button className="nextButton ml-3" color="success" size="md" onClick={props.getQuitLeagues} > {'Quit Leagues'} </Button>
            </div>
          </Col>
        </Row>

    </div>
  )
}

const ActionJoinLeagueCell = ({
    value: initialValue,
    row: { index },
    column: { league_id },
    joinLeague, // This is a custom function that we supplied to our table instance
  }) => {

    const [value, setValue] = React.useState(initialValue)

    const onClick = e => {
      joinLeague(initialValue, index);
    }

    React.useEffect(() => {
      setValue(initialValue)
    }, [initialValue])

    if (!value){return '';}
    return <div className="renderComponent" value={value} onClick={onClick} >Join League </div>;

  }


const ReactCustomTable = ({ columns, data, joinLeague }) => {
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
    } = useTable({
      columns,
      data,
      joinLeague,
    },

    );

    return (
      <Styles>
      <div className="tableWrap">
        <table {...getTableProps()}>
          <thead style={{backgroundColor: "gray", "color":"white", "fontSize": "0.7rem", textTransform: "uppercase"}}>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th
                    {...column.getHeaderProps({
                      className: column.collapse ? 'collapse' : '',
                    })}
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} style={{ "fontSize": "0.7rem",}}>
            {rows.map((row, i) => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return (
                      <td
                        {...cell.getCellProps({
                          className: cell.column.className ? cell.column.className : cell.column.className,
                        })}
                      >
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
        {/*
        Pagination can be built however you'd like.
        This is just a very basic UI implementation:
      */}
      </div>
      <div className="pagination">

      </div>
    </Styles>
    );
  };
