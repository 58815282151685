import React from 'react';
import { Modal, ModalBody } from 'reactstrap';

import {
  login_google
} from 'utils/api.js';

import 'common/Auth.css';
import logo from 'img/google-g-icon.png';
import FontAwesome from 'react-fontawesome';

export default class GoogleAuth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loginInProgress: false,
      gClient: null,
    };
    this.toggleLoginInProgress = this.toggleLoginInProgress.bind(this);
    this.checkAuthStatus = this.checkAuthStatus.bind(this);
    this.onAuthSuccess = this.onAuthSuccess.bind(this);
  }

  componentDidMount() {
    this.initalizeGoogleLogin();
  }

  componentWillReceiveProps(nextProps) {

  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    //this.initalizeGoogleLogin();
  }

  initalizeGoogleLogin(){
    (function (d, s, id, cb) {
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return; }
      let js = d.createElement(s);
      js.id = id;
      js.src = "https://accounts.google.com/gsi/client";
      js.onload = cb;
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'google-login', () => {
      window.google.accounts.id.initialize({
        client_id: '680849138860-n0ogj29fodnf7bfngbqvr9kiuho3k5n5.apps.googleusercontent.com',
        cancel_on_tap_outside: false,
        callback: (response) => {
          console.log("Encoded JWT ID token: " + response.credential);
          this.onAuthSuccess(response.credential);
        },
      });
      /*window.google.accounts.id.renderButton(
        document.getElementsByName("buttonDiv")[0],
        { theme: "outline", size: "large" }  // customization attributes
      );
      window.google.accounts.id.renderButton(
        document.getElementsByName("buttonDiv")[1],
        { theme: "outline", size: "large" }  // customization attributes
      );*/

      let buttonDivs = document.getElementsByName("buttonDiv");
      for (var xx=0; xx<buttonDivs.length; xx++){
        window.google.accounts.id.renderButton(
          buttonDivs[xx],
          { theme: "outline", size: "large" }  // customization attributes
        );
      }
      window.google.accounts.id.prompt((notification) => {
          console.log(notification);
          if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
              // try next provider if OneTap is not displayed or skipped
              //alert('Google Login not supported');
          }
      });
    }));

    if (window.google && window.google.accounts && window.google.accounts.id){
      let buttonDivs = document.getElementsByName("buttonDiv");
      for (var xx=0; xx<buttonDivs.length; xx++){
        window.google.accounts.id.renderButton(
          buttonDivs[xx],
          { theme: "outline", size: "large" }  // customization attributes
        );
      }


    }

  }

  toggleLoginInProgress(event){
    this.setState({
      loginInProgress: !this.state.loginInProgressModal,
    });
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  checkAuthStatus() {
    console.log("Init checkAuthStatus");
    this.state.gClient.requestAccessToken();
  }

  onAuthSuccess(id_token) {
    this.toggleLoginInProgress();
    //const id_token = googleUser.getAuthResponse().id_token;
    login_google(id_token,
      (response) => {
        if (response.data.success) {
          this.props.onSuccessRedirect(response.data.me);
        } else {
          this.props.onError();
        }
        this.toggleLoginInProgress();
    }, (error) => {
      this.props.onError();
      this.toggleLoginInProgress();
    });
  }
  render() {
    const modalStyles = {
        'position': 'absolute',
        'top': '30%',
        'left': '48%',
        'transform': 'translate(-50%, -50%) !important',
    };

    return (
      <>
      <span>
        <Modal isOpen={this.state.loginInProgress} toggle={this.toggleLoginInProgress}
          className="loginModal" style={modalStyles}>
          <ModalBody>
            <FontAwesome name="spinner" className="" size="3x" spin />
          </ModalBody>
        </Modal>
      </span>
      <div name="buttonDiv"></div>
      {/*
      <div className="googleAuthLoginButton p-1" onClick={this.checkAuthStatus}>

        <div className="d-flex justify-content-center">
          <div className="googleAuthLoginButtonImgContainer">
            <img src={logo} className="googleAuthLoginButtonImg" alt=""/>
          </div>
          <span className="googleAuthLoginIconText">{' Sign in with Google'}</span>
        </div>
      </div>
      */}
      </>
    )
  }
}
