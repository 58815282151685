import React from 'react';
import { Modal, ModalHeader, ModalBody, Row, Col, Table } from 'reactstrap';

import FontAwesome from 'react-fontawesome';

import 'common/McModal.css'
import 'common/LoginModal.css';


export default class CupScoringInfoModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };

    this.toggle = this.toggle.bind(this);

  }


  toggle(event) {
    this.setState({
      modal: !this.state.modal,
    });

    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

  }


  render() {

    const cupScorePointsRows = [];
    this.props.cupScorePoints.forEach((item, index) => {
        cupScorePointsRows.push(
            <tr className="d-flex">
              <td className="text-center" style={{width:'20%'}}>{item.position}</td>
              <td className="text-center" style={{width:'20%'}}>{item.custom_event_point}</td>
              <td className="text-center" style={{width:'20%'}}>{item.major_event_point}</td>
              <td className="text-center" style={{width:'20%'}}>{item.other_event_point}</td>
              <td className="text-center" style={{width:'20%'}}>{item.large_event_point}</td>
            </tr>

        );
    });
    let gs = {'backgroundColor':(this.props.scoringMethod==='Gross ScoreX'?'lemonchiffon':'')};
    let fw = {'backgroundColor':(this.props.scoringMethod==='FedEx WeightedX'?'lemonchiffon':'')};
    let fe = {'backgroundColor':(this.props.scoringMethod==='FedEx EqualX'?'lemonchiffon':'')};
    return (
      <>
      <a href="#cupScoreInfo" id="cupScoreInfo" onClick={this.toggle}>
        {this.props.embedIn==='CommissionerCup' &&
          <FontAwesome name="question-circle" className="ml-1" />
        }
        {this.props.embedIn==='UserCup' &&
          <span>click here.</span>
        }
        <Modal isOpen={this.state.modal} toggle={this.toggle} className="loginModal" autoFocus={false}>
          <ModalHeader toggle={this.toggle} className="mcModalHeader">{'Cup Scoring Methods'}</ModalHeader>
          <ModalBody>
              <Row className="profileFormRow pb-3 pb-sm-0">
                <Col  className="" style={{fontSize:'13px'}}>
                  <p style={gs}>
                    <b>Gross Score:</b> Scores are the sum of finishing scores in each event. If your league allows multiple entries, each entry must participate in all cup events. Entries missing an event are cut from the competition. Disregard the point scale below for gross score leaderboards.
                  </p>
                  <p style={fw}>
                    <b>FedEx Weighted:</b> Points are awarded based on finishing place using the same scale as the actual FedEx Cup. Major tournaments are worth twice as much as non-major events.
                  </p>
                  <p style={fe}>
                    <b>FedEx Equal:</b> Points are awarded based on finishing place using the same scale as the actual FedEx Cup. Non-major tournaments are awarded the same point value as major events.
                  </p>
                  <p style={fe}>
                    <b>Short Season (recommended):</b> We recommend using Short Season if your league is playing fewer than 10 tournaments. This format does not overweight winning a single tournament like FedEx and results in a much more competitive contest.
                  </p>
                  <p style={fe}>
                    <b>Large League:</b> The Large League format is best used for golf pools with many thousands of participants. To keep people interested, points are awarded all the way to 1000th place.
                  </p>
                  <p style={{backgroundColor:'lemonchiffon',borderRadius:'5px',padding:'5px'}}>
                    <span >This league is set to <b>{this.props.scoringMethod}</b>.</span>
                  </p>
                </Col>
              </Row>
              <Row className="profileFormRow pb-3 pb-sm-0 mt-n1">
                <Col  className="">
                  <span style={{fontSize:'13px'}}><b>Point Scale:</b></span>
                  <Table responsive className="table table-border table-head-fixed">
                    <thead>
                      <tr className="d-flex" style={{backgroundColor: "#f6f6f6", color: "#7c7c7c", "fontSize": "0.7rem", textTransform: "uppercase"}}>
                        <th className="text-center" style={{width:'20%'}}>Position</th>
                        <th className="text-center" style={{width:'20%'}}>Short Season</th>
                        <th className="text-center" style={{width:'20%'}}>Major Event</th>
                        <th className="text-center" style={{width:'20%'}}>Other Event</th>
                        <th className="text-center" style={{width:'20%'}}>Large League</th>
                      </tr>
                    </thead>
                    <tbody style={{fontSize:'13px'}}>
                      {cupScorePointsRows}
                    </tbody>
                  </Table>
                </Col>
              </Row>
          </ModalBody>
        </Modal>
      </a>
      </>
    );
  }
}
