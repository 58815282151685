import React from 'react';
import Select from 'react-select';


import {
  Row, Col,
  Button,
  Modal, ModalHeader, ModalBody,
  Badge
} from 'reactstrap';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'common/ConfirmAlert.css';

import FontAwesome from 'react-fontawesome';

import GolferModal from 'common/GolferModal.js';
import AddMemberModal from 'common/AddMemberModal';


import {
  get_event_field_team,
  get_event_field_team_members,
  save_lineup,
} from 'utils/api.js';

import 'pages/user/PickTeam.css';

export default class PickTeamModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      golferModalOpen: false,
      selectedTabIndx: 1,
      golferModalGolferId: null,
      members: [],
      teamName: '',
      selectedMemberId: '',
      team: {
        groupA: [null, null, null, null],
        groupB: [null, null, null, null],
        groupC: [null, null, null, null, null],
        groupD: [null, null, null]
      },
      golfers: {
        groupA: [],
        groupB: [],
        groupC: [],
        groupD: [],
      },
      selectedEntry: null,
      entries: [],
      totalTeams: null,
      editMode: true,
      error: null,
      promo: false,
      cup: null,
      midsave: false,
      maxEntriesAllow: 0,
      maxEntriesPerUserAllow: 0,
      noMoreTeam: false,
      mcAdvConfigGrp: null,
      userLedgerIdDepositFund: null,
      use_team_names: this.props.selectedLeague && this.props.selectedLeague.whiteLabel ? this.props.selectedLeague.whiteLabel.use_team_names:true,
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
    this.getEventField = this.getEventField.bind(this);
    this.saveLineup = this.saveLineup.bind(this);
    this.selectGolfer = this.selectGolfer.bind(this);
    this.unselectGolfer = this.unselectGolfer.bind(this);
    this.toggleGolferModal = this.toggleGolferModal.bind(this);
    this.showGolferDetails = this.showGolferDetails.bind(this);
    this.getEventFieldMembers = this.getEventFieldMembers.bind(this);

  }
  componentDidMount() {
    this.getEventField(this.props.eventid);
    this.setState({error: null});
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.props.isOpen){
      document.querySelector('body').classList.remove('modal-open');
    }else{
      document.querySelector('body').classList.add('modal-open');
    }
  }

  componentWillReceiveProps(nextProps) {
      console.log(nextProps.leagueid);
      console.log(nextProps.eventid);
      this.getEventField(nextProps.eventid);
      this.setState({
        teamModalOpen: nextProps.teamModalOpen,
        selectedTabIndx: 1,
        golferModalOpen: false,
        golferModalGolferId: null,
        teamName: '',
        selectedMemberId: '',
        team: {
          groupA: [null, null, null, null],
          groupB: [null, null, null, null],
          groupC: [null, null, null, null, null],
          groupD: [null, null, null]
        },
        golfers: {
          groupA: [],
          groupB: [],
          groupC: [],
          groupD: [],
        },
        selectedEntry: null,
        entries: [],
        totalTeams: null,
        editMode: true,
        error: null,
        promo: false,
        cup: null,
        midsave: false,
        maxEntriesAllow: 0,
        maxEntriesPerUserAllow: 0,
        noMoreTeam: false,
        mcAdvConfigGrp: null,
        use_team_names: nextProps.selectedLeague && nextProps.selectedLeague.whiteLabel ? nextProps.selectedLeague.whiteLabel.use_team_names:true,

      });
  }

  toggleTeamModal(){
    this.setState({
      teamModalOpen: !this.state.teamModalOpen,
    });
  }

  handleChange(event) {
    this.setState({[event.target.name]: event.target.value});
  }

  handleTabChange(selectedTabIndx) {
    let grp = 'groupA';
    switch(this.state.selectedTabIndx) {
      case 1:
        grp = 'groupA';
        break;
      case 2:
        grp = 'groupB';
        break;
      case 3:
        grp = 'groupC';
        break;
      case 4:
        grp = 'groupD';
        break;
      default:
        // code block
    }
    if (selectedTabIndx > this.state.selectedTabIndx && this.state.team[grp].indexOf(null) > -1){
      let msg = 'Incomplete Roster: Please select all golfers.';
      confirmAlert({
        title: 'Alert',
        message: msg,
        buttons: [
          {
            label: 'OK',
            onClick: () => { }
          }
        ]
      });
      return;
    }

    this.setState({selectedTabIndx});
  }


  getEventFieldMembers(leagueid, memberid) {
    get_event_field_team_members(leagueid, ({members}) => {
      this.setState({members:members, selectedMemberId:memberid});

    }, (error) => {
      console.log("An unexpected error occurred loading this content.");
    });
  }

  getEventField(eventid) {
    get_event_field_team(eventid, ({members, golfers, totalTeams, promo, maxEntriesAllow, maxEntriesPerUserAllow, league}) => {
      const golfersObj = {
        groupA: golfers[0],
        groupB: golfers[1],
        groupC: golfers[2],
        groupD: golfers[3]
      }

      //const noMoreTeam = (((maxEntriesPerUserAllow>0 && maxEntriesPerUserAllow<=entries.length) ||
      //                  (maxEntriesAllow>0 && maxEntriesAllow<=totalTeams))?true:false);

      this.setState({members:members, golfers: golfersObj, totalTeams, promo, maxEntriesAllow:maxEntriesAllow,
                    maxEntriesPerUserAllow:maxEntriesPerUserAllow, noMoreTeam:true,
                    league,});

    }, (error) => {
      console.log("An unexpected error occurred loading this content.");
    });
  }

  saveLineup() {
    let msg = null;
    if (!this.state.teamName){
      msg = 'Please enter team name.';
    }else if (!this.state.selectedMemberId){
      msg = 'Please choose member.';
    }

    if (msg){
      confirmAlert({
        title: 'Alert',
        message: msg,
        buttons: [
          {
            label: 'OK',
            onClick: () => { }
          }
        ]
      });
      return;
    }


    this.setState({midsave: true, error: null})
    let team = {
      groupA: [],
      groupB: [],
      groupC: [],
      groupD: []
    };
    team['groupA'] = this.state.team['groupA'].map(g => g.golferid );
    team['groupB'] = this.state.team['groupB'].map(g => g.golferid );
    team['groupC'] = this.state.team['groupC'].map(g => g.golferid );
    team['groupD'] = this.state.team['groupD'].map(g => g.golferid );
    save_lineup(
      this.props.eventid,
      team,
      this.state.selectedEntry ? this.state.selectedEntry.entry_id : null,
      this.state.teamName,
      null, //cupOptIn,
      'Non Resident', //resState,
      null, //userLedgerIdDepositFund,
      this.state.selectedMemberId,
      ({success, entries, entry_id, cup, totalTeams, userDetails, league, error}) => {
        if (!success) {
          this.setState({error, midsave: false});
          return;
        }
        let selectedEntry = null;
        for (let entry of entries) {
          if (entry.entry_id === entry_id) {
            selectedEntry = entry;
            break;
          }
        }
        //console.log ('after save : ' + this.props.maxEntriesPerUserAllow + ' ' + this.state.maxEntriesPerUserAllow);

        let noMoreTeam = (((this.state.maxEntriesPerUserAllow>0 && this.state.maxEntriesPerUserAllow<=entries.length) ||
                          (this.state.maxEntriesAllow>0 && this.state.maxEntriesAllow<=totalTeams))?true:false);

        //console.log ('after save : ' + noMoreTeam);

        this.setState({entries, editMode: false, selectedEntry, cup, midsave: false, totalTeams, noMoreTeam:noMoreTeam, userDetails, league, userLedgerIdDepositFund: league.userLedgerIdDepositFund,});

        this.props.toggle();
        /*
        confirmAlert({
          title: 'Success',
          message: this.state.teamName + ' is created Sucessfully.',
          buttons: [
            {
              label: 'OK',
              onClick: () => { this.props.toggle();}
            }
          ]
        });*/

    }, (error) => {
      this.setState({error: 'An unexpected error occurred. Please try again later or contact support if this persists.', midsave: false});
    })
  }


  selectGolfer(group, pos, golferid) {
    let team = {...this.state.team};
    const emptySlot = pos;

    if (emptySlot === -1) {
      console.log('cannot select golfer - team full');
    } else {
      let golfer = this.state.golfers[group].filter(g => g.golferid === golferid)[0];
      team[group][emptySlot] = golfer;
      this.setState({team});
    }
  }

  unselectGolfer(group, pos) {
    let team = {...this.state.team};
    team[group][pos] = null;
    this.setState({team});

  }
  toggleGolferModal() {
    this.setState({golferModalOpen: !this.state.golferModalOpen});
  }
  showGolferDetails(golferid) {
    this.setState({
      golferModalGolferId: golferid,
      golferModalOpen: true
    });
  }


  render() {
    const commonPickGroupProps = {
      team: this.state.team,
      golfers: this.state.golfers,
      selectGolfer: this.selectGolfer,
      unselectGolfer: this.unselectGolfer,
      showGolferDetails: this.showGolferDetails,
      editMode: this.state.editMode
    }

    const memberOptions = [];
    let selectedMember = null;
    this.state.members.forEach((item, index) => {
        memberOptions.push(
            { value: item.id, label: item.name}
        );
        if (this.state.selectedMemberId && this.state.selectedMemberId === item.id){
          selectedMember = { value: item.id, label: item.name};
        }
    });


    let leagueInfo = null;


    return (
      <span>
      <Modal isOpen={this.props.isOpen} className="editModal">
        <ModalHeader toggle={this.props.toggle}>Add Team</ModalHeader>
        <ModalBody>
            <Row className="">
              <Col className="mt-1" style={{textAlign:'left'}}>

                <label className="formContent_formInput_label">MEMBER</label>
                <div className="d-flex">
                  <div style={{width:'75%'}}>
                    <Select className="no-border"
                      isClearable
                      isSearchable
                      placeholder="Choose Member"
                      options={memberOptions}
                      value={selectedMember}
                      onChange={(selectedOption) => {
                          let teamName = this.state.teamName;
                          if(!this.state.use_team_names){
                            teamName = selectedOption && selectedOption.value?'ENTRY'+selectedOption.value:'';
                          }
                          this.setState({teamName:teamName, selectedMemberId: (selectedOption && selectedOption.value?selectedOption.value:null)});
                        }
                      }
                    />
                  </div>
                  <AddMemberModal leagueid={this.props.leagueid} leagueInfo={leagueInfo} fetchMembers={this.getEventFieldMembers}/>
                </div>
              </Col>
            </Row>
            {this.state.use_team_names &&
            <Row className="">
              <Col className="mt-1">
                <label className="formContent_formInput_label">TEAM NAME</label>
                <input type="text" name='teamName' value={this.state.teamName} className="formContent_formInput"
                  onChange={this.handleChange}  placeholder="Enter team name"/>
              </Col>
            </Row>
            }
            <Row className=" my-2">
              {this.state.selectedTabIndx === 1 &&
              <Col className=" mt-1" style={{textAlign:'left'}}>
                <div className='d-flex'>
                    <div className='d-flex' style={{marginLeft:'20px', color:'gray'}}>
                      <FontAwesome name="angle-left" size="2x" />
                      <div className="backBtnText">Back</div>
                    </div>
                    <label className="formContent_formInput_label" style={{textAlign:'center', fontSize:'18px'}}>GROUP A</label>
                    <div className='d-flex' style={{marginRight:'20px', color:'green', cursor:'pointer'}} onClick={()=>this.handleTabChange(2)}>
                      <div className="nextBtnText">Next</div>
                      <FontAwesome name="angle-right" size="2x" />
                    </div>
                </div>
                <div className=" mx-3">
                  <PickGolfer group={'groupA'} pos={0} {...commonPickGroupProps}/>
                  <PickGolfer group={'groupA'} pos={1} {...commonPickGroupProps}/>
                  <PickGolfer group={'groupA'} pos={2} {...commonPickGroupProps}/>
                  <PickGolfer group={'groupA'} pos={3} {...commonPickGroupProps}/>
                </div>
              </Col>
              }

              {this.state.selectedTabIndx === 2 &&
              <Col className=" mt-1" style={{textAlign:'left'}}>
                <div className='d-flex'>
                  <div className='d-flex' style={{marginLeft:'20px',  color:'green', cursor:'pointer'}} onClick={()=>this.handleTabChange(1)}>
                    <FontAwesome name="angle-left" size="2x" />
                    <div className="backBtnText">Back</div>
                  </div>
                  <label className="formContent_formInput_label" style={{textAlign:'center', fontSize:'18px'}}>GROUP B</label>
                  <div className='d-flex' style={{marginRight:'20px', color:'green', cursor:'pointer'}} onClick={()=>this.handleTabChange(3)}>
                    <div className="nextBtnText">Next</div>
                    <FontAwesome name="angle-right" size="2x" />
                  </div>
                </div>
                <div className=" mx-3">
                  <PickGolfer group={'groupB'} pos={0} {...commonPickGroupProps}/>
                  <PickGolfer group={'groupB'} pos={1} {...commonPickGroupProps}/>
                  <PickGolfer group={'groupB'} pos={2} {...commonPickGroupProps}/>
                  <PickGolfer group={'groupB'} pos={3} {...commonPickGroupProps}/>
                </div>
              </Col>
              }

              {this.state.selectedTabIndx === 3 &&
              <Col className=" mt-1" style={{textAlign:'left'}}>
                <div className='d-flex'>
                  <div className='d-flex' style={{marginLeft:'20px',  color:'green', cursor:'pointer'}} onClick={()=>this.handleTabChange(2)}>
                    <FontAwesome name="angle-left" size="2x" />
                    <div className="backBtnText">Back</div>
                  </div>
                  <label className="formContent_formInput_label" style={{textAlign:'center', fontSize:'18px'}}>GROUP C</label>
                  <div className='d-flex' style={{marginRight:'20px', color:'green', cursor:'pointer'}} onClick={()=>this.handleTabChange(4)}>
                    <div className="nextBtnText">Next</div>
                    <FontAwesome name="angle-right" size="2x" />
                  </div>
                </div>
                <div className=" mx-3">
                  <PickGolfer group={'groupC'} pos={0} {...commonPickGroupProps}/>
                  <PickGolfer group={'groupC'} pos={1} {...commonPickGroupProps}/>
                  <PickGolfer group={'groupC'} pos={2} {...commonPickGroupProps}/>
                  <PickGolfer group={'groupC'} pos={3} {...commonPickGroupProps}/>
                  <PickGolfer group={'groupC'} pos={4} {...commonPickGroupProps}/>
                </div>
              </Col>
              }

              {this.state.selectedTabIndx === 4 &&
              <Col className=" mt-1" style={{textAlign:'left'}}>
                <div className='d-flex'>
                  <div className='d-flex' style={{marginLeft:'20px',  color:'green', cursor:'pointer'}} onClick={()=>this.handleTabChange(3)}>
                    <FontAwesome name="angle-left" size="2x" />
                    <div className="backBtnText">Back</div>
                  </div>
                  <label className="formContent_formInput_label" style={{textAlign:'center', fontSize:'18px'}}>GROUP D</label>
                  <div className='d-flex' style={{marginRight:'20px', color:'grey',}} >
                    <div className="nextBtnText">Next</div>
                    <FontAwesome name="angle-right" size="2x" />
                  </div>
                </div>
                <div className=" mx-3">
                  <PickGolfer group={'groupD'} pos={0} {...commonPickGroupProps}/>
                  <PickGolfer group={'groupD'} pos={1} {...commonPickGroupProps}/>
                  <PickGolfer group={'groupD'} pos={2} {...commonPickGroupProps}/>
                </div>
              </Col>
              }
            </Row>
            {this.state.selectedTabIndx === 4 &&
              <Row>
                <Col style={{textAlign:'right'}}>
                  <Button color="success" className="my-2" onClick={()=>this.handleTabChange(5)}>Review</Button>
                </Col>
              </Row>
            }

            {this.state.selectedTabIndx === 5 &&
            <>
            <Row>
              <Col className=" mt-1" style={{textAlign:'left'}}>
                <div className='d-flex'>
                  <div className='d-flex' style={{marginLeft:'20px',  color:'green', cursor:'pointer'}} onClick={()=>this.handleTabChange(4)}>
                    <FontAwesome name="angle-left" size="2x" />
                    <div className="backBtnText">Back</div>
                  </div>
                  <label className="formContent_formInput_label" style={{textAlign:'center', fontSize:'18px'}}>ROSTER</label>
                  <div className='d-flex' style={{marginRight:'20px', color:'grey',}} >
                    <div className="nextBtnText">Next</div>
                    <FontAwesome name="angle-right" size="2x" />
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="teamModalBody mt-2 mx-2">
              <Col className="">
                <div className="pb-1 teamModalGroup">{'GROUP A'}</div>
                <div>{this.state.team['groupA'][0] && this.state.team['groupA'][0].golfer}
                  {this.state.team['groupA'][0] && this.state.team['groupA'][0].status === 'wd' && <Badge color="secondary" style={{marginLeft: "0.5rem"}}> WD </Badge>}
                </div>
                <div>{this.state.team['groupA'][1] && this.state.team['groupA'][1].golfer}
                  {this.state.team['groupA'][1] && this.state.team['groupA'][1].status === 'wd' && <Badge color="secondary" style={{marginLeft: "0.5rem"}}> WD </Badge>}
                </div>
                <div>{this.state.team['groupA'][2] && this.state.team['groupA'][2].golfer}
                  {this.state.team['groupA'][2] && this.state.team['groupA'][2].status === 'wd' && <Badge color="secondary" style={{marginLeft: "0.5rem"}}> WD </Badge>}
                </div>
                <div>{this.state.team['groupA'][3] && this.state.team['groupA'][3].golfer}
                  {this.state.team['groupA'][3] && <Badge color="primary" style={{marginLeft: "0.5rem"}}> ALT </Badge>}
                  {this.state.team['groupA'][3] && this.state.team['groupA'][3].status === 'wd' && <Badge color="secondary" style={{marginLeft: "0.5rem"}}> WD </Badge>}
                </div>
              </Col>
              <Col className="">
                <div className="teamModalGroup pb-1">{'GROUP B'}</div>
                <div>{this.state.team['groupB'][0] && this.state.team['groupB'][0].golfer}
                  {this.state.team['groupB'][0] && this.state.team['groupB'][0].status === 'wd' && <Badge color="secondary" style={{marginLeft: "0.5rem"}}> WD </Badge>}
                </div>
                <div>{this.state.team['groupB'][1] && this.state.team['groupB'][1].golfer}
                  {this.state.team['groupB'][1] && this.state.team['groupB'][1].status === 'wd' && <Badge color="secondary" style={{marginLeft: "0.5rem"}}> WD </Badge>}
                </div>
                <div>{this.state.team['groupB'][2] && this.state.team['groupB'][2].golfer}
                  {this.state.team['groupB'][2] && this.state.team['groupB'][2].status === 'wd' && <Badge color="secondary" style={{marginLeft: "0.5rem"}}> WD </Badge>}
                </div>
                <div>{this.state.team['groupB'][3] && this.state.team['groupB'][3].golfer}
                  {this.state.team['groupB'][3] && <Badge color="primary" style={{marginLeft: "0.5rem"}}> ALT </Badge>}
                  {this.state.team['groupB'][3] && this.state.team['groupB'][3].status === 'wd' && <Badge color="secondary" style={{marginLeft: "0.5rem"}}> WD </Badge>}
                </div>
              </Col>
            </Row>
            <Row className="teamModalBody py-2 mx-2">
              <Col className="">
                <div className="teamModalGroup pb-1">{'GROUP C'}</div>
                <div>{this.state.team['groupC'][0] && this.state.team['groupC'][0].golfer}
                  {this.state.team['groupC'][0] && this.state.team['groupC'][0].status === 'wd' && <Badge color="secondary" style={{marginLeft: "0.5rem"}}> WD </Badge>}
                </div>
                <div>{this.state.team['groupC'][1] && this.state.team['groupC'][1].golfer}
                  {this.state.team['groupC'][1] && this.state.team['groupC'][1].status === 'wd' && <Badge color="secondary" style={{marginLeft: "0.5rem"}}> WD </Badge>}
                </div>
                <div>{this.state.team['groupC'][2] && this.state.team['groupC'][2].golfer}
                  {this.state.team['groupC'][2] && this.state.team['groupC'][2].status === 'wd' && <Badge color="secondary" style={{marginLeft: "0.5rem"}}> WD </Badge>}
                </div>
                <div>{this.state.team['groupC'][3] && this.state.team['groupC'][3].golfer}
                  {this.state.team['groupC'][3] && this.state.team['groupC'][3].status === 'wd' && <Badge color="secondary" style={{marginLeft: "0.5rem"}}> WD </Badge>}
                </div>
                <div>{this.state.team['groupC'][4] && this.state.team['groupC'][4].golfer}
                  {this.state.team['groupC'][4] && <Badge color="primary" style={{marginLeft: "0.5rem"}}> ALT </Badge>}
                  {this.state.team['groupC'][4] && this.state.team['groupC'][4].status === 'wd' && <Badge color="secondary" style={{marginLeft: "0.5rem"}}> WD </Badge>}
                </div>
              </Col>
              <Col className="">
                <div className="teamModalGroup pb-1">{'GROUP D'}</div>
                <div>{this.state.team['groupD'][0] && this.state.team['groupD'][0].golfer}
                  {this.state.team['groupD'][0] && this.state.team['groupD'][0].status === 'wd' && <Badge color="secondary" style={{marginLeft: "0.5rem"}}> WD </Badge>}
                </div>
                <div>{this.state.team['groupD'][1] && this.state.team['groupD'][1].golfer}
                  {this.state.team['groupD'][1] && this.state.team['groupD'][1].status === 'wd' && <Badge color="secondary" style={{marginLeft: "0.5rem"}}> WD </Badge>}
                </div>
                <div>{this.state.team['groupD'][2] && this.state.team['groupD'][2].golfer}
                  {this.state.team['groupD'][2] && <Badge color="primary" style={{marginLeft: "0.5rem"}}> ALT </Badge>}
                  {this.state.team['groupD'][2] && this.state.team['groupD'][2].status === 'wd' && <Badge color="secondary" style={{marginLeft: "0.5rem"}}> WD </Badge>}
                </div>
              </Col>
            </Row>
            <Row>
              <Col style={{textAlign:'right'}}>
                <Button color="success" className="my-2" onClick={this.saveLineup} disabled={this.props.midsave}>Create Team</Button>
              </Col>
            </Row>
            </>
            }

            {
              this.state.error && <div style={{color: "red", textAlign: "left", paddingBottom: "1rem"}}>{this.state.error}</div>
            }



        </ModalBody>
      </Modal>
      <GolferModal isOpen={this.state.golferModalOpen} toggle={this.toggleGolferModal} golferid={this.state.golferModalGolferId}/>
      </span>
    )
  }
}

const PickGolfer = (props) => {
  let teamGroup = props.team[props.group];
  let golfers = props.golfers[props.group];
  let selectedGolfer = teamGroup[props.pos];
  let selectedGolferId = teamGroup[props.pos]?teamGroup[props.pos].golferid:null;
  let selectedValue = null;
  const isAlternateSlot = (props.pos === teamGroup.length-1?true:false);
  console.log('selectedGolferId :' + selectedGolferId);
  const selectedGolferIds = teamGroup.filter(g => g).map(g => g.golferid );

  let golferOptions = [];
  if (golfers){
    golfers.forEach((golfer, index) => {
      let option = { value: golfer.golferid,
        isDisabled: (selectedGolferIds.indexOf(golfer.golferid) > -1 ? true : false ),
        label: golfer.golfer/*<div className={"teamSlotGolfer"}>
          <div className="d-flex justify-content-start">
            <div className="d-block">
              <img src={golfer.image} alt={golfer.golfer} className="teamSlotGolfer_img" onError={(e) => e.target.src = "https://media.majorschallenge.com/headshots/headshot.png"}/>
            </div>
            <div className="pl-1">
              <h6 className={'teamSlotGolfer_name_option'} alt={golfer.golfer}>
                {golfer.golfer}
              </h6>
              <div className="teamSlotGolfer_meta">T10s: {golfer.t10s}
                <span>Cuts {golfer.cutsmade}</span>
                {(golfer.injury && golfer.injury.isWithdrawn === 1) &&
                  <Badge className="mx-2 text-white bg-danger" style={{marginTop:'1px'}} color="denger">OUT</Badge>
                }
                {(golfer.injury && golfer.injury.isWithdrawn === 0) &&
                  <img src={Largewarning} alt="alt" style={{paddingLeft: "10px", height:'17px', marginBottom:'3px'}}/>
                }
                {(!golfer.injury) &&
                  <span></span>
                }
              </div>
            </div>
          </div>
          </div>*/
      };
      if (selectedGolfer && golfer.golferid === selectedGolferId){
        selectedValue = { value: golfer.golferid,
          isDisabled: (teamGroup.indexOf(golfer.golferid) > -1 ? true : false ),
          label: golfer.golfer/*<div className={isAlternateSlot ? "teamSlotGolfer teamSlotAlternate" : "teamSlotGolfer"}>
            <div className="d-flex justify-content-start">
              <div className="d-block">
                <img src={golfer.image} alt={golfer.golfer} className="teamSlotGolfer_img" onError={(e) => e.target.src = "https://media.majorschallenge.com/headshots/headshot.png"}/>
              </div>
              <div className="pl-1">
                <h6 className={'teamSlotGolfer_name'} alt={golfer.golfer}  onClick={()=>props.showGolferDetails(golfer.golferid)}>
                  {isAlternateSlot && <Badge className="mr-1">ALT</Badge>}
                  {golfer.golfer}
                </h6>
                <div className="teamSlotGolfer_meta">T10s: {golfer.t10s}
                  <span>Cuts {golfer.cutsmade}</span>
                  {(golfer.injury && golfer.injury.isWithdrawn === 1) &&
                    <Badge className="mx-2 text-white bg-danger" style={{marginTop:'1px'}} color="denger">OUT</Badge>
                  }
                  {(golfer.injury && golfer.injury.isWithdrawn === 0) &&
                    <img src={Largewarning} alt="alt" style={{paddingLeft: "10px", height:'17px', marginBottom:'3px'}}/>
                  }
                  {(!golfer.injury) &&
                    <span></span>
                  }
                </div>
              </div>
            </div>
            </div>*/
        };
      }
      golferOptions.push(
          option
      );
    });
  }

  /*const placeholder = <div className={isAlternateSlot ? "teamSlotEmpty teamSlotAlternate" : "teamSlotEmpty"}>
    <Row>
      <Col xs="2">
        <img src="https://media.majorschallenge.com/headshots/headshot.png" alt="choose a golfer" className="teamSlotGolfer_img"/>
      </Col>
      <Col xs="10">
        <div className="teamSlotEmpty_text">
        {
          isAlternateSlot ? "Choose Alternate" : "Choose Starter"
        }
        </div>
      </Col>
    </Row>
  </div>;*/


  return (
    <Select className="no-border my-2" isClearable isSearchable options={golferOptions}
    value={selectedValue}
    placeholder = {isAlternateSlot ? "Choose Alternate" : "Choose Starter"}
    onChange={(selectedOption) => {
        console.log(selectedOption);
        if (selectedOption){
          props.selectGolfer(props.group, props.pos, selectedOption.value);
        }else{
          props.unselectGolfer(props.group, props.pos);
        }
      }
    } />

  )
}
