import React from 'react';

import {
  Button,
  Container,
  Row,
  Col
} from 'reactstrap';

import {OnboardingWrapper} from 'common/LandingWrapper.js';

import {
  check_password_reset,
  submit_password_reset
} from 'utils/api.js';

export default class PasswordReset extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      code: props.match.params.code,
      valid: null,
      newPass: '',
      confirmNewPass: '',
      error: null,
      message: null
    }
    this.renderPasswordReset = this.renderPasswordReset.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {
    check_password_reset(this.state.code, (response) => {
      this.setState({valid: true});
    }, (error) => {
      this.setState({error: 'An unexpected error occurred. Has your password reset link expired?'});
    });
  }
  handleChange(event) {
    this.setState({[event.target.name]: event.target.value});
  }
  handleSubmit(event) {
    event.stopPropagation();
    event.preventDefault();
    submit_password_reset(this.state.code, this.state.newPass, this.state.confirmNewPass,
      (response) => {
        if (response.success) {
          this.setState({message: 'Your password has been changed. Sign in to get back to your leagues.', error: null});
        } else {
          this.setState({error: response.error})
        }
      }, (error) => {
        this.setState({error: 'An unexpected error occurred.'});
      }
    );
  }
  render() {
    if (this.state.valid) {
      return this.renderPasswordReset();
    }

    let message = "Checking your password reset code...";
    if (this.state.valid === false) {
      message = "Your password reset code was not valid.";
    }
    return (
      <OnboardingWrapper {...this.props}>
        <Container className="text-center landingCard mt-4">
          <h5>
            {message}
          </h5>
        </Container>
      </OnboardingWrapper>
    )
  }
  renderPasswordReset() {
    return (
      <OnboardingWrapper {...this.props}>
        <Container className="landingCard mt-4">
          <h2 className="pb-1 text-center">{"Password Reset"}</h2>
          <form onSubmit={this.handleSubmit}>
            <Row className="justify-content-center">
              <Col xs="12" sm="9" md="8" lg="6" xl="5">
                <label className="signupFormLabel">
                  New Password:
                  <input type="password" name='newPass' value={this.state.newPass} className="form-control" onChange={this.handleChange}  />
                </label>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col xs="12" sm="9" md="8" lg="6" xl="5">
                <label className="signupFormLabel">
                  Confirm New Password:
                  <input type="password" name='confirmNewPass' value={this.state.confirmNewPass} className="form-control" onChange={this.handleChange}  />
                </label>
              </Col>
            </Row>
            <input type="submit" value="PasswordReset" hidden />
          </form>
          <Row className="justify-content-center text-right">
            <Col xs="12" sm="9" md="8" lg="6" xl="5">
              {
                this.state.error && <div style={{color: "red", textAlign: "left", paddingBottom: "1rem"}}>{this.state.error}</div>
              }
              {
                this.state.message ?
                  <div style={{color: "#282", textAlign: "left", paddingBottom: "1rem"}}>{this.state.message}</div> :
                  <Button color="primary" onClick={this.handleSubmit}>
                    Reset Password
                  </Button>
              }
            </Col>
          </Row>
        </Container>
      </OnboardingWrapper>
    )
  }
}
