import React from 'react';

import {
  withRouter,
} from 'react-router-dom';

import {
  logout,
  get_myprofile,
} from 'utils/api.js';

import {
  Desktop,
  NotDesktop
} from 'utils/responsive.js';

import {
  DesktopProfile,
  MobileProfile
} from './ProfileNav.js';

import './Profile.css';

class Profile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      leaguesCnt:0,
    };

    this.handleGetMyProfile = this.handleGetMyProfile.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }

  componentDidMount() {
    this.handleGetMyProfile();
  }

  handleGetMyProfile(){
    get_myprofile(({leaguesCnt}) => {
      this.setState({loading: false, leaguesCnt: leaguesCnt});
    }, (error) => {
      console.log(error);
    });
  }

  handleLogout(event) {
    logout((response) => {
        /*window.FB.logout((response) => {
          console.log(response);
        });*/
        this.props.loggedOutCallback();
        this.props.history.push("/");
      }, (error) => {
        console.log(error);
        console.log("Error occurred trying to logout!");
    })
    event.preventDefault();
  }

  render() {
    return (
      <div className="profileWrapper">
        <Desktop>
          <DesktopProfile {...this.state} handleLogout={this.handleLogout} updateMe={this.props.updateMe} me={this.props.me}/>
        </Desktop>
        <NotDesktop>
          <MobileProfile {...this.state} handleLogout={this.handleLogout} updateMe={this.props.updateMe} me={this.props.me}/>
        </NotDesktop>
      </div>
    );
  }
}


export default withRouter(Profile);
