import React from 'react';
import Responsive from 'react-responsive';

export const LargeDesktop = props => <Responsive {...props} minWidth={1200} />;
export const Desktop = props => <Responsive {...props} minWidth={992} />;
export const Tablet = props => <Responsive {...props} minWidth={768} maxWidth={991} />;
export const Mobile = props => <Responsive {...props} maxWidth={767} />;
export const MobilePortrait = props => <Responsive {...props} maxWidth={480} />;
export const NotMobilePortrait = props => <Responsive {...props} minWidth={481} />;
export const MobileLandscapeOrTablet = props => <Responsive {...props} minWidth={481} maxWidth={991}/>;
export const NotDesktop = props => <Responsive {...props} maxWidth={991} />;
export const NotLargeDesktop = props => <Responsive {...props} maxWidth={1199} />;

export const isDesktop = () => window.innerWidth >= 992;
export const isMobPortrait = () => (window.innerWidth <= 480);
export const isMobLandscape = () => (window.innerWidth >= 480 && window.innerWidth < 767);
export const isTablet = () => window.innerWidth >= 768 && window.innerWidth < 992;
