import React from 'react';

import {
  Row,
  Col,
  ButtonGroup, Button,
  Alert,
  Input,
} from 'reactstrap';

import AuthnetPay from 'common/AuthnetPay.js';

import numeral from 'numeral';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'common/ConfirmAlert.css';

import {
  MobileHeader,
  ContentWrapper
} from 'common/CommissionerComponents.js';

import {
  get_league_balance,
  submit_payment
} from 'utils/api.js';

import 'pages/commissioner/Balance.css';


export default class CommissionerBalance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stripe: null,
      cost: null,
      balance: null
    };
    this.getFinancials = this.getFinancials.bind(this);
  }
  componentDidMount() {
    this.getFinancials(this.props.activeLeague);
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.activeLeague === nextProps.activeLeague) {
      return;
    }
    this.getFinancials(nextProps.activeLeague);
  }

  getFinancials(leagueid) {
    get_league_balance(leagueid, ({success, cost, balance, club}) => {
      if (success) {
        this.setState({cost, balance});
      }
    }, (error) => {
      console.log('get_league_balance error: ', error);
    });
  }
  render() {
    return (

        <div>
          <MobileHeader to="Credit Balance" leagueid={this.props.activeLeague}/>
          <ContentWrapper>
            {
              this.props.balancewarning &&
              <Alert color="danger">
                <div>{/*
                  <p>We've noticed your league's credit balance is low.</p>
                  {!!this.state.cost && this.state.cost.pending_billable_entries > 0 &&
                   <p>Your league has {this.state.cost.pending_billable_entries} entries in an upcoming Major tournament, which will result in an overcharge of your balance when the tournament goes live.</p>
                  }
                  <p>If your credits fall below zero your members will not be able to view event results.</p>
                  */}
                  <p style={{marginBottom:'-3px'}}>LOW CREDIT BALANCE: If your credits fall below zero your members will not be able to view the leaderboard.</p>
                </div>
              </Alert>
            }

            <div className="mb-2" style={{fontSize: "1.75em", fontWeight: 300}}>
              {'Credit Balance'}
            </div>
            <LeagueFinancials {...this.state} />
            <div className="pt-3">

                <CheckoutForm activeLeague={this.props.activeLeague} getFinancials={this.getFinancials} fetchCommissionerData={this.props.fetchCommissionerData}/>

            </div>
          </ContentWrapper>
        </div>

    )
  }
}

class CheckoutForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: "",
      otheramount: "",
      cardholder: "",
      submitted: false,
      message: null,
      error: null
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleOtherAmountChange = this.handleOtherAmountChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChange(event) {
    this.setState({[event.target.name]: event.target.value, message: null, submitted: false});
  }
  handleOtherAmountChange(event) {
    this.setState({[event.target.name]: event.target.value,
      amount:event.target.value, message: null, submitted: false});
  }
  handleSubmit(e) {
    e.preventDefault();
    let validationMsg = '';
    if (this.state.amount < 5 ){
      validationMsg = 'Deposit minimum is $5';
    }
    if (this.state.amount > 10000 ){
      validationMsg = 'Deposit maximum is $10,000';
    }
    if (validationMsg !== ''){
      confirmAlert({
        title: 'Alert',
        message: validationMsg,
        buttons: [
          {
            label: 'OK',
            onClick: () => { }
          }
        ]
      });
      return false;
    }
    if (!!this.state.cardholder && this.state.amount && this.state.amount > 0) {
      this.setState({error: null, submitted: true});
      this.props.stripe.createToken({name: this.state.cardholder}).then(({token}) => {
        submit_payment({
          league: this.props.activeLeague,
          amount: this.state.amount,
          token: token
        }, ({success, error}) => {
          if (success) {
            this.setState({amount: "", cardholder: "", message: 'Your payment has been processed, and your credit balance has been updated.'});
            this.props.fetchCommissionerData(this.props.activeLeague);
            this.props.getFinancials(this.props.activeLeague);
          } else {
            this.setState({submitted: false, error});
          }
        }, (error) => {
          console.dir(error);
          this.setState({submitted: false, error: 'An error occurred processing your payment.'});
        });
      });
    } else {
      if (!this.state.cardholder) {
        this.setState({error: "Cardholder name cannot be empty."});
      } else {
        this.setState({error: "Amount must be greater than 0."});
      }
    }
  }

  preValidation = () => {
    let validationMsg = '';
    if (this.state.amount < 5 ){
      validationMsg = 'Deposit minimum is $5';
    }
    if (this.state.amount > 10000 ){
      validationMsg = 'Deposit maximum is $10,000';
    }
    if (validationMsg !== ''){
      confirmAlert({
        title: 'Alert',
        message: validationMsg,
        buttons: [
          {
            label: 'OK',
            onClick: () => { }
          }
        ]
      });
      return false;
    }
    return true;
  }

  setPaymentStatus = () => {
    this.setState({amount: "", cardholder: "", message: 'Your payment has been processed. Thank you.'});
    this.props.fetchCommissionerData(this.props.activeLeague);
    this.props.getFinancials(this.props.activeLeague);
  }


  render() {

    return (
      <form onSubmit={this.handleSubmit}>
        <div className="mb-2"> All season long competitions are FREE.</div>
        <div className="mb-2" style={{fontSize: "1.50em", fontWeight: 300}}>
          {'Buy More Credits'}
        </div>
        {/*<div>Your balance is deducted 1 credit for each team entered into a PGA Major.</div>*/}
        <Row className="justify-content-between creditBalanceRow">
          <Col xs="12" className="d-flex justify-content-start">
            <ButtonGroup size="sm">
              <Button
                onClick={() => this.setState({amount: '25', otheramount:'', message: null, submitted: false})}
                active={this.state.amount==='25' && this.state.otheramount===''}>
                <span className="">$25</span>
              </Button>
              <Button
              onClick={() => this.setState({amount: '50', otheramount:'', message: null, submitted: false})}
              active={this.state.amount==='50' && this.state.otheramount===''}>
                <span>$50</span>
              </Button>
              <Button
              onClick={() => this.setState({amount: '100', otheramount:'', message: null, submitted: false})}
              active={this.state.amount==='100' && this.state.otheramount===''}>
                <span>$100</span>
              </Button>
              <Button
              onClick={() => this.setState({amount: '250', otheramount:'', message: null, submitted: false})}
              active={this.state.amount==='250' && this.state.otheramount===''}>
                <span>$250</span>
              </Button>
              <div className="btn-group">
                <Input placeholder="Other" value={this.state.otheramount} name="otheramount"
                onChange={this.handleOtherAmountChange} type="number"
                className={this.state.otheramount===''?'':'active'}/>
              </div>
            </ButtonGroup>
          </Col>

        </Row>
        {!!this.state.amount && this.state.amount > 0 &&
          <Row className="justify-content-between ">
            <Col xs="12" className="d-flex justify-content-start">
              <div className="pt-3">
                <div className="pb-3">Amount to be charged: <strong>${this.state.amount}</strong></div>

                <label style={{display: "block"}}>

                  <AuthnetPay amount={this.state.amount} leagueId={this.props.activeLeague}
                    leagueEventId={0} entryId={0}
                    preValidation={this.preValidation}
                    setPaymentStatus={this.setPaymentStatus}/>

                </label>

                <p>{this.state.error}</p>
              </div>
            </Col>
          </Row>
        }
        {!!this.state.message && <p className="mt-3">{this.state.message}</p>}
      </form>
    )
  }
}

const LeagueFinancials = (props) => {
  const balance = props.balance ? numeral(props.balance).format('0') : '';
  // const cost = props.cost;
  // let num_members = '', num_paid_events = '', cost_estimate = '';
  // if (cost) {
  //   num_members = cost.num_members;
  //   num_paid_events = cost.num_paid_events;
  //   cost_estimate = cost.cost_estimate;
  // }
  return (
    <div className="pt-3">
      Current balance: <strong>{balance}</strong>
      <br />
      <br />
      Your balance is deducted 1 credit for each team entered into a weekly tournament during a major.
      <br />

      <div className="small text-muted">Major tournaments include the Players, Masters, US Open, British Open, and PGA Championship. For example, if you have ten friends playing in the Masters, and each person enters one team, it would be a 10-team golf pool costing 10 credits. All non-major weekly tournaments are free.</div>
    </div>
  )
}
