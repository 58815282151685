import React from 'react';
import {
  Col,
  Button,
  Container,
} from 'reactstrap';

import { withRouter } from 'react-router-dom';

import { check_invitation, join_league, logout } from 'utils/api';

import './JoinLeague.css';

class JoinLeague extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      league: props.match.params.league,
      code: props.match.params.code,
      valid_invite: null,
      invalid_reason: "",
      league_name: "",
    };

    this.checkCode = this.checkCode.bind(this);
    this.handleJoin = this.handleJoin.bind(this);
  }

  render() {
    if (this.state.valid_invite === null) {
      this.checkCode();
    }
    return (
      <Container fluid style={{backgroundColor:"#e8e8e8", minHeight: "100vh"}}>
        <Container style={{paddingTop:"5.5rem", paddingBottom:"2rem"}}>
          <h1 className="text-center p-3" style={{fontWeight:"bolder", color:"#444"}}>Join League</h1>
          <Col lg={{size:6, offset: 3}} md={{size:10, offset:1}}>
          {this.state.valid_invite === null ?
            undefined :
            this.state.valid_invite ?
              <Join handleJoin={this.handleJoin} {...this.state} /> :
              <Invalid logout={this.logg_out} {...this.state} />
          }
          </Col>
        </Container>
      </Container>
    );
  }

  logg_out = () => {
    logout((response) => {
        this.props.loggedOutCallback();
        this.props.history.push(window.location.pathname);
      }, (error) => {
        console.log(error);
        console.log("Error occurred trying to logout!");
    })
  }

  checkCode() {
    check_invitation(
      this.state.league,
      this.state.code,
    ({success, reason, reasonCd, email, loggedInEmail, league_name, invited_by, active, me}) => {
      if(!success && reason === 'You are already a member of this league.'){
        this.props.history.push("/league/"+this.state.league);
        this.props.loggedInCallback(me);
        return;
      }

      this.setState({
        valid_invite: success,
        invalid_reason: reason,
        reasonCd,
        league_name,
        invited_by,
        email,
        loggedInEmail,
      })
    }, (error) => {
      this.setState({valid_invite: false, invalid_reason: "An unexpected error occurred. Please try again later."});
    });
  }

  handleJoin() {
    join_league(
      this.state.league,
      this.state.code,
    ({success, error, me}) => {
      if (success) {
        this.props.updateMe(me);
        this.props.history.push("/league/"+this.state.league);
      } else {
        alert(error);
      }
    }, (error) => {
      alert("Error occurred while trying to join the league.");
    });
  }
}

const Join = (props) =>
  <center>
    <h2>{props.league_name}</h2>
    <h6>Invited by: {props.invited_by}</h6>
    <Button className="joinButton" onClick={props.handleJoin} block> Join </Button>
  </center>

const Invalid = (props) =>
  <center>
    {props.reasonCd !== 2 && <h6>{props.invalid_reason}</h6>}
    {props.reasonCd === 2 &&
      <div>I’m sorry but this invite is intended for <strong>{props.email}</strong>, but you are logged in with <strong>{props.loggedInEmail}</strong>. Please
        <span onClick={props.logout} className="linkComponent">&nbsp;log off&nbsp;</span>
        and try the invite link again. You can also request an invite to <strong>{props.email}</strong>.
      </div>
    }

  </center>

export default withRouter(JoinLeague);
