import {
  Container, Row, Col,
} from 'reactstrap';

import React from 'react';

const RosterRow = (props) => {

  props.roster.sort((a, b)=>{
    if (a.tier === b.tier){
      let aLastNm = a.name.split(' ')[a.name.split(' ').length-1];
      let bLastNm = b.name.split(' ')[b.name.split(' ').length-1];

      return aLastNm.toUpperCase() < bLastNm.toUpperCase() ? -1 : 1;
    }
    return a.tier - b.tier;
  })


  const player = (p) => {
    const progress = p.status !== "active" ?
                      " ("+p.status.toUpperCase()+")" :
                      (p.thru === 18 ?
                        " (F)" :
                        (p.thru === 0 ?
                          "" : " (" + p.thru + ")"));

    let score = 'E';
    let color = '#515356';

    if (p.score > 0) {
      score = '+'+p.score;
    } else if (p.score < 0) {
      score = p.score;
      color = '#e74c3c';
    }
    return  <Row noGutters>
              <Col xs="2" style={{textAlign: "right", color: color, whiteSpace: "nowrap"}} className="pr-1">
                {score}
              </Col>
              <Col xs="10" style={{whiteSpace: "nowrap", color: "#515356"}}>
                <span className="pl-1" style={p.status === "active" ? {} : {textDecoration: 'line-through'}}>
                  {p.name + (p.alternate ? "*" : "")}
                </span>
                <span>{progress}</span>
              </Col>
            </Row>
  }

  const style = {
    fontSize: ".8rem",
    paddingTop: ".5rem",
    paddingBottom: ".5rem",
  }

  return (
    <div style={style} className="d-flex justify-content-between">
      <div>
        <Container>{player(props.roster[0])}</Container>
        <Container>{player(props.roster[1])}</Container>
        <Container>{player(props.roster[2])}</Container>
      </div>
      <div>
        <Container>{player(props.roster[3])}</Container>
        <Container>{player(props.roster[4])}</Container>
        <Container>{player(props.roster[5])}</Container>
      </div>
      <div>
        <Container>{player(props.roster[6])}</Container>
        <Container>{player(props.roster[7])}</Container>
        <Container>{player(props.roster[8])}</Container>
        <Container>{player(props.roster[9])}</Container>
      </div>
      <div>
        <Container>{player(props.roster[10])}</Container>
        <Container>{player(props.roster[11])}</Container>
      </div>
    </div>
  )
}

export {RosterRow};
