import React from 'react';

import {
  Alert,
  Row,
  Col
} from 'reactstrap';

import AuthnetPay from 'common/AuthnetPay.js';

//import numeral from 'numeral';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'common/ConfirmAlert.css';

import {
  MobileHeader,
  ContentWrapper
} from 'common/CommissionerComponents.js';

import {
  get_league_balance,
  submit_payment
} from 'utils/api.js';


export default class CommissionerClubBalance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stripe: null,
      cost: null,
      balance: null,
      club: null,
    };

    this.getFinancials = this.getFinancials.bind(this);
  }
  componentDidMount() {
    this.getFinancials(this.props.activeLeague);
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.activeLeague === nextProps.activeLeague) {
      return;
    }
    this.getFinancials(nextProps.activeLeague);
  }

  getFinancials(leagueid) {
    get_league_balance(leagueid, ({success, cost, balance, club}) => {
      if (success) {
        //alert(club.cost + club.discount);
        this.setState({cost, balance, club});
      }
    }, (error) => {
      console.log('get_league_balance error: ', error);
    });
  }
  render() {
    return (

        <div>
          <MobileHeader to="Subscription" leagueid={this.props.activeLeague}/>
          <ContentWrapper>
            {
              this.props.balancewarning &&
              <Alert color="danger">
                <div>{/*
                  <p>We've noticed your league's credit balance is low.</p>
                  {!!this.state.cost && this.state.cost.pending_billable_entries > 0 &&
                   <p>Your league has {this.state.cost.pending_billable_entries} entries in an upcoming Major tournament, which will result in an overcharge of your balance when the tournament goes live.</p>
                  }
                  <p>If your credits fall below zero your members will not be able to view event results.</p>
                  */}
                  <p style={{marginBottom:'-3px'}}>LOW CREDIT BALANCE: If your credits fall below zero your members will not be able to view the leaderboard.</p>
                </div>
              </Alert>
            }

            <div className="mb-2" style={{fontSize: "1.75em", fontWeight: 300}}>
              {'Subscription'}
            </div>
            <LeagueFinancials {...this.state} {...this.props}/>
            {this.props.isItChildClub === false &&
              <div className="pt-3">
                  <CheckoutForm activeLeague={this.props.activeLeague} getFinancials={this.getFinancials}
                  fetchCommissionerData={this.props.fetchCommissionerData} clubCost={this.state.club} {...this.props}/>
              </div>
            }
          </ContentWrapper>
        </div>

    )
  }
}

class CheckoutForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: "500",
      discount: 0,
      cardholder: "",
      submitted: false,
      message: null,
      error: null
    }
    this.handleChange = this.handleChange.bind(this);

  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.clubCost){
      let amount = (parseInt(nextProps.clubCost.cost)-parseInt(nextProps.clubCost.discount));
      this.setState({amount: ""+amount,discount:parseInt(nextProps.clubCost.discount)});
    }
  }

  handleChange(event) {
    this.setState({[event.target.name]: event.target.value, message: null, submitted: false});
  }

  preValidation = () => {
    /*let validationMsg = '';
    if (this.state.amount < 5 ){
      validationMsg = 'Deposit minimum is $5';
    }
    if (this.state.amount > 10000 ){
      validationMsg = 'Deposit maximum is $10,000';
    }
    if (validationMsg !== ''){
      confirmAlert({
        title: 'Alert',
        message: validationMsg,
        buttons: [
          {
            label: 'OK',
            onClick: () => { }
          }
        ]
      });
      return false;
    }*/
    return true;
  }

  setPaymentStatus = () => {
    this.setState({amount: "", cardholder: "", message: 'Your payment has been processed. Thank you.'});
    this.props.fetchCommissionerData(this.props.activeLeague);
    this.props.getFinancials(this.props.activeLeague);
  }

  render() {

    let origCost = parseInt(this.state.amount) + parseInt(this.state.discount);
    return (
      (['paid','perpetual','unlimited','promotional'].indexOf(this.props.clubstatus)>-1)?
        <div>{!!this.state.message && <p className="mt-3">{this.state.message}</p>}</div>:
      <>
      <div>
          <div className="mb-0" style={{fontSize: "1.5em", fontWeight: 300}}>
            Purchase Subscription - ${origCost} Annually
          </div>

          {!!this.state.amount && this.state.amount > 0 &&
            <Row className="justify-content-between ">
              <Col xs="12" className="d-flex justify-content-start">
                <div className="pt-2">
                  <div className="pb-3">Amount to be charged: <strong>${this.state.amount}</strong>
                  {this.state.discount > 0 && <span> (discount applied)</span>}
                  </div>
                  <label style={{display: "block"}}>
                    <AuthnetPay amount={this.state.amount} leagueId={this.props.activeLeague}
                      leagueEventId={0} entryId={0}
                      preValidation={this.preValidation}
                      setPaymentStatus={this.setPaymentStatus}/>
                  </label>
                  <div className="small text-muted mt-2">Please email <a class="" href="mailto:billing@majorschallenge.com">billing@majorschallenge.com</a> if you instead require an invoice for payment.</div>
                  <p>{this.state.error}</p>
                </div>
              </Col>
            </Row>
          }
      </div>
      {!!this.state.message && <p className="mt-3">{this.state.message}</p>}
      </>
    )
  }
}

const LeagueFinancials = (props) => {
  //const balance = props.balance ? numeral(props.balance).format('0') : '';
  return (
    <div className="pt-3">
      Subscription Status: <span className="text-uppercase"><strong>{props.clubstatus}</strong></span>
      <br />
      {
        props.isItChildClub &&
        <div className="small text-muted">This league is complimentary with a paid subscription to {props.parentClubLeagueName}</div>
      }
      {
        props.isItChildClub === false &&
        <div className="small text-muted">Your subscription entitles you to run unlimited pools with unlimited entries for the entire PGA season.
        </div>
      }
    </div>
  )
}
