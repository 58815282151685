import React from 'react';

import {
  Card, CardHeader, CardBody,CardText,
  Table,
  Button, Row, Col,
  Badge,
} from 'reactstrap';

import LoadingModal from 'common/LoadingModal.js';
import GolferModal from 'common/GolferModal.js';
import {EventTitle} from 'common/EventTitle.js';
import numeral from 'numeral';

import Largewarning from 'img/largewarning.png';

import {
  get_leagueContestOADField
} from 'utils/api.js';



export default class LeagueContestOADFields extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      fields: [],
      golferModalOpen: false,
      golferModalGolferId: null,
      error: null,
    };

  }


  componentDidMount() {
    this.fetchLeagueContestFieldData(this.props.activeLeague, this.props.leaguecontestId, this.props.contesteventId);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.activeLeague !== this.props.activeLeague ||
      nextProps.leaguecontestId !== this.props.leaguecontestId) {
      this.fetchLeagueContestFieldData(nextProps.activeLeague, nextProps.leaguecontestId, nextProps.contesteventId);
    }
  }

  fetchLeagueContestFieldData = (league, leaguecontestId, contesteventId) => {

    get_leagueContestOADField(league, leaguecontestId, contesteventId,
     ({success, error, fields}) => {
        this.setState({isLoading:false, fields:(fields||[]) });
    }, (error) => {
      console.log(error);
      this.setState({isLoading:false, fields:[]});
    });
  }


  toggleGolferModal = () => {
    this.setState({golferModalOpen: !this.state.golferModalOpen});
  }

  showGolferDetails = (golferid) => {
    if (!golferid) return;
    this.setState({
      golferModalGolferId: golferid,
      golferModalOpen: true
    });
  }


  render() {

    return (
      <>
          <LoadingModal modalScroll={true} isLoading={this.state.isLoading} />

          <Header toggleViewFields={this.props.toggleViewFields} />
          <div className="oadField ml-2">
            <EventTitle event={this.props.event} showProps={{purse:true, defending_champ:true}} className="mt-1 small"/>

          </div>
          <Fields fields={this.state.fields}
            selectedGolferIds={this.props.selectedGolferIds}
            toggle={this.toggleGolferModal}
            selectGolfer={this.props.selectGolfer}
            toggleViewFields={this.props.toggleViewFields}
            showGolferDetails={this.showGolferDetails}
            />

          <GolferModal isOpen={this.state.golferModalOpen} toggle={this.toggleGolferModal} golferid={this.state.golferModalGolferId}/>
      </>
    );
  }
}

const Header = (props) => {
  return <Row noGutters className="pt-0 d-flex justify-content-center ml-2">
    <Col className="d-flex justify-content-between">
      <div className="d-flex justify-content-start">
          {/*<h3 className="my-2" style={{fontWeight:'bolder'}}>{this.state.leagueContests.name}</h3>*/}
          <h3 className="my-2" style={{fontWeight:'bolder'}}>{'One-And-Done'}</h3>
      </div>
      <div className="d-flex justify-content-end mr-3">
          <Button outline size="sm" color="primary" className="my-2" onClick={props.toggleViewFields}>
          Back
          </Button>
      </div>
    </Col>
  </Row>
}

class Fields extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      breakdown: [],
    };

  }

  componentDidMount() {

  }

  componentWillReceiveProps(nextProps) {

  }

  selectGolfer = (golfer_id)=>{
    this.props.selectGolfer(golfer_id);
    this.props.toggleViewFields();
  }


  render() {

    const rows = [];
    this.props.fields.forEach((item, index) => {

        rows.push(
            <tr className="">
              <td className="text-center">
                {this.props.selectedGolferIds.indexOf(item.golfer_id) < 0 &&
                <span className="availableGolfer_plus" onClick={(e) => this.selectGolfer(item.golfer_id)}>+</span>
                }
              </td>
              <td className="text-center">{item.world_rank}</td>
              <td className="pgaLeaderboardPlayer">
                <div className="d-flex">
                  <span onClick={() => this.props.showGolferDetails(item.golfer_id)}>
                    {item.first_name + ' ' + item.last_name}
                  </span>

                  {(item.injury_iswithdrawn === 1) &&
                    <div><Badge className="mx-2 text-white bg-danger" style={{marginTop:'1px'}} color="denger">OUT</Badge></div>
                  }
                  {(item.injury_iswithdrawn === 0) &&
                    <img src={Largewarning} alt="alt" style={{paddingLeft: "10px", height:'17px', marginBottom:'3px'}}/>
                  }
                </div>
              </td>
              <td className="text-center">{item.picked}</td>
              <td className="text-center">{item.available}</td>
              <td className="text-right" style={{borderLeft:"1px solid #dee2e6"}}>{item.winnings_yr1}</td>
              <td className="text-center">{item.events_yr1}</td>
              <td className="text-center">{item.cuts_yr1}</td>
              <td className="text-right" style={{borderLeft:"1px solid #dee2e6"}}>{item.winnings_yr2}</td>
              <td className="text-center">{item.events_yr2}</td>
              <td className="text-center">{item.cuts_yr2}</td>
            </tr>

        );
    });

    return (

      <Card className="my-4">
        <CardHeader className="d-flex justify-content-between px-2">
          <div className="d-flex justify-content-start ml-2" style={{fontWeight:'500', color:'green'}}>
            {'Select a golfer'}
          </div>
          <div className="d-flex justify-content-end">

          </div>
        </CardHeader>
        <CardBody className="p-0">
          <Row className="profileFormRow pb-3 pb-sm-0 mt-n1">
            <Col  className="">
              <Table responsive>
                <thead>
                <tr className="" style={{backgroundColor: "#f6f6f6", color: "#7c7c7c", "fontSize": "0.7rem", textTransform: "uppercase"}}>
                  <th className="text-center" rowSpan="2" style={{verticalAlign:"middle", width:'60px'}}></th>
                  <th className="text-center" rowSpan="2" style={{verticalAlign:"middle", width:'60px'}}>World Rank</th>
                  <th className="text-left" rowSpan="2" style={{verticalAlign:"middle"}} >Golfer</th>
                  <th className="text-center" rowSpan="2" style={{verticalAlign:"middle"}}>Picked</th>
                  <th className="text-center" rowSpan="2" style={{verticalAlign:"middle"}}>Available</th>
                  <th className="text-center" colSpan="3" style={{verticalAlign:"middle", borderLeft:"2px solid #dee2e6"}}>Current Year</th>
                  <th className="text-center" colSpan="3" style={{verticalAlign:"middle", borderLeft:"2px solid #dee2e6"}}>Previous Year</th>
                </tr>
                <tr className="" style={{backgroundColor: "#f6f6f6", color: "#7c7c7c", "fontSize": "0.7rem", textTransform: "uppercase"}}>

                    <th className="text-right" style={{verticalAlign:"middle", borderLeft:"2px solid #dee2e6"}}>Winnings</th>
                    <th className="text-center" >Events</th>
                    <th className="text-center">Cuts</th>
                    <th className="text-right" style={{verticalAlign:"middle", borderLeft:"2px solid #dee2e6"}}>Winnings</th>
                    <th className="text-center" >Events</th>
                    <th className="text-center">Cuts</th>
                  </tr>
                </thead>
                <tbody style={{fontSize:'13px', borderBottom:'1px solid #dee2e6', height:'500px'}}>
                  {rows}
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row className="profileFormRow pb-3">
            <Col  className="ml-2 text-center">
              <div style={{fontSize:'.85em'}}>Click on golfer for more info.</div>
            </Col>
          </Row>
        </CardBody>
      </Card>

    );
  }
}
