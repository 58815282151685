import React from 'react';

import LandingWrapper from 'common/LandingWrapper.js';

import {
  Container
} from 'reactstrap';

export class LandingPerfectTeam extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <LandingWrapper {...this.props}>
        <PerfectTeamContent />
      </LandingWrapper>
    )
  }
}

export class AuthedPerfectTeam extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <Container className="py-5">
        <PerfectTeamContent />
      </Container>
    )
  }
}
const PerfectTeamContent = (props) => (
  <div className="landingCard text-justify mt-4" style={{fontSize: ".85em"}}>
    <h1 className="text-center">MAJORS CHALLENGE™</h1>
    <h3 className="text-center">$250,000 Perfect Team Contest</h3>
    <h3 className="text-center">Official Rules</h3>

    <h5>What’s a Perfect Team?</h5>
    <p>A perfect team is a lineup of fantasy golfers with the lowest possible score.
    You must select the lowest scores from each selection group. This is different than selecting
    the lowest 12 golfers in a tournament. Score a perfect team and win $250,000. See section 10 for
    complete lineup rules.</p>

    <p>NO PURCHASE NECESSARY TO ENTER OR WIN. A PURCHASE WILL NOT IMPROVE YOUR CHANCES OF WINNING.
    THIS CONTEST IS OPEN TO PERSONS WHO ARE: (A) REGISTERED USERS; (B) LEGAL RESIDENTS OF THE
    UNITED STATES; AND (C) AT LEAST 18 OR AGE OF MAJORITY, WHICHEVER IS OLDER IN STATE OF RESIDENCE,
    ON DATE OF ENTRY. VOID IN ALL U.S. TERRITORIES AND POSSESSIONS AND WHERE PROHIBITED BY LAW. DATA
    RATES MAY APPLY.</p>

    <p>To play in the $250,000 Perfect Team Contest go to <a href="https://www.majorschallenge.com/club/free">https://www.majorschallenge.com/club/free.</a></p>

    <p><h5>Contest Rules</h5></p>

    <p>1. Acceptance of Rules. By entering the “$250,000 Perfect Team Contest” (the “Contest”), operated by
    Roxi Fantasy Gaming LLC at majorschallenge.com (the “Website”), you hereby accept and agree to these
    official rules (the “Official Rules”) of this Contest and the decisions of the Administrator in connection
    with this Contest, whose decisions are final. By entering into this contest, you affirm that you have
    read, understand and comply with all terms in our Terms of Service.
    <a href="https://www.majorschallenge.com/terms">https://www.majorschallenge.com/terms</a>. If you are a wireless service subscriber and have a smartphone,
    tablet or other wireless device that allows you access to the Internet, you may also enter the Contest
    via any of those devices during the Contest Period (as defined below). You may be charged data
    rates according to the Terms and Conditions of your Service Agreement with your wireless carrier.
    Participants should contact their wireless carrier for information on charges, if any, relating to your
    participation in this Contest.</p>

    <p>2. Overview. The Contest is a fantasy game that lets you test your golf knowledge by picking a team of
    twelve (12) golfers at specific PGA tournaments throughout the season. You must be a registered member
    of Majors Challenge to enter and win. No purchase necessary.</p>

    <p>3. Eligibility. This Contest is offered and open to persons who: (a) are Registered Users; (b) are legal
    residents of the Unites States; and (c) are the age of majority in your jurisdiction of residence (this is at
    least 18 in most states, 19 years of age in Nebraska and Alabama, and 21 years of age for activities related
    to our services in Massachusetts). For purposes of these Official Rules, “Registered User” means a person
    who has successfully completed the registration form. Additionally, in order to be eligible to claim any
    Contest prize you must provide your: (a) legal first and last name; (b) valid email address; (c) date of
    birth; (c) state of residence. Please see our Terms of Service for complete age, geography and other
    eligibility requirements. Employees, directors, and immediate family members of the Contest Entities
    and their parents, affiliated and subsidiary companies are not eligible to participate in this Contest.
    For purpose of the foregoing &quot;immediate family&quot; means parents, spouses, children, siblings or any
    members of the same households of such employees and directors. This Contest is void in Puerto
    Rico, all U.S. territories and possessions and where prohibited by law.</p>

    <p>4. Contest Period. The Contest refers to any PGA tournament advertised on Website as
    participating in the “$250,000 Perfect Team Contest”. Each designated tournament provides opportunity
    to enter into Contest and claim its prize. Each designated Contest shall begin at the start of a PGA
    tournament and conclude with that same PGA tournament. Please note that the frequency of
    Contest and prize amounts are subject to change without notice. Any PGA tournament not
    designated on Website as participating in the Contest is not eligible for prizes. To be eligible for a
	Contest, your entry must be received prior to the first scheduled tee time on the first
    day of the designated PGA tournament.</p>

    <p>5. Entering the Contest. To enter the Contest, you must be a member of a Majors Challenge league
		currently promoting a Perfect Team Contest. To enter Contest user may go to the Contest page <a href="https://www.majorschallenge.com/club/free">https://www.majorschallenge.com/club/free</a>. Once registered, you must select a team of 12 golfers, plus 4
    alternates. The object of the Contest is to have the lowest possible combined to par score based on
    the performance of golfers in your lineup. For complete rules as it pertains to team selection, alternates,
    cuts and scoring see the Website How to Play section: <a href="https://www.majorschallenge.com/rules">https://www.majorschallenge.com/rules</a>. By
    entering this Contest, you agree to Majors Challenge’s use of your data in accordance with the Majors
    Challenge Privacy Policy (<a href="https://www.majorschallenge.com/privacy">https://www.majorschallenge.com/privacy</a>) and Terms of Use
    (<a href="https://www.majorschallenge.com/terms">https://www.majorschallenge.com/terms</a>). Limit of one (1) entry per person during any individual week
    where Contest is offered. Users who exceed the limit may be disqualified.</p>

    <p>6. Lineup Instructions. All lineups consist of 12 golfers, plus 4 alternates from the field of golfers in the
    current PGA tournament. Golfers are separated into four selection tiers. You are required to pick 3 golfers
    from tier 1, 3 golfers from tier 2, 4 golfers from tier 3 and 2 golfers from tier 4. Additionally, you are
    required to choose one alternate from each tier. The distribution of golfers across selection tiers is at the
    sole discretion of Majors Challenge. The list of available golfers will be published at the time that the
    Contest for the tournament is made available. Once published, the list is considered final. Majors
    Challenge will not update or modify the list of golfers, regardless of whether a golfer plays in or attends a
    tournament. Users accept the responsibility to perform due diligence on their lineup to ensure their
    golfers are in the official field of PGA players. Selecting a golfer who does not play in a tournament will
    result in an alternate substitution, or in some cases a cut fantasy player. See How to Play for complete
    rules on alternates and cut golfers. A maximum of one (1) lineup per individual account holder is
    permitted. At sole discretion of Majors Challenge and its insurer(s), in the event of any duplicate entries
    identified from one individual, then only the first entry received from that individual shall stand. All other
    duplicate entries will be deemed null and void and not eligible for Contest prize. Determining which
    lineup to consider first is at the sole discretion of Majors Challenge and its insurer(s).</p>

    <p>7. Cancellations, Suspensions and Shortened Tournaments. In order to qualify for the Contest jackpot, the
    PGA tournament must complete four 18-hole rounds of golf with a minimum of 90 players starting the
    event.</p>

    <p>8. Scoring. Your fantasy score is the cumulative four-round score for all twelve golfers in your lineup.
    Alternate and cut golfer scores are calculated as described in the How to Play section of the website.
    Tournaments requiring a playoff to determine a winner do not impact the Perfect Score calculation.</p>

    <p>9. Affidavit of Eligibility/Liability Release and Publicity Release. Winner will be required to complete and
    submit an Affidavit of Eligibility/Liability Release and where lawful, a Publicity release (collectively,
    the &quot;Release Forms&quot;) as well as a w-9 tax form within five (5) days of notification of winning. Such Affidavit
    must be signed and returned within five (5) days of issuance. Failure to return such documents within the
    specified time-period, non-compliance with these Official Rules, the return of any Prize or Prize
    notification, or if any potential Winner cannot be reached or declines a Prize for any reason, could render
    such person as disqualified.</p>

    <p>10. Winning. Winning this Contest requires you to select the lowest final round scoring golfers from each
    selection tier. Your lineup must include the three lowest scoring golfers from selection tier 1, three lowest
    scoring golfers from selection tier 2, four lowest scoring golfers from selection tier 3, and two lowest
    scoring golfers from selection tier 4. Please understand, this is different than selecting the lowest 12
    golfers in a PGA tournament. The Perfect Team jackpot is only awarded when a lineup satisfies the conditions stated above.
	Having the lowest score compared to other lineups does not qualify for Contest jackpot. Unless
    otherwise stated, there are no other prizes guaranteed in the Contest. Providing false information or
    violating any clause in these rules or our Terms of Service will invalidate your entry and disqualifies
    you from any prize. Winner or Winners will be notified by email. Notification will include instruction
    regarding how to claim your Prize as described in Section 13 below. Prizes must be redeemed within
    thirty (30) days of contests end. Unclaimed prizes after thirty (30) days are deemed void. Contestant
    provided email addresses are assumed to be valid and monitored. Roxi Fantasy Gaming LLC is not responsible
    for unread email notifications, email notifications sent to spam or which become undeliverable for other reasons.</p>

    <p>11. Publicity Release Terms. Except where prohibited by law, the Winner&#39;s acceptance of the Prize constitutes
    permission for the Contest Entities to use Winner&#39;s name, photograph, likeness, statements,
    Promotion Entry, biographical information, voice, and address (city and state) worldwide and in all forms
    of media, in perpetuity, without further compensation.</p>

    <p>12. Scoring Revisions and Corrections. Final tournament scores sent by our data provider at the conclusion of a
    tournament will be the basis for calculating scores and results for each Contest. Majors Challenge is
    not responsible for the accuracy of this data. Lineups only qualify for jackpot payout when the actual PGA
    scores satisfy Contest rules. From time to time the PGA may have reason to adjust a golfer’s score
    after official posting. These scoring changes may or may not be reflected in our data provider feed. Majors
    Challenge will determine, at its sole discretion, whether any corrections impact jackpot eligibility.</p>

    <p>13. Prizes. Achieving a Perfect Team and satisfying Contest eligibility requirements makes you eligible
    for Contest jackpot of $250,000. In the event of multiple winners in the same contest, the prize pool will
    be shared evenly between all winning and eligible entrants. Neither federal nor state nor local income tax of
    any kind shall be withheld or paid by Majors Challenge on behalf any winner. Winners understand that it is their
    responsibility to pay, according to law, it’s applicable income taxes. Contest is insured by BEST Hole
    In One, PO Box 6057, Lindenhurst, IL 60046.</p>

    <p>14. Improper Conduct. Administrator, in its sole discretion, may disqualify any entrant from participation in or
    use of any or all portions of this Contest, and refuse to award the Prize, if entrant engages in any
    conduct Administrator deems to be improper, unfair or otherwise adverse to the operation of the
    Contest or detrimental to other entrants of the Contest. Such improper conduct includes,
    without limitation, falsifying personal information required during entrant registration or Prize claim,
    violating any term or condition stated herein, accumulating entries through methods, such as automated
    computer scripts or any other programming techniques, allowing others to use entrant&#39;s personal
    information for the purpose of accumulating entries, or intentionally trying to defraud, reverse engineer,
    disassemble or otherwise tamper with the computer programs in connection with this Contest.
    Winner agrees that Administrator may void any part of the Prize that a Winner may have won
    and/or require the return of any part of the Prize that the Winner may have won as a result of
    such improper conduct. Winner further acknowledges that any forfeiture of the Prize and/or return of the
    Prize shall in no way prevent Administrator from pursuing other avenues of recourse such as criminal or
    civil proceedings in connection with such conduct. WARNING: ANY ATTEMPT TO DELIBERATELY DAMAGE
    OR UNDERMINE THE LEGITIMATE OPERATION OF THIS CONTEST MAY BE A VIOLATION OF CRIMINAL
    AND CIVIL LAWS, AND SHOULD SUCH AN ATTEMPT BE MADE, EACH OF THE CONTEST
    ENTITIES RESERVES THE RIGHT TO SEEK DAMAGES OR OTHER REMEDIES FROM ANY SUCH PERSON(S)
    RESPONSIBLE FOR SUCH ATTEMPT TO THE FULLEST EXTENT PERMITTED BY LAW.</p>

    <p>15. Integrity of Contest. If, for any reason, the Contest is not capable of running as planned by
    reason of infection by computer virus, worms, bugs, tampering, unauthorized intervention, fraud,
    technical failures or any other causes which Administrator, in its sole opinion believes could corrupt or
    affect the administration, security, fairness, integrity or proper conduct of this Contest, Administrator
    reserves the right at its sole discretion to cancel, terminate, modify or suspend this Contest.</p>

    <p>16. Lost or Corrupted Entries. The Contest Entities and their parents, affiliated and subsidiary companies
    and advertising and promotion agencies, assume no liability and are not responsible for, and you hereby
    forever waive any rights to any claim in connection with lost, late, incomplete, corrupted, stolen,
    misdirected, illegible or postage-due entries or mail, if applicable; or for any computer, telephone, cable,
    network, satellite, electronic or Internet hardware or software malfunctions, unauthorized human
    intervention, or the incorrect or inaccurate capture of entry or other information, or the failure to capture
    any such information. The Contest Entities and their parents, affiliated and subsidiary companies,
    advertising and promotion agencies, are not responsible for any incorrect or inaccurate information,
    whether caused by registration information submitted by end users or tampering, hacking, or by any of the
    equipment or programming associated with or utilized in this Contest, and assumes no responsibility
    for any error, omission, interruption, deletion, defect, delay in operation or transmission, communications
    line failure, theft or destruction or unauthorized access to the Contest.</p>

    <p>17. Damaged Property. The Contest Entities and their parents, affiliated and subsidiary companies and
    advertising and promotion agencies, assume no liability and are not responsible for, and you hereby
    forever waive any rights to any claim in connection with, injury or damage to any entrants or to any other
    person&#39;s computer or property related to or resulting from participating in this Contest or
    downloading materials from this Contest.</p>

    <p>18. Contest Errors. The Contest Entities and their parents, affiliated and subsidiary companies and
    advertising and promotion agencies, assume no liability and are not responsible for, and you hereby
    forever waive any rights to any claim in connection with, errors and/or ambiguity: (a) in the Contest;
    (b) in any related advertising or promotion of this Contest; and/or (c) in these Official Rules. In the
    event of any ambiguity(s) or error(s) in these Official Rules, Administrator reserves the right to modify
    these Official Rules for clarification purposes or to correct any such ambiguity or error (s) without
    materially affecting the terms and conditions of the Contest.</p>

    <p>19. Release and Indemnity. Each entrant in this Contest, including, without limitation, the Winner, hereby
    releases and agrees to hold harmless the Contest Entities and their parents, affiliates, subsidiaries,
    advertising and promotion agencies and their respective directors, officers, employees, representatives
    and agents from any and all liability for any injuries, loss or damage of any kind to person, including death
    and property arising in whole or in part, directly or indirectly, from acceptance, possession, use or misuse
    of the Prize, participation in this Contest and participation in any activity related to this Contest.</p>

    <p>20. Limitation of Liability. IN NO EVENT WILL THE CONTEST ENTITIES, THEIR PARENTS, AFFILIATES,
    SUBSIDIARIES AND RELATED COMPANIES, THEIR ADVERTISING OR PROMOTION AGENCIES OR THEIR
    RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, REPRESENTATIVES AND AGENTS, BE RESPONSIBLE OR
    LIABLE FOR ANY DAMAGES OR LOSSES OF ANY KIND, INCLUDING DIRECT, INDIRECT, INCIDENTAL,
    CONSEQUENTIAL OR PUNITIVE DAMAGES ARISING OUT OF YOUR ACCESS TO AND USE OF THE
    CONTEST OR THE DOWNLOADING FROM AND/OR PRINTING MATERIAL DOWNLOADED FROM THE
    CONTEST. SOME JURISDICTIONS MAY NOT ALLOW THE LIMITATIONS OR EXCLUSION OF LIABILITY FOR
    INCIDENTAL OR CONSEQUENTIAL DAMAGES OR EXCLUSION OF IMPLIED WARRANTIES, SO SOME OF THE
    ABOVE LIMITATIONS OR EXCLUSIONS MAY NOT APPLY TO YOU. CHECK YOUR LOCAL LAWS FOR ANY
    RESTRICTIONS OR LIMITATIONS REGARDING THESE LIMITATIONS OR EXCLUSIONS.</p>

    <p>21. Disclaimer of Warranties. THE CONTEST AND PRIZE ARE PROVIDED &quot;AS IS&quot; WITHOUT WARRANTY OF
    ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF
    MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT.</p>

    <p>22. Identity Disputes. In the event of a dispute as to the identity of a winner based on an email address, or
    other provide information, the winning entry will be declared made by the Authorized Account Holder of
    the email address submitted at time of registration into the Contest. For purposes of these Official
    Rules, &quot;Authorized Account Holder&quot; is defined as the natural person who is assigned to an email address by
    an Internet access provider, wireless carrier, online service provider or other organization (e.g., business,
    educational, institution, etc.) that is responsible for assigning email addresses for the domain associated
    with the submitted email address.</p>

    <p>23. Binding Arbitration. The parents waive all rights to trial in any action or proceeding instituted in connection
    with these Official Rules and/or this Contest. Any controversy or claim arising out of or relating to
    these Official Rules and/or this Contest shall be settled by binding arbitration in accordance with the
    commercial arbitration rules of the American Arbitration Association. Any such controversy or claim shall
    be arbitrated on an individual basis and shall not be consolidated in any arbitration with any claim or
    controversy of any other party. The arbitration shall be conducted in the State of New Jersey, in the City of
    Morristown and judgment on the arbitration award may be entered into any court having jurisdiction
    thereof.</p>

    <p>24. Governing Law and Jurisdiction. This promotion is governed by U.S. law and is subject to all applicable
    federal, state and local laws and regulations. Void where prohibited by law. All issues and questions
    concerning the construction, validity, interpretation and enforceability of these Official Rules, or the rights
    and obligations of entrant and Administrator in connection with this Contest, shall be governed by,
    and construed in accordance with, the laws of the State of New Jersey, U.S.A. without giving effect to the
    conflict of laws and rules thereof, and any matters or proceedings which are not subject to arbitration, as
    set forth in Section 23 of these Official Rules and/or for entering any judgment on an arbitration award,
    shall take place in the State of New Jersey, in the City of Morristown.</p>
  </div>
)
