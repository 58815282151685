import React from 'react';

import {
  Card,
  Button
} from 'reactstrap';

import styled from 'styled-components'
import { useTable } from "react-table";


import {
  MobileHeader,
  ContentWrapper
} from 'common/AdminComponents.js';

import {
  get_report_list,
  get_report_result,
} from 'utils/api.js';


import 'common/McModal.css';
import CsvDownloader from 'react-csv-downloader';
import FontAwesome from 'react-fontawesome';


const Styles = styled.div`

  /* This is required to make the table full-width */
  display: block;
  max-width: 100%;
  /* This will make the table scrollable when it gets too small */
  .tableWrap {
    display: block;
    max-width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    border-bottom: 0px solid black;
  }
  table {
    /* Make sure the inner table is always as wide as needed */
    width: 100%;
    border-spacing: 0;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }

      :nth-child(even) {
          background-color: #f6f6f6;
      }
    }
    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 0px solid black;
      border-right: 0px solid black;
      /* The secret sauce */
      /* Each cell should grow equally */
      width: 1%;
      /* But "collapsed" cells should be as small as possible */
      &.collapse {
        width: 0.0000000001%;
      }
      :last-child {
        border-right: 0;
      }
    }
  }
  .pagination {
    padding: 0.5rem;
  }

`

export default class MailchimpReports extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      reportList: [],
      reportId: null,
      reportIndx: -1,
      reportResultCols: [],
      reportResult: null,
      toggleState: true,
      type: props.type,
    };

    this.getReportList = this.getReportList.bind(this);
    this.getReportResult = this.getReportResult.bind(this);
    this.setToggleState = this.setToggleState.bind(this);

  }

  componentDidMount() {
    this.getReportList();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.type === this.props.type) {
      return;
    }
    this.getReportList();
    this.setToggleState();

  }

  getReportList() {
    get_report_list(this.props.type,
    ({reportList}) => {
      this.setState({
        reportList,
      });
    }, (error) => {
      console.log(error);
    });
  }

  getReportResult(reportId, reportIndx) {
    this.setState({
      reportId,
      reportIndx,
    });

    get_report_result(reportId,
    ({reportResult}) => {
      // build result columns
      let reportResultCols = [];
      if (reportResult && reportResult.length >0){
        let cols = Object.getOwnPropertyNames(reportResult[0]);
        reportResultCols = cols.map(a => {
          let dateformat = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/;
          let className = '';
          //alert(reportResult[0][a]);
          if(reportResult[0][a] && reportResult[0][a].toString().match(dateformat)){
            className = 'text-nowrap'
          }
          return {
            'Header': a,
            'accessor': a,
            'className': className,
          }
        });
      }

      this.setState({
        reportResultCols,
        reportResult,
        toggleState: false,
      });
    }, (error) => {
      console.log(error);
    });
  }

  setToggleState(){
    this.setState({ toggleState: !this.state.toggleState});
  }


  render() {
    const csvExprtColumns = this.state.reportResultCols.map(a => {
      return {
        'id': a.Header,
        'displayName': a.Header,
      }
    });
    
    return (
      <div>
        <MobileHeader to="Reports"/>
        <ContentWrapper>
          <Card className="pb-4">
            <Title />
            {this.state.toggleState &&
            <ReactCustomTable columns={repotListColumns} data={this.state.reportList}
              getReportResult={this.getReportResult}/>
            }

            {this.state.reportList && this.state.reportList.length>0 && this.state.reportIndx>-1 && this.state.toggleState === false &&
              <div>
                <div className="d-flex justify-content-around  p-2">
                    <div className="col-sm-6 pt-1 ml-n3" style={{"fontSize": "1rem", "fontWeight": "bold", "textTransform": "uppercase",}}>
                      {this.state.reportList[this.state.reportIndx].name}
                    </div>
                    <div className="col-sm-6 text-right pr-0">
                      <div className="btn-group">
                        <CsvDownloader
                          filename={this.state.reportList[this.state.reportIndx].name}
                          separator=","
                          wrapColumnChar='"'
                          columns={csvExprtColumns}
                          datas={this.state.reportResult}>
                          {
                            this.state.reportResult.length > 0 ?
                            <Button size="sm" color="success" outline className="ml-3 ml-md-none text-nowrap"
                              style={{minWidth:'60px'}}>
                              <FontAwesome name="file-excel-o" />&nbsp;&nbsp;<FontAwesome name="download" />
                            </Button> :
                            <Button size="sm" color="secondary" outline disabled className="ml-3 ml-md-none text-nowrap"
                              style={{minWidth:'60px'}}>
                              <FontAwesome name="file-excel-o" />&nbsp;&nbsp;<FontAwesome name="download" />
                            </Button>
                          }
                        </CsvDownloader>
                        <Button size="sm" color="success" outline className="ml-3 rounded" onClick={()=>{this.setToggleState()}}>Back</Button>
                      </div>
                    </div>

                </div>
                <div>
                  <ReactCustomTable columns={this.state.reportResultCols} data={this.state.reportResult} />
                </div>
              </div>
            }
            </Card>
        </ContentWrapper>
      </div>
    );
  }


}

const Title = (props) =>
  <h2 className="text-center p-2 pt-4" style={{color:"#212121"}}>Reports Console</h2>


const ActionCell = ({
    value: initialValue,
    row: { index },
    column: { id },
    getReportResult, // This is a custom function that we supplied to our table instance
  }) => {
    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(initialValue)

    // We'll only update the external data when the input is blurred
    const onClick = e => {
      getReportResult(initialValue, index);
    }

    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
      setValue(initialValue)
    }, [initialValue])

    return <Button size="sm" color="primary" value={value} onClick={onClick} >Run </Button>

  }

const repotListColumns = [
      {
        Header: "SEQ",
        accessor: "seq"
      },
      {
        Header: "Name",
        accessor: "name"
      },
      {
        Header: "Action",
        accessor: "id",
        Cell: ActionCell
      }
    ];

const ReactCustomTable = ({ columns, data, getReportResult }) => {
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
    } = useTable({
      columns,
      data,
      getReportResult,
    },

    );

    return (
      <Styles>
      <div className="tableWrap">
        <table {...getTableProps()}>
          <thead style={{backgroundColor: "gray", "color":"white", "fontSize": "0.7rem", textTransform: "uppercase"}}>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th
                    {...column.getHeaderProps({
                      className: column.collapse ? 'collapse' : '',
                    })}
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} style={{ "fontSize": "0.7rem",}}>
            {rows.map((row, i) => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return (
                      <td
                        {...cell.getCellProps({
                          className: cell.column.className ? cell.column.className : cell.column.className,
                        })}
                      >
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
        {/*
        Pagination can be built however you'd like.
        This is just a very basic UI implementation:
      */}
      </div>
      <div className="pagination">

      </div>
    </Styles>
    );
  };
