import React from 'react';

import LandingWrapper from 'common/LandingWrapper.js';

import {
  Container
} from 'reactstrap';

export class LandingTerms extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <LandingWrapper {...this.props}>
        <TermsContent />
      </LandingWrapper>
    )
  }
}

export class AuthedTerms extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <Container className="py-5">
        <TermsContent />
      </Container>
    )
  }
}
const TermsContent = (props) => (
  <div className="landingCard text-justify mt-4" style={{fontSize: ".85em"}}>
    <h1 className="text-center">MAJORS CHALLENGE™ TERMS OF SERVICE</h1>

    <h5>1. PREAMBLE</h5>

    <p>The following Terms of Service (“Terms”) set forth the terms and conditions of the agreement
    between you, an individual user (“You”), and Roxi Fantasy Gaming (“Majors Challenge™”) in
    conjunction with Majors Challenge™’s offering of fantasy golf contests (“the Service”) at the
    two domains www.majorschallenge.com and www.mc.golf (individually and collectively “the
    Site”) and through our iPhone and Android apps, to the extent available.</p>

    <p>These Terms are to be read in conjunction with the Majors Challenge™ Privacy Policy and the
    Majors Challenge™ game rules.</p>

    <h5>2. THE SERVICE</h5>

    <p>Majors Challenge™ will provide you with access to fantasy golf contests in which you must
    select a team of players through the use of your skill and knowledge of professional golf.
    For each contest, winners are determined by Majors Challenge™ based on the ability to predict
    the performance of professional athletes over the course of multiple, real-world sporting events.
    Majors Challenge™ shall have the sole discretion of determining its game rules and selecting winners
    of these contests. Further, Majors Challenge™ shall be the sole arbiter of disputes over who wins each contest.
    </p>

    <h5>3. ACCEPTANCE OF TERMS</h5>

    <p>By accessing the Site and using any part of the Site or Service, you agree to be bound by these
    Terms. It is your responsibility to review these Terms regularly to determine whether they have
    changed. If you do not agree to all of the Terms, then you may not access the Site or attempt to
    use our Services.</p>

    <h5>4. TERMS REPRESENT ENTIRE AGREEMENT</h5>

    <p>These Terms constitute the entire agreement between you and Majors Challenge™ with respect
    to the Site, and these Terms supersede any prior agreements, oral or written, between you and
    Majors Challenge™. A provision of these Terms may be waived only by written instrument
    executed by the party entitled to the benefit of such provisions. The failure of Majors
    Challenge™ to exercise or enforce any right or provision of these Terms will not constitute a
    waiver of such right or provision.</p>

    <h5>5. MODIFICATION OF TERMS</h5>

    <p>Majors Challenge™ reserves the right, at its sole discretion, to modify or replace the existing
    Terms of Service at any time. Should any change in Terms occur, you shall be responsible for
    reviewing and becoming familiar with any such modifications. Your continued use of the
    Majors Challenge™ Site and Service upon any change or modification of the Terms constitutes
    your constructive acknowledgement and acceptance of these modifications.</p>

    <h5>6. USER ELIGIBILTY</h5>

    <p>By using our Site or Service, you hereby represent and warrant that you are fully able
    and competent to enter into the terms, conditions, obligations, affirmations, representations
    and warranties set forth in the Terms of Service and to abide by and comply with these terms.</p>

    <p>Further, you represent and warrant that:</p>

    <p>i) You are the age of majority in your jurisdiction of residence (this is at least 18 in
    most states, 19 years of age in Nebraska and Alabama, and 21 years of age for activities
    related to our services in Massachusetts).</p>

    <p>ii) If you are entering a contest that includes a prize provided by Majors Challenge,
      you are a citizen or resident of the United States of America (For purposes of clarity, citizens and
      residents of Puerto Rico are not eligible). Prizes provided by entities other than Majors Challenge are
      not subject to this clause but may be subject to other restrictions communicated by the entity providing the prize.</p>

    <p>iii) You are physically located in a jurisdiction in which participation in any contest you choose to participate
    in is allowed by applicable law.</p>

    <p>iv) If you are entering a contest that includes a prize provided by Majors Challenge, you are not an employee, officer, director, contractor, or agent of Majors Challenge™, nor are you an immediate family member of any officer, director, contractor or agent of Majors Challenge™. (The term “immediate family member,” as defined herein, shall include parents, children, spouses, siblings, or any other person residing in the same household of an officer, director, contractor, or agent).</p>

    <p>v) You are not a professional or amateur golfer whose individual statistics or
    performance may be used to determine any part of the outcome of a fantasy sports
    contest, or a sports agent, team employee, referee, or league official associated with an
    athletic competition that is the subject of fantasy golf contests.</p>

    <p>vi) If you are entering a contest that includes a prize provided by Majors Challenge, you will establish only one account to participate in the Services offered on the Majors Challenge™ Site, and only you will operate the account.</p>

    <p>vii) You will abide at all times by these Terms and any other agreements between you
    and Majors Challenge™.</p>

    <p>viii) You are not subject to backup withholding tax because: (a) you are exempt from backup withholding, or (b) you have not been notified by the Internal Revenue Service (IRS) that you are subject to backup withholding as a result of a failure to report all interest or dividends, (c) the IRS has notified you that you are no longer subject to backup withholding, or (d) you are not a resident of the United States.
    </p>

    <p>ix) If you decide to participate in our Perfect Team Contest, you acknowledge that your eligibility to win the Perfect Team jackpot will be determined by our contest insurance provider. Currently, our contest insurance provider is Best Hole In One LLC, located at PO BOX 6057, Lindenhurst, IL 60046. If, for any reason, you are not approved for a prize by Best Hole In One LLC, you understand that Majors Challenge™ bears no responsibility towards you. Moreover, you agree to furnish any requested information to Best Hole In One LLC or any subsequent insurance provider to verify your identity, contest results, and eligibility for a prize. Instructions for redeeming prizes will be sent via email exclusively. Prizes not claimed within 3 months of initial contact will be forfeited.</p>

    <p>If any of the above representations and warranties are not met, in addition to any other rights and
    remedies available under law or equity, Majors Challenge™ reserves the right to suspend or
    terminate your account and to revoke or withhold the awarding of any prizes associated with
    your account.</p>

    <p>Further, in the event that Majors Challenge™ discovers that you have opened more than one
    account, in addition to any other rights that Majors Challenge™ may have, Majors Challenge™
    reserves the right to suspend or terminate any or all of your accounts and terminate, withhold or
    revoke the award of any prizes.</p>

    <h5>7. USER CONDUCT</h5>

    <p>As a condition of use, you agree not to use the Site or Service for any purpose that is unlawful or
    prohibited by these Terms of Service, or any other purpose not reasonably intended by Majors
    Challenge™. By way of example, and not as a limitation, you agree to conduct yourself with
    good sportsmanship at all times, not to purchase multiple teams within a given contest unless the
    contest affirmatively allows for multiple entries by the same user, and not to engage in Collusion
    with other users to win any contest (the term “Collusion” shall include, but is not limited to,
    working together with another user to better any one entry at the expense of another).</p>

    <p>In addition, you agree not to abuse, harass, impersonate, intimidate or threaten other Majors
    Challenge™ users; not to post or transmit, or cause to be posted or transmitted, any materials
    (“User Content”) that are infringing, libelous, defamatory, abusive, offensive, obscene,
    pornographic, vulgar, or otherwise in violation of any law or right of any third party; not to use
    the Site for any unauthorized purpose, or in violation of any applicable law, including intellectual
    property laws; not to post or transmit, or cause to be posted or transmitted, any communication
    or solicitation designed or intended to obtain password, account, or private information from any
    Majors Challenge™ user; not to create or submit unwanted email (&quot;Spam&quot;) to any other Majors
    Challenge™ users; and not to infringe upon the intellectual property rights of Majors
    Challenge™, its users, or any third party.</p>

    <p>Further, you agree not to submit comments discussing or linking to affiliate programs, multi-
    level marketing schemes, personal business ventures, or off-topic content; not to post, email,
    transmit, upload, or otherwise make available any material that contains software viruses or any
    other computer code, files or programs designed or functioning to interrupt, destroy, or limit the
    functionality of any computer software or hardware or telecommunications equipment; and not
    to use any robot, spider, scraper or other automated means to access the site for any purpose
    (except for RSS feed access) without our express written permission.</p>

    <p>Finally, you agree that you will not: (1) take any action that imposes, or may impose, in our sole
    discretion, an unreasonable or disproportionately large load on our infrastructure; (2) interfere or
    attempt to interfere with the proper working of the Site or any activities conducted on the Site; or
    (3) bypass any measures Majors Challenge™ may use to prevent or restrict access to the Site.</p>

    <p>Violation of our rules may result in the removal of your content from the Site and/or the cancellation of your account. You acknowledge and agree that Majors Challenge™ may remove any User Content and terminate any Majors Challenge™ account at any time for any reason (including, but not limited to, upon receipt of claims or allegations from third parties or authorities relating to such User Content). IN ADDITION, ANY ATTEMPT BY A USER TO DELIBERATELY DAMAGE ANY WEBSITE OR UNDERMINE THE LEGITIMATE OPERATION OF OUR CONTESTS IS A VIOLATION OF CRIMINAL AND CIVIL LAWS, AND SHOULD SUCH AN ATTEMPT OCCUR, MAJORS CHALLENGE™ RESERVES THE RIGHT TO SEEK DAMAGES FROM ANY SUCH USER TO THE FULLEST EXTENT AVAILABLE BY LAW.</p>

    <p>To report Terms of Service abuse, please contact us at legal@majorschallenge.com.</p>

    <h5>8. USER CONTENT</h5>

    <p>You understand that all content created by users of either the Site or Service (“User Content”),
    including but not limited to profile information and communications with other users, whether
    privately transmitted or made publicly available, is the sole responsibility of the person from
    which such User Content originated. This means that you are entirely responsible for all User
    Content that you upload, post, share, email, transmit, or otherwise make available via the Site.
    Under no circumstances will Majors Challenge™ be liable in any way for any User Content
    uploaded, posted, shared, emailed, transmitted or otherwise made available via the Site.</p>

    <p>You acknowledge that Majors Challenge™ may or may not pre-screen User Content, but that
    Majors Challenge™ and its designees have the right (but not the obligation) in their sole
    discretion to pre-screen, refuse, permanently delete, and/or move any User Content available via
    the Site. Without limiting the foregoing, Majors Challenge™ and its designees shall have the
    right to remove any User Content that violates these Terms or is otherwise objectionable in
    Majors Challenge™’s sole discretion. You understand that by using the Site, you may be
    exposed to User Content that you may consider offensive or objectionable. You agree that you
    must evaluate, and bear all risks associated with, the use or disclosure of any User Content. You
    further acknowledge and agree that you bear the sole risk of reliance on any content available on
    or through the Site.</p>

    <p>With respect to User Content you submit or make available on the Site, you grant Majors
    Challenge™ an irrevocable, fully sub-licensable, perpetual, world-wide, royalty-free, non-
    exclusive license to use, distribute, reproduce, modify, adapt, publish, translate, publicly perform
    and publicly display such User Content (in whole or in part) and to incorporate such User
    Content into other works in any format or medium now known or later developed.</p>

    <p>You are solely responsible for your interactions with other users of the Site. Majors Challenge™
    reserves the right, but has no obligation, to monitor disputes between you and other users.</p>

    <h5>9. RIGHTS TO USE OF YOUR PERSONAL INFORMATION</h5>

    <p>For Majors Challenge™ exclusive contests, participants acknowledge that upon being notified of their potential win and until informed otherwise, they are obligated to make themselves accessible to Majors Challenge™ for publicity, advertising, and promotional purposes associated with the contest. This obligation extends from the date of notification until they are notified otherwise. These exclusive contests are those organized solely by Majors Challenge™ or in collaboration with partners, distinct from privately organized contests by regular users or third-party organizations.</p>

    <h5>10. REGISTRATION FOR ACCOUNT</h5>

    <p>In order to use our Site or Service, you must register for an account. By registering as a user, you
    agree to provide accurate, current and complete information about yourself as prompted (such
    information being the “Registration Data”) and to maintain and promptly update the Registration
    Data to keep it accurate, current and complete. Furthermore, you will not take any steps to mask
    your identity, location or demographic information. If you provide any information that is
    inaccurate, not current or incomplete, or Majors Challenge™ has reasonable grounds to suspect
    that such information is inaccurate, not current or incomplete, Majors Challenge™ may deny you
    access to areas requiring registration, at its sole discretion.</p>

    <h5>11. ACCOUNT PASSWORD AND SECURITY</h5>

    <p>At the time of registering for an online account, you must provide a valid email address and
    supply a username and password to be used in conjunction with your account. Many portions of
    the Site require registration for access. You are responsible for maintaining the confidentiality of
    your username and password, and are fully responsible for all uses of your username and
    password, whether by you or others. You agree to (a) keep your username and password
    confidential and not share them with anyone else; (b) immediately notify Majors Challenge™ of
    any unauthorized use of your username and password or account or any other breach of security;
    and (c) use only your username and password to access the Site’s Restricted Areas. IF THERE
    IS UNAUTHORIZED USE OF YOUR ACCOUNT, YOU WILL BE LIABLE FOR THE
    LOSSES INCURRED BY YOURSELF, MAJORS CHALLENGE™ AND OTHERS DUE TO
    ANY UNAUTHORIZED USE.</p>

    <h5>12. COMMUNICATIONS AND DISCLOSURES</h5>

    <p>As a result of your registration for the Service, you may receive certain commercial
    communications from Majors Challenge™. You understand and agree that these
    communications are part of your registration, and that you may opt out of receiving these
    communications at any time by either using the unsubscribe functionality where available or
    sending an email to legal@majorschallenge.com.</p>

    <p>You acknowledge, consent and agree that Majors Challenge™ may access, preserve and disclose
    your account information and content if required to do so by law or in a good faith belief that
    such access, preservation, or disclosure is reasonably necessary: (i) to comply with legal process;
    (ii) to enforce these Terms of Service; (iii) to respond to claims that any content violates the
    rights of third parties; (iv) to respond to your requests for customer service; or (v) to protect the
    rights, property, or personal safety of Majors Challenge™, its Users and the public.</p>

    <h5>13. DISQUALIFICATION OF USERS</h5>

    <p>Majors Challenge™, in its sole discretion, may disqualify any entrant from the Site or Service,
    refuse to award fantasy points or prizes and require the return of any prizes, if an entrant engages
    in conduct Majors Challenge™ deems to be improper, unfair or otherwise adverse to the
    operation of the Site or Service or in any way detrimental to other entrants. Improper conduct
    includes, but is not limited to: falsifying personal information required to use the Service or to
    claim a prize; accumulating, or attempting to accumulate, points or prizes through unauthorized
    methods such as automated scripts or other automated means; tampering with the administration
    of the Service or trying to in any way tamper with the computer programs associated with this
    Service; obtaining other entrants’ information and spamming other entrants; engaging in
    Collusion with other contest participants; abusing the Majors Challenge™ Site in any way; or
    using language that in the sole discretion of Majors Challenge™ is deemed offensive,
    intimidating, or in violation of the spirit of building a healthy and friendly fantasy sports
    environment. You further acknowledge that the forfeiture and/or return of any prize shall in no
    way prevent Majors Challenge™ from pursuing criminal or civil proceedings in connection with
    such conduct.</p>

    <h5>14. TECHNICAL MALFUNCTIONS</h5>

    <p>You will not hold Majors Challenge™ or its partners responsible for: any incorrect, invalid or
    inaccurate entry information; human errors; technical malfunctions; failures, including public
    utility or telephone outages; omissions, interruptions, latency, deletions or defects of any
    telephone system or network, computer online systems, data, computer equipment, servers,
    providers, or software (including, but not limited to, software and operating systems that do not
    permit an entrant to participate in the Service), including without limitation any injury or damage
    to any entrant’s or any other person’s computer equipment relating to or resulting from
    participation in the Service; inability to access the Site, or any web pages of Majors Challenge™;
    theft, tampering, destruction, or unauthorized access to, or alteration of, entries and/or images of
    any kind; data that is processed late or incorrectly or is incomplete or lost due to telephone,
    computer or electronic malfunction or traffic congestion on telephone lines or transmission
    systems, or the Internet, or any Service provider’s facilities, or any Site for any other reason
    whatsoever; typographical, printing or other errors; or any combination thereof.</p>

    <p>Majors Challenge™ and any partners are also not responsible for incomplete, illegible,
    misdirected or stolen entries. If for any reason the Service is not capable of running as originally
    planned, as well as if the Site or Service becomes corrupted or does not allow for the proper
    usage and processing of entries in accordance with the rules, or if infection by a computer (or
    similar) virus, bugs, tampering, unauthorized intervention, actions by entrants, fraud, technical
    failures, or any other causes of any kind, in the sole opinion of Majors Challenge™ corrupts or
    affects the administration, security, fairness, integrity or proper conduct of the Service, Majors
    Challenge™ reserves the right, in its sole discretion, to disqualify any individual implicated in
    such action and/or to cancel, terminate, extend, modify or suspend the Service, and select the
    winner(s) from all eligible entries received. If such cancellation, termination, modification or
    suspension occurs, notification will be posted on the Majors Challenge™ Site.</p>

    <p>From time to time servers may overload and data may be lost. Majors Challenge™ assumes no
    responsibility for the occasional loss of data, irrespective of whether this loss is temporary or
    permanent in nature.</p>

    <p>The failure of Majors Challenge™ to comply with any provision of these rules due to an act of
    god, hurricane, war, fire, riot, earthquake, terrorism, act of public enemies, actions of
    governmental authorities outside of the control of Majors Challenge™ (excepting compliance
    with applicable codes and regulations) or other force majeure event will not be considered a
    breach of these terms.</p>

    <h5>15. TAXATION</h5>

    <p>At the beginning of each year, Majors Challenge™ will reach out to all winners of Majors Challenge™ exclusive contests who have earned $600 or more (after deducting all entry fees) in the previous year. These winners will be asked to provide their address and social security details. This information is necessary for filing a 1099-MISC form with the Internal Revenue Service (IRS). It is important to note that the responsibility for paying taxes on any winnings lies solely with each user. This process applies specifically to contests organized exclusively by Majors Challenge™ and not to privately organized contests by regular users or third-party organizations.
    </p>

    <h5>16. NON-AFFILIATION WITH ANY SPORTS LEAGUE OR PLAYERS ASSOCATION</h5>

    <p>Majors Challenge™, as well as its Site and Service, is not in any way affiliated with any
    professional or amateur sports league, or with any players association or collection of players.
    The use of players’ names in conjunction with their statistics in our contests is intended for
    informational purposes only and should not be construed as an endorsement of our contests.</p>

    <h5>17. INDEMNITY</h5>

    <p>BY USING THE MAJORS CHALLENGE™ SERVICE OR ACCEPTING ANY PRIZE, YOU
    AGREE TO RELEASE AND TO HOLD HARMLESS MAJORS CHALLENGE™ AND EACH
    OF ITS SUBSIDIARIES, AFFILIATES AND AGENCIES, AS WELL AS THE OFFICERS,
    DIRECTORS, EMPLOYEES, SHAREHOLDERS AND REPRESENTATIVES OF ANY OF
    THE FOREGOING ENTITIES FROM ANY AND ALL LIABILITY, CLAIMS OR ACTIONS
    OF ANY KIND WHATSOEVER, INCLUDING, BUT NOT LIMITED TO, FOR INJURIES,
    DAMAGES OR LOSSES TO PERSONS AND PROPERTY WHICH MAY BE SUSTAINED
    IN CONNECTION WITH PARTICIPATION IN THE SERVICE, THE RECEIPT,
    OWNERSHIP, USE OR MISUSE OF ANY PRIZE AND CLAIMS BASED ON PUBLICITY
    RIGHTS, DEFAMATION OR INVASION OF PRIVACY.</p>

    <h5>18. NO WARRANTIES</h5>

    <p>TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW,
    MAJORS CHALLENGE™ DISCLAIMS ALL WARRANTIES--STATUTORY, EXPRESS OR
    IMPLIED--INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF
    MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-
    INFRINGEMENT OF PRORPIETARY RIGHTS.</p>

    <p>FURTHER, YOU EXPRESSLY UNDERSTAND AND AGREE THAT YOUR USE OF THE
    SITE IS AT YOUR SOLE RISK. THE SITE IS PROVIDED ON AN “AS IS” AND “AS
    AVAILABLE” BASIS. THE SERVICE, CONTENT, AND SITE ARE PROVIDED ON AN
    &quot;AS IS&quot; BASIS, WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR
    IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF</p>

    <p>MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR NON-
    INFRINGEMENT. YOU ACKNOWLEDGE THAT MAJORS CHALLENGE™ HAS NO
    CONTROL OVER, AND NO DUTY TO TAKE ANY ACTION REGARDING: WHICH
    USERS GAIN ACCESS TO THE SITE OR USE THE SITE; WHAT EFFECTS THE
    CONTENT MAY HAVE ON YOU; HOW YOU MAY INTERPRET OR USE THE
    CONTENT; OR WHAT ACTIONS YOU MAY TAKE AS A RESULT OF HAVING BEEN
    EXPOSED TO THE CONTENT. YOU RELEASE MAJORS CHALLENGE™ FROM ALL
    LIABILITY FOR YOUR HAVING ACQUIRED OR NOT ACQUIRED CONTENT
    THROUGH THE SERVICE OR THE SITE. THE SITE MAY CONTAIN OR DIRECT YOU
    TO SITES CONTAINING INFORMATION THAT SOME PEOPLE MAY FIND OFFENSIVE
    OR INAPPROPRIATE. MAJORS CHALLENGE™ MAKES NO REPRESENTATIONS
    CONCERNING ANY CONTENT CONTAINED IN OR ACCESSED THROUGH THE SITE
    AND MAJORS CHALLENGE™ WILL NOT BE RESPONSIBLE OR LIABLE FOR THE
    ACCURACY, COPYRIGHT COMPLIANCE, LEGALITY OR DECENCY OF MATERIAL
    CONTAINED IN OR ACCESSED THROUGH THE SITE.</p>

    <p>ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF
    THE SITE OR SERVICE IS DONE AT YOUR OWN RISK. YOU WILL BE SOLELY
    RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA
    THAT RESULTS FROM THE DOWNLOAD OR USE OF DOWNLOADED MATERIAL.</p>

    <h5>19. LIMITATION ON LIABILITY</h5>

    <p>YOU UNDERSTAND AND AGREE THAT, UNDER NO CIRCUMSTANCES, AND UNDER
    NO LEGAL THEORY, SHALL MAJORS CHALLENGE™ BE LIABLE TO YOU FOR ANY
    INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES,
    INCLUDING WITHOUT LIMITATION DAMAGES FOR LOSS OF PROFITS, GOODWILL,
    USE, DATA OR OTHER INTANGIBLE LOSSES (EVEN IF MAJORS CHALLENGE™ HAD
    BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), RESULTING FROM: (i)
    THE USE OR THE INABILITY TO USE THE SITE; (ii) UNAUTHORIZED ACCESS TO OR
    ALTERATION OF YOUR TRANSMISSIONS OR DATA; (iii) STATEMENTS OR</p>

    <p>CONDUCT OF ANY THIRD PARTY ON THE SERVICE; OR (iv) ANY OTHER MATTER
    RELATING TO THE SITE. NOTWITHSTANDING ANYTHING TO THE CONTRARY
    CONTAINED HEREIN, MAJORS CHALLENGE™’S LIABILITY TO YOU FOR ANY
    CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT
    ALL TIMES BE LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU TO MAJORS
    CHALLENGE™ FOR GENERAL USE OF THE SITE DURING THE TERM OF YOUR
    REGISTRATION FOR THE SITE, NOT INCLUDING ANY ENTRY FEES FOR CONTESTS
    COMPLETED PRIOR TO THE CIRCUMSTANCES GIVING RISE TO THE CLAIM. THIS
    LIMITATION ON LIABILITY SHALL EXTEND TO THE FULLEST EXTENT
    PERMISSIBLE PURSUANT TO APPLICABLE LAWS.</p>

    <h5>20. OUR PROPRIETARY RIGHTS</h5>

    <p>All title, ownership and intellectual property rights in and to the Site are owned by Majors
    Challenge™ or its licensors. You acknowledge and agree that the Site contains proprietary and
    confidential information that is protected by applicable intellectual property and other laws.
    Except as expressly authorized by Majors Challenge™, you agree not to modify, rent, lease,
    loan, sell, publicly display, distribute or create derivative works based on the Site, in whole or in
    part.</p>

    <h5>21. LINKS TO THIRD PARTY SITES</h5>

    <p>The Site provides, or third parties may provide, links to other World Wide Web sites or
    resources, including, but not limited to, third party advertisers. Recognizing that Majors
    Challenge™ has no control over such sites and resources, you acknowledge and agree that
    Majors Challenge™ is not responsible for the availability of such external sites or resources, and
    does not endorse and is not responsible or liable for any content, advertising, products or other
    materials on or available from such sites or resources. You further acknowledge and agree that
    Majors Challenge™ shall not be responsible or liable, directly or indirectly, for any damage or
    loss caused or alleged to be caused by or in connection with use of or reliance on any such
    content, goods or services available on or through any such Site or resource. ACCESS AND
    USE OF THIRD PARTY SITES, INCLUDING THE INFORMATION, MATERIALS,
    PRODUCTS AND SERVICES OF THIRD PARTY SITES OR AVAILABLE THROUGH
    THIRD PARTY SITES IS SOLELY AT YOUR OWN RISK.</p>

    <h5>22. TERMINATION</h5>

    <p>Majors Challenge™ may terminate or suspend the Site and any and all Services and your Majors
    Challenge™ account immediately, without prior notice or liability, if you breach any of the
    terms or conditions of the Terms of Service. Upon termination of your account, your right to use
    the Site will immediately cease, and all rights under this contract shall cease other than those
    deemed to survive termination under Section 34 of the Terms. If you wish to terminate your
    Majors Challenge™ account, you may simply discontinue using the Site or contact us with a
    note to say you wish to terminate your account. The best way to contact us about terminating
    your account is legal@majorschallenge.com.</p>

    <h5>23. NO THIRD PARTY BENEFICIARIES</h5>

    <p>You agree that, except as otherwise expressly provided in these Terms, there shall be no third
    party beneficiaries to these Terms of Service. This means, among other things, that you agree, to
    the full extent permissible by law, no third party shall have any rights to bring claims arising out
    of these Terms of Service on your behalf or to claim for themselves rights that are afforded to
    you under this agreement.</p>

    <h5>24. NOTICE AND PROCEDURE FOR MAKING CLAIMS OF COPYRIGHT
    INFRINGEMENT</h5>

    <p>Majors Challenge™ may, in appropriate circumstances and at its discretion, disable and/or
    terminate the accounts of users who infringe the intellectual property of others. If you are a
    copyright owner, or an agent of a copyright owner, and believe that any content infringes upon
    your copyrights, you may submit notification pursuant to the Digital Millennium Copyright Act
    (&quot;DMCA&quot;) by providing our designated Copyright Agent, with the following information in
    writing (see 17 USC 512(c)(3)): (i) a physical or electronic signature of a person authorized to
    act on behalf of the owner of an exclusive right that is allegedly infringed; (ii) identification of
    the copyrighted work claimed to have been infringed, or, if multiple copyrighted works at a
    single online site are covered by a single notification, a representative list of such works at that
    site; (iii) identification of the material that is claimed to be infringing or to be the subject of
    infringing activity and that is to be removed or access to which is to be disabled, and information
    reasonably sufficient to permit the service provider to locate the material; (iv) information
    reasonably sufficient to permit the service provider to contact the complaining party, such as an
    address, telephone number, and, if available, an electronic mail address at which the complaining
    party may be contacted; (v) a statement that the complaining party has a good faith belief that
    use of the material in the manner complained of is not authorized by the copyright owner, its
    agent, or the law; and (vi) a statement that the information in the notification is accurate, and
    under penalty of perjury, that the complaining party is authorized to act on behalf of the owner of
    an exclusive right that is allegedly infringed.</p>

    <p>Majors Challenge™’s designated Copyright Agent to receive notifications of claimed
    infringement is Daniel Pitluk:</p>
    <p>Mail: Majors Challenge, 95 West Main Street, Suite 5-171, Chester, NJ 07930</p>
    <p>Email: legal@majorschallenge.com</p>

    <h5>25. CHOICE OF LAW</h5>

    <p>You agree that any claim you may have against Majors Challenge™, irrespective of whether the claim
    that is brought arises under contract, tort or other legal theory, shall be interpreted exclusively under the
    law of the State of New York. No user of this site, successor in interest, or any other party seeking to
    bring suit on behalf of the interests of a user may attempt to argue any state law claims under any theory
    other than the State of New York. THIS CHOICE OF LAW CLAUSE SHALL APPLY WITHOUT
    REGARD TO CONFLICT OF LAW PRINCIPLES AND IRRESPECTIVE OF THE UNDERLYING
    CAUSE OF ACTION.</p>

    <h5>26. ARBITRATION</h5>

    <p>Any controversy or claim arising out of, in connection with, or in any way relating to the Terms
    of Service, with the exception of those controversies or claims specifically excluded in the
    following paragraph, shall be settled by the American Arbitration Association (“AAA”) in
    connection with its rules.</p>

    <p>You and Majors Challenge™ agree that the following types of disputes are not subject to this
    binding arbitration provision: (1) any disputes seeking to enforce or protect, or concerning the
    validity of either any of your intellectual property rights or Majors Challenge™’s intellectual
    property rights; and (2) any claim for injunctive relief.</p>

    <p>Any such arbitration shall be held within a fifty mile radius of Morristown, New Jersey.</p>

    <h5>27. FORUM SELECTION</h5>

    <p>For any dispute not subject to arbitration, or where no election to arbitrate has been made, you
    and Majors Challenge™ agree to submit to the personal and exclusive jurisdiction of and venue
    in the federal and state courts located within a fifty mile radius of Morristown, NJ. You further
    agree to accept service of process by mail, and hereby waive any and all jurisdictional and venue
    defenses otherwise available.</p>

    <h5>28. WAIVER AND SEVERABILITY AND TERMS</h5>

    <p>The failure of Majors Challenge™ to exercise or enforce any right or provision of the Terms
    shall not constitute a waiver of such right or provision. If any provision of the Terms is found by
    a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should
    endeavor to give effect to the parties’ intentions as reflected in the provision, and the other
    provisions of the Terms remain in full force and effect.</p>

    <h5>29. ASSIGNMENT</h5>

    <p>The Terms, and any rights and licenses granted hereunder, may not be transferred or assigned by
    you but may be assigned by Majors Challenge™ without restriction. Any assignment attempted
    to be made in violation of these Terms of Service shall be deemed void. In addition, you agree
    that to the fullest extent permissible by law, no third party shall have any rights to bring claims
    arising out of this Terms of Service on your behalf or to claim for themselves rights that are
    afforded to you under this agreement.</p>

    <h5>30. NO AGENCY</h5>

    <p>You agree that no joint venture, partnership, employment, or agency relationship of any kind
    exists between you and Majors Challenge™. YOU FURTHER ACKNOWLEDGE THAT BY
    SUBMITTING USER CONTENT TO MAJORS CHALLENGE™, NO CONFIDENTIAL,
    FIDUCIARY, CONTRACTUALLY IMPLIED OR OTHER RELATIONSHIP IS CREATED
    BETWEEN YOU AND MAJORS CHALLENGE™.</p>

    <h5>31. STATUTE OF LIMITATIONS</h5>

    <p>You agree that regardless of any statute or law to the contrary, any claim or cause of action
    arising out of or related to the use of the Site or the Terms must be filed within one (1) year after
    such claim or cause of action arose or be forever barred.</p>

    <h5>32. HEADINGS</h5>

    <p>The headings and section titles in the Terms are for convenience only and have no legal or
    contractual effect.</p>

    <h5>33. PRIZES ARE SUBJECT TO FINAL APPROVAL, RATIFICATION AND PAYMENT
    BY INSURANCE PROVIDER</h5>

    <p>The Perfect Team jackpot is funded through our insurance carrier, which at present is Best Hole
    In One LLC, PO BOX 6057, Lindenhurst, IL 60046. If for any reason payment of a prize to you is not
    approved and ratified by Best Hole In One LLC or our successor insurance company that we may retain,
    you understand that Majors Challenge™ will have no liability to you with respect to the payout or non-payout
    of any prize whatsoever, for any reason. In the event that you are deemed to be a contest winner, the obligation
    is affirmatively on you to provide all requested information to Best Hole In One LLC to confirm your identity and
    allow them to make a final assessment of eligibility for a prize. In the event that a mathematical tie in the final
    score of any Majors Challenge™ contest, Majors Challenge™, in conjunction with its insurance provider, will determine
    the contest winner, if any, at its sole discretion, by any means it so chooses.</p>

    <h5>34. SURVIVAL</h5>

    <p>The following sections of these Terms shall survive the termination of the Terms and/or your
    account, irrespective of whether terminated by you or Majors Challenge™: Sections 6, 8, 9, 14,
    17, 18, 19, 20, 21, 22, 23, 25, 26, 27, 29, 31, 33 and 34.</p>
  </div>
)
