import React from 'react';
import ReactDOM from 'react-dom';

import {
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Nav,
  NavItem,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  UncontrolledDropdown,
} from 'reactstrap';

import {
  Link,
  NavLink,
  withRouter,
} from 'react-router-dom';

import FontAwesome from 'react-fontawesome';

import brand from 'img/brand.png';
// import logo from 'img/logo.png';

import 'common/TopNav.css';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'common/ConfirmAlert.css';

//Added by Nandan
import ReactPixel from 'react-facebook-pixel';

import { MobilePortrait, NotMobilePortrait } from 'utils/responsive.js';

class TopNav extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      navOpen: false,
    };

    this.setSelectedLeague = this.setSelectedLeague.bind(this);
    this.toggleNav = this.toggleNav.bind(this);
    this.closeNav = this.closeNav.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.createLeague = this.createLeague.bind(this);
    this.createCharityLeague = this.createCharityLeague.bind(this);
    this.joinAPool = this.joinAPool.bind(this);
  }

  componentDidMount() {
    this.setSelectedLeague();

    //fbpixel
    ReactPixel.init('368318914012574', {em: this.props.me.usr.username}, { debug: true, autoConfig: false });
    ReactPixel.pageView();
    ReactPixel.fbq('track', 'PageView');


  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.activeLeague !== this.props.activeLeague) {
      this.setSelectedLeague();
    }
  }

  setSelectedLeague() {
    let selectedLeague = 'Dashboard';

    if (this.props.activeLeague !== null) {
      for (var league of this.props.me.leagues) {
        if (league.leagueid === this.props.activeLeague) {
          selectedLeague = league;
          break;
        }
      }
    }

    this.setState({ selectedLeague });
  }

  toggleNav() {
    this.setState({
      navOpen: !this.state.navOpen,
    });
  }

  closeNav() {
    this.setState({navOpen: false});
  }

  createLeague(){
    this.props.history.push('/createleague');
  }

  createCharityLeague(){
    this.props.history.push('/createcharityleague');
  }

  joinAPool(){
    this.props.history.push('/joingolfpool');
  }

  handleClickOutside(event) {
    const domNode = ReactDOM.findDOMNode(this);
    if (!domNode || !domNode.contains(event.target)) {
      this.setState({
        navOpen: false
      });
      event.stopPropagation();
      event.preventDefault();
    }
  }

  handleOpen() {
    document.addEventListener('click', this.handleClickOutside, true);
  }

  handleClose() {
    document.removeEventListener('click', this.handleClickOutside, true);
  }

  render() {
    let leagueData = {
      isCommissioner: false,
      cupId: null,
      defaultEvent: null,
      clubName: null
    }

    if (this.props.activeLeague !== null) {
      leagueData.isCommissioner = this.state.selectedLeague.commissioner;
      leagueData.cupId = this.state.selectedLeague.cupid;
      leagueData.defaultEvent = this.state.selectedLeague.default_event;
      leagueData.clubName = this.state.selectedLeague.club;
      leagueData.cupName = (this.state.selectedLeague.whiteLabel && this.state.selectedLeague.whiteLabel.contest_name)?this.state.selectedLeague.whiteLabel.contest_name:'The Cup';
    }

    if (this.props.me && (!this.props.me.usr.fName || !this.props.me.usr.lName) &&
      !this.props.history.location.pathname.toLowerCase().includes('/profile')){
        confirmAlert({
          title: 'Missing Info',
          message: 'Please provide your first and last name.',
          buttons: [
            {
              label: 'OK',
              onClick: () => {

              }
            }
          ]
        });
      this.props.history.push('/profile/settings');
      return '';
    }

    let selectedLeague = this.state.selectedLeague;
    let whiteLabel = selectedLeague && selectedLeague.whiteLabel;
    const brandLogo = (selectedLeague && selectedLeague.whiteLabel)?selectedLeague.whiteLabel.logo:brand;
    const topBannerColor = (selectedLeague && selectedLeague.whiteLabel && selectedLeague.whiteLabel.branding.topBannerColor)?selectedLeague.whiteLabel.branding.topBannerColor:'#34495e';

    //const brandLogo = (this.props.me && this.props.me.branding)?this.props.me.branding.logo:brand;
    //const topBannerColor = (this.props.me && this.props.me.branding && this.props.me.branding.branding && this.props.me.branding.branding.topBannerColor)?this.props.me.branding.branding.topBannerColor:'#34495e';
    return (
      <Navbar dark expand="lg" color="faded" className="fixed-top topNav px-0 py-1 py-lg-0" style={{backgroundColor:topBannerColor}}>
        {!whiteLabel &&
        <NavbarBrand href="/" className="mx-3">
          <img src={brandLogo} alt="Majors Challenge brand" style={{height: "42px", cursor:'pointer'}} className=""/>
          {/* <img src={logo} alt="Majors Challenge logo" style={{height: "42px"}} className="d-block d-md-none" /> */}
        </NavbarBrand>
        }
        {whiteLabel &&
        <NavbarBrand href={`/league/${this.props.activeLeague}`} className="mx-3">
          <img src={brandLogo} alt="Majors Challenge brand" style={{height: "42px", cursor:'pointer'}} className=""/>
          {/* <img src={logo} alt="Majors Challenge logo" style={{height: "42px"}} className="d-block d-md-none" /> */}
        </NavbarBrand>
        }

        <NavbarToggler onClick={this.toggleNav} className="topNav_toggler mx-3"/>
        <Collapse
          navbar
          className="topNavWrapper"
          isOpen={this.state.navOpen}
          onEntered={this.handleOpen}
          onExited={this.handleClose} >
          <Nav navbar className="topNavContainer" style={{backgroundColor:topBannerColor}}>
            <LeagueDropdown
              activeLeague={this.props.activeLeague}
              selectedLeague={this.state.selectedLeague}
              me={this.props.me}
              history={this.props.history}
              closeNav={this.closeNav}
              createLeague={this.createLeague}
              createCharityLeague={this.createCharityLeague}
              joinAPool={this.joinAPool}
            />
            <LeagueNav
              activeLeague={this.props.activeLeague}
              {...leagueData}
              closeNav={this.closeNav}
            />
            {
              (this.props.me.usr.is_admin) &&
              <Link className="d-block topNavProfilec ml-5 my-1 ml-lg-1 my-lg-auto mx-lg-1" to="/admin" onClick={() => {this.closeNav();}} style={{textDecoration: "none"}}>
                <MobilePortrait>
                  <span className="topNavProfile_label leagueNavItem" style={{color:'#94a9be'}}>{" Admin "}</span>
                </MobilePortrait>
                <NotMobilePortrait>
                  <FontAwesome name="user" className="topNavProfile_icon" size="2x" style={{color:'#94a9be'}}/>
                </NotMobilePortrait>
              </Link>
            }
            <Link className="d-block topNavProfilex ml-5 my-1 ml-lg-1 my-lg-auto mx-lg-3" to="/profile" onClick={() => {this.closeNav();}}
              style={{textDecoration: "none"}}>
              <MobilePortrait>
                <span className="topNavProfile_label leagueNavItem" style={{color:'#94a9be'}}>{" Profile "}</span>
              </MobilePortrait>
              <NotMobilePortrait>
                <FontAwesome name="user-circle" className="topNavProfile_icon" size="2x" style={{color:'#94a9be'}}/>
              </NotMobilePortrait>

              {/*<span className="topNavProfile_label">{" Profile "}</span>*/}
            </Link>

          </Nav>
        </Collapse>
      </Navbar>
    )
  }
}

const LeagueDropdown = ({activeLeague, selectedLeague, me, history, closeNav, createLeague, createCharityLeague, joinAPool}) => {

  let leagueDropdownItem = (name, leagueid, commissioner) => {
    let clickFunc = (leagueid) => () => {
      if (activeLeague !== leagueid) {
        history.push('/league/'+leagueid)
      }
      closeNav();
    };
    return  (
      <DropdownItem key={ leagueid } onClick={ clickFunc(leagueid) }>
        { name }
      </DropdownItem>
    )
  }

  let dropdownLabel = "Leagues";
  if (activeLeague !== null) {
    dropdownLabel = selectedLeague.name;
  } else if (history.location.pathname.toLowerCase().includes('createleague')) {
    dropdownLabel = "Create League";
  } else if (history.location.pathname.toLowerCase().includes('createcharityleague')) {
    dropdownLabel = "Start a Fundraiser";
  } else if (history.location.pathname.toLowerCase().includes('joingolfpool')) {
    dropdownLabel = "Join a Pool";
  }

  return (
    <UncontrolledDropdown className="ml-5 ml-lg-0 leagueDropdownContainer" nav inNavbar>
      <DropdownToggle nav className="leagueDropdown">
        {dropdownLabel} <FontAwesome name="chevron-down" style={{fontSize: "14px"}}/>
      </DropdownToggle>
      <DropdownMenu>
        { me.leagues.map(({name, leagueid, commissioner}) => leagueDropdownItem(name, leagueid, commissioner)) }
        <DropdownItem divider />
        <DropdownItem onClick={() => {history.push('/'); closeNav();}}>
          Dashboard
        </DropdownItem>
        <DropdownItem onClick={() => {createLeague(); closeNav();}}>
          Create a League
        </DropdownItem>
        <DropdownItem onClick={() => {createCharityLeague(); closeNav();}}>
          Start a Fundraiser
        </DropdownItem>
        <DropdownItem onClick={() => {joinAPool(); closeNav();}}>
          Join a Pool
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

const LeagueNav = ({activeLeague, defaultEvent, cupId, isCommissioner, closeNav, clubName, cupName}) => {
  if (activeLeague === null) {
    return (
      <div className="leagueNavContainer ml-5 mr-lg-auto">
        <LeagueNavItem
          path={"/games"}
          label="How to Play"
          closeNav={closeNav}
        />
        <LeagueNavItem
          path={"/createleague"}
          label="Create A League +"
          closeNav={closeNav}
        />
      </div>
    )
  }
  return (
    <div className="leagueNavContainer ml-5 mr-lg-auto">
      <LeagueNavItem
        path={"/league/"+activeLeague}
        label="League Home"
        closeNav={closeNav}
        exact={true}
      />
      { !!defaultEvent &&
        <EventsLeagueNavItem
          path={"/events/"+defaultEvent}
          label="Events"
          closeNav={closeNav}
        />
      }
      <LeagueNavItem
        path={"/members/"+activeLeague}
        label="Members"
        closeNav={closeNav}
      />
      { !!cupId &&
        <LeagueNavItem
          path={"/cup/"+cupId}
          label={cupName}
          closeNav={closeNav}
        />
      }
      { !!isCommissioner ?
        <LeagueNavItem
          path={"/commissioner/"+activeLeague}
          label="Commissioner"
          closeNav={closeNav}
        /> :
        <HowToPlayNavItem
          closeNav={closeNav}
          activeLeague={activeLeague}
          clubName={clubName}
        />
      }
    </div>
  )
}

const HowToPlayNavItem = ({closeNav, activeLeague, clubName}) => {
  if (clubName) {
    return (
      <LeagueNavItem
        path={`/league/${activeLeague}/help`}
        label="How to Play"
        closeNav={closeNav}
        exact={true}
      />
    )
  } else {
    return (
      <LeagueNavItem
        path={"/games"}
        label="How to Play"
        closeNav={closeNav}
      />
    )
  }
}

const LeagueNavItem = ({path, label, closeNav, exact}) => {
  return (
    <NavLink
      to={path}
      className="leagueNavItem_link mx-lg-3"
      activeClassName="leagueNavItem_link__active"
      onClick={()=>{closeNav()}}
      exact={exact}>
      <NavItem className="leagueNavItem">
        {label}
      </NavItem>
    </NavLink>
  )
}

const EventsLeagueNavItem = ({path, label, closeNav}) => {
  const isEventsPath = (match, location) => {
    return location.pathname.indexOf("/events") === 0;
  }

  return (
    <NavLink
      to={path}
      className="leagueNavItem_link mx-lg-3"
      activeClassName="leagueNavItem_link__active"
      isActive={isEventsPath}
      onClick={()=>{closeNav()}}>
      <NavItem className="leagueNavItem">
        {label}
      </NavItem>
    </NavLink>
  )
}

export default withRouter(TopNav);
