import React from 'react';
import {
  Row,
  Col,
  Modal,
  ModalBody,
  ModalHeader,ModalFooter, Button,
  Badge,
} from 'reactstrap';

import FontAwesome from 'react-fontawesome';

import 'common/TeamModal.css';

import {
  get_teams_details_entry
} from 'utils/api.js';

export default class TeamModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      entry: props.entry,
      roster: null,
    };


  }
  componentDidMount() {
    if (this.props.entry){
      this.getTeamDetailsEntry(this.props.entry.entryId);
    }
  }
  componentWillReceiveProps(nextProps) {

    if (!this.state.entry || (nextProps.entry && nextProps.entry.entryId !== this.state.entry.entryId)) {
      this.setState({
        entry: nextProps.entry,
        roster: null,
        loading: true,
      });
      if (nextProps.entry){
        this.getTeamDetailsEntry(nextProps.entry.entryId);
      }
    }
  }

  getTeamDetailsEntry = (entryId) => {

    get_teams_details_entry(entryId,
      ({success, roster, error}) => {
        this.setState({loading:false});
        if (success) {
          this.setState({roster:roster});
        } else {
          console.log(error);
        }
      }, (error) => {
        console.log(error);
      });

  }

  render() {
    if (!this.state.entry){return '';}
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} className="">
        <div className="">
          <ModalHeader toggle={this.props.toggle} className="mcModalHeader">Team - {this.state.entry.team}</ModalHeader>
          <ModalBody className="teamModalBody">
            {this.state.loading &&
              <div className=" text-center py-2">
                <FontAwesome name="spinner" size="4x" spin />
              </div>
            }
            {this.state.roster &&
            <>
            <Row noGutters className="teamModalBody">
              <Col className="">
                <div className="pb-1 teamModalGroup">{'GROUP A'}</div>
                <div>{this.state.roster['groupA'][0].golfer_name}
                  {this.state.roster['groupA'][0].status === 'wd' && <Badge color="secondary" style={{marginLeft: "0.5rem"}}> WD </Badge>}
                </div>
                <div>{this.state.roster['groupA'][1].golfer_name}
                  {this.state.roster['groupA'][1].status === 'wd' && <Badge color="secondary" style={{marginLeft: "0.5rem"}}> WD </Badge>}
                </div>
                <div>{this.state.roster['groupA'][2].golfer_name}
                  {this.state.roster['groupA'][2].status === 'wd' && <Badge color="secondary" style={{marginLeft: "0.5rem"}}> WD </Badge>}
                </div>
                <div>{this.state.roster['groupA'][3].golfer_name}
                  {this.state.roster['groupA'][3].alternate && <Badge color="primary" style={{marginLeft: "0.5rem"}}> ALT </Badge>}
                  {this.state.roster['groupA'][3].status === 'wd' && <Badge color="secondary" style={{marginLeft: "0.5rem"}}> WD </Badge>}
                </div>
              </Col>
              <Col className="">
                <div className="teamModalGroup pb-1">{'GROUP B'}</div>
                <div>{this.state.roster['groupB'][0].golfer_name}
                  {this.state.roster['groupB'][0].status === 'wd' && <Badge color="secondary" style={{marginLeft: "0.5rem"}}> WD </Badge>}
                </div>
                <div>{this.state.roster['groupB'][1].golfer_name}
                  {this.state.roster['groupB'][1].status === 'wd' && <Badge color="secondary" style={{marginLeft: "0.5rem"}}> WD </Badge>}
                </div>
                <div>{this.state.roster['groupB'][2].golfer_name}
                  {this.state.roster['groupB'][2].status === 'wd' && <Badge color="secondary" style={{marginLeft: "0.5rem"}}> WD </Badge>}
                </div>
                <div>{this.state.roster['groupB'][3].golfer_name}
                  {this.state.roster['groupB'][3].alternate && <Badge color="primary" style={{marginLeft: "0.5rem"}}> ALT </Badge>}
                  {this.state.roster['groupB'][3].status === 'wd' && <Badge color="secondary" style={{marginLeft: "0.5rem"}}> WD </Badge>}
                </div>
              </Col>
            </Row>
            <Row noGutters className="py-2">
              <Col className="">
                <div className="teamModalGroup pb-1">{'GROUP C'}</div>
                <div>{this.state.roster['groupC'][0].golfer_name}
                  {this.state.roster['groupC'][0].status === 'wd' && <Badge color="secondary" style={{marginLeft: "0.5rem"}}> WD </Badge>}
                </div>
                <div>{this.state.roster['groupC'][1].golfer_name}
                  {this.state.roster['groupC'][1].status === 'wd' && <Badge color="secondary" style={{marginLeft: "0.5rem"}}> WD </Badge>}
                </div>
                <div>{this.state.roster['groupC'][2].golfer_name}
                  {this.state.roster['groupC'][2].status === 'wd' && <Badge color="secondary" style={{marginLeft: "0.5rem"}}> WD </Badge>}
                </div>
                <div>{this.state.roster['groupC'][3].golfer_name}
                  {this.state.roster['groupC'][3].status === 'wd' && <Badge color="secondary" style={{marginLeft: "0.5rem"}}> WD </Badge>}
                </div>
                <div>{this.state.roster['groupC'][4].golfer_name}
                  {this.state.roster['groupC'][4].alternate && <Badge color="primary" style={{marginLeft: "0.5rem"}}> ALT </Badge>}
                  {this.state.roster['groupC'][4].status === 'wd' && <Badge color="secondary" style={{marginLeft: "0.5rem"}}> WD </Badge>}
                </div>
              </Col>
              <Col className="">
                <div className="teamModalGroup pb-1">{'GROUP D'}</div>
                <div>{this.state.roster['groupD'][0].golfer_name}
                  {this.state.roster['groupD'][0].status === 'wd' && <Badge color="secondary" style={{marginLeft: "0.5rem"}}> WD </Badge>}
                </div>
                <div>{this.state.roster['groupD'][1].golfer_name}
                  {this.state.roster['groupD'][1].status === 'wd' && <Badge color="secondary" style={{marginLeft: "0.5rem"}}> WD </Badge>}
                </div>
                <div>{this.state.roster['groupD'][2].golfer_name}
                  {this.state.roster['groupD'][2].alternate && <Badge color="primary" style={{marginLeft: "0.5rem"}}> ALT </Badge>}
                  {this.state.roster['groupD'][2].status === 'wd' && <Badge color="secondary" style={{marginLeft: "0.5rem"}}> WD </Badge>}
                </div>
              </Col>
            </Row>
            </>
           }

          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.props.toggle}>
              {'Ok'}
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    )
  }
}
