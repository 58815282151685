import React from 'react';

import {
  Button,
  Container,
  Row,
  Col
} from 'reactstrap';

import {
  Link,
  withRouter
} from 'react-router-dom';

import FontAwesome from 'react-fontawesome';

import GoogleAuth from 'common/GoogleAuthNew.js';
import FacebookAuth from 'common/FacebookAuth.js';

import {OnboardingWrapper} from 'common/LandingWrapper.js';

import {
  signup
} from 'utils/api.js';

import 'pages/user/JoinLeague.css';
import 'pages/user/Signup.css';

class Signup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      firstname: '',
      lastname: '',
      status: null,
      error: null
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onSuccessRedirect = this.onSuccessRedirect.bind(this);
    this.onFacebookError = this.onFacebookError.bind(this);
    this.onGoogleError = this.onGoogleError.bind(this);
  }
  handleChange(event) {
    this.setState({[event.target.name]: event.target.value});
  }
  handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({status: false});

    signup(this.state.email, this.state.firstname, this.state.lastname, this.state.password,
      (response) => {
        //console.log(response);
        if (!response.data.success){ // show console.error();
          this.setState({
            status: null,
            error: response.data.error,
          })
        }else{
          this.setState({success: true})
        }
      }, (error) => {
        if (error.response.status === 400) {
          this.setState({
            status: null,
            error: "We couldn't sign you up. Please fill out all the fields."
          })
        } else if (error.response.status === 403) {
          this.setState({
            status: null,
            error: "It looks like you already have an account with that email address."
          })
        }
      }
    )
  }
  onSuccessRedirect(me) {
    this.props.history.push("/");
    this.props.loggedInCallback(me);
  }
  onFacebookError() {
    this.setState({status: null, error: "There was a problem signing you up with Facebook."});
  }
  onGoogleError() {
    this.setState({status: null, error: "There was a problem signing you up with Google."});
  }
  render() {
    if (this.state.success === true) {
      return (
        <SignupWrapper {...this.props}>
          <h6 style={{fontWeight: 400}} className="p-3">
            Your account is almost setup! Just click the link we sent to your email:
          </h6>
          <p>{this.state.email}</p>
        </SignupWrapper>
      )
    }

    return (
      <SignupWrapper {...this.props} >
        <h2 className="joinContent_leaguename"  style={{fontSize:"26px"}}>{"Create your Majors Challenge account today!"}</h2>
        <Row className="justify-content-center pb-3" style={{display:"none"}}>
          <span>
              {"Member of a Country Club? "}
              <Link to="/clubs">Find out about our Club Pricing</Link>
          </span>
        </Row>

        {/*<Row className="justify-content-center pb-3">
          <Col xs="12" sm="9" md="8" lg="6" xl="5">
            <FacebookAuth onSuccessRedirect={this.onSuccessRedirect} onError={this.onFacebookError}/>
          </Col>
        </Row>
        <Row className="justify-content-center pb-3">
          <Col xs="12" sm="9" md="8" lg="6" xl="5" style={{textAlign: "center", color: "#888"}}>
            {"OR"}
          </Col>
        </Row>*/}
        <div class="justify-content-center pb-3 row d-none d-md-flex ">
          <div class="">
              <FacebookAuth onSuccessRedirect={this.onSuccessRedirect} onError={this.onFacebookError}/>
          </div>
          <div class="ml-3">
              <GoogleAuth fakeProp={true} onSuccessRedirect={this.onSuccessRedirect} onError={this.onGoogleError} />
          </div>
        </div>
        <div class="justify-content-center pb-3 row d-flex d-md-none ">
          <div class="">
              <FacebookAuth onSuccessRedirect={this.onSuccessRedirect} onError={this.onFacebookError}/>
          </div>
        </div>
        <div class="justify-content-center pb-3 row d-flex d-md-none ">
          <div class="">
              <GoogleAuth fakeProp={true} onSuccessRedirect={this.onSuccessRedirect} onError={this.onGoogleError} />
          </div>
        </div>
        <div class="justify-content-center pb-3 row">
          <div class="" style={{borderBottom: "2px inset darkgray",width: "100px",marginBottom: "12px"}}></div>
          <div class="mx-3" style={{textAlign: "center", color: "#888"}}>
            {"OR"}
          </div>
          <div class="" style={{borderBottom: "2px inset darkgray",width: "100px",marginBottom: "12px"}}></div>
        </div>
        <form onSubmit={this.handleSubmit}>
          <Row noGutters className="justify-content-center">
            <Col xs="10" sm="9" md="9" lg="9" xl="8">
              <label className="joinContent_signuplabel">
                <input type="text" name='email' value={this.state.email} className="joinContent_signupinput" onChange={this.handleChange}  placeholder="Email"/>
              </label>
            </Col>
          </Row>
          <Row noGutters className="justify-content-center">
            <Col xs="10" sm="9" md="9" lg="9" xl="8">
              <label className="joinContent_signuplabel">
                <input type="text" name='firstname' value={this.state.firstname} maxlength="30" className="joinContent_signupinput" onChange={this.handleChange}  placeholder="First Name"/>
              </label>
            </Col>
          </Row>
          <Row noGutters className="justify-content-center">
            <Col xs="10" sm="9" md="9" lg="9" xl="8">
              <label className="joinContent_signuplabel">
                <input type="text" name='lastname' value={this.state.lastname} maxlength="30" className="joinContent_signupinput" onChange={this.handleChange}  placeholder="Last Name"/>
              </label>
            </Col>
          </Row>
          <Row noGutters className="justify-content-center">
            <Col xs="10" sm="9" md="9" lg="9" xl="8">
              <label className="joinContent_signuplabel">
                <input type="password" name='password' value={this.state.password} className="joinContent_signupinput" onChange={this.handleChange}  placeholder="Password"/>
              </label>
            </Col>
          </Row>
          <input type="submit" value="Signup" hidden />
        </form>
        <Row noGutters className="justify-content-center pt-4">
          <Col xs="10" sm="9" md="9" lg="9" xl="8">
            <p>
              By signing up, you agree to the Majors Challenge&nbsp;
              <Link to="/terms" target="_blank">Terms and Conditions <FontAwesome name="external-link" style={{fontSize: ".75em"}} /></Link>
              {' and '}
              <Link to="/privacy" target="_blank">Privacy Policy <FontAwesome name="external-link" style={{fontSize: ".75em"}} /></Link>.
            </p>
          </Col>
        </Row>
        <Row noGutters className="justify-content-center pt-4">
          <Col xs="10" sm="9" md="9" lg="9" xl="8">
            {
              this.state.error && <div style={{color: "red", textAlign: "left", paddingBottom: "1rem"}}>{this.state.error}</div>
            }
            {
              this.state.status === null ?
                <Button color="success" onClick={this.handleSubmit} block>
                  <FontAwesome name="check-square-o"/>&nbsp;Signup
                </Button>
              :
              <Button color="success" disabled block>
                <FontAwesome name="spinner" spin />
              </Button>
            }
          </Col>
        </Row>
      </SignupWrapper>
    );
  }
}

const SignupWrapper = ({children, ...props}) => (
  <OnboardingWrapper {...props}>
    <Container fluid className="joinContainer">
      <Row noGutters>
        <Col xs="12" sm="10" md="9" lg="8" xl="6" className="joinContent mx-auto">
          {children}
        </Col>
      </Row>
    </Container>
  </OnboardingWrapper>
)

export default withRouter(Signup);
