import React from 'react';
import {
  Row,
  Button,
  Card,
  Input,
} from 'reactstrap';

import { EditorState, convertToRaw, ContentState} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
//import draftToMarkdown from 'draftjs-to-markdown';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import 'common/RichEditor.css';

import {
  MobileHeader,
  ContentWrapper
} from 'common/CommissionerComponents.js';



import {
update_whitelabel,
} from 'utils/api.js';

export default class WhiteLabel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        editorStateIntro: EditorState.createEmpty(),
        editorStatePrize: EditorState.createEmpty(),
    };
  }

  componentDidMount() {
    console.log('dd' + this.props.selectedLeague);
    if (!this.props.selectedLeague || !this.props.selectedLeague.whiteLabel){
      return;
    }
    this.setEditorValues(this.props.selectedLeague.whiteLabel);
  }

  componentWillReceiveProps(nextProps) {
    console.log('dd' + nextProps.selectedLeague);
    if (!nextProps.selectedLeague || !nextProps.selectedLeague.whiteLabel){
      return;
    }
    this.setEditorValues(nextProps.selectedLeague.whiteLabel);
  }

  setEditorValues: Function = (whiteLabel) => {
    console.log('intro - ' + whiteLabel.introduction);
    this.setState({contest_name:whiteLabel.contest_name});
    const contentBlock = htmlToDraft(whiteLabel.introduction||'');
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorStateIntro = EditorState.createWithContent(contentState);
      this.setState({editorStateIntro});
    }
    console.log('prize - ' + whiteLabel.prize);
    const contentBlockPrize = htmlToDraft(whiteLabel.prize||'');
    if (contentBlockPrize) {
      const contentStatePrize = ContentState.createFromBlockArray(contentBlockPrize.contentBlocks);
      const editorStatePrize = EditorState.createWithContent(contentStatePrize);
      this.setState({editorStatePrize});
    }
  }

  onEditorStateIntroChange: Function = (editorStateIntro) => {
    this.setState({editorStateIntro,});
  }

  onEditorStatePrizeChange: Function = (editorStatePrize) => {
    this.setState({editorStatePrize,});
  }

  clearWhiteLabel: Function = (fldNm) => {
    if (fldNm === 'introduction'){
      this.setState({editorStateIntro:EditorState.createEmpty()});
    }else if (fldNm === 'prize'){
      this.setState({editorStatePrize:EditorState.createEmpty()});
    }
  }

  handleChange: Function = (event) => {
    this.setState({[event.target.name]: event.target.value});
  }

  saveWhiteLabel: Function = (fldNm) => {
    let fldVal = '';
    if (['introduction','prize'].indexOf(fldNm) >-1){
      const editorState = fldNm === 'introduction'?this.state.editorStateIntro:this.state.editorStatePrize;
      fldVal = draftToHtml(convertToRaw(editorState.getCurrentContent()));
      fldVal = fldVal.replaceAll('background-color: rgb(255,255,255);','');
      if (fldVal.replaceAll('<p>','').replaceAll('</p>','').replaceAll('&nbsp;','').replaceAll('\n','') === ''){fldVal='';}
    }else{
      fldVal = this.state.contest_name;
    }
    update_whitelabel(this.props.activeLeague,fldNm, fldVal,
    ({success, error, me}) => {
      if (success) {
        console.log(me);
        this.props.updateMe(me);
      } else {
        console.log(error);
      }
    }, (error) => {
      console.log(error);
    });
  }



  render() {
    return (
      <div>
        <MobileHeader to="WhiteLabel" leagueid={this.props.activeLeague} />
        <ContentWrapper>
          <div className="mb-2" style={{fontSize: "1.75em", fontWeight: 300}}>
            {'White Label'}
          </div>

          <div className="p-2 mb-1">
            <Row className="contentTitle pb-2 pl-1">
              How To Play: Introduction
            </Row>
            <Row className="pb-2 pl-1" style={{fontSize: ".8rem", color: "#999"}}>
              Use this section to welcome your members to the golf pool. This text appears at the top of How To Play.
            </Row>
            <Row>
              <Editor
                editorState={this.state.editorStateIntro}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class px-2"
                toolbarClassName="toolbar-class"
                editorStyle={{border:'1px solid #F1F1F1', height:'200px', backgroundColor:'white',}}
                onEditorStateChange={this.onEditorStateIntroChange}/>
            </Row>
            <Row className="py-2 px-2">
              <Button color="secondary" className="mx-1" onClick={()=>this.clearWhiteLabel('introduction')}>
                {'Clear'}
              </Button>
              <Button color="primary"  className="mx-1" onClick={()=>this.saveWhiteLabel('introduction')} >
                {'Save'}
              </Button>
            </Row>
          </div>

          <div className="p-2 mb-4">
            <Row className="contentTitle pb-2 pl-1">
              How To Play: Prizes
            </Row>
            <Row className="pb-2 pl-1" style={{fontSize: ".8rem", color: "#999"}}>
              If you are awarding prizes then list them here. If empty then the section will be hidden.
            </Row>
            <Row>
              <Editor
                editorState={this.state.editorStatePrize}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class px-2"
                toolbarClassName="toolbar-class"
                editorStyle={{border:'1px solid #F1F1F1', height:'200px', backgroundColor:'white', lineHeight: '50%'}}
                onEditorStateChange={this.onEditorStatePrizeChange}/>
            </Row>
            <Row className="py-2 px-2">
              <Button color="secondary" className="mx-1" onClick={()=>this.clearWhiteLabel('prize')}>
                {'Clear'}
              </Button>
              <Button color="primary"  className="mx-1" onClick={()=>this.saveWhiteLabel('prize')} >
                {'Save'}
              </Button>
            </Row>
          </div>

          {this.props.selectedLeague.cupid &&
            <div className="p-2 mb-4">
              <Row className="contentTitle pb-2 pl-1">
                Season Long Contest Name
              </Row>
              <Row>
              <Input type="text" placeholder="Enter the contest name" name="contest_name"
                value={this.state.contest_name} onChange={this.handleChange} />
              </Row>
              <Row className="py-2 px-2">
                <Button color="primary"  className="mx-1" onClick={()=>this.saveWhiteLabel('contest_name')} >
                  {'Save'}
                </Button>
              </Row>
            </div>
          }

        </ContentWrapper>
      </div>
    )
  }
}
