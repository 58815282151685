import React from 'react';
import {
  Button,
  Col,
  Row,
} from 'reactstrap';

import { CopyToClipboard } from 'react-copy-to-clipboard';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'common/ConfirmAlert.css';

import InviteMultipleModal from 'common/InviteMultipleModal';

import {
  MobileHeader,
  ContentWrapper
} from 'common/CommissionerComponents.js';

import {
  get_leaguesettings,
} from 'utils/api.js';

export default class Invite extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      settings: {loading: true,},
    };

    this.fetchLeagueSettings = this.fetchLeagueSettings.bind(this);

    this.webUrlCopyConfirmation = this.webUrlCopyConfirmation.bind(this);
  }

  componentDidMount() {
    this.fetchLeagueSettings(this.props.activeLeague);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.activeLeague === nextProps.activeLeague) {
      return;
    }

    this.fetchLeagueSettings(nextProps.activeLeague);
  }

  fetchLeagueSettings(league) {
    if (!league) {
      return;
    }
    get_leaguesettings(league,
    ({success, settings, error}) => {
      if (success) {
        this.setState({
          settings,
        });
      } else {
        console.log(error);
      }
    }, (error) => {
      console.log(error);
    });
  }

  webUrlCopyConfirmation(){
    confirmAlert({
      title: 'Confirmation',
      message: 'Web Address Copied to Clipboard',
      buttons: [
        {
          label: 'OK',
          onClick: () => { }
        }
      ]
    });
  }

  render() {
    let contentParams = {...this.state.settings};
    contentParams.league = this.props.activeLeague;

    contentParams.webUrlCopyConfirmation = this.webUrlCopyConfirmation;

    return (
      <div>
        <MobileHeader to="Invite" leagueid={this.props.activeLeague} />
        <ContentWrapper>
          <InviteHeader league={this.props.activeLeague} />
          <InviteContent {...contentParams} />
        </ContentWrapper>
      </div>
    );
  }
}

const InviteHeader = (props) => (
  <div className="">
    <div className="d-none d-lg-block pb-4" style={{fontSize: "1.75em", fontWeight: 300}}>
      Invite
    </div>
  </div>
)

const InviteContent = (props) => {
  if (props.loading) {
    return null;
  }

  return (
    <Col sm="12" md="9" lg="7">
      {!props.clubSlug && <LeagueInviteWeb {...props} />}
      {props.clubSlug && <LeagueInviteClubWeb {...props} />}
      <Divider />
      <LeagueInviteEmail {...props} />
      {/*
      <Divider />
      <LeagueInviteFacebook {...props} />
      <Divider />
      <LeagueInviteTwitter {...props} />
      */}
    </Col>
  )
}

const Divider = () => <Row style={{borderTop:"1px solid #ccc"}} className="mt-3 mb-3" />

const LeagueInviteWeb = (props) => {
  let slugClipTxt = window.location.origin + '/ileague/'+ props.leagueSlug ;
  const clipBtn = props.allowPublicAccess ?
    <Button color="primary" size="sm" outline className="ml-2 mt-n1"
        onClick={()=>props.webUrlCopyConfirmation()}>
        Copy
    </Button>:
    <Button color="danger" size="sm" outline className="ml-2 mt-n1" disabled
        onClick={()=>props.webUrlCopyConfirmation()}>
        DISABLED
    </Button>;

  return (
    <div>
      <Row className="contentTitle pb-2">
        Web / Social / Text
      </Row>
      <Row className="pb-2" style={{fontSize: ".8rem", color: "#999"}}>
        Share this link in an email, social media or text message. Be aware there is no traceability on where a user came from when socializing your web link. Consider adding a password in League Settings.
      </Row>
      <Row className="pt-2 ml-n3 " noGutters style={{fontSize: ".9rem",}}>
        <Col>
        <p>
          <span className="mr-2" style={{color: "#555"}}>
            <span className="text-nowrap">
              <span style={{textDecoration:''}}>{window.location.host + '/ileague/'+props.leagueSlug}</span>
              <CopyToClipboard text= {slugClipTxt}>
                {clipBtn}
              </CopyToClipboard>
            </span>
          </span>
        </p>
        </Col>
      </Row>
    </div>
  )
}

const LeagueInviteClubWeb = (props) => {
  let slugClipTxt = window.location.origin + '/club/'+ props.clubSlug ;

  return (
    <div>
      <Row className="contentTitle pb-2">
        Web / Social / Text
      </Row>
      <Row className="pb-2" style={{fontSize: ".8rem", color: "#999"}}>
        Your league has a dedicated web address. Share this link in emails, newsletters, online posts or where ever else you communicate with your members.
      </Row>
      <Row className="pt-2 ml-n3 " noGutters style={{fontSize: ".9rem",}}>
        <Col>
        <p>
          <span className="mr-2" style={{color: "#555"}}>
            <span className="text-nowrap">
              <span style={{textDecoration:''}}>{window.location.host + '/club/'+props.clubSlug}</span>
              <CopyToClipboard text= {slugClipTxt}>
                <Button color="primary" size="sm" outline className="ml-2 mt-n1"
                onClick={()=>props.webUrlCopyConfirmation()}>
                Copy</Button>
              </CopyToClipboard>
            </span>
          </span>
        </p>
        </Col>
      </Row>
    </div>
  )
}

const LeagueInviteEmail = (props) =>{
  let leagueInfo = {...props};
  return(
  <div>
    <Row className="contentTitle pb-2">
      Email
    </Row>
    <Row className="pb-2" style={{fontSize: ".8rem", color: "#999"}}>
      <p>Email invites can only be used by the recipient it was sent to.</p>
    </Row>
    <Row className="pt-0 ml-n3 " noGutters style={{fontSize: ".9rem",}}>
      <Col>
      <p>
        <InviteMultipleModal league={ props.league} leagueInfo={leagueInfo} embedIn={'Invite'}/>
      </p>
      </Col>
    </Row>
  </div>
  )
}
/*const LeagueInviteFacebook = (props) =>
    <div>
      <Row className="contentTitle pb-2">
        Facebook
      </Row>
      <Row className="pb-2" style={{fontSize: ".8rem", color: "#999"}}>
        Coming soon
      </Row>

    </div>

const LeagueInviteTwitter = (props) =>{
  return (
    <div>
      <Row className="contentTitle pb-2">
        Twitter
      </Row>
      <Row className="pb-2" style={{fontSize: ".8rem", color: "#999"}}>
        Coming soon
      </Row>

    </div>
  )

}*/
