import React from 'react';
import {
  Button,
  Row, Col,
  Card, CardTitle,
  Table,
} from 'reactstrap';

import {NavLink} from 'react-router-dom';

import InviteModal from 'common/InviteModal';
import { get_teams_field } from 'utils/api.js';

export default class Field extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      canInvite: false,
      field: [],
      userTeams: 0,
    };

    this.fetchTeamField = this.fetchTeamField.bind(this);
    this.setCanInvite = this.setCanInvite.bind(this);
  }

  setCanInvite(props) {
    let canInvite = false;

    for (var { leagueid, invite_access } of props.me.leagues) {
      if (leagueid === props.activeLeague) {
        canInvite = invite_access;
        break;
      }
    }

    this.setState({
      canInvite,
    })
  }

  componentDidMount() {
    this.fetchTeamField(this.props.match.params.eventid);
    this.setCanInvite(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.eventid !== this.props.match.params.eventid) {
      this.fetchTeamField(nextProps.match.params.eventid);
    }
    this.setCanInvite(nextProps);
  }

  fetchTeamField(eventid) {
    get_teams_field(eventid,
    ({success, error, field, league, userTeams}) => {
      if (!success) {
        console.log(error);
        this.setState({
          field: [],
          userTeams: 0,
        });
        return;
      }

      if (this.props.activeLeague !== league) {
        this.props.selectLeague(league);
      }

      this.setState({ field, userTeams });
    }, (error) => {
      console.log(error);
      this.setState({
        field: [],
        userTeams: 0,
      });
    });
  }

  render() {
    return (
      <Row className="pt-4">
        <Col md="12" lg={{size:"8", offset:"2"}}>
          <FieldList {...this.state} {...this.props}/>
        </Col>
      </Row>
    );
  }
}

const FieldList = (props) => {
  const rows = props.field.map((item, index) => {
    return  <tr key={item.team}>
              <td>{item.team}</td>
              <td>{item.owner}</td>
            </tr>
  });

  return (
    <div className="d-flex justify-content-center">
      <Card body className="p-0" style={{maxWidth: 600}}>
        <CardTitle className="d-flex flex-wrap">
          <div className="mr-auto m-2" style={{whiteSpace: "nowrap"}}>
            {props.field.length + ' Teams Entered'}
          </div>
          <div className="mt-2">
            <NavLink to={"/events/"+props.match.params.eventid} className="mx-2" style={{color: "inherit", textDecoration: "none", cursor: "inherit"}}>
              <Button color="success" >
              {props.field.length === 0 ? "Enter Your Team" : "Your Teams ("+props.userTeams+")"}
              </Button>
            </NavLink>
            { props.canInvite &&
              <span className="mx-2">
                <InviteModal league={ props.activeLeague } />
              </span>
            }
          </div>
        </CardTitle>
        <Table>
          <thead>
            <tr style={{backgroundColor: "#fafafa", color: "gray", "fontSize": "0.85rem"}}>
              <th>{'Team'}</th>
              <th>{'Owner'}</th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </Table>
      </Card>
    </div>
  )
}
