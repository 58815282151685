import React from 'react';
import ReactDOM from 'react-dom';

import {
  Badge,
  Row,Col,
  Table,
  Card
} from 'reactstrap';

import {
  withRouter,
  NavLink
} from 'react-router-dom';

import PublicEventsLeaderboardTopNav from 'pages/user/PublicEventsLeaderboardTopNav.js';
import PublicLeaderboardPage from 'pages/user/PublicLeaderboard.js';

import 'pages/user/Events.css';
import EventRulesModal from 'pages/user/EventRulesModal';
import RenderBannerAds from 'common/RenderBannerAds.js';
import {EventTitle} from 'common/EventTitle.js';
import {shadeColor} from 'utils/constants.js';

import {
  get_pub_league_events,logout,
} from 'utils/api.js';

class PublicEventsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      events: [],
      appPageConfig: null,
      activeLeague: null,
      selectedLeague: null,
      notMemberOf: false,
    };
    this.fetchLeagueEvents = this.fetchLeagueEvents.bind(this);
    this.getMatchingEvent = this.getMatchingEvent.bind(this);
  }

  componentDidMount() {
    if (this.props.me){
      logout((response) => {
          this.props.loggedOutCallback();
        }, (error) => {
          console.log(error);
          console.log("Error occurred trying to logout!");
      })
    }
    const leagueSlug = this.props.match.params.leagueslug;


    if (!leagueSlug) {
      console.log('Unrecognized event identifier.')
      return;
    }

    this.fetchLeagueEvents(leagueSlug);
  }

  componentWillReceiveProps(nextProps) {

  }

  fetchLeagueEvents(leagueSlug) {
    this.setState({notMemberOf: false});
    get_pub_league_events(leagueSlug,'',
      ({success, error, events, league}) => {
        this.setState({ loading: false });

        if (!success) {
          console.log(error);
          this.props.history.push("/");
          return;
        }
        let leagueeventId = this.props.match.params.leagueevent;
        let eventid = league.default_event;
        if (leagueeventId && events.filter((e)=>{return e.eventid === (parseInt(leagueeventId)||0);}).length > 0){
          eventid = parseInt(leagueeventId);
        }
        this.setState({events, activeLeague:league.leagueid, eventid:eventid , club:league.club, clubstatus: league.clubstatus, selectedLeague: league});

      },
      (error) => {
        console.log('error getting leaderboard: ' + error);
        this.setState({eventid:0, loading: false});
      }
    );
  }

  getMatchingEvent() {
    const eventid = this.state.eventid;
    if (isNaN(eventid)) {
      return null;
    }
    for (var e of this.state.events) {
      if (e.eventid === eventid) {
        return e;
      }
    }
    return null;
  }

  selectEventId = (eventid) =>{
    this.setState({eventid});
  }


  render() {
    if (this.state.loading) {
      return null;
    }

    const event = this.getMatchingEvent();
    let content;


    if(this.state.notMemberOf){
      content = <EventNotMemberOf />
    } else if (!event) {
      content = <EventNotFound />
    } else if (event.status === "open") {
      content = ''
    } else if (event.status === "scheduled") {
      let eventRules = <span style={{fontSize: "1.25rem"}}>Event Rules</span>
      let prize = <span style={{fontSize: "1.25rem"}}>Prizes</span>
      content = <div className="d-flex flex-column justify-content-center text-center">
        <EventTitle event={event} className="text-center mt-4"/>
        <span>
          <EventRulesModal activeLeague={this.state.activeLeague} event={event} component={eventRules} />
          {event.prizePayout &&
            <>
            <span className="mx-2">&bull;</span>
            <EventRulesModal activeLeague={this.state.activeLeague} event={event} component={prize} />
            </>
          }
          {event.perfect_team &&
            <span style={{fontSize: "1.25rem"}}>
              <span className="mx-2">&bull;</span>
              <a target="_blank" href="/perfectteam">Perfect Team</a>
            </span>
          }
          </span>
        <span className="mb-4" style={{color: "#999"}}>(subject to change)</span>


        {(!event.toc || !event.isTocFinalEvent) &&
        <h5 className="text-center mx-4">This event has been scheduled, but the golfer field has yet to be finalized.</h5>
        }


        <Row noGutters className="pt-2 d-flex justify-content-center">
          <Col lg="7" className="my-1">
            <RenderBannerAds configGrp={'RENDER_ALL_BANNER'} leagueId={this.state.activeLeague} place={'EVENT'}/>
          </Col>
        </Row>

        {(event.toc) &&
          <Row noGutters className="pt-4 d-flex justify-content-center">
            <Col lg="4" className="my-1">
              <TOCLeaderboard isTocFinalEvent={event.isTocFinalEvent} tocLeaderboard={event.tocLeaderboard}/>
            </Col>
          </Row>
        }

      </div>
    } else {
      let leagueName = this.state.selectedLeague?this.state.selectedLeague.leagueName:"";

      content = <PublicLeaderboardPage event={event} leagueName={leagueName} activeLeague={this.state.activeLeague}
                clubstatus={this.state.clubstatus} selectedLeague={this.state.selectedLeague} history={this.props.history}/>

    }

    return (
      <>
      <PublicEventsLeaderboardTopNav selectedLeague={this.state.selectedLeague} activeLeague={this.state.activeLeague}
        setPostLoginURL={this.props.setPostLoginURL}/>
      <div className="eventsWrapper">
        <EventsHeader events={this.state.events} eventid={this.state.eventid} selectedLeague={this.state.selectedLeague} selectEventId={this.selectEventId}/>
        <div className="eventsContainer">
          {content}
        </div>
      </div>
      </>
    )
  }
}

const EventNotMemberOf = (props) => (
  <Row noGutters className="pt-2 d-flex justify-content-center">
    <Col lg="7" className="my-1">
      <center>
        <h3>{"Sorry, you are not a member of the league for the tournament you’re attempting to access. Please contact the league’s commissioner."}</h3>
      </center>
    </Col>
  </Row>

)

const EventNotFound = (props) => (
  <center>
    <h2>{'The event you were looking for could not be found.'}</h2>
  </center>
)

class EventsHeaderImpl extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentSeason: null,
      seasons: {},
    };

    this.updateSeason = this.updateSeason.bind(this);
    this.pickEvent = this.pickEvent.bind(this);
  }

  componentDidMount() {
    this.updateSeason(this.props.eventid, this.props.events);
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.eventid === nextProps.eventid){return;}
    this.updateSeason(nextProps.eventid, nextProps.events);
  }

  updateSeason(eventId, events) {
    const event = events.find(({eventid}) => eventid === eventId);
    if (event === undefined) { return; }

    const currentSeason = event.season;

    const seasons = {};
    events.forEach((evt) => {
      if (!(evt.season in seasons)) { seasons[evt.season] = []; }
      seasons[evt.season].push(evt);
    })

    this.setState({
      currentSeason,
      seasons,
    });
  }

  pickEvent({eventid}) {
    this.props.selectEventId(eventid);
    //this.props.history.push('/events/'+eventid);
  }

  render() {
    const events = [];

    Object.keys(this.state.seasons).sort().forEach((season) => {
      if (parseInt(season, 10) !== this.state.currentSeason) {
        events.push(
          <span className="eventsHeader_item" key={'season'+season} onClick={() => {this.pickEvent(this.state.seasons[season][0])}}>
            <li className="eventsHeader_itemcell">{season}</li>
          </span>
        );
      } else {
        const sEvents = this.state.seasons[season];
        sEvents.forEach((evt) => {
          events.push(<EventsHeaderItem key={evt.eventid} {...evt} selectedEventId={this.props.eventid} selectEventId={this.props.selectEventId} history={this.props.history}/>)
        })
      }
    });

    const selectedLeague = this.props.selectedLeague;
    const eventRibbonColor = (selectedLeague && selectedLeague.whiteLabel && selectedLeague.whiteLabel.branding.topBannerColor)?shadeColor(selectedLeague.whiteLabel.branding.topBannerColor,-30):'#2c3e50';


    return (
      <div className="eventsHeaderContainer" style={{backgroundColor:eventRibbonColor}}>
        <ul className="eventsHeader">
          {events}
        </ul>
      </div>
    )
  }
}

const EventsHeader = withRouter(EventsHeaderImpl);

class EventsHeaderItem extends React.Component {
  constructor(props) {
    super(props);
    this.scrollIntoView = this.scrollIntoView.bind(this);
    this.makePath = this.makePath.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
  }
  componentDidMount() {
    //const path = this.makePath();
    //if (path === this.props.history.location.pathname) {
      //this.scrollIntoView();
    //}
    if (this.props.selectedEventId === this.props.eventid){
      this.scrollIntoView(this.props.eventid);
    }
    window.addEventListener('mouseup', this.handleMouseUp.bind(this));
    window.addEventListener('mousemove', this.handleMouseMove.bind(this));
  }
  componentWillUnmount() {
    window.removeEventListener('mouseup', this.handleMouseUp.bind(this));
    window.removeEventListener('mousemove', this.handleMouseMove.bind(this));
  }
  makePath() {
    return '#';
  }
  async scrollIntoView(eventid) {
    this.props.selectEventId(eventid);
    let domNode = ReactDOM.findDOMNode(this);
    if (domNode) {
      // Unfortunately, scrollIntoView with the object param is not supported in all browsers
      // domNode.scrollIntoView({
      //   behavior: "smooth",
      //   block: "center",
      //   inline: "center"
      // })
      let start = domNode.parentNode.scrollLeft;
      let currentTime = 0;
      const increment = 17;
      const box = domNode.getBoundingClientRect();
      const diff = box.width/2 + box.x - window.innerWidth/2;
      const duration = 400; // milliseconds

      // http://easings.net/#easeInOutQuad
      const easeInOutQuad = (t, s, c, d) => {
        t /= d/2;
        if (t < 1) {
          return c/2 * t * t + s;
        }
        t--;
        return -c/2 * (t * (t - 2) - 1) + s;
      }
      let animateScroll = function() {
        currentTime += increment;
        domNode.parentNode.scrollLeft = easeInOutQuad(currentTime, start, diff, duration);;
        if (currentTime < duration) {
          setTimeout(animateScroll, increment);
        }
      }

      animateScroll();
    }
  }
  handleMouseUp(e) {
    if (this.dragging) {
      this.dragging = false;
    }
  }
  handleMouseDown(e) {
    if (!this.dragging) {
      this.dragging = true;
      this.prevClientX = e.clientX;
      e.preventDefault();
    }
  }
  handleMouseMove(e) {
    if (this.dragging) {
      let domNode = ReactDOM.findDOMNode(this);
      if (domNode !== null) {
        let parent = domNode.parentNode;
        const currClientX = e.clientX;
        parent.scrollLeft -= currClientX - this.prevClientX;
        this.prevClientX = currClientX;
      }
    }
  }
  render() {
    let badge;
    if (["round1", "round2", "round3", "round4"].indexOf(this.props.status) !== -1) {
      badge = <Badge color="primary" className="eventsHeader_itembadge">LIVE</Badge>
    } else if (this.props.status === "open") {
      badge = <Badge color="warning" className="eventsHeader_itembadge">OPEN</Badge>
    }

    const navLinkProps = {
      to: this.makePath(),
      activeClassName: "eventsHeader_item__active",
      className: (this.props.selectedEventId === this.props.eventid ? "eventsHeader_item eventsHeader_item__active" : "eventsHeader_item"),
      exact: true,
      onClick:()=>{this.scrollIntoView(this.props.eventid)},
      onMouseUp: this.handleMouseUp,
      onMouseDown: this.handleMouseDown
    }



    return (
      <NavLink {...navLinkProps}>
        <li className="eventsHeader_itemcell">{badge} {this.props.name}</li>
      </NavLink>
    )
  }
}

const TOCLeaderboard = (props) => {
  if (!props.isTocFinalEvent) {
    return null;
  }

  let entryRows = props.tocLeaderboard.map((item, index) => {
    let rowclass = item.usersRow ?' pgaLeaderboardRow personal':' pgaLeaderboardRow';
    return (
      <tr key={index} className={rowclass}>
        <td>{item.owner}</td>
        <td>{item.eventName}</td>
        <td>{item.rank}</td>
      </tr>
    )
  });

  if (props.tocLeaderboard.length === 0){
    entryRows = <tr key={'00'}>
      <td colSpan="12" className="text-center ">{'No qualifiers yet'}</td>
    </tr>
  }

  return (
    <Card body className="p-0">
      <Row className="justify-content-between p-3">
        <Col >
          <h5>{'Tournament of Champion Qualifiers'}</h5>
        </Col>
      </Row>
      <Table className="text-left">
        <thead>
          <tr style={{backgroundColor: "#fafafa", color: "gray", "fontSize": "0.85rem"}}>
            <th>{'Owner Name'}</th>
            <th>{'Tournament Name'}</th>
            <th>{'Place'}</th>
          </tr>
        </thead>
        <tbody>
          {entryRows}
        </tbody>
      </Table>
    </Card>
  )
}

export default PublicEventsContainer;
