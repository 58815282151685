import React from 'react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { withRouter, Link } from 'react-router-dom';

import FacebookAuth from 'common/FacebookAuth.js';
import GoogleAuth from 'common/GoogleAuthNew.js';
import 'common/McModal.css'
import 'common/LoginModal.css';

import {
  login,
  start_password_reset
} from 'utils/api.js';

class LoginModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: (props.autoOpen?true:false),
      email: (this.props.passedEmail?this.props.passedEmail:''),
      password: '',
      reset: false,
      error: null,
      message: null
    };

    this.toggle = this.toggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleShowResetPassword = this.handleShowResetPassword.bind(this);
    this.handleShowLogin = this.handleShowLogin.bind(this);
    this.handleResetPassword = this.handleResetPassword.bind(this);
    this.onSuccessRedirect = this.onSuccessRedirect.bind(this);
    this.onFacebookError = this.onFacebookError.bind(this);
    this.onGoogleError = this.onGoogleError.bind(this);
  }

  componentWillReceiveProps(nextProps) {
      if (nextProps.passedEmail !== this.props.passedEmail) {
        this.setState({email:nextProps.passedEmail});
      }
  }

  toggle(event) {
    this.setState({
      modal: !this.state.modal,
      email: (this.props.passedEmail?this.props.passedEmail:''),
      password: '',
      reset: false,
      error: null,
      message: null
    });
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  handleChange(event) {
    this.setState({[event.target.name]: event.target.value});
  }

  handleSubmit(event) {
    login(this.state.email, this.state.password,
      (response) => {
        if (response.data.success) {
          this.onSuccessRedirect(response.data.me);
        } else {
          this.setState({error: "There was a problem logging you in."});
        }
      }, (error) => {
        this.setState({error: "Invalid email or password."});
    })
    event.preventDefault();
  }

  handleShowResetPassword(event) {
    this.setState({
      password: '',
      reset: true,
      error: null
    });
    event.preventDefault();
    event.stopPropagation();
  }

  handleShowLogin(event) {
    this.setState({
      email: '',
      password: '',
      reset: false,
      error: null,
      message: null
    });
    event.preventDefault();
    event.stopPropagation();
  }

  handleResetPassword(event) {
    start_password_reset(this.state.email,
      ({success}) => {
        if (success) {
          this.setState({message: 'Check your email for a password reset link.', error: null});
        } else {
          this.setState({message: 'Sorry, we did not find an account matching that email.', error: null});
        }
      }, (error) => {
        this.setState({error: "An unexpected error occurred. Try again later."});
    });
    event.preventDefault();
    event.stopPropagation();
  }

  onSuccessRedirect(me) {
    if (this.props.loginToHome){
      this.props.history.push("/");
    }
    this.props.loggedInCallback(me, this.props.history);
  }

  onFacebookError() {
    this.setState({error: "There was a problem logging you in with Facebook."});
  }
  onGoogleError() {
    this.setState({error: "There was a problem logging you in with Google."});
  }

  render() {



    const passwordInput = (
      <label>
        <div>
          Password
        </div>
        <input type="password" name='password' value={this.state.password} onChange={this.handleChange} className="form-control" />
      </label>
    );

    const resetPasswordMessage = (
      <p>
        {this.state.message}
      </p>
    );

    const action = this.state.reset === false ? 'Sign In' : 'Reset Password';

    const showResetPasswordLink = <a href="/" onClick={this.handleShowResetPassword}>Forgot Password?</a>

    const signupOnClick = () => {this.toggle()} // NOTE this is not the same as using {this.toggle} directly, since onClick funciton will pass in the event parameter
    const showSignupLink = <span>New to Majors Challenge? <Link to="/signup" onClick={signupOnClick}>Sign Up</Link></span>
    const showLoginLink = <a href="/" onClick={this.handleShowLogin}>Back to Sign In</a>
    let element = <Button className="m-2 loginButton" color="success" outline onClick={this.toggle}>Sign In</Button>
    if (this.props.loginLink) {
      element = <a href="/" className="loginLink" onClick={this.toggle}>Sign In</a>
    }
    if (this.props.loginButtonActivation){
      element = <Button className="m-2 loginButtonActivation" color="success" outline onClick={this.toggle}>Sign In</Button>
    }

    return (
      <span>
        {element}
        <Modal isOpen={this.state.modal} toggle={this.toggle} className="loginModal">
          <ModalHeader toggle={this.toggle} className="mcModalHeader">{action}</ModalHeader>
          <ModalBody>
            <form onSubmit={this.state.reset === false ? this.handleSubmit : this.handleResetPassword}>

              <div className="justify-content-center pb-3 row d-none d-md-flex ">
                <div className="">
                    <FacebookAuth onSuccessRedirect={this.onSuccessRedirect} onError={this.onFacebookError}/>
                </div>
                <div className="ml-3">
                    <GoogleAuth fakeProp={this.state.modal} onSuccessRedirect={this.onSuccessRedirect} onError={this.onGoogleError} />
                </div>
              </div>
              <div className="justify-content-center pb-3 row d-flex d-md-none ">
                <div className="">
                    <FacebookAuth onSuccessRedirect={this.onSuccessRedirect} onError={this.onFacebookError}/>
                </div>
              </div>
              <div className="justify-content-center pb-3 row d-flex d-md-none ">
                <div className="">
                    <GoogleAuth fakeProp={this.state.modal} onSuccessRedirect={this.onSuccessRedirect} onError={this.onGoogleError} />
                </div>
              </div>
              <div className="justify-content-center pb-3 row">
                <div className="" style={{borderBottom: "2px inset darkgray",width: "100px",marginBottom: "12px"}}></div>
                <div className="mx-3" style={{textAlign: "center", color: "#888"}}>
                  {"OR"}
                </div>
                <div className="" style={{borderBottom: "2px inset darkgray",width: "100px",marginBottom: "12px"}}></div>
              </div>
              <div>
                <label>
                  <div>
                    Email / Username
                  </div>
                  <input type="text" name='email' value={this.state.email} onChange={this.handleChange}  className="form-control" />
                </label>
              </div>
              <div>
                {
                  this.state.reset === false ? passwordInput : resetPasswordMessage
                }
              </div>
              <input type="submit" value="Sign In" hidden />
              <div className="d-flex justify-content-between align-items-center pt-3">
                {this.state.reset === false ? showResetPasswordLink : showLoginLink}
                {
                  this.state.error && <div style={{color: "red"}} className="pr-2">{this.state.error}</div>
                }

                  <button type="submit" className="btn btn-primary" onClick={this.state.reset === false ? this.handleSubmit : this.handleResetPassword}>
                    {action}
                  </button>

              </div>
              <div>
                {!this.props.disallowSignup && showSignupLink}
              </div>
            </form>
          </ModalBody>
        </Modal>
      </span>
    );
  }
}

export default withRouter(LoginModal);
