import { get_promo } from './api.js';

let PROMO_DATA = null;

export async function get_promo_content() {
  if (!PROMO_DATA) {
    try {
      const data = await get_promo();
      PROMO_DATA = data.data;
      setTimeout(() => {PROMO_DATA = null;}, 300000);
    } catch (err) {
      console.log(`Error fetching promo content: ${err}`);
    }
  }
  return PROMO_DATA;
}
