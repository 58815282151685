import React from 'react';
import Linkify from 'react-linkify';

import {
  Alert,
  Container, Row, Col, ButtonGroup, Button,
  Table,
} from 'reactstrap';

import { NotMobilePortrait, MobilePortrait, LargeDesktop, NotLargeDesktop } from 'utils/responsive.js';

import numeral from 'numeral';

import {EventTitle} from 'common/EventTitle.js';
import GolferModal from 'common/GolferModal.js';


import { get_LCleaderboards } from 'utils/api.js';

import 'pages/user/Leaderboard.css';
import { Bootstrap2Toggle } from 'react-bootstrap-toggle';
import "utils/bootstrap2-toggle.css";

export default class LeaderboardLeagueContestsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: null,
      showCuts: false,
      golferModalOpen: false,
      golferModalGolferId: null,
      pgaLeaderboard: true,
      friendFocus: false,
      pgaBreakdown: false,
      leaderboardRecPerPage: 2000,
      appPageConfig:{},
      mcAdvConfigGrp: null,
      selectionBreakdownGolferType: true,
    };
    this.fetchLeaderboardsData = this.fetchLeaderboardsData.bind(this);
    this.fetchLeaderboardsDataInterval = this.fetchLeaderboardsDataInterval.bind(this);
    this.toggleFocus = this.toggleFocus.bind(this);
    this.toggleViewCuts = this.toggleViewCuts.bind(this);
    this.toggleGolferModal = this.toggleGolferModal.bind(this);
    this.showGolferDetails = this.showGolferDetails.bind(this);
    this.renderError = this.renderError.bind(this);
    this.toggleBreakdown = this.toggleBreakdown.bind(this);

    this.interval = setInterval(this.fetchLeaderboardsDataInterval, 60*1000);
  }


  fetchLeaderboardsData(leagueid, eventid, leaguecontestid) {

      get_LCleaderboards(leagueid, eventid, leaguecontestid,
        ({success, lcData, eventData,  pgaData, golfersTeams, oadSelectionBreakdown, oadMoneySelectionBreakdown, error}) => {
          if (!success) {
            this.setState({loading: false, error});
            return;
          }
          if (eventData.status === 'open' && lcData.contestType === 'ONE_AND_DONE'){
            this.props.history.push('/league/'+leagueid+(lcData.contestType === 'ONE_AND_DONE'?'/odcontest':'/sdcontest'));
            return;
          }
          let golfer_counts = {};
          let my_golfers = new Set();

          this.setState({lcData, eventData, pgaData, golfer_counts, my_golfers, oadSelectionBreakdown, oadMoneySelectionBreakdown, loading: false});

        },
        (error) => {
          console.log('error getting leaderboard: ' + error);
          this.setState({ loading: false, error: 'The leaderboard failed to load, possibly due to a network problem. Please refresh the page to reload the leaderboard.' });
        }
      )
  }
  fetchLeaderboardsDataInterval() {
    this.fetchLeaderboardsData(this.props.activeLeague, this.props.eventid, this.props.leaguecontestid);
  }
  componentDidMount() {
      this.fetchLeaderboardsData(this.props.activeLeague, this.props.eventid, this.props.leaguecontestid);
  }
  componentWillReceiveProps(nextProps) {
      if (nextProps.eventid !== this.props.eventid) {
        this.fetchLeaderboardsData(nextProps.activeLeague, nextProps.eventid, this.props.leaguecontestid)
      }
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  toggleFocus() {
    this.setState({ friendFocus: !this.state.friendFocus })
  }
  toggleViewCuts() {
    this.setState({ showCuts: !this.state.showCuts })
  }
  toggleGolferModal() {
    this.setState({golferModalOpen: !this.state.golferModalOpen});
  }
  toggleBreakdown() {
    this.setState({pgaBreakdown: !this.state.pgaBreakdown});
  }
  showGolferDetails(golferid) {
    this.setState({
      golferModalGolferId: golferid,
      golferModalOpen: true
    });
  }

  toggleSelectionBreakdownType = () => {
    this.setState({selectionBreakdownGolferType:!this.state.selectionBreakdownGolferType})
  }

  renderError() {

    return (
      <Container className="">
        <EventTitle event={this.state.eventData} suppressEventRule={true} className="px-3 px-lg-4"/>
        <h4 className="px-3 pt-5 px-lg-4" style={{fontWeight: "200", letterSpacing: "0.7px"}}>{this.state.error}</h4>
      </Container>
    )
  }
  render() {
    if (this.state.loading) {
      return null;
    }
    if (this.state.error) {
      return this.renderError();
    }

    const goBack = () => {
      /*if (this.props.location.state && this.props.location.state.from){
        this.props.history.push(this.props.location.state.from);
      }else{
        this.props.history.push('/league/'+this.props.activeLeague+(this.props.contesttype === 'ONE_AND_DONE'?'/odcontest':'/sdcontest'));
      }*/
  		//this.props.history.goBack();
      this.props.history.push('/league/'+this.props.activeLeague+(this.props.contesttype === 'ONE_AND_DONE'?'/odcontest':'/sdcontest'));
  	}



    return (
      <Container className="">
        <Row noGutters className="pt-0 d-flex justify-content-center">
          <Col className="d-flex justify-content-between">
            <div className="d-flex justify-content-start">
              <EventTitle event={this.state.eventData} suppressEventRule={true}
              showProps={{purse:true}}
              className="px-0 contestlederboard"  />
            </div>
            <div className="d-flex justify-content-end mr-3">
              <div>
                <Button outline size="sm" color="primary" className="my-2" onClick={goBack}>
                Back
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        {this.state.lcData.contestType === 'ONE_AND_DONE' &&
        <Row noGutters className="pt-4 d-flex justify-content-center d-xl-none">
          <ButtonGroup>
            <Button style={{minWidth: 130}} color="success" active={this.state.pgaLeaderboard} onClick={() => this.setState({pgaLeaderboard: true})}>
              Leaderboard
            </Button>
            <Button style={{minWidth: 130, borderLeft:'1px solid'}} color="success" active={!this.state.pgaLeaderboard && this.state.selectionBreakdownGolferType} onClick={() => this.setState({pgaLeaderboard: false, selectionBreakdownGolferType:true})}>
              Breakdown
            </Button>
            {(this.state.eventData.status === "closed" && this.state.lcData.noGolfersPerWk > 1) &&
            <Button style={{minWidth: 130, borderLeft:'1px solid'}} color="success" active={!this.state.pgaLeaderboard && !this.state.selectionBreakdownGolferType} onClick={() => this.setState({pgaLeaderboard: false, selectionBreakdownGolferType: false})}>
              Results
            </Button>
            }
          </ButtonGroup>
        </Row>
        }
        <LargeDesktop>
        <Row noGutters className="pt-2 justify-content-center mt-2">
            <Col xs="auto" className="mr-4">
              <PgaLeaderboard {...this.state} activeLeague={this.props.activeLeague} toggleViewCuts={this.toggleViewCuts} showGolferDetails={this.showGolferDetails} toggleBreakdown={this.toggleBreakdown}/>
            </Col>
            {this.state.lcData.contestType === 'ONE_AND_DONE' &&
            <Col xs="auto" className="ml-4">
              <SelectionBorakdown {...this.state} showGolferDetails={this.showGolferDetails}
                toggleSelectionBreakdownType={this.toggleSelectionBreakdownType}/>
            </Col>
            }
        </Row>
        </LargeDesktop>

        <NotLargeDesktop>
        <Row noGutters className="pt-2 justify-content-center">
          {this.state.pgaLeaderboard &&
            <Col xs="auto" className="">
              <PgaLeaderboard {...this.state} toggleViewCuts={this.toggleViewCuts} showGolferDetails={this.showGolferDetails} toggleBreakdown={this.toggleBreakdown}/>
            </Col>
          }
          {!this.state.pgaLeaderboard &&
            <Col xs="auto" className="">
              <SelectionBorakdown {...this.state} showGolferDetails={this.showGolferDetails}
                toggleSelectionBreakdownType={this.toggleSelectionBreakdownType}/>
            </Col>
          }
        </Row>
        </NotLargeDesktop>

        <div className="small muted mt-4" style={{textAlign:'center'}}>
          Choose friends to follow in  <a href={`/members/${this.props.activeLeague}`}>Members section</a>.
        </div>

        <GolferModal isOpen={this.state.golferModalOpen} toggle={this.toggleGolferModal} golferid={this.state.golferModalGolferId}/>
      </Container>
    );
  }
}

const PgaLeaderboard = (props) => {
  return <div>
          <LargeDesktop>
          <div className="d-flex justify-content-between mb-2">
            <div className="pgaLeaderboardTitle"> PGA Leaderboard </div>
          </div>
          </LargeDesktop>
          <PgaNormalLeaderboard {...props} />
        </div>
}

const roundScore = (score) => {
  const color = (score !== null && score < 0) ? "#e74c3c" : "#515356";
  const content = score === 0 ? "E" :
          score === null ?
            "-" : score > 0 ?
                "+"+score : score;
  return <span style={{color: color}}> {content} </span>
}

const PgaNormalLeaderboard = (props) => {

  //props.eventData.status = "round2";
  //props.eventData.projected_cut = -2;

  const thru = (t) => {
    return t === 18 ?
      <span style={{color: "#aaa"}}>F</span> :
      (t === 0 ? "-" : t)
  }

  const pgaRowData = () => {
    var rowData = []

    for (var player in props.pgaData) {
      if (props.showCuts || props.pgaData[player].status === 'active') {
        rowData.push(props.pgaData[player]);
      }
    }

    if (props.eventData.status === "open"){
      rowData.sort((a, b)=>{
          if (!a.teamName || !b.teamName) {
            if (a.teamName) { return -1; }
            if (b.teamName) { return 1; }
            return a.player.toUpperCase() < b.player.toUpperCase() ? -1 : 1;
          }

          if (a.teamName === b.teamName) {
            return a.player.toUpperCase() < b.player.toUpperCase() ? -1 : 1;
          }else{
            return a.teamName.toUpperCase() < b.teamName.toUpperCase() ? -1 : 1;
          }

          return a.player.toUpperCase() < b.player.toUpperCase() ? -1 : 1;
      });
    }else{
      rowData.sort((a, b)=>{
          if (a.place === null || b.place === null) {
            if (a.place !== null) { return -1; }
            if (b.place !== null) { return 1; }
            return a.player.toUpperCase() < b.player.toUpperCase() ? -1 : 1;
          }

          let a_place = a.place[0]==='T' ? a.place.substr(1) : a.place;
          let b_place = b.place[0]==='T' ? b.place.substr(1) : b.place;

          if (a_place === b_place) {
            return a.player.toUpperCase() < b.player.toUpperCase() ? -1 : 1;
          }

          return a_place - b_place;
      });
    }

    return rowData
  }


  const showR1Score = props.eventData.status === "closed" || props.eventData.status === "round1";
  const showR2Score = props.eventData.status === "closed" || props.eventData.status === "round2";
  const showR3Score = props.eventData.status === "closed" || props.eventData.status === "round3";
  const showR4Score = props.eventData.status === "closed" || props.eventData.status === "round4";
  const isGameLive = ['round1','round2','round3','round4'].indexOf(props.eventData.status) >-1 ? true : false;

  let projected_cut_visible = 0;

  const pgaRows = pgaRowData().map((item, index) => {
    const teams = item.teamName;

    if (projected_cut_visible === 1) {
      projected_cut_visible = -1;
    }
    if (props.eventData.status === "round2" && projected_cut_visible === 0 && item.total > props.eventData.projected_cut){
      projected_cut_visible = 1;
    }

    let classname = "pgaLeaderboardRow";
    if (item.usersRow) {
      classname += " personal";
    }

    if (props.lcData.contestType === 'ONE_AND_DONE' && !item.usersRow && teams > 0 && item.frndsRow){
      classname += " others";
    }

    if (item.status === 'WD*') {
        return <tr key={index} className={classname}>
          <td className="text-center">{item.status}</td>
          <td className="pgaLeaderboardPlayer font-weight-bold">
            <span onClick={() => props.showGolferDetails(item.id)}>
              {item.player}
            </span>
          </td>
          <NotMobilePortrait>
          { showR1Score &&
            <td className="text-center font-weight-light">{ roundScore(null) }</td>
          }
          { showR2Score &&
            <td className="text-center font-weight-light">{ roundScore(null) }</td>
          }
          { showR3Score &&
            <td className="text-center font-weight-light">{ roundScore(null) }</td>
          }
          { showR4Score &&
            <td className="text-center font-weight-light">{ roundScore(null) }</td>
          }
          </NotMobilePortrait>
          <MobilePortrait>
          {(props.lcData.contestType === 'ONE_AND_DONE' && isGameLive) &&
            <td className="text-center font-weight-light">{ roundScore(null) }</td>
          }
          </MobilePortrait>
          <td className="text-center font-weight-bold">{roundScore(null)}</td>
          { props.eventData.status !== "closed" &&
            <td className="text-center">{thru(0)}</td>
          }
          { props.eventData.status === "closed" &&
            <td className="text-right">${numeral(item.earnings).format('0,0')}</td>
          }
          { props.lcData.contestType === 'SEASON_DRAFT' && <td className="text-left text-nowrap">{teams}</td>}
          { props.lcData.contestType === 'ONE_AND_DONE' && <td className={teams > 0 ?"text-center font-weight-bold" :"text-center"}>{teams}</td>}
        </tr>
    }

    let projectedCut = (props.eventData.projected_cut === 0 ? 'E' :
                          (props.eventData.projected_cut>0?'+'+props.eventData.projected_cut:
                          '-'+props.eventData.projected_cut)
                       );
    return (
      <>
      { projected_cut_visible === 1 &&
        <tr key={index + projected_cut_visible} className={classname}>
          <td colSpan="6" style={{backgroundColor:"lightgrey",paddingLeft:"15px", textAlign:"center"}}>Projected Cut {projectedCut}</td>
        </tr>
      }
      <tr key={index} className={classname}>
        <td className="text-center">{item.status === 'active' ? item.place : item.status.toUpperCase()}</td>
        <td className="pgaLeaderboardPlayer font-weight-bold">
          <span onClick={() => props.showGolferDetails(item.id)}>
            {item.player}
          </span>
        </td>
        <NotMobilePortrait>
        { showR1Score &&
          <td className="text-center font-weight-light">{ roundScore(item.thru === 0 && props.eventData.status === 'round1' ? null : item.r1) }</td>
        }
        { showR2Score &&
          <td className="text-center font-weight-light">{ roundScore(item.thru === 0 && props.eventData.status === 'round2' ? null : item.r2) }</td>
        }
        { showR3Score &&
          <td className="text-center font-weight-light">{ roundScore(item.thru === 0 && props.eventData.status === 'round3' ? null : item.r3) }</td>
        }
        { showR4Score &&
          <td className="text-center font-weight-light">{ roundScore(item.thru === 0 && props.eventData.status === 'round4' ? null : item.r4) }</td>
        }
        </NotMobilePortrait>
        <MobilePortrait>
        {(props.lcData.contestType === 'ONE_AND_DONE' && isGameLive) &&
          <td className="text-center font-weight-light">{ roundScore(item.thru === 0? null : item[props.eventData.status.replace('ound','')]) }</td>
        }
        </MobilePortrait>
        <td className="text-center font-weight-bold">{roundScore(item.total)}</td>
        { props.eventData.status !== "closed" &&
          <td className="text-center">{thru(item.thru)}</td>
        }
        { props.eventData.status === "closed" &&
          <td className="text-right">${numeral(item.earnings).format('0,0')}</td>
        }

        { props.lcData.contestType === 'SEASON_DRAFT' && <td className="text-left text-nowrap">{teams}</td>}
        { props.lcData.contestType === 'ONE_AND_DONE' && <td className={teams > 0 ?"text-center font-weight-bold" :"text-center"}>{teams}</td>}
      </tr>
    </>
    )


    }
  );

  return (
    <div className="table-responsive">
      <Table size="sm" className="pgaLeaderboardTable">
        <thead>
          <tr style={{color: "gray", fontSize: "0.65rem", textTransform: "uppercase",}}>
            <th className="text-center">Place</th>
            <th>Player</th>
            <NotMobilePortrait>
            { showR1Score &&
              <th className="text-center">{ props.eventData.status === "closed" ? "R1" : "Today" }</th>
            }
            { showR2Score &&
              <th className="text-center">{ props.eventData.status === "closed" ? "R2" : "Today" }</th>
            }
            { showR3Score &&
              <th className="text-center">{ props.eventData.status === "closed" ? "R3" : "Today" }</th>
            }
            { showR4Score &&
              <th className="text-center">{ props.eventData.status === "closed" ? "R4" : "Today" }</th>
            }
            </NotMobilePortrait>
            <MobilePortrait>
            {(props.lcData.contestType === 'ONE_AND_DONE' && isGameLive) &&
              <th className="text-center">{"Today" }</th>
            }
            </MobilePortrait>
            <th className="text-center">Total</th>
            { props.eventData.status !== "closed" &&
              <th className="text-center">Thru</th>
            }
            { props.eventData.status === "closed" &&
              <th className="text-left">Earnings</th>
            }
            { props.lcData.contestType === 'SEASON_DRAFT' && <th className="text-left">Team</th>}
            { props.lcData.contestType === 'ONE_AND_DONE' && <th className="text-center">Picked</th>}
          </tr>
        </thead>
        <tbody>
          {pgaRows}
        </tbody>
      </Table>
      <div style={{color: "blue"}} className="ml-2 mb-1" onClick={() => {props.toggleViewCuts()}}>
        {props.showCuts ?
          "Hide Cut Golfers" :
          "Show Cut Golfers"
        }
      </div>


    </div>
  )
}

const SelectionBorakdown = (props) => {
  return <div>
          <LargeDesktop>
          <div className="d-flex justify-content-between mb-2">
            <div className="pgaLeaderboardTitle"> Selection Breakdown </div>
            {(props.eventData.status === "closed" && props.lcData.noGolfersPerWk > 1) &&
            <div className="d-flex justify-content-end ml-4">
              <Bootstrap2Toggle
                onClick={(state)=>{props.toggleSelectionBreakdownType(state)}}
                on={'By Golfer'}
                off={'By Money'}
                offstyle="secondary toggle-font-size-small"
                onstyle="secondary toggle-font-size-small"
                active={props.selectionBreakdownGolferType}
                width="100px"
              />
            </div>
            }
          </div>
          </LargeDesktop>
          {/*
          <MobilePortrait>
          <div className="d-flex justify-content-between mb-2">
            <div className="pgaLeaderboardTitle">  </div>
            {(props.eventData.status === "closed" && props.lcData.noGolfersPerWk > 1) &&
            <div className="d-flex justify-content-end ml-4">
              <Bootstrap2Toggle
                onClick={(state)=>{props.toggleSelectionBreakdownType(state)}}
                on={'By Golfer'}
                off={'By Money'}
                offstyle="secondary toggle-font-size-small"
                onstyle="secondary toggle-font-size-small"
                active={props.selectionBreakdownGolferType}
                width="100px"
              />
            </div>
            }
          </div>
          </MobilePortrait>*/}
          <SelectionBorakdownDetails {...props} />
        </div>
}

const SelectionBorakdownDetails = (props) => {

  const thru = (t) => {
    return t === 18 ?
      <span style={{color: "#aaa"}}>F</span> :
      (t === 0 ? "-" : t)
  }

  const showR1Score = props.eventData.status === "closed" || props.eventData.status === "round1";
  const showR2Score = props.eventData.status === "closed" || props.eventData.status === "round2";
  const showR3Score = props.eventData.status === "closed" || props.eventData.status === "round3";
  const showR4Score = props.eventData.status === "closed" || props.eventData.status === "round4";
  const isGameLive = ['round1','round2','round3','round4'].indexOf(props.eventData.status) >-1 ? true : false;
  let golferList = [];
  let useridList = [];
  let rows = [];
  let kIndx = 1;
  if (props.selectionBreakdownGolferType){
    props.oadSelectionBreakdown.sort((_a, _b)=>{
      const a_golfer = props.pgaData[_a.golfer_id];
      const b_golfer = props.pgaData[_b.golfer_id];
      if (!a_golfer || !b_golfer){return 0;}
      let a = {..._a, ...a_golfer};
      let b = {..._b, ...b_golfer};
      if (a.place === null || b.place === null) {
        if (a.place !== null) { return -1; }
        if (b.place !== null) { return 1; }
        return a.golfer_id === b.golfer_id ? (a.user_name.toUpperCase() < b.user_name.toUpperCase() ? -1 : 1) : (a.golfer_id-b.golfer_id);
      }

      let a_place = a.place[0]==='T' ? a.place.substr(1) : a.place;
      let b_place = b.place[0]==='T' ? b.place.substr(1) : b.place;

      if (a_place === b_place) {
        return a.golfer_id === b.golfer_id ? (a.user_name.toUpperCase() < b.user_name.toUpperCase() ? -1 : 1) : (a.golfer_id-b.golfer_id);
      }

      return a_place - b_place;
    });

    rows = props.oadSelectionBreakdown.map((_item, index) => {
      const golfer = props.pgaData[_item.golfer_id];
      const item = {..._item, ...golfer};
      let isPresent = golferList.indexOf(item.golfer_id) >-1? true: false;
      if (golferList.indexOf(item.golfer_id) <0){golferList.push(item.golfer_id);}

      let classname = "pgaLeaderboardRow";
      if (_item.usersRow) {
        classname += " personal";
      }else if (_item.frndsRow){
        classname += " others";
      }

      if (isPresent){
        return (
          <tr key={kIndx++} className={classname}>
            <td className="no-border-top py-0"></td>
            <td className="no-border-top py-0">
              {item.user_name}
            </td>
            <td colSpan="4" className="no-border-top py-0"></td>
          </tr>
        )
      }
      return (
        <>

        <tr key={kIndx++} className="pgaLeaderboardRow">
          <td className="text-center pb-0">{item.status === 'active' ? item.place : (item.status ? item.status.toUpperCase(): '-')}</td>
          <td className="pgaLeaderboardPlayer font-weight-bold  pb-0">
            <span onClick={() => props.showGolferDetails(item.id)}>
              {item.player}
            </span>
          </td>

          {(isGameLive) &&
            <td className="text-center font-weight-light  pb-0">{ roundScore(item.thru === 0? null : item[props.eventData.status.replace('ound','')]) }</td>
          }

          <td className="text-center font-weight-bold  pb-0">{roundScore(item.total)}</td>
          { props.eventData.status !== "closed" &&
            <td className="text-center pb-0">{thru(item.thru)}</td>
          }
          { props.eventData.status === "closed" &&
            <td className="text-right pb-0">${numeral(item.earnings).format('0,0')}</td>
          }
        </tr>
        <tr key={kIndx++} className={classname}>
          <td className="no-border-top py-0"></td>
          <td className=" no-border-top py-0">
            {item.user_name}
          </td>
          <td colSpan="4" className="no-border-top py-0"></td>
        </tr>
      </>
      )


      }
    );
  }else{
    props.oadMoneySelectionBreakdown.sort((a, b)=>{
      if (parseInt(a.earnings) === parseInt(b.earnings)) {
        return a.user_name.toUpperCase() === b.user_name.toUpperCase() ? 0 :(a.user_name.toUpperCase() < b.user_name.toUpperCase() ? -1 : 1);
      }
      return parseInt(b.earnings) - parseInt(a.earnings);
    });

    let new_pos = 0;
    let last_user_id = 0;
    rows = props.oadMoneySelectionBreakdown.map((item, index) => {

      if (!item.new_pos){
        item.new_pos = ++new_pos + '';
        last_user_id = item.user_id;
        for (let x=index+1; x<props.oadMoneySelectionBreakdown.length;x++){
          if (item.earnings === props.oadMoneySelectionBreakdown[x].earnings && item.user_id !== props.oadMoneySelectionBreakdown[x].user_id){
            if (item.new_pos.indexOf('T') < 0) {item.new_pos = 'T' + new_pos;}
            props.oadMoneySelectionBreakdown[x].new_pos = item.new_pos;
            if (last_user_id != props.oadMoneySelectionBreakdown[x].user_id) {new_pos ++;}
            last_user_id = props.oadMoneySelectionBreakdown[x].user_id;
          }else if (item.earnings === props.oadMoneySelectionBreakdown[x].earnings && item.user_id === props.oadMoneySelectionBreakdown[x].user_id){
            props.oadMoneySelectionBreakdown[x].new_pos = item.new_pos;
          }
        }
      }

      let isPresent = useridList.indexOf(item.user_id) >-1? true: false;
      if (useridList.indexOf(item.user_id) <0){useridList.push(item.user_id);}

      let classname = "pgaLeaderboardRow";
      if (item.usersRow) {
        classname += " personal";
      }else if (item.frndsRow){
        classname += " others";
      }


      if (isPresent){
        return (
          <tr key={kIndx++} className="pgaLeaderboardRow">
            <td className="no-border-top py-0"></td>
            <td className="no-border-top py-0">
              <span onClick={() => props.showGolferDetails(item.golfer_id)}>
                {item.golfer}
              </span>
            </td>
            <td className="no-border-top py-0 text-center">{item.place}</td>
            <td className="no-border-top text-right py-0">${numeral(item.golfer_earnings).format('0,0')}</td>
          </tr>
        )
      }
      return (
        <>

        <tr key={kIndx++} className={classname}>
          <td className="text-center font-weight-bold  pb-0">{item.new_pos}</td>
          <td className=" font-weight-bold  pb-0">
            {item.user_name}
          </td>
          <td className="text-center font-weight-bold  pb-0"></td>
          <td className="text-right font-weight-bold pb-0">${numeral(item.earnings).format('0,0')}</td>

        </tr>
        <tr key={kIndx++} className="pgaLeaderboardRow">
          <td className="no-border-top py-0"></td>
          <td className="pgaLeaderboardPlayer no-border-top py-0">
            <span onClick={() => props.showGolferDetails(item.golfer_id)}>
              {item.golfer}
            </span>
          </td>
          <td className="no-border-top py-0 text-center">{item.place}</td>
          <td className="no-border-top text-right py-0">${numeral(item.golfer_earnings).format('0,0')}</td>
        </tr>
      </>
      )


      }
    );
  }

  return (
    <div className="table-responsive">
      <Table size="sm" className="pgaLeaderboardTable">
        {props.selectionBreakdownGolferType &&
        <thead>
          <tr style={{color: "gray", fontSize: "0.65rem", textTransform: "uppercase",}}>
            <th className="text-center">Place</th>
            <th>Player</th>

            {(isGameLive) &&
              <th className="text-center">{"Today" }</th>
            }

            <th className="text-center">Total</th>
            { props.eventData.status !== "closed" &&
            <th className="text-center">Thru</th>
            }
            { props.eventData.status === "closed" &&
              <th className="text-right">Earnings</th>
            }
          </tr>
        </thead>
        }
        {!props.selectionBreakdownGolferType &&
        <thead>
          <tr style={{color: "gray", fontSize: "0.65rem", textTransform: "uppercase",}}>
            <th className="text-center">Place</th>
            <th>Player</th>
            <th className="text-center"></th>
            <th className="text-right">Earnings</th>
          </tr>
        </thead>
        }
        <tbody>
          {rows}
        </tbody>
      </Table>

    </div>
  )
}
