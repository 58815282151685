import React from 'react';

import {
  Breadcrumb, BreadcrumbItem
} from 'reactstrap';

import {
  Link,
} from 'react-router-dom';

export const MobileHeader = (props) => {
  let activeCrumb = null;
  if (props.to) {
    activeCrumb = <BreadcrumbItem active>{props.to}</BreadcrumbItem>
  }
  return (
    <div className="d-flex flex-column d-lg-none p-3">
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to={"/admin"}>Menu</Link>
        </BreadcrumbItem>
        {activeCrumb}
      </Breadcrumb>
    </div>
  )
}

export const ContentWrapper = (props) => {
  return (
    <div className="py-3 pb-3 p-lg-0">
      {props.children}
    </div>
  )
}
