import React from 'react';

import {
  Container,
  Row,
  Col,
  Button,
} from 'reactstrap';

import {
  Link
} from 'react-router-dom';

import FontAwesome from 'react-fontawesome';

import {MainLandingWrapper} from 'common/LandingWrapper.js';

import laptop from 'img/laptop.png';
import phone from 'img/phone.png';
import iconComms from 'img/icon-communication.png';
import iconConsole from 'img/icon-console.png';
import iconCup from 'img/icon-cup.png';
import iconDevice from 'img/icon-device.png';
import iconLeaderboard from 'img/icon-leaderboard.png';
import iconPricing from 'img/icon-pricing.png';
import iconSchedule from 'img/icon-schedule.png';
import iconStats from 'img/icon-stats.png';

import { get_promo_content } from '../../utils/promoContent.js';

import 'pages/user/Landing.css';

const Landing = (props) => {
  return (
    <MainLandingWrapper {...props}>
      <Hero/>
      <PromoEvent />
      <BigDeal />
      <HowItWorks />
      <CommishOffer />
      <PeopleAreTalking />
    </MainLandingWrapper>
  )
}

const Hero = (props) => (
  <div>
    <div className="landingHeroBg"/>
    <Container className="landingHero">
      <Row noGutters>
        <Col xs={{size: 12}} sm={{size: 9, offset: 2}} md={{size: 6, offset: 3}} lg={{size: 4, offset: 0}}>
          <div className="landingHero_title">
            Golf Pools<br/>Simplified.
          </div>
          <div className="landingHero_subtitle">
            Gone are the cumbersome days of combing over spreadsheets.
            As commissioner, we give you complete control over running your fantasy golf pool the way you want.
            You focus on picking the winning team and we’ll take care of the rest.
          </div>
          <div className="d-flex justify-content-start">
            <Link to="/signup">
              <Button color="success" className="landingHeroSignup">Create a Golf Pool</Button>
            </Link>
          </div>
          <div className="text-center">
            <p className="landingHero_details"><span style={{fontWeight: "500", color: "#fff"}}>100% free</span> to create and manage your league.</p>
            <p className="landingHero_details" style={{marginTop: "-1rem"}}>Each team entry for the Majors only costs $1.</p>
            <p className="landingHero_details"><span style={{fontWeight: "500", color: "#fff"}}>Invited to a league?</span> Follow the link in your email.</p>
            <p className="landingHero_details">
              <Link to="/ileague" className="d-xl-none d-lg-none">
                <Button color="success" className="landingHeroSignup mt-3">Join a Golf Pool</Button>
              </Link>
            </p>
          </div>
        </Col>
        <Col lg={{size: 8, offset: 0}} className="d-none d-lg-block landingHero_laptop pt-3 pt-lg-0">
          <img src={laptop} alt="Laptop" className="landingHero_laptop__img"/>
        </Col>
      </Row>
    </Container>
  </div>
)

const BigDeal = (props) => (
  <div className="landingSection1">
    <Container className="landingContainer" id="features">
      <h3 className="landingSectionTitle__dark text-center">What's the big deal?</h3>
      <Row>
        <BigDealCell
          image={iconSchedule}
          title="Custom Schedule"
          text="Want to skip a week or two? Only interested in the majors? No problem. You decide what PGA tournaments you want your league to participate in."
        />
        <BigDealCell
          image={iconConsole}
          title="Commissioner's Console"
          text="As commissioner, you have tools for managing every aspect of your league. Change the event type or payouts based on your league members preferences. "
        />
        <BigDealCell
          image={iconLeaderboard}
          title="Live Leaderboards"
          text="Follow the PGA Tour action as it unfolds right beside your league's leaderboard. See who has which players and who’s moving up and down the leaderboard."
        />
        <BigDealCell
          image={iconDevice}
          title="Any Device"
          text="Make last minute changes to your league, make last minute swaps, follow your league's leaderboard along with the tour leaders all from your mobile device."
        />
        <BigDealCell
          image={iconComms}
          title="League Communication"
          text="Have something you want your league to know about? Leave a broadcast message to everyone or contact individuals right from your commissioner console."
        />
        <BigDealCell
          image={iconStats}
          title="Player Statistics"
          text="No need to visit other sites for player insights. Majors Challenge has a robust player archive with relevant player stats to help you pick a winning team."
        />
        <BigDealCell
          image={iconPricing}
          title="Simple Pricing"
          text="Every aspect of managing your league is FREE. As league commissioner you pay $1 for each team entered in an event. Your friends play for free. It doesn’t get more simple than that."
        />
        <BigDealCell
          image={iconCup}
          title="Majors Cup Challenge"
          text="You can choose to opt into (for free) the Majors Cup Challenge which automatically aggregates a season-long leaderboard in a race to the cup champion."
        />
      </Row>
    </Container>
  </div>
)

const BigDealCell = (props) => (
  <Col xs="12" sm="6" lg="3" className="pb-3">
    <img src={props.image} alt={props.title} className="landingBD_image"/>
    <h6 className="landingBD_title">{props.title}</h6>
    <p className="landingBD_text">{props.text}</p>
  </Col>
)


const HowItWorks = (props) => (
  <div className="landingSection2">
    <Container className="landingContainer pb-3 pb-lg-0" id="howitworks">
      <Row>
        <Col lg={{size: 4}} className="d-none d-lg-block landingHero_phone">
          <img src={phone} alt="Phone" className="landingHero_phone__img"/>
        </Col>
        <Col xs="12" lg="8">
          <h3 className="landingSectionTitle__white text-left">How does it work?</h3>
          <Row>
            <Col xs="12" md="6">
              <div className="landingStepNumberCircle">1</div>
              <h6 className="landingSubtitle__white">Create a League</h6>
              <p className="landingText__white">
                Create a league or join a friend’s league. The commissioner invites friends to join the league and Majors Challenge takes care of the rest.
              </p>
            </Col>
            <Col xs="12" md="6">
              <div className="landingStepNumberCircle">2</div>
              <h6 className="landingSubtitle__white">Choose Your Players</h6>
              <p className="landingText__white">
                Every week is a fresh start, and you pick 12 different players. Remember, there is no weekly obligation and you can play any week you want.
              </p>
            </Col>
          </Row>
          <Row className="pt-xl-4">
            <Col xs="12" md="6">
              <div className="landingStepNumberCircle">3</div>
              <h6 className="landingSubtitle__white">Follow the Action</h6>
              <p className="landingText__white">
                Follow the PGA Tour action as it unfolds right beside your league's leaderboard. See who has which players and who’s moving up the leaderboard.
              </p>
            </Col>
            <Col xs="12" md="6">
              <div className="landingStepNumberCircle">4</div>
              <h6 className="landingSubtitle__white">View the Winners</h6>
              <p className="landingText__white">
                A variable number of points is awarded to each team, based on how the team is doing relative to the winning team.
                &nbsp;<Link to="/rules">See all the rules here.</Link>
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  </div>
)

const CommishOffer = (props) => (
  <div className="landingSection3">
    <Container className="landingContainer text-center">
      <h6 className="landingSection_smalltitle" style={{color: "#ffffff"}}>Special Offer</h6>
      <h3 className="landingSection_ctatitle pb-2">First 50 teams free!</h3>
      <p className="landingText__white ml-auto mr-auto" style={{maxWidth: 400}}>
        Tee it up with Majors Challenge Fantasy Golf and we'll give you 50 team entry credits.
        After the first tournament, you'll understand why Majors Challenge is the
        preferred platform for league commissioners.
      </p>
      <p className="landingText__white" style={{fontWeight: 500}}>
        Be the commish... before one of your buddies is.
      </p>
      <Link to="/signup">
        <Button color="primary" outline className="landingSection_ctabutton">Create a Golf Pool</Button>
      </Link>
    </Container>
  </div>
)

const PeopleAreTalking = (props) => (
  <div className="landingSection1">
    <Container className="landingContainer text-center">
      <h6 className="landingSection_smalltitle">People are talking</h6>
      <Row className="justify-content-center" noGutters>
        <Col xs="12" md={{size: 1, offset: 0}}>
          <FontAwesome name="quote-left" className="landingJumboQuotes"/>
        </Col>
        <Col xs="12" md={{size: 6}}>
          <p className="landingQuote">
            I spent literally hours each week managing my league.
            Finally, a platform that takes the grunt work off my plate
            so I can simply enjoy participating again.
          </p>
        </Col>
        <Col xs="12" md={{size: 1}}>
          <FontAwesome name="quote-right" className="landingJumboQuotes"/>
        </Col>
      </Row>
      <Row className="justify-content-center pt-3">
        <span style={{fontFamily: "Roboto", color: "#90a4ae", lineHeight: "20px"}}>Peter Murray</span>
      </Row>
      <Row className="justify-content-center pb-5">
        <span style={{fontSize: "12px", fontFamily: "Roboto", color: "#90a4ae", letterSpacing: "0.3px", lineHeight: "20px"}}>Pittsburgh, PA</span>
      </Row>
    </Container>
  </div>
)

export class PromoEvent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }
  componentDidMount() {
    get_promo_content().then(({landing}) => {
      this.setState({landing});
    });
  }
  render() {
    if (!this.state || !this.state.landing) {
      return null;
    }
    return (
     <div className="landingSection4">
       <Container className="landingContainer text-center">
         <h3 className="landingSectionTitle__dark text-center">{this.state.landing.title}</h3>
         <Row className="pb-3">
           <Col xs="6" sm="7" md="6" lg="3" className="landingPromoEvent_cell">
             <div className="landingPromoEvent_celldata">{this.state.landing.event}</div>
             <div className="landingPromoEvent_celllabel">Promo event</div>
           </Col>
           <Col xs="6" sm="5" md="6" lg="2" className="landingPromoEvent_cell">
             <div className="landingPromoEvent_celldata">{this.state.landing.date}</div>
             <div className="landingPromoEvent_celllabel">Date</div>
           </Col>
           <Col xs="6" sm="7" md="6" lg="3" className="landingPromoEvent_cell">
             <div className="landingPromoEvent_celldata">{this.state.landing.prize}</div>
             <div className="landingPromoEvent_celllabel">Top Prize</div>
           </Col>
           <Col xs="6" sm="5" md="6" lg="2" className="landingPromoEvent_cell">
             <div className="landingPromoEvent_celldata">{this.state.landing.winners}</div>
             <div className="landingPromoEvent_celllabel">Winners</div>
           </Col>
           <Col xs="12" lg="auto" className="landingPromoEvent_cell">
             <Link to="/signup">
               <Button color="success" className="landingPromoEvent_cta">Enter</Button>
             </Link>
           </Col>
         </Row>
         <Row className="pb-5">
           <Col className="landingPromoEvent_cell">
				<div>Pick a perfect team and win $250,000!</div>
				<div className="promoBold">Enter for FREE</div>
				<Link to="/250k">What's a Perfect Team?</Link>
		   </Col>
         </Row>
       </Container>
     </div>


    )
  }
}

export default Landing;
