import React from 'react';
import {
  Row, Col,
  Button, ButtonGroup,
  Container,
  Card,
  Input,
} from 'reactstrap';
import Select from 'react-select';

import { withRouter, Link } from 'react-router-dom';

import {stringIsFloat} from 'utils/constants.js';

import {
  create_league,
  next_events,
  } from 'utils/api';

import numeral from 'numeral';
import 'pages/user/CreateLeague.css';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'common/ConfirmAlert.css';

class CreateLeague extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      next: null,
      majors: null,
      step: 1,
      validationMsg: "",
      leagueName: "",
      majorsOnly: true,
      tournamentsType: "", // both, majorsOnly, nextEvent
      cup: false,
      variablePool: false,
      variableMultiplier: "1.00",
      multiplierOptions: ["0.10", "0.25", "0.50", "0.75", "1.00"],
      classicFee: 5,
      feeOptions: [5,10,25,50,100,200],
      teams: 10,
      showEstimator: false,
      poolType: 'classic', //variable, classic, seasonLong
      seasonLongFee: '',
      isSeasondraft: false,
      isOneanddone: false,
    };

    this.navigationClick = this.navigationClick.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleTournamentsRadio = this.handleTournamentsRadio.bind(this);
    this.handleCupRadio = this.handleCupRadio.bind(this);
    this.handleVariableRadio = this.handleVariableRadio.bind(this);
    this.handleMultiplierChange = this.handleMultiplierChange.bind(this);
    this.handleFeeChange = this.handleFeeChange.bind(this);
    this.handleTeams = this.handleTeams.bind(this);
    this.createLeagueNotAllowed = this.createLeagueNotAllowed.bind(this);
    this.toggleEstimator = this.toggleEstimator.bind(this);
  }

  createLeagueNotAllowed(){
    confirmAlert({
      title: 'Alert',
      message: 'Before creating a league please provide a valid email address.',
      buttons: [
        {
          label: 'OK',
          onClick: () => { this.props.history.push('/profile/settings'); }
        }
      ]
    });

  }
  render() {

    if (this.props.me.usr.useremail === ''){
      this.createLeagueNotAllowed();

      return (
        <Container fluid style={{backgroundColor:"rgba(236, 240, 241, 1)", minHeight: "100vh"}}>
        </Container>
      );
    }

    let bodyParams = {...this.state}
    bodyParams.navigationClick = this.navigationClick;
    bodyParams.handleNext = this.handleNext;
    bodyParams.handleChange = this.handleChange;
    bodyParams.handleTournamentsRadio = this.handleTournamentsRadio;
    bodyParams.handleCupRadio = this.handleCupRadio;
    bodyParams.handleVariableRadio = this.handleVariableRadio;
    bodyParams.handleMultiplierChange = this.handleMultiplierChange;
    bodyParams.handleFeeChange = this.handleFeeChange;
    bodyParams.handleTeams = this.handleTeams;
    bodyParams.toggleEstimator = this.toggleEstimator;
    bodyParams.handleOtherGameFormat = this.handleOtherGameFormat;

    return (
      <Container fluid style={{backgroundColor:"rgba(236, 240, 241, 1)", minHeight: "100vh"}}>
        <Container style={{paddingTop:"5.5rem", paddingBottom:"2rem"}}>
          <Card className="pb-4">
            <Title />
            <Body {...bodyParams} />
          </Card>
        </Container>
      </Container>
    );
  }

  componentDidMount() {
    next_events(
      ({success, next, majors, firstleague})=>{
        if (success) {
          this.setState({next, majors, firstleague});
        } else {
          console.log('CreateLeague call to api/next_events failed.')
        }
      },
      ()=>{
        console.log('CreateLeague call to api/next_events failed.')
      }
    );
  }

  navigationClick(step) {
    return () => {
      if (this.state.step !== step) {
        this.setState({step});
      }
    }
  }

  handleNext() {
    this.setState({validationMsg:''});
    if (this.state.step == 2){
      if ((this.state.tournamentsType === '' || this.state.tournamentsType === 'noWeekly') && !this.state.isOneanddone && !this.state.isSeasondraft){
        this.setState({validationMsg:'Please select at least one game type.'});
        return;
      }
      if (this.state.tournamentsType === '' && (this.state.isOneanddone || this.state.isSeasondraft)){
        this.setState({tournamentsType:'noWeekly'});

      }
    }
    let nextStep = this.state.step + 1;

    if (nextStep > 3) {
      create_league(
        this.state.leagueName,
        this.state.tournamentsType,
        this.state.cup,
        this.state.variablePool,
        this.state.variableMultiplier,
        this.state.classicFee,
        this.state.poolType,
        this.state.seasonLongFee,
        this.state.isOneanddone,
        this.state.isSeasondraft,
      ({success, error, step, league, me}) => {
        if (success) {
          this.props.updateMe(me);
          this.props.selectLeague(league);
          this.props.history.push("/commissioner/"+league+'/members');
        } else {
          this.setState({validationMsg: error, step:step});
          //alert(error);
        }
      }, (error) => {
        console.log(error);
        alert("Error occurred while trying to create the league.");
      });
    } else {
      this.setState({step: nextStep});
    }
  }

  toggleEstimator(event) {
    let showEstimator = event.target.name === "showEstimator";
    if (showEstimator === this.state.showEstimator) {
      return;
    }
    this.setState({showEstimator});
  }

  handleChange(event) {
    if (event.target.name  === 'seasonLongFee'){
      if (!stringIsFloat(event.target.value)) {
        return;
      }
    }
    this.setState({[event.target.name]: event.target.value});
  }

  /*handleTournamentsRadio(event) {
    this.setState({tournamentsType: event.target.id});
  }*/
  handleTournamentsRadio(value) {
    this.setState({tournamentsType: value});
  }

  handleCupRadio(event) {
    let value = event.target.id === "yes";
    if (this.state.cup === value) {
      return;
    }
    this.setState({cup: value});
  }

  handleVariableRadio(event) {
    if (event.target.id === "variablePool"){
      this.setState({variablePool: true, poolType:'variable', seasonLongFee:''});
    }else if (event.target.id === "classicPool"){
      this.setState({variablePool: false, poolType:'classic', seasonLongFee:'', classicFee:5});
    }else if (event.target.id === "seasonLongPool"){
      this.setState({variablePool: false, poolType:'seasonLong', seasonLongFee:'', classicFee:0});
    }

  }

  handleMultiplierChange(multiplier) {
    if (this.state.variableMultiplier === multiplier) {
      return;
    }
    this.setState({variableMultiplier: multiplier});
  }

  handleFeeChange(fee) {
    if (this.state.poolType === 'classic'){
      if (this.state.classicFee === fee) {
        return;
      }
      this.setState({classicFee: fee});
    } else if (this.state.poolType === 'seasonLong'){
      this.setState({seasonLongFee: fee});
    }
  }

  handleTeams(event) {
    let value = parseInt(event.target.value, 10);
    if (this.state.teams === value) {
      return;
    }
    this.setState({teams: value});
  }

  handleOtherGameFormat = (e)=>{
    if (e.target.value === 'oneanddone'){
      this.setState({isOneanddone:e.target.checked})
    }else if (e.target.value === 'seasondraft'){
      this.setState({isSeasondraft:e.target.checked})
    }
  }
}

const Title = (props) =>
  <h2 className="text-center p-2 pt-4" style={{color:"#212121"}}>Create New League</h2>

const Body = (props) => {
  let stepContent;
  switch (props.step) {
    default:
    case 1:
      stepContent = <LeagueName {...props} />;
      break;
    case 2:
      stepContent = <Tournaments {...props} />;
      break;
    case 3:
      stepContent = <PointPool {...props} />;
      break;
  }

  return (
    <div>
      <div className="p-3">
        <Col lg={{size:6, offset: 3}} md={{size:10, offset:1}}>
          <Steps step={props.step} navigationClick={props.navigationClick} />
          <Validations validationMsg={props.validationMsg}/>
          {stepContent}
          <Row className="pt-4">
            <Button className="nextButton" color="success" size="lg" onClick={props.handleNext} block> Next </Button>
          </Row>
          {props.step === 2 && <LearnMore />}
        </Col>
      </div>
    </div>
  )
}

const Steps = (props) =>
  <div className="d-flex justify-content-between text-center px-sm-1 px-md-2 px-lg-3 mb-4">
    <Step current={props.step} index={1} title="League Name" navigationClick={props.navigationClick}/>
    <Step current={props.step} index={2} title="Tournaments" navigationClick={props.navigationClick} next={props.next} majors={props.majors} />
    <Step current={props.step} index={3} title="Point Pool" navigationClick={props.navigationClick} />
  </div>

const Step = (props) =>
  <div className={ "step d-flex align-items-center flex-column" + (props.current === props.index ? " activeStep" : "") }  onClick={props.navigationClick(props.index)}>
    <div className="stepIndex">{props.index}</div>
    <div className="stepTitle pt-2">{props.title}</div>
  </div>

const Validations = (props) => {
  return ( props.validationMsg ?
  <div className={ "d-flex align-items-center flex-column mb-3 mt-n2" } >
    <div className="pt-2 text-danger" >{props.validationMsg}</div>
  </div>:'');
}

const LearnMore = (props) =>
  <Row className="pt-3 pb-1 justify-content-center">
    <div className="">
      <Link className="" to="/games" style={{fontSize:'0.9em'}}>Learn about our game formats </Link>
    </div>
  </Row>


const LeagueName = (props) => {
  return (
    <div>
      <Row className="contentTitle pb-2">
        League Name
      </Row>
      <Row>
        <Input placeholder="Enter league name" value={props.leagueName} name="leagueName" onChange={props.handleChange} maxLength="30"/>
      </Row>
      <Row className="pt-2 pb-2" style={{fontSize: ".85rem", color: "#78909c"}}>
        Your league will be private and hidden from the public.
      </Row>
      <Row className="contentTitle py-2">
        Pricing and Credits
      </Row>
      <Row style={{fontSize: ".9rem", color: "#78909c"}} className="pb-3">
        No payment is required to create a league.
      </Row>
      <Row style={{fontSize: ".9rem", color: "#78909c"}} className="pb-3">
        As league commissioner you are charged $1 for each team entered into a weekly tournament during a PGA major. Your members do not pay anything to play. Participating in non-major events and season long competitions are completely free. You can add funds to your league at any time. See the Commissioner Console for more information.
      </Row>

      {/*}<Row style={{fontSize: ".9rem", color: "#78909c"}} className="pb-3">
        You can add funds to your league at any time. See the Commissioner Console for more information.
  	</Row>*/}

      { props.firstleague &&
        <Row style={{fontSize: ".9rem", color: "#78909c"}} className="pb-3">
          <span className='font-weight-bold mr-2'>Signup Bonus:</span> $50 in free credits added to your first league!
        </Row>
      }
      { props.firstleague === false &&
        <Row style={{fontSize: ".9rem", color: "red"}} className="pb-0">
          <p><span className='font-weight-bold mr-2'>PRO TIP:</span> Use your existing golf pool instead of creating a new one. This way you keep your members and results and avoid additional charges.</p>
        </Row>
      }

	  {/*
	  <Row className="contentTitle py-2">
        Golf Club Pricing
      </Row>

       <Row style={{fontSize: ".9rem", color: "#78909c"}} className="pb-3">
        We offer exclusive pricing for country clubs and their members. Let us help you determine the best type of league for your membership. Please contact us for more information.
      </Row>

      <Row style={{fontSize: ".9rem", color: "#78909c"}} className="pb-3">
        Contact:&nbsp;<a href="clubs@majorschallenge.com">clubs@majorschallenge.com</a>
      </Row>
	*/}

    </div>
  )
}

const Tournaments = (props) => {
  let tournamentsOptions = [{value: 'both', label: 'All Majors + Next Event'},
    {value: 'majorsOnly', label: 'All Majors'},
    {value: 'nextEvent', label: 'Next Event'},
    {value: 'noWeekly', label: 'No Weekly Tournaments'},
  ];
  let selectedTournament = tournamentsOptions.filter((x)=>{return x.value === props.tournamentsType;});

  return(
  <div>
    <Row className="contentTitle">
      Weekly Tournaments
    </Row>
    <Row className="pb-2" style={{fontSize: ".8rem", color: "#78909c"}}>
      You can always opt in/out of specific tournaments via the Commissioner Console.
    </Row>
    <Row className="pt-2 pb-1">
      <Col>
        <Select className="no-border my-2" isClearable isSearchable options={tournamentsOptions}
        value={selectedTournament}
        placeholder = {"Choose Tournaments"}
        onChange={(selectedOption) => {
            console.log(selectedOption);
            if (selectedOption){
              props.handleTournamentsRadio(selectedOption.value);
            }else{
              props.handleTournamentsRadio('');
            }
          }
        } />
      </Col>
    </Row>
    <Row className="contentTitle mt-3">
      Other Game Formats
    </Row>
    <Row className="pb-2" style={{fontSize: ".8rem", color: "#78909c"}}>
      Add a season long game to your league. These contests are independent of weekly tournaments.
    </Row>
    <Row className="pt-2 pb-1 ml-2">
      <Col>
        <div classname="d-flex">
          <div className="mb-2">
            <Input type="checkbox" value="oneanddone" checked={props.isOneanddone} onChange={props.handleOtherGameFormat}>One-And-Done</Input>
            <span>One-And-Done Fantasy</span>
          </div>
          <div>
            <Input type="checkbox" value="seasondraft" checked={props.isSeasondraft} onChange={props.handleOtherGameFormat}></Input>
            <span>Season Long Draft</span>
          </div>
        </div>
      </Col>
    </Row>
    {/*
    <Row className="pt-2 pb-1">
      <Col>
        <Input type="radio" name="tournaments" id="both" className="mr-3"
          value={'both'} checked={props.tournamentsType==='both'} onClick={props.handleTournamentsRadio} readOnly />
        <span style={{color:"#555"}}> All Majors + Next Event </span>
      </Col>
    </Row>
    <Row className="pt-2 pb-1">
      <Col>
        <Input type="radio" name="tournaments" id="majorsOnly" className="mr-3"
          value={'majorsOnly'} checked={props.tournamentsType==='majorsOnly'} onClick={props.handleTournamentsRadio} readOnly />
        <span style={{color:"#555"}}> All Majors </span>
        <div style={{fontSize:".85em", color:"#78909c"}}>
          {props.majors === null ? "Enter the remaining Majors Tournaments of the current season." :
            props.majors.map((event, index)=>{
              return (index === 0 ? "" : " \u2022 ") + event.name;
            })
          }
        </div>
      </Col>
    </Row>
    <Row className="pt-2 pb-1">
      <Col>
        <Input type="radio" name="tournaments" id="nextEvent" className="mr-3"
          value={'nextEvent'} checked={props.tournamentsType==='nextEvent'} onClick={props.handleTournamentsRadio} readOnly />
        <span style={{color:"#555"}}> Next Event </span>
        <div style={{fontSize:".85em", color:"#78909c"}}>
          {props.next === null ? "Only enter the next upcoming tournament for now, and use the commissioner console to add others later." :
            props.next.name + " \u2022 " + props.next.dates
          }
        </div>
      </Col>
    </Row>
    {/* <hr />
    <Row className="contentTitle">
      Enable Cup Participation
    </Row>
    <Row className="pb-2" style={{fontSize: ".8rem", color: "#78909c"}}>
      Conduct multi-tournament, season-long leaderboard. Member participation is optional.
    </Row>
    <Row className="pt-2" noGutters>
      <Col>
        <Input type="radio" name="cup" id="yes" className="mr-3" value={!!props.cup} checked={!!props.cup} onClick={props.handleCupRadio} />
        <span style={{color:"#555"}}> Yes </span>
      </Col>
    </Row>
    <Row noGutters>
      <Col>
        <Input type="radio" name="cup" id="no" className="mr-3" value={!props.cup} checked={!props.cup} onClick={props.handleCupRadio} />
        <span style={{color:"#555"}}> No </span>
      </Col>
    </Row> */}
  </div>);
}

const PointPool = (props) =>
  <div>
    <Row className="contentTitle">
      Weekly Point Pool
    </Row>
    <Row className="pb-2" style={{fontSize: ".8rem", color: "#78909c"}}>
      How do you want points to be tallied and awarded into a winning purse?
    </Row>
  {(props.tournamentsType === '' || props.tournamentsType == 'noWeekly') &&
    <Row className="pt-2 pb-1">
      <Col>
        <span style={{color:"#555"}}> You have chosen not to play weekly tournaments. No point pool setup required.</span>
      </Col>
    </Row>
  }
  {(props.tournamentsType !== '' && props.tournamentsType !== 'noWeekly') &&
    <>
    <Row className="pt-2 pb-1">
      <Col>
        <Input type="radio" name="pointpool" id="classicPool" className="mr-3"
          value={props.poolType} checked={props.poolType==='classic'} onClick={props.handleVariableRadio} />
        <span style={{color:"#555"}}> Fixed Entry Amount</span>
        <div style={{fontSize:".85em", color:"#78909c"}}>
          Each entry owes the same amount towards the tournament point pool.
        </div>
      </Col>
    </Row>
    { props.poolType==='classic' &&
      <div>
        <Row>
          <ButtonGroup className="pt-2 pb-2 pl-3">
            {props.feeOptions.map((item, index) => (
              <Button className="poolOption" onClick={() => props.handleFeeChange(item)} active={props.classicFee === item} key={index}>
                {item}
              </Button>
              ))}
          </ButtonGroup>
        </Row>
        {/*props.showEstimator ?
          <>
            <Row>
              <Card body>
                <ClassicEstimator {...props} />
                <Row className="mt-3" style={{fontSize:".85rem", color:"#78909c"}}>
                  <Col>
                    This is an example payout structure. You are able to customize all aspects of your league in the Commissioner Console.
                  </Col>
                </Row>
              </Card>
            </Row>
            <Row>
              <output style={{color: "#157EFB", fontSize: ".85em", cursor: "pointer"}} name="hideEstimator" onClick={props.toggleEstimator}>
                Hide the example payout structure
              </output>
            </Row>
          </>:
          <Row>
            <output style={{color: "#157EFB", fontSize: ".85em", cursor: "pointer"}} name="showEstimator" onClick={props.toggleEstimator}>
              View an example payout structure
            </output>
          </Row>
        */}
      </div>
    }
    <Row className="pt-2 pb-1">
      <Col>
        <Input type="radio" name="pointpool" id="variablePool" className="mr-3"
          value={props.poolType} checked={props.poolType==='variable'} onClick={props.handleVariableRadio} />
        <span style={{color:"#555"}}> Stroke Play</span>
        <div style={{fontSize:".85em", color:"#78909c"}}>
          Stroke play is a variable format similar to stroke play golf. If a team is 10 strokes off the lead then they owe 10 into the purse. Just like golf you don't know your loss until the tournament ends. This format can produce very large purses.
        </div>
        {props.poolType==='variable' &&
    		<div style={{padding:"10px",fontSize:".85em", color:"#78909c"}}>
    		  How much per stroke would you like to play for?
    		</div>
        }
      </Col>
    </Row>
    {props.poolType==='variable' &&
      <div>
        <Row>
          <ButtonGroup className="pt-2 pb-2 pl-3">
            {props.multiplierOptions.map((item, index) => (
              <Button className="poolOption" onClick={() => props.handleMultiplierChange(item)} active={props.variableMultiplier === item} key={index}>
                {item}
              </Button>
              ))}
          </ButtonGroup>
        </Row>
        {props.showEstimator ?
          <>
            <Row>
              <Card body>
                <VariableEstimator {...props} />
                <Row className="mt-3" style={{fontSize:".85rem", color:"#78909c"}}>
                  <Col>
                    This is an example payout structure. You are able to customize all aspects of your league in the Commissioner Console.
                  </Col>
                </Row>
              </Card>
            </Row>
            <Row>
              <output style={{color: "#157EFB", fontSize: ".85em", cursor: "pointer"}} name="hideEstimator" onClick={props.toggleEstimator}>
                Hide the example payout structure
              </output>
            </Row>
          </>:
          <Row className="pl-2">
            <output style={{color: "#157EFB", fontSize: ".85em", cursor: "pointer"}} name="showEstimator" onClick={props.toggleEstimator}>
              View an example payout structure
            </output>
          </Row>
        }
      </div>
    }
    <Row className="pt-2 pb-1">
      <Col>
        <Input type="radio" name="pointpool" id="seasonLongPool" className="mr-3"
          value={props.poolType} checked={props.poolType==='seasonLong'} onClick={props.handleVariableRadio} />
        <span style={{color:"#555"}}> One-Time Season Long Payment</span>
        <div style={{fontSize:".85em", color:"#78909c"}}>
          Collect once for entry into tournaments all season long. You'll need to determine your total collections and manage fixed payout amounts when choosing this option.
        </div>
      </Col>
    </Row>
    { props.poolType==='seasonLong' &&
      <div>
        <Row>
          <Input type="number" value={props.seasonLongFee} name="seasonLongFee" className="ml-2"
           onChange={props.handleChange} placeholder="e.g. 10"/>


        </Row>
        {/*props.showEstimator ?
          <>
            <Row>
              <Card body>
                <ClassicEstimator {...props} />
                <Row className="mt-3" style={{fontSize:".85rem", color:"#78909c"}}>
                  <Col>
                    This is an example payout structure. You are able to customize all aspects of your league in the Commissioner Console.
                  </Col>
                </Row>
              </Card>
            </Row>
            <Row>
              <output style={{color: "#157EFB", fontSize: ".85em", cursor: "pointer"}} name="hideEstimator" onClick={props.toggleEstimator}>
                Hide the example payout structure
              </output>
            </Row>
          </>:
          <Row>
            <output style={{color: "#157EFB", fontSize: ".85em", cursor: "pointer"}} name="showEstimator" onClick={props.toggleEstimator}>
              View an example payout structure
            </output>
          </Row>
        */}
      </div>
    }
    </>
  }
  </div>

const VariableEstimator = (props) => {
  let points = props.teams * props.variableMultiplier;

  if (props.teams < 20) {
    points *= 15;
  } else if (props.teams < 50) {
    points *= 30;
  } else {
    points *= 60;
  }

  let pool = numeral(points).format('0,0[.]00');
  let loss = numeral(points/props.teams).format('0,0[.]00')
  let win1 = numeral(65 * points / 100).format('0,0[.]00');
  let win2 = numeral(25 * points / 100).format('0,0[.]00');
  let win3 = numeral(10 * points / 100).format('0,0[.]00');

  return (
    <div style={{color:"#555"}}>
      <Row className="pb-3" style={{fontSize: "1rem", fontWeight: "500"}}>
        <Col> Purse Estimator: </Col>
      </Row>
      <TeamCount {...props} />
      <Row noGutters>
        <Col> Estimated purse </Col>
        <Col> {pool} </Col>
      </Row>
      <Row noGutters>
        <Col> Average loss <span style={{color:"#78909c"}}>(per team)</span> </Col>
        <Col> {loss} </Col>
      </Row>
      <Row noGutters className="pt-3">
        <Col> {'1st Place'} </Col>
        <Col> {win1} <span style={{color:"#78909c"}}>(65%)</span> </Col>
      </Row>
      <Row noGutters>
        <Col> {'2nd Place'} </Col>
        <Col> {win2} <span style={{color:"#78909c"}}>(25%)</span> </Col>
      </Row>
      <Row noGutters>
        <Col> {'3rd Place'} </Col>
        <Col> {win3} <span style={{color:"#78909c"}}>(10%)</span> </Col>
      </Row>
    </div>
  )
}

/*
const ClassicEstimator = (props) => {
  let points = props.teams * props.classicFee;
  let pool = numeral(points).format('0,0[.]00');
  let win1 = numeral(65 * points / 100).format('0,0[.]00');
  let win2 = numeral(25 * points / 100).format('0,0[.]00');
  let win3 = numeral(10 * points / 100).format('0,0[.]00');

  return (
    <div style={{color:"#555"}}>
      <Row className="pb-3" style={{fontSize: "1rem", fontWeight: "500"}}>
        <Col> Payout Structure </Col>
      </Row>
      <TeamCount {...props} />
      <Row noGutters className="pt-3">
        <Col> Point pool </Col>
        <Col> {pool} </Col>
      </Row>
      <Row noGutters className="pt-3">
        <Col> {'1st Place'} </Col>
        <Col> {win1} <span style={{color:"#78909c"}}>(65%)</span> </Col>
      </Row>
      <Row noGutters>
        <Col> {'2nd Place'} </Col>
        <Col> {win2} <span style={{color:"#78909c"}}>(25%)</span> </Col>
      </Row>
      <Row noGutters>
        <Col> {'3rd Place'} </Col>
        <Col> {win3} <span style={{color:"#78909c"}}>(10%)</span> </Col>
      </Row>
    </div>
  )
}*/
const TeamCount = (props) =>
  <Row style={{color:"#78909c"}}>
    <Col className="d-flex">
      <span style={{lineHeight:"2rem"}}> How many teams do you think you will have: </span>
      <Input type="select" value={props.teams} name="entryCount" id="exampleEntryCount" className="ml-1 mr-1" style={{width:"5em", color:"#78909c", height:"2em"}} onChange={props.handleTeams}>
        <option>4</option>
        <option>6</option>
        <option>8</option>
        <option>10</option>
        <option>15</option>
        <option>20</option>
        <option>30</option>
        <option>40</option>
        <option>50</option>
        <option>75</option>
        <option>100</option>
        <option>150</option>
        <option>200</option>
        <option>300</option>
        <option>400</option>
        <option>500</option>
        <option>600</option>
      </Input>
    </Col>
  </Row>

export default withRouter(CreateLeague);
