import React from 'react';

import {
  Button,
  Col,
  Container,
  Row,
} from 'reactstrap';

import {
  Link,
  withRouter
} from 'react-router-dom';

import FontAwesome from 'react-fontawesome';

import {OnboardingWrapper} from 'common/LandingWrapper.js';
import LoginModal from 'common/LoginModal';
import FacebookAuth from 'common/FacebookAuth.js';
import GoogleAuth from 'common/GoogleAuthNew.js';

import { check_league, login, add_self_member} from 'utils/api';

import 'pages/user/JoinLeague.css';
import 'pages/user/Signup.css';

class UnauthedJoinLeague extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      leagueslug: null,
      leagueid: null,
      leagueName: "",
      leagueType: "",
      commissioner: "",
      commissionerId: "",
      email: '',
      validLeague: null,
      status: null,
      error: null,
      leaguePwd: "",
      password: '',
      firstname: '',
      lastname: '',
      showSignUp: false,
      showNewAccountCreated: false,
    };

    this.toggleShowSignUp = this.toggleShowSignUp.bind(this);

    this.checkLeague = this.checkLeague.bind(this);
    this.handleLeagueAuthSubmit = this.handleLeagueAuthSubmit.bind(this);

    this.handleChange = this.handleChange.bind(this);

    this.renderEmailForm = this.renderEmailForm.bind(this);
    this.renderLeagueAuthForm = this.renderLeagueAuthForm.bind(this);
    this.renderSignupForm = this.renderSignupForm.bind(this);
    this.renderLoginForm = this.renderLoginForm.bind(this);

    this.handleLoginSubmit = this.handleLoginSubmit.bind(this);
    this.handleSignupSubmit = this.handleSignupSubmit.bind(this);

    this.onSuccessRedirect = this.onSuccessRedirect.bind(this);
    this.onFacebookError = this.onFacebookError.bind(this);
    this.onGoogleError = this.onGoogleError.bind(this);
  }

  componentDidMount() {
    //this.props.setPostLoginURL( this.props.postLoginURL );
    if (this.props.match.params.leagueslug){
      this.checkLeague();
    }
  }

  toggleShowSignUp(event){
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.setState({showSignUp: !this.state.showSignUp,
        email: '',
        status: null,
        error: null,
        password: '',
        firstname: '',
        lastname: '',
      });

  }

  checkLeague() {
    let leagueslug = this.props.match.params.leagueslug;
    if (!leagueslug){
      leagueslug = this.state.leagueslug;
    }
    check_league(
      leagueslug,
      null,
    ({success, reason, leagueName, leagueType, commissioner, leagueid, commissionerId}) => {
      if (!success && this.props.match.params.leagueslug && reason === 'Invalid League ID'){
        this.props.history.push("/");
        return;
      }
      this.setState({
        validLeague: success,
        error: reason,
        leagueName,
        leagueType,
        commissioner,
        leagueid,
        commissionerId,
        showSignUp:(leagueid!==null?true:false),
      })
    }, (error) => {
      console.log(error);
      this.props.history.push("/");
    });
  }

  handleLeagueAuthSubmit(event){
    event.preventDefault();
    event.stopPropagation();
    let leagueslug = this.props.match.params.leagueslug;
    if (!leagueslug){
      leagueslug = this.state.leagueslug;
    }
    this.setState({status: false});
    check_league(
      leagueslug,
      this.state.leaguePwd,
    ({success, reason, leagueName, leagueType, commissioner, leagueid, commissionerId}) => {
      this.setState({
        error: reason,
        leagueName,
        leagueType,
        commissioner,
        leagueid,
        commissionerId,
        status: null,
        showSignUp:(leagueid!==null?true:false),
      })
    }, (error) => {
      console.log(error);
      this.props.history.push("/");
    });
  }


  handleLoginSubmit(event){
    event.preventDefault();
    event.stopPropagation();

    this.setState({status: false});

    login(this.state.email, this.state.password,
      (response) => {
        if (response.data.success) {
          //this.props.history.push('/');
          //this.props.loggedInCallback(response.data.me, this.props.history);
          this.handleSignupSubmit();
        } else {
          this.setState({status:null, error: "There was a problem logging you in."});
        }
      }, (error) => {
        this.setState({status:null, error: "Invalid email or password."});
    })
  }

  handleSignupSubmit(event){
    if (event){
      event.preventDefault();
      event.stopPropagation();
    }
    this.setState({status: false});

    add_self_member(this.state.leagueid, this.state.commissionerId, this.state.firstname, this.state.lastname, this.state.email, this.state.password,
      ({success, reason, me}) => {

        try{
          if (success){
            if (me){
              this.props.history.push('/league/'+this.state.leagueid);
              this.props.loggedInCallback(me, this.props.history);
              this.props.history.push('/league/'+this.state.leagueid);
            }else{
              this.setState({showNewAccountCreated:true});
            }
          }
          this.setState({
            error: reason,
            status: null,
          });
        }catch(e){
          console.log(e);
        }

      }, (error) => {

      }
    )
  }

  handleChange(event) {
    this.setState({[event.target.name]: event.target.value});
  }

  onSuccessRedirect(me) {
    this.handleSignupSubmit();
  }

  onFacebookError() {
    this.setState({error: "There was a problem logging you in with Facebook."});
  }
  onGoogleError() {
    this.setState({error: "There was a problem logging you in with Google."});
  }

  renderSignupForm() {
    return (
      <div>
        <form onSubmit={this.handleSignupSubmit}>
          <Row noGutters className="justify-content-center">
            <Col xs="10" sm="9" md="9" lg="9" xl="8">
              <label className="joinContent_signuplabel">
                <input type="text" name='email' value={this.state.email} className="joinContent_signupinput" onChange={this.handleChange}  placeholder="Email"/>
              </label>
            </Col>
          </Row>
          <Row noGutters className="justify-content-center">
            <Col xs="10" sm="9" md="9" lg="9" xl="8">
              <label className="joinContent_signuplabel">
                <input type="text" name='firstname' value={this.state.firstname} maxlength="30" className="joinContent_signupinput" onChange={this.handleChange}  placeholder="First Name"/>
              </label>
            </Col>
          </Row>
          <Row noGutters className="justify-content-center">
            <Col xs="10" sm="9" md="9" lg="9" xl="8">
              <label className="joinContent_signuplabel">
                <input type="text" name='lastname' value={this.state.lastname} maxlength="30" className="joinContent_signupinput" onChange={this.handleChange}  placeholder="Last Name"/>
              </label>
            </Col>
          </Row>
          <Row noGutters className="justify-content-center">
            <Col xs="10" sm="9" md="9" lg="9" xl="8">
              <label className="joinContent_signuplabel">
                <input type="password" name='password' value={this.state.password} className="joinContent_signupinput" onChange={this.handleChange}  placeholder="Password"/>
              </label>
            </Col>
          </Row>
          <input type="submit" value="Signup" hidden />
        </form>
        <Row noGutters className="justify-content-center pt-4">
          <Col xs="10" sm="9" md="9" lg="9" xl="8">
            <p>
              By signing up, you agree to the Majors Challenge&nbsp;
              <Link to="/terms" target="_blank">Terms and Conditions</Link>
              {' and '}
              <Link to="/privacy" target="_blank">Privacy Policy</Link>.
            </p>
          </Col>
        </Row>
        <Row className="justify-content-center text-center p-2">
          <Col xs="10" sm="9" md="8" lg="6" xl="5">
            {
              this.state.error && <div style={{color: "red", textAlign: "center", paddingBottom: "1rem"}}>{this.state.error}</div>
            }
            {
              this.state.status === null ?
                <Button color="success" onClick={this.handleSignupSubmit} block>Signup</Button>
              :
              <Button color="success" disabled block>
                <FontAwesome name="spinner" spin />
              </Button>
            }
          </Col>
        </Row>
        <Row className="justify-content-center text-center p-1">
          <Col xs="10" sm="9" md="9" lg="9" xl="8">
            <span>Already a Member? </span>
            <span style={{whiteSpace: "nowrap", fontSize: "0.95rem", paddingLeft:'8px', color:"#007bff", cursor:'pointer'}}>
              <span onClick={(event) => {this.toggleShowSignUp(event)}}>Sign In</span>
            </span>
          </Col>
        </Row>
      </div>
    )
  }

  renderLoginForm() {
    return (
      <div>
        <form onSubmit={this.handleLoginSubmit}>
          <Row noGutters className="justify-content-center">
            <Col xs="10" sm="9" md="9" lg="9" xl="8">
              <label className="joinContent_signuplabel">
                <input type="text" name="email" value={this.state.email}
                  className="joinContent_signupinput" onChange={this.handleChange}
                  placeholder="User Name"/>
              </label>
            </Col>
          </Row>
          <Row noGutters className="justify-content-center">
            <Col xs="10" sm="9" md="9" lg="9" xl="8">
              <label className="joinContent_signuplabel">
                <input type="password" name="password" value={this.state.password}
                  className="joinContent_signupinput" onChange={this.handleChange}
                  placeholder="Password"/>
              </label>
            </Col>
          </Row>
          <input type="submit" value="Signup" hidden />
        </form>
        <Row className="justify-content-center text-right p-4">
          <Col xs="10" sm="9" md="8" lg="6" xl="5">
            {
              this.state.error &&
                <div style={{color: "red", textAlign: "center", paddingBottom: "1rem"}}>{this.state.error}</div>
            }
            {
              this.state.status === null ?
                <div justify-content-center>
                  <div>
                    <Button color="success" onClick={this.handleLoginSubmit} block> Login </Button>
                  </div>
                </div>
              :
              <Button color="success" disabled block>
                <FontAwesome name="spinner" spin />
              </Button>
            }
          </Col>
        </Row>
        <Row className="justify-content-center text-center p-1">
          <Col xs="10" sm="9" md="9" lg="9" xl="8">
            <span>New to Majors Challenge? </span>
            <span style={{whiteSpace: "nowrap", fontSize: "0.95rem", paddingLeft:'5px', color:"#007bff", cursor:'pointer'}}>
              <span onClick={(event) => {this.toggleShowSignUp(event)}}>Sign Up</span>
            </span>
          </Col>
        </Row>
      </div>
    )
  }

  renderEmailForm() {
    return (
      <div>
        <div className="justify-content-center pb-3 row d-none d-md-flex ">
          <div className="">
              <FacebookAuth onSuccessRedirect={this.onSuccessRedirect} onError={this.onFacebookError}/>
          </div>
          <div className="ml-3">
              <GoogleAuth fakeProp={true} onSuccessRedirect={this.onSuccessRedirect} onError={this.onGoogleError} />
          </div>
        </div>
        <div className="justify-content-center pb-3 row d-flex d-md-none ">
          <div className="">
              <FacebookAuth onSuccessRedirect={this.onSuccessRedirect} onError={this.onFacebookError}/>
          </div>
        </div>
        <div className="justify-content-center pb-3 row d-flex d-md-none ">
          <div className="">
              <GoogleAuth fakeProp={true} onSuccessRedirect={this.onSuccessRedirect} onError={this.onGoogleError} />
          </div>
        </div>
        <div className="justify-content-center pb-3 row">
          <div className="" style={{borderBottom: "2px inset darkgray",width: "100px",marginBottom: "12px"}}></div>
          <div className="mx-3" style={{textAlign: "center", color: "#888"}}>
            {"OR"}
          </div>
          <div className="" style={{borderBottom: "2px inset darkgray",width: "100px",marginBottom: "12px"}}></div>
        </div>
        {this.state.showSignUp? this.renderSignupForm():this.renderLoginForm()}
        <Row noGutters className="justify-content-center">
          <Col xs="10" sm="9" md="9" lg="9" xl="8">
            <div className="mt-2 text-center">
              Commissioner:
              <a href={"mailto:"+this.state.commissioner}>
                <span style={{whiteSpace: "nowrap", fontSize: "0.85rem", paddingLeft:'10px'}}>
                  <FontAwesome name="envelope" className="mr-2" />
                  <span>{this.state.commissioner}</span>
                </span>
              </a>
            </div>
          </Col>
        </Row>
      </div>
    )
  }

  renderNewAccountCreated(){
    return (
      <div>
        <h6 style={{fontWeight: 400}} className="p-3">
          Your account is almost setup! Just click the link we sent to your email:
        </h6>
        <p>{this.state.email}</p>
      </div>
    )
  }

  renderLeagueAuthForm(){
    return (
      <div>
        <form onSubmit={this.handleLeagueAuthSubmit}>
          {!this.props.match.params.leagueslug &&
          <Row noGutters className="justify-content-center">
            <Col xs="10" sm="9" md="9" lg="9" xl="8">
              <label className="joinContent_signuplabel">
                <input type="text" name="leagueslug" value={this.state.leagueslug}
                  className="joinContent_signupinput" onChange={this.handleChange}
                  placeholder="League ID"/>
              </label>
            </Col>
          </Row>
          }
          <Row noGutters className="justify-content-center">
            <Col xs="10" sm="9" md="9" lg="9" xl="8">
              <label className="joinContent_signuplabel">
                <input type="password" name="leaguePwd" value={this.state.leaguePwd}
                  className="joinContent_signupinput" onChange={this.handleChange}
                  placeholder="Password"/>
              </label>
            </Col>
          </Row>
          <input type="submit" value="Let Me In" style={{border:'0',padding:'0', fontSize:'0'}} />
        </form>
        <Row className="justify-content-center text-right p-4">
          <Col xs="10" sm="9" md="8" lg="6" xl="5">
            {
              this.state.error &&
                <div style={{color: "red", textAlign: "center", paddingBottom: "1rem"}}>{this.state.error}</div>
            }
            {
              this.state.status === null ?
                <div justify-content-center>
                  <div>
                    <Button color="success" onClick={this.handleLeagueAuthSubmit} block> Let Me In </Button>
                  </div>
                </div>
              :
              <Button color="success" disabled block>
                <FontAwesome name="spinner" spin />
              </Button>
            }
          </Col>
        </Row>
        {!this.props.match.params.leagueslug &&
          <Row noGutters className="justify-content-center">
            <Col xs="10" sm="9" md="9" lg="9" xl="8">
              <div className="mt-3 text-center" style={{fontSize:'.8rem'}}>
                Ask the Commissioner for these credentials or request an email invite
              </div>
            </Col>
          </Row>
        }
        {this.state.commissioner &&
          <Row noGutters className="justify-content-center">
            <Col xs="10" sm="9" md="9" lg="9" xl="8">
              <div className="mt-3 text-center">
                Commissioner:
                <a href={"mailto:"+this.state.commissioner}>
                  <span style={{whiteSpace: "nowrap", fontSize: "0.85rem", paddingLeft:'10px'}}>
                    <FontAwesome name="envelope" className="mr-2" />
                    <span>{this.state.commissioner}</span>
                  </span>
                </a>
              </div>
            </Col>
          </Row>
        }
        {!this.props.match.params.leagueslug &&
        <Row noGutters className="justify-content-center">
          <Col xs="10" sm="9" md="9" lg="9" xl="8">
            <div className="mt-3 text-center">
              Already in a league? <LoginModal loginLink disallowSignup loginToHome {...this.props} />
            </div>
          </Col>
        </Row>
        }
        {this.props.match.params.leagueslug &&
        <Row noGutters className="justify-content-center">
          <Col xs="10" sm="9" md="9" lg="9" xl="8">
            <div className="mt-3 text-center">
              Already in a league? <LoginModal loginLink disallowSignup {...this.props} />
            </div>
          </Col>
        </Row>
        }
      </div>
    )
  }



  render() {
    if (this.state.validLeague === null && this.props.match.params.leagueslug) {
      return null;
    }

    return (
      <OnboardingWrapper {...this.props}>
        <Container fluid className="joinContainer">
          <Row noGutters>
            <Col xs="12" sm="10" md="9" lg="8" xl="6" className="joinContent mx-auto">
              {this.props.match.params.leagueslug &&
                <div className="joinContent_title">Welcome to the league</div>
              }
              {!this.props.match.params.leagueslug &&
                <div className="joinContent_title">Join An Existing Golf Pool</div>
              }
              <h2 className="joinContent_leaguename">{this.state.leagueName}</h2>
              { this.state.showNewAccountCreated ? this.renderNewAccountCreated() :
                ( this.state.leagueid === null ? this.renderLeagueAuthForm(): this.renderEmailForm() )
              }
            </Col>
          </Row>
        </Container>
      </OnboardingWrapper>
    );
  }
}

export default withRouter(UnauthedJoinLeague);
