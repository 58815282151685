import React from 'react';

import {
  Container,
  Button,
} from 'reactstrap';

import { LandingHeader, MinimalLandingHeader, ClubLandingHeader, DeepURLLandingHeader } from 'common/LandingHeader.js';
import LandingFooter from 'common/LandingFooter.js';

import './LandingWrapper.css';

const LandingWrapper = ({children, ...props}) => (
  <div className="mcPubContainer">
    <LandingHeader {...props} />
    <Container style={{paddingTop:"2rem", paddingBottom:"2rem", minHeight: "calc(100vh - 120px)"}}>
      {children}
    </Container>
    <LandingFooter {...props}/>
  </div>
)

const ClubHeader = () =>
  <div>
    <div className="d-none d-lg-inline-block text-center" style={{width: "100%",  height: "70px",  backgroundColor: "#4e6bf5", zIndex: 10, position:"relative", fontFamily: "Roboto"}}>
      <span className="d-inline-block" style={{color:"#ffffff", lineHeight: "70px", fontWeight: "700"}}>Majors Challenge for Golf Clubs. &nbsp;</span>
      <span className="d-inline-block" style={{color:"#d4d9ee", fontWeight:"500", lineHeight: "70px"}}>Are you looking to build an audience and engagement at your club?</span>
      <div className="align-top d-inline-block ml-5" style={{height: "70px", marginTop:"15px"}}>
        <a href="/clubs">
          <Button color="secondary" outline className="clubHeader_ctabutton">Learn More</Button>
        </a>
      </div>
    </div>
    <div className="d-none d-md-inline-block d-lg-none text-center" style={{width: "100%",  height: "70px",  backgroundColor: "#4e6bf5", zIndex: 10, position:"relative", fontFamily: "Roboto"}}>
      <span className="d-inline-block" style={{color:"#ffffff", lineHeight: "70px", fontWeight: "500"}}>Majors Challenge for Golf Clubs. &nbsp;</span>
      <div className="align-top d-inline-block ml-5" style={{height: "70px", marginTop:"15px"}}>
        <a href="/clubs">
          <Button color="secondary" outline className="clubHeader_ctabutton">Learn More</Button>
        </a>
      </div>
    </div>
    <div className="d-block d-md-none text-center" style={{width: "100%",  height: "140px",  backgroundColor: "#4e6bf5", zIndex: 10, position:"relative", fontFamily: "Roboto"}}>
      <div className="d-block py-3" style={{color:"#d4d9ee", fontWeight:"500"}}>Introducing:<br/>Majors Challenge for Golf Clubs.</div>
      <div className="d-block" style={{height: "70px"}}>
        <a href="/clubs">
          <Button color="secondary" outline className="clubHeader_ctabutton">Learn More</Button>
        </a>
      </div>
    </div>
  </div>

const NotClubHeader = () =>
  <div>
    <div className="d-none d-lg-inline-block text-center" style={{width: "100%",  height: "70px",  backgroundColor: "#4e6bf5", zIndex: 10, position:"relative", fontFamily: "Roboto"}}>
      <span className="d-inline-block" style={{color:"#d4d9ee", fontWeight:"500", lineHeight: "70px"}}>Just looking for a better way to manage your personal golf pool?</span>
      <div className="align-top d-inline-block ml-5" style={{height: "70px", marginTop:"13px"}}>
        <a href="/">
          <Button color="secondary" outline className="clubHeader_ctabutton">Visit Our Main Site</Button>
        </a>
      </div>
    </div>
    <div className="d-none d-md-inline-block d-lg-none text-center" style={{width: "100%",  height: "70px",  backgroundColor: "#4e6bf5", zIndex: 10, position:"relative", fontFamily: "Roboto"}}>
      <span className="d-inline-block" style={{color:"#d4d9ee", fontWeight:"500", lineHeight: "70px"}}>Manage your personal golf pools. &nbsp;</span>
      <div className="align-top d-inline-block ml-5" style={{height: "70px", marginTop:"15"}}>
        <a href="/">
          <Button color="secondary" outline className="clubHeader_ctabutton">Visit Our Main Site</Button>
        </a>
      </div>
    </div>
    <div className="d-block d-md-none text-center" style={{width: "100%",  height: "140px",  backgroundColor: "#4e6bf5", zIndex: 10, position:"relative", fontFamily: "Roboto"}}>
      <div className="d-block py-3" style={{color:"#d4d9ee", fontWeight:"500"}}>Not a club?<br/>Manage your personal golf pools. &nbsp;</div>
      <div className="d-block" style={{height: "70px"}}>
        <a href="/">
          <Button color="secondary" outline className="clubHeader_ctabutton">Visit Our Main Site</Button>
        </a>
      </div>
    </div>
  </div>

export default LandingWrapper;

export const MainLandingWrapper = ({children, ...props}) => (
  <div className="mcPubContainer">
    <LandingHeader headerTransparent {...props} />

    <div style={{paddingTop:"2rem", minHeight: "calc(100vh - 120px)", width: "100vw"}}>
      {children}
      <ClubHeader />
    </div>
    <LandingFooter {...props}/>
  </div>
)

export const OnboardingWrapper = ({children, ...props}) => (
  <div className="mcPubContainer">
    <MinimalLandingHeader {...props} />
    <div className="onboardingBg"/>
    <div className="pt-0 pt-md-4"style={{minHeight: "calc(100vh - 60px)", width: "100vw"}}>
      {children}
    </div>
  </div>
)

export const ClubLandingWrapper = ({children, ...props}) => (
  <div className="mcPubContainer">
    <ClubLandingHeader />
    <div style={{paddingTop:"2rem", minHeight: "calc(100vh - 120px)", width: "100vw"}}>
      {children}
      <NotClubHeader />
    </div>
    <LandingFooter/>
  </div>
)

export const DeepURLOnboardingWrapper = ({children, ...props}) => (
  <div className="mcPubContainer">
    <DeepURLLandingHeader {...props} />
    <div className="onboardingBg"/>
    <div className="pt-0 pt-md-4"style={{minHeight: "calc(100vh - 60px)", width: "100vw"}}>
      {children}
    </div>
  </div>
)
