import React from 'react';
import Linkify from 'react-linkify';

import {
  Card, CardHeader, CardBody, CardSubtitle, CardText,
  Table,
  Badge,
  Collapse,
  Button, Row, Col,
  Modal, ModalBody, ModalHeader,
  Alert,
} from 'reactstrap';
import {Link,} from 'react-router-dom';

import Select from 'react-select';

import FontAwesome from 'react-fontawesome';

import LoadingModal from 'common/LoadingModal.js';
import GolferModal from 'common/GolferModal.js';
import LeagueContestOADFields from 'pages/user/LeagueContestOADFields.js';

import numeral from 'numeral';

import { isMobPortrait, } from 'utils/responsive.js';


import {
  get_leagueContestOAD, save_lc_oad_entry, get_leagueContestOADBreakdown
} from 'utils/api.js';
import { CURRENT_SEASON } from 'utils/constants';


export default class LeagueContestOAD extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      season: props.season?props.season:CURRENT_SEASON,
      leagueContest: null,
      contesttype: props.contesttype,
      golferModalOpen: false,
      golferModalGolferId: null,
      error: null,
      isPrevPicksOpen: false,
      selectedGolferId: null,
      selectedTeamIndx: 0,
      selectedEntryIndx: 0,
      selectedGolferIds: [],
    };

  }


  componentDidMount() {
    this.fetchLeagueContestData(this.props.activeLeague, this.props.season);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.activeLeague !== this.props.activeLeague ||
      nextProps.season !== this.props.season || nextProps.contesttype !== this.props.contesttype) {
      this.fetchLeagueContestData(nextProps.activeLeague, nextProps.season);
    }else if (nextProps.activeLeague === this.props.activeLeague &&
        nextProps.season === this.props.season && nextProps.contesttype === this.props.contesttype &&
        nextProps.passedEventid !== (this.props.passedEventid||0)){
          let paramEvent = nextProps.passedEventid;
          if (nextProps.showLB && paramEvent && this.state.leagueContest && this.state.leagueContest.leaderboard && this.state.leagueContest.leaderboard.length > 0){
            //const found = this.state.leagueContest.leaderboard.find(ele => ele.event_id === parseInt(paramEvent));
            //if (found){
              this.props.toggleShowLCLeaderboard(parseInt(paramEvent),this.state.leagueContest.leagueContestId);
            //}
          }
      }
  }

  fetchLeagueContestData = (league, season) => {
    this.setState({season:season});
    get_leagueContestOAD(league, season,
     ({leagueContestPresent, leagueContest}) => {
        if (leagueContest && leagueContest.nextEvent && leagueContest.nextEvent.teams.length === 0){
          leagueContest.nextEvent.teams.push( {
            "id": 0,
            "name": 'team',
            "entries": []
          });
        }

        if (leagueContest && leagueContest.nextEvent &&
          leagueContest.nextEvent.teams.length > 0 && leagueContest.nextEvent.teams[0].entries.length < leagueContest.noGolfersPerWk){
            for (var ii = leagueContest.nextEvent.teams[0].entries.length; ii < leagueContest.noGolfersPerWk; ii++){
              leagueContest.nextEvent.teams[0].entries.push( {
                "id": 0,
                "golferid": 0
              });
            }
        }

        if (leagueContest && leagueContest.nextEvent){
          leagueContest.nextEvent.availableGolfers.sort((a, b)=>{
            return a.golfer_name.toUpperCase() < b.golfer_name.toUpperCase() ? -1 : 1;
          });
          leagueContest.nextEvent.teams[0].entries.sort((a,b)=>{return (a.id===0?99999999999:a.id)-(b.id===0?99999999999:b.id)});
        }

        let paramEvent = this.props.passedEventid;
        if (this.props.showLB && paramEvent && leagueContest.leaderboard && leagueContest.leaderboard.length > 0){
          //const found = leagueContest.leaderboard.find(ele => ele.event_id === parseInt(paramEvent));
          //if (found){
            this.props.toggleShowLCLeaderboard(parseInt(paramEvent),leagueContest.leagueContestId);
          //}
        }

        this.setState({
          isLoading:false, leagueContest
        });

    }, (error) => {
      console.log(error);
      this.setState({
        leagueContest: null
      });
    });
  }

  saveTeamEntries = (teams) => {
    this.setState({isLoading:true, error:''});
    let leagueContest = this.state.leagueContest;
    save_lc_oad_entry(this.props.activeLeague, leagueContest.nextEvent.contestEventid, teams,
     ({success, error, teams, allTeams, prevPicks}) => {
       if (success){
        let leagueContest = this.state.leagueContest;
        leagueContest.nextEvent.teams = teams;
        leagueContest.nextEvent.allTeams = allTeams;
        leagueContest.prevPicks = prevPicks;
        if (leagueContest.nextEvent.teams.length > 0 && leagueContest.nextEvent.teams[0].entries.length < leagueContest.noGolfersPerWk){
            for (var ii = leagueContest.nextEvent.teams[0].entries.length; ii < leagueContest.noGolfersPerWk; ii++){
              leagueContest.nextEvent.teams[0].entries.push( {
                "id": 0,
                "golferid": 0
              });
            }
        }
        leagueContest.nextEvent.teams[0].entries.sort((a,b)=>{return (a.id===0?99999999999:a.id)-(b.id===0?99999999999:b.id)});
        this.setState({isLoading:false, leagueContest, isPrevPicksOpen:true});
      }else{
        if (error === 'Late entry. The tournament has already started.'){
          let lc = this.state.leagueContest;
          lc.nextEvent.status = 'round1';
          this.setState({isLoading:false, error, leagueContest: lc});
        }else{
          this.setState({isLoading:false, error,});
        }
      }

    }, (error) => {
      console.log(error);
      this.setState({
        leagueContest: null
      });
    });
  }

  setSeason = (season) => {
    this.fetchLeagueContestData(this.props.activeLeague, season);
  }

  toggleGolferModal = () => {
    this.setState({golferModalOpen: !this.state.golferModalOpen});
  }

  showGolferDetails = (golferid) => {
    if (!golferid) return;
    this.setState({
      golferModalGolferId: golferid,
      golferModalOpen: true
    });
  }

  selectGolfer = (golferid, teamIndx, entryIndx) => {
    //let leagueContest = this.state.leagueContest;
    //leagueContest.nextEvent.teams[teamIndx].entries[entryIndx].golferid = golferid;
    let teams = JSON.parse(JSON.stringify(this.state.leagueContest.nextEvent.teams));
    teams[teamIndx||this.state.selectedTeamIndx].entries[entryIndx||this.state.selectedEntryIndx].golferid = golferid;
    //this.setState({teams});
    this.saveTeamEntries(teams);
  }

  togglePrevPicksOpen = ()=>{
    this.setState({isPrevPicksOpen: !this.state.isPrevPicksOpen});
  }

  toggleLoading = (isLoading) =>{
    this.setState({isLoading:isLoading})
  }

  toggleViewFields = (event, selectedTeamIndx, selectedEntryIndx, selectedGolferIds) => {
    if (!this.state.isViewFields){
      this.setState({isViewFields: !this.state.isViewFields, selectedTeamIndx, selectedEntryIndx, selectedGolferIds});
    }else{
      this.setState({isViewFields: !this.state.isViewFields});
    }
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  render() {
    if (this.state.isViewFields){
      return (
        <LeagueContestOADFields activeLeague={this.props.activeLeague}
          leaguecontestId={this.state.leagueContest.leagueContestId}
          contesteventId={this.state.leagueContest.nextEvent.contestEventid}
          toggleViewFields={this.toggleViewFields}
          selectGolfer={this.selectGolfer}
          selectedGolferIds={this.state.selectedGolferIds}
          event={this.state.leagueContest.nextEvent}
        />
      )
    }
    return (
      <>
          <LoadingModal modalScroll={true} isLoading={this.state.isLoading} />

          <Header toggleShowAllLeagueConest={this.props.toggleShowAllLeagueConest} />

          <OADAlert oadMsg={this.state.leagueContest && this.state.leagueContest.msg} />

          <NextEvent activeLeague={this.props.activeLeague}
            leagueContest={this.state.leagueContest}
            error={this.state.error}
            selectGolfer={this.selectGolfer}
            showGolferDetails={this.showGolferDetails}
            saveTeamEntries={this.saveTeamEntries}
            toggleViewFields={this.toggleViewFields}/>

          <PrevPicks activeLeague={this.props.activeLeague}
            leagueContest={this.state.leagueContest}
            isPrevPicksOpen={this.state.isPrevPicksOpen}
            showGolferDetails={this.showGolferDetails}
            togglePrevPicksOpen = {this.togglePrevPicksOpen}/>

          <LeagueContestLeaderboard activeLeague={this.props.activeLeague}
            leagueContest={this.state.leagueContest}
            toggleLoading={this.toggleLoading} />

          <GolferModal isOpen={this.state.golferModalOpen} toggle={this.toggleGolferModal} golferid={this.state.golferModalGolferId}/>
      </>
    );
  }
}

const Header = (props) => {
  return <Row noGutters className="pt-0 d-flex justify-content-center ml-2">
    <Col className="d-flex justify-content-between">
      <div className="d-flex justify-content-start">
          {/*<h3 className="my-2" style={{fontWeight:'bolder'}}>{this.state.leagueContests.name}</h3>*/}
          <h3 className="my-2" style={{fontWeight:'bolder'}}>{'One-And-Done'}</h3>
      </div>
      <div className="d-flex justify-content-end mr-3">
          <Button outline size="sm" color="primary" className="my-2" onClick={props.toggleShowAllLeagueConest}>
          Back
          </Button>
      </div>
    </Col>
  </Row>
}

const OADAlert = (props) => {
  return props.oadMsg !== undefined && props.oadMsg &&
      <Row noGutters className="d-flex justify-content-center mt-2">
        <Col lg="12">
          <Alert color="success" className="my-0">
            <strong>{'Commissioner Announcement'}</strong>
	          <div>
              <Linkify properties={{target: '_blank'}}>
                <span style={{"fontSize": "0.80rem", whiteSpace: "pre-line"}}>{props.oadMsg}</span>
              </Linkify>
	          </div>
          </Alert>
        </Col>
      </Row>
}

const NextEvent = (props) => {
  if (!props.leagueContest){
    return null;
  }

  if (!props.leagueContest.nextEvent) {
    return (
      <div>
      <Card className="my-4">
        <CardHeader className="d-flex justify-content-between px-2">
          <div className="d-flex justify-content-start" style={{fontWeight:'700', color:'green'}}>
            {'Next Event'}
          </div>
          <div className="d-flex justify-content-end mr-2">
            <EventScheduleModal events={props.leagueContest.events}/>
          </div>
        </CardHeader>
        <CardBody className="p-0">
          <Table responsive className="mb-0">
            <tbody style={{fontSize: ".9em"}}>
              <td>
                <CardSubtitle>No Events Scheduled</CardSubtitle>
                <CardText className="d-flex align-items-center" style={{"color": "#aaa", "fontSize": "0.77rem"}}>
                  <span>This league has no scheduled events for the rest of this season.</span>
                </CardText>
              </td>
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </div>);
  }

  return (
    <div>
      <Card className="my-4">
        <CardHeader className="d-flex justify-content-between px-2">
          <div className="d-flex justify-content-start" style={{fontWeight:'700', color:'green'}}>
            {'Next Event'}
          </div>
          <div className="d-flex justify-content-end mr-2">
            <EventScheduleModal events={props.leagueContest.events}/>
          </div>
        </CardHeader>
        <CardBody className="p-0">
          <Table responsive className="mb-0">
            <tbody style={{fontSize: ".9em"}} >
            <tr>
              <td className="mt-4">
                <div className="d-flex justify-content-between">
                  <CardSubtitle className="mt-1">{props.leagueContest.nextEvent.name}
                  { props.leagueContest.nextEvent.status === "scheduled" &&
                    <Badge color="success" style={{marginLeft: "0.5rem"}}>Scheduled</Badge>
                  }
                  { props.leagueContest.nextEvent.status === "open" &&
                    <Badge color="warning" style={{marginLeft: "0.5rem"}}> Open </Badge>
                  }
                  { ["round1", "round2", "round3", "round4"].indexOf(props.leagueContest.nextEvent.status) !== -1 &&
                    <Badge color="info" style={{marginLeft: "0.5rem"}}> Live </Badge>
                  }
                  </CardSubtitle>
                  <div className="d-flex justify-content-end mt-2 mr-2">
                    <Link to={`/onedonerules`} style={{fontSize: "0.77rem"}}>How to Play</Link>
                  </div>
                </div>
                <CardText className="d-flex align-items-center mb-1" style={{"color": "#aaa", "fontSize": "0.77rem"}}>
                  <span className="pr-1">
                    {props.leagueContest.nextEvent.dates} &bull; {props.leagueContest.nextEvent.course}.
                  </span>
                </CardText>
                <div className="d-flex justify-content-between">
                  <div className="">
                    <CardText className="d-flex align-items-center mb-1" style={{"color": "#aaa", "fontSize": "0.70rem"}}>
                      <span className="pr-1">
                        Purse: ${numeral(props.leagueContest.nextEvent.purse).format('0,0')}
                      </span>
                    </CardText>
                    <CardText className="d-flex align-items-center" style={{"color": "#aaa", "fontSize": "0.70rem"}}>
                      <span className="pr-1">
                        Defending Champion: {props.leagueContest.nextEvent.defending_champ}
                      </span>
                    </CardText>
                  </div>
                  {(props.leagueContest.nextEvent.noMoreTeam && ["round1", "round2", "round3", "round4"].indexOf(props.leagueContest.nextEvent.status)>-1) &&
                  <div className="d-flex justify-content-end align-items-top mr-2">
                    <div>
                      <Button color="success" className="ml-auto text-nowrap">
                        <Link to={{pathname: "/league/" + props.activeLeague + "/odcontest/event/"+props.leagueContest.nextEvent.eventid, state: { from: "/league/" + props.activeLeague + "/odcontest" }}}
                          style={{color: "inherit", textDecoration: "none", cursor: "inherit"}}>
                          {isMobPortrait()?'Leaderboard':'Live Leaderboard'}
                        </Link>
                      </Button>
                    </div>
                  </div>
                  }
                </div>

                {(props.leagueContest.nextEvent.status === 'scheduled' || !props.leagueContest.nextEvent.availableGolfers || props.leagueContest.nextEvent.availableGolfers.length === 0 ) &&
                  <CardText className="d-flex align-items-center">
                    <span className="pr-1">
                      {'Field has not yet been finalized. Check back soon.'}
                    </span>
                  </CardText>
                }

              </td>
            </tr>
            </tbody>
          </Table>
          {props.error &&
          <div className="text-center text-danger">{props.error}</div>
          }
          <GolferTeam activeLeague={props.activeLeague}
            leagueContest={props.leagueContest}
            selectGolfer={props.selectGolfer}
            showGolferDetails={props.showGolferDetails}
            saveTeamEntries={props.saveTeamEntries}
            toggleViewFields={props.toggleViewFields}/>
        </CardBody>
      </Card>
    </div>
  )
}


const checkIfImageExists = (url, callback) => {
  const img = new Image();
  img.src = url;

  if (img.complete) {
    callback(true);
  } else {
    img.onload = () => {
      callback(true);
    };

    img.onerror = () => {
      callback(false);
    };
  }
}

const GolferTeam = (props) => {

  if (!props.leagueContest || !props.leagueContest.nextEvent || props.leagueContest.nextEvent.teams.length === 0 || props.leagueContest.nextEvent.availableGolfers.length === 0){
    return null;
  }

  const nextEvent = props.leagueContest.nextEvent;

  if (nextEvent.status === 'scheduled' || nextEvent.status === 'closed'){return null;}

  if (nextEvent.noMoreTeam){
  return (
    <div>
      <div className="px-2 d-flex justify-content-between my-2">
        {["open"].indexOf(nextEvent.status)>-1 &&
        <div className="ml-1 card-subtitle">
          {"Commissioner not allowing new teams."}
        </div>
        }

      </div>
    </div>
  )
  }

  const rows = nextEvent.teams[0].entries.map((entry, index) => {
    return (
          <GolferRow index={index} nextEvent={nextEvent} entries={nextEvent.teams[0].entries}
            availableGolfers={nextEvent.availableGolfers} allTeams={props.leagueContest.allTeams}
            prevPicks = {props.leagueContest.prevPicks}
            selectGolfer={props.selectGolfer} showGolferDetails={props.showGolferDetails}
            toggleViewFields={props.toggleViewFields}/>
    );
  });



  return (
    <div>
      <div className="px-2 d-flex justify-content-between">
          <div className="">
            {rows}
          </div>
          {["round1", "round2", "round3", "round4"].indexOf(nextEvent.status)>-1 &&
            <div className="d-flex justify-content-end align-items-top my-2">
              <div>
              <Button color="success" className="ml-auto text-nowrap mr-2">
                <Link to={{pathname: "/league/" + props.activeLeague + "/odcontest/event/"+nextEvent.eventid, state: { from: "/league/" + props.activeLeague + "/odcontest" }}}
                  style={{color: "inherit", textDecoration: "none", cursor: "inherit"}}>
                  {isMobPortrait()?'Leaderboard':'Live Leaderboard'}
                </Link>
              </Button>

              </div>
            </div>
          }
        </div>
    </div>
  )
}

class GolferRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imageVerified: false,
      grpGCls: 'golfer-headshot-mid mt-n2',
      imgURL: 'https://media.majorschallenge.com/headshots/headshot.png',
    }
  }

  componentDidMount() {

  }

  componentWillReceiveProps(nextProps) {
    if (this.props.index === nextProps.index && nextProps.entries[nextProps.index].golferid !== this.props.entries[this.props.index].golferid) {
      this.setState({imageVerified:false});
    }
  }

  render() {
    const availableGolfers = this.props.availableGolfers;
    let golfer = this.props.entries[this.props.index];
    let m_golfer = this.props.availableGolfers.filter((g)=>{
      return g.golferid === this.props.entries[this.props.index].golferid;
    })[0];
    if (!m_golfer){
      golfer = {id: 0, golferid:0,golfer_name:''};
    }else{
      golfer = {...golfer, ...m_golfer};
    }

    const IMAGE_BASE_URL = 'https://media.majorschallenge.com/headshots/';
    const IMAGE_NO_HEADSHOT = "https://media.majorschallenge.com/headshots/headshot.png";

    if (!this.state.imageVerified){
      this.setState({'imageVerified':true});

      if (golfer.golferid > 0){
        checkIfImageExists(IMAGE_BASE_URL + golfer.golfer_name.replace(/[\W_]+/g,'').toLowerCase() + '.png', (exists) => {
          //if (!exists) {this.setState({grpGCls: 'golfer-headshot-big golfer-name-initial'});}
          if (exists) {this.setState({imgURL: IMAGE_BASE_URL + golfer.golfer_name.replace(/[\W_]+/g,'').toLowerCase() + '.png'});}
          if (!exists) {this.setState({imgURL: IMAGE_NO_HEADSHOT});}
        });
      }else{
        this.setState({imgURL: IMAGE_NO_HEADSHOT});
      }
    }

    /*var names = golfer.golfer_name.split(' '),
      initials = names[0].substring(0, 1).toUpperCase(),
      shortName = initials + '. ';

    if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
        shortName += names[names.length - 1];
    }*/



    let golferOptions = [],
      tempSelectedGolferIds = [],
      selectedGolferIds = [],
      selectedValue = null;

    this.props.entries.forEach((e)=>{
      if (e.golferid>0){tempSelectedGolferIds.push(e.golferid)}
    });

    this.props.prevPicks.forEach((t)=>{
      if (t.golfer_id && !t.substitute_ind){
        selectedGolferIds.push(t.golfer_id);
      }
    });

    if (availableGolfers){
      availableGolfers.forEach((g, index) => {
        let option = { value: g.golferid,
          isDisabled: (selectedGolferIds.indexOf(g.golferid) > -1 || tempSelectedGolferIds.indexOf(g.golferid) > -1 ? true : false ),
          label: g.golfer_name
        };
        if (g.golferid === golfer.golferid){
          selectedValue = { value: g.golferid,
            label: g.golfer_name
          };
        }
        golferOptions.push(
            option
        );
      });
    }

    let live_golfer_name = selectedValue ? selectedValue.label : 'No Selection';

    //let conCls =  'golfer-headshot-container-big';
    //const bgCls = {backgroundImage: 'url(' + IMAGE_BASE_URL + golfer.golfer_name.replace(/[\W_]+/g,'').toLowerCase() + '.png)'};
    const bgCls = {borderRadius:'0', backgroundImage: 'url(' + this.state.imgURL +')'};
    const isGameLive = ["round1", "round2", "round3", "round4"].indexOf(this.props.nextEvent.status)>-1 ? true : false;
    const golferContWidth = isMobPortrait() && isGameLive ? '190px' : (isMobPortrait() && !isGameLive ? '225px' : '280px');
    return (
      <div className="d-flex justify-content-left align-items-center mb-4 py-2 pl-3">
        <div className={''} style={{zIndex:this.props.index}} onClick={() => this.props.showGolferDetails(golfer.golferid)}>
          <div className={this.state.grpGCls} style={bgCls}><div>{''}</div></div>
        </div>

        <div style={{width:golferContWidth}} className="ml-2">

          {this.props.nextEvent.status === 'open' &&

            <Select className="no-border my-2" isClearable isSearchable options={golferOptions}
            value={selectedValue}
            placeholder = {<div className="" style={{fontSize:'1.1em', color:'black'}}>Choose Golfer {this.props.index+1}</div>}
            onChange={(selectedOption) => {
                console.log(selectedOption);
                this.setState({imageVerified:false});
                if (selectedOption){
                  this.props.selectGolfer(selectedOption.value, 0, this.props.index);
                }else{
                  this.props.selectGolfer(0, 0, this.props.index);
                }
              }
            } />
            }
            {["round1", "round2", "round3", "round4"].indexOf(this.props.nextEvent.status)>-1 &&
              <div className="font-weight-bold" style={{fontSize:'1.1em'}}>{live_golfer_name}</div>
            }
        </div>
        {this.props.nextEvent.status === 'open' &&
        <div style={{fontSize:'.9em'}}  className="ml-2">
            <Link className="" to="/" onClick={(e)=>{this.props.toggleViewFields(e, 0, this.props.index, selectedGolferIds);}}>{'Full Field'} </Link>
        </div>
        }
      </div>
    )

  }
}


const PrevPicks = (props) => {

  if (!props.leagueContest || props.leagueContest.noMoreTeam){
    return null;
  }

  const prevPicks = props.leagueContest.prevPicks;

  let totalPicks = 0;
  const rows = prevPicks.map((entry, index) => {
    totalPicks += parseInt(entry.earnings);
    return (
          <PrevPicksGolferRow index={index} entry={entry} showGolferDetails={props.showGolferDetails} activeLeague={props.activeLeague}/>
    );
  });



  return (
    <Card className="my-4">
      <CardHeader className="d-flex justify-content-between px-2" >
        <div className="d-flex justify-content-start" style={{fontWeight:'700', color:'green'}}>
          {'My Picks'}
        </div>
        <div className="d-flex justify-content-end">

        </div>
      </CardHeader>


      <CardBody className="p-0">
        <CardSubtitle className="my-3 ml-2 d-flex justify-content-between" onClick={props.togglePrevPicksOpen}>
          <div>
            Season Earnings: <b>${numeral(totalPicks).format('0,0')}</b>
          </div>
          <div className="d-flex justify-content-end mr-4 mt-1">
            <FontAwesome size="lg" name={props.isPrevPicksOpen ? "angle-up" : "angle-down"} />
          </div>
        </CardSubtitle>
        <Collapse isOpen={props.isPrevPicksOpen}>
        <Table responsive className="mb-0">
          <thead>
            <tr style={{backgroundColor: "#fafafa", color: "gray", "fontSize": "0.85rem"}}>
              <th className=""></th>
              <th>Name</th>
              <th className="text-left">Tournament</th>
              {/*<th className="text-left ">Date</th>*/}
              <th className="text-right pr-2">Place</th>
              <th className="text-right pr-4" style={{width:'140px'}}>Earnings</th>
            </tr>
          </thead>
          <tbody style={{fontSize: ".9em"}}>
            {rows}
          </tbody>
        </Table>
        </Collapse>
      </CardBody>

    </Card>

  )
}

class PrevPicksGolferRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imageVerified: false,
      grpGCls: 'golfer-headshot-small mt-n2',
      imgURL: 'https://media.majorschallenge.com/headshots/headshot.png',
    }
  }

  componentDidMount() {

  }

  componentWillReceiveProps(nextProps) {
    if (this.props.index === nextProps.index && nextProps.entry.golfer_id !== this.props.entry.golfer_id) {
      this.setState({imageVerified:false});
    }
  }

  render() {
    let entry = this.props.entry;
    const IMAGE_BASE_URL = 'https://media.majorschallenge.com/headshots/';
    const IMAGE_NO_HEADSHOT = "https://media.majorschallenge.com/headshots/headshot.png";

    if (!this.state.imageVerified){
      this.setState({'imageVerified':true});

      if (entry.golfer_id){
        checkIfImageExists(IMAGE_BASE_URL + entry.golfer.replace(/[\W_]+/g,'').toLowerCase() + '.png', (exists) => {
          if (exists) {this.setState({imgURL: IMAGE_BASE_URL + entry.golfer.replace(/[\W_]+/g,'').toLowerCase() + '.png'});}
          if (!exists) {this.setState({imgURL: IMAGE_NO_HEADSHOT});}
        });
      }else{
        this.setState({imgURL: IMAGE_NO_HEADSHOT});
      }
    }


    const bgCls = {borderRadius:'0', backgroundImage: 'url(' + this.state.imgURL +')'};
    return (
      <tr  className="">
        <td className="text-center">
          <div className={''} style={{zIndex:this.props.index}} onClick={() => this.props.showGolferDetails(entry.golfer_id)}>
            <div className={this.state.grpGCls} style={bgCls}><div>{''}</div></div>
          </div>
        </td>
        <td className="text-left">
          {entry.golfer}
        </td>
        <td className="text-nowrap">
          {entry.e_status === 'open' &&
            entry.tournament
          }
          {entry.e_status !== 'open' &&
          <Link to={{pathname: "/league/" + this.props.activeLeague + "/odcontest/event/"+entry.event_id, state: { from: "/league/" + this.props.activeLeague +"/odcontest"}}}>
          {entry.tournament}
          </Link>
          }
        </td>
        {/*
        <td className="text-left">
          {entry.start_date}
        </td>*/}
        <td className="text-right">
          {entry.place}
        </td>
        <td className="align-middle text-right pr-4">
          <div style={{width: '120px'}}>
            ${numeral(entry.earnings).format('0,0')}
          </div>
        </td>
      </tr>
    )

  }
}

const LeagueContestLeaderboard = (props) => {
  if (!props.leagueContest || props.leagueContest.leaderboard.length === 0) {
    return null;
  }

  let leaderboard = [];
  props.leagueContest.leaderboard.forEach((l) =>{
    let r_data = leaderboard.filter((lx)=>{return lx.user_id === l.user_id})[0];
    if (!r_data){
      r_data = {
        user_id: l.user_id,
        user_name: l.user_name,
        usersRow: l.usersRow,
        frndsRow: l.frndsRow,
        earnings: 0,
        details: []
      }
      leaderboard.push(r_data);
    }
    r_data.earnings += parseInt(l.earnings);
    r_data.details.push({...l});
  });
  leaderboard.sort((a,b)=>{return b.earnings - a.earnings;});

  let summaryRows = [];
  leaderboard.forEach((item, index) => {
    summaryRows.push(<LeagueContestLeaderboardRow item={item} key={index} index={index} activeLeague={props.activeLeague} />);
  });

  return (
    <div>
      <Card className="my-4">
        <CardHeader className="d-flex justify-content-between px-2">
          <div className="d-flex justify-content-start" style={{fontWeight:'700', color:'green'}}>
            {'Leaderboard'}
          </div>
          <div className="d-flex justify-content-end mr-2">
            <BreakdownModal activeLeague={props.activeLeague}
              leagueContestId={props.leagueContest.leagueContestId}
              toggleLoading={props.toggleLoading}
              nextEvent={props.leagueContest.nextEvent}
              prevPicks={props.leagueContest.prevPicks}/>
          </div>
        </CardHeader>
        <CardBody className="p-0">
          <Table responsive className="mb-0">
            <thead>
              <tr style={{backgroundColor: "#fafafa", color: "gray", "fontSize": "0.85rem"}}>
                <th className="text-center">POS</th>
                <th className="">Name</th>
                <th style={{paddingLeft:'45px'}}>Total Earnings</th>
                <th></th>
              </tr>
            </thead>
            <tbody style={{fontSize: ".9em"}}>
              {summaryRows}
            </tbody>
          </Table>
        </CardBody>
      </Card>
      <div className=" muted mt-n3 mb-4" style={{textAlign:'center', fontSize:'.9rem'}}>
        Choose friends to follow in  <a href={`/members/${props.activeLeague}`}>Members section</a>.
      </div>
    </div>
  )
}

class LeagueContestLeaderboardRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = { collapsed: true };
    this.toggle = this.toggle.bind(this);
  }
  toggle() {
    this.setState({collapsed: !this.state.collapsed});
  }

  render() {

    let bodyrowclass = "completedEventRowBody";
    if (!this.state.collapsed) {
      bodyrowclass += " expanded";
    }

    let classname = "pgaLeaderboardRow";
    if (this.props.item.usersRow) {
      classname += " personal";
    }else if (this.props.item.frndsRow){
      classname += " others";
    }

    return (
      <>
        <tr  className={classname} onClick={this.toggle} style={{fontSize: ".9rem"}}>
          <td className="text-center" >
            {this.props.index + 1}
          </td>
          <td className=" align-middle" >
            {this.props.item.user_name}
          </td>
          <td className="align-middle text-right">
            <div style={{width: '120px'}}>
              ${numeral(this.props.item.earnings).format('0,0')}
            </div>
          </td>
          <td className="align-middle">
            <FontAwesome size="lg" name={this.state.collapsed ? "angle-down" : "angle-up"} />
          </td>
        </tr>
        <tr className={bodyrowclass}>
          <td colSpan="6" style={{padding: 0, borderTop: "none"}} className=" align-middle">
            <Collapse isOpen={!this.state.collapsed} className=" mb-1">
              <LeagueContestLeaderboardDetailsRow details={this.props.item.details} activeLeague={this.props.activeLeague}/>
            </Collapse>
          </td>
        </tr>
      </>
    );
  }

}

class LeagueContestLeaderboardDetailsRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }


  render() {
    const rows = this.props.details.map((detail, index) => {
      return (
        <tr key={index} className="">
          <td className="text-nowrap">{detail.golfer}</td>
          <td className="text-nowrap">
            {detail.event_id === 0 ? detail.tournament :<Link to={`/league/`+ this.props.activeLeague + `/odcontest/event/`+ detail.event_id}>{detail.tournament}</Link>}
          </td>
          <td className="text-center">{detail.place}</td>
          <td className="text-right pr-4">
            <div className="mr-4">
            ${numeral(detail.earnings).format('0,0[.]00')}
            </div>
          </td>
        </tr>
      )
    });

    return (
      <Table className="contestLBDetailsTable" style={{}}>
        <thead>
          <tr className="contestLBDetailsHeader">
            <th className="text-nowrap">Golfer</th>
            <th className="text-left">Tournament</th>
            <th className="text-center">Place</th>
            <th className="text-right pr-4">
              <div className="mr-4">Earnings</div>
            </th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </Table>
    );
  }
}

class BreakdownModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      breakdown: [],
      gridDataSort: {
        sortByFld: '',
        sortOrder: '',
        sortFunc: null,
        sortFlds: ['wr','first_name__last_name','picked|wr','available|wr'],
        sortIcons: ['sort','sort','sort','sort']
      }
    };

  }

  componentDidMount() {
    //this.fetchBreakdownData(this.props.activeLeague, this.props.leagueContestId);
  }

  componentWillReceiveProps(nextProps) {
    /*if (nextProps.activeLeague !== this.props.activeLeague ||
      nextProps.leagueContestId !== this.props.leagueContestId) {
      this.fetchBreakdownData(nextProps.activeLeague, nextProps.leagueContestId);
    }*/
  }

  toggle = (event) => {
    if (!this.state.modal && this.state.breakdown.length === 0){
      this.fetchBreakdownData(this.props.activeLeague, this.props.leagueContestId);
    }
    this.setState({
      modal: !this.state.modal,
    });

    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

  }

  fetchBreakdownData = (league, leagueContestId) => {
    this.props.toggleLoading(true);
    get_leagueContestOADBreakdown(league, leagueContestId,
     ({success, error, breakdown}) => {
       this.props.toggleLoading(false);
       if (success){
         this.sortBy('wr');
         this.setState({breakdown});
       }

    }, (error) => {
      console.log(error);
    });
  }

  sortBy = (sortByFld) =>{
    let sortFunc = null;
    let gridDataSort = this.state.gridDataSort;
    gridDataSort.sortOrder = (sortByFld === gridDataSort.sortByFld && gridDataSort.sortOrder === 'up')?'down':'up';
    sortFunc = (x,y) => {
      let gdSort = this.state.gridDataSort;
      let a = x, b = y;

      let sortFlds_0 = gdSort.sortByFld.split('|');
      let sortDecision = 0;
      for (var i0=0; i0< sortFlds_0.length; i0++){
        if (gdSort.sortOrder === 'down' && i0===0){
          a = y;
          b = x;
        }else{
          a = x;
          b = y;
        }
        let f0 = sortFlds_0[i0];
        const sortFlds = f0.split('__');
        let a_val = null, b_val = null;
        if (sortFlds.length > 1){
          a_val = ''; b_val = '';
          sortFlds.forEach((f, i) => {
            a_val += a[f] + ' ';
            b_val += b[f] + ' ';
          });

        }else{
          a_val = a[f0];
          b_val = b[f0];
          try{a_val=parseInt(a_val);}catch(e){a_val = a[f0];}
          try{b_val=parseInt(b_val);}catch(e){b_val = b[f0];}
        }
        //if (i0===0){
          sortDecision = (a_val === b_val) ? 0 : (a_val < b_val ? -1 : 1);
        //}else{
        //  sortDecision = (a_val === b_val) ? 0 : (a_val < b_val ? 1 : -1);
        //}
        if (sortDecision !== 0){break;}
      }

      return sortDecision;
    }
    gridDataSort.sortByFld = sortByFld;
    gridDataSort.sortFunc = sortFunc;
    gridDataSort.sortIcons.forEach((item, i) => {
      let ps = gridDataSort.sortFlds.indexOf(sortByFld);
      gridDataSort.sortIcons[i] = (i===ps)?"sort-"+ gridDataSort.sortOrder:'';
    });

    this.setState({gridDataSort});
  }

  render() {
    let selectedGolferIds = []
    if (this.props.nextEvent){
      this.props.nextEvent.allTeams.forEach((t)=>{
        t.entries.forEach((e)=>{
          if (e.golferid>0){selectedGolferIds.push(e.golferid)}
        });
      });
    }
    this.props.prevPicks.forEach((e)=>{
        if (e.golfer_id && selectedGolferIds.indexOf(e.golfer_id) <0){selectedGolferIds.push(e.golfer_id)}
    });

    const rows = [];
    let breakdown = this.state.breakdown;
    if (this.state.gridDataSort.sortFunc !== null) {
      breakdown.sort(this.state.gridDataSort.sortFunc);
    }

    breakdown.forEach((item, index) => {
        let cls = selectedGolferIds.indexOf(item.golfer_id) > -1 ?'text-danger text-left pl-1': 'text-left pl-1';
        rows.push(
            <tr className="d-flex">
              <td className="text-center" style={{width:'19%'}}>{item.world_rank}</td>
              <td className={cls} style={{width:'42%'}}>{item.first_name + ' ' + item.last_name}</td>
              <td className="text-center pl-4" style={{width:'22%'}}>{item.picked}</td>
              <td className="text-center" style={{width:'32%'}}>{item.available}</td>
            </tr>

        );
    });

    const cls1 = isMobPortrait()?'text-center text-wrap':'text-center text-nowrap';
    const gridDataSort = this.state.gridDataSort;
    return (
      <>
      <Button outline color="primary" size="sm" onClick={this.toggle}>
        Breakdown
        <Modal isOpen={this.state.modal} toggle={this.toggle} className="golferModal" autoFocus={false} >
          <ModalHeader toggle={this.toggle} className="mcModalHeader">Breakdown</ModalHeader>
          <ModalBody>
              <Row className="profileFormRow pb-3 pb-sm-0 mt-n1">
                <Col  className="">
                  <Table responsive className="table table-border table-head-fixed">
                    <thead>
                      <tr className="d-flex" style={{backgroundColor: "#f6f6f6", color: "#7c7c7c", "fontSize": "0.7rem", textTransform: "uppercase"}}>
                        <th className="text-center" style={{width:'15%'}} onClick={() => {this.sortBy('wr')}}>
                          <div>
                            Rank <FontAwesome size="xl" className="" name={gridDataSort.sortIcons[0]} />
                          </div>
                        </th>
                        <th className="text-left" style={{width:'38%'}} onClick={() => {this.sortBy('first_name__last_name')}}>Golfer <FontAwesome size="lg" className="" name={gridDataSort.sortIcons[1]} /></th>
                        <th className={cls1} style={{width:'22%'}} onClick={() => {this.sortBy('picked|wr')}}>Picked By <FontAwesome size="lg" className="" name={gridDataSort.sortIcons[2]} /></th>
                        <th className={cls1} style={{width:'22%'}} onClick={() => {this.sortBy('available|wr')}}>Available To <FontAwesome size="lg" className="" name={gridDataSort.sortIcons[3]} /></th>
                      </tr>
                    </thead>
                    <tbody style={{fontSize:'13px', borderBottom:'1px solid #dee2e6', height:'500px'}}>
                      {rows}
                    </tbody>
                  </Table>
                </Col>
              </Row>
              <Row className="pb-3 justify-content-center">
                <div className="text-danger" style={{fontSize:'.85em'}}>{'Your Golfers In Red.'}</div>
              </Row>
          </ModalBody>
        </Modal>
      </Button>
      </>
    );
  }
}

class EventScheduleModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      breakdown: [],
    };
  }

  componentDidMount() {

  }

  componentWillReceiveProps(nextProps) {

  }

  toggle = (event) => {
    this.setState({
      modal: !this.state.modal,
    });

    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

  }



  render() {

    const rows = [];
    this.props.events.forEach((item, index) => {
        let cls = 'text-left text-nowrap';
        if (item.status && (item.status === 'open' || item.status.indexOf('round')>-1)){cls +=' text-success font-weight-bold'}
        else if (item.status === 'closed'){cls +=' text-muted'}
        rows.push(
            <tr className={isMobPortrait()?'d-flex':'d-flex'}>
              <td className={cls} style={{width:'40%'}}>{item.tournament_name}</td>
              <td className='text-right' style={{width:'25%'}}>${numeral(item.purse).format('0,0')}</td>
              <td className={isMobPortrait()?'text-left':'text-left text-nowrap'} style={{width:'20%'}}>{item.defending_champ}</td>
              <td className='text-center text-nowrap' style={{width:'20%'}}>{item.start_date}</td>
            </tr>
        );
    });

    return (
      <>
      <Button outline color="primary" size="sm" onClick={this.toggle}>
        Full Schedule
        <Modal isOpen={this.state.modal} toggle={this.toggle} className={isMobPortrait()?'oadFullScheduleMob':'oadFullScheduleDesktop'} autoFocus={false} >
          <ModalHeader toggle={this.toggle} className="mcModalHeader">Full Schedule</ModalHeader>
          <ModalBody>
              <Row className="profileFormRow pb-3 pb-sm-0 mt-n1">
                <Col  className="">
                  <Table responsive className="table table-border table-head-fixed">
                    <thead>
                      <tr className={isMobPortrait()?'d-flex':'d-flex'} style={{backgroundColor: "#f6f6f6", color: "#7c7c7c", "fontSize": "0.7rem", textTransform: "uppercase"}}>
                        <th className="text-left" style={{width:'40%'}}>Tournament</th>
                        <th className={isMobPortrait()?'text-right':'text-right mr-4'} style={{width:'25%'}}>Purse</th>
                        <th className={isMobPortrait()?'text-left':'text-left text-nowrap'} style={{width:'20%'}}>Defending Champ</th>
                        <th className={isMobPortrait()?'text-center text-nowrap':'text-center text-nowrap mr-3'} style={{width:'20%'}}>Start Date</th>
                      </tr>
                    </thead>
                    <tbody style={{fontSize:'13px', borderBottom:'1px solid #dee2e6', borderRight:'1px solid #dee2e6', height:'500px'}}>
                      {rows}
                    </tbody>
                  </Table>
                </Col>
              </Row>
          </ModalBody>
        </Modal>
      </Button>
      </>
    );
  }
}
