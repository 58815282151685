import React from 'react';

import {
  Row, Col
} from 'reactstrap';

import {
  MobileHeader,
  ContentWrapper
} from 'common/ProfileComponents.js';

import {
  change_password,
  start_password_reset
} from 'utils/api.js';

export default class ProfileChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      success: null,
      error: null
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleResetPassword = this.handleResetPassword.bind(this);
  }
  componentDidMount() {
  }
  handleChange(event) {
    this.setState({[event.target.name]: event.target.value});
  }
  handleSubmit(event) {
    if (this.state.newPass !== this.state.confirmNewPass) {
      this.setState({success: false, error: "New passwords do not match."});
      event.stopPropagation();
      return;
    }
    change_password(
      this.state.oldPass,
      this.state.newPass,
      this.state.confirmNewPass,
      ({success, error}) => {
        this.setState({success, error});
      },
      (error) => {
        console.log(error);
        this.setState({success: false, error: "Error occurred."});
      }
    );
  }

  handleResetPassword(event) {
    start_password_reset(this.props.me.usr.useremail,
      ({success}) => {
        if (success) {
          this.setState({message: 'Check your email for a password reset link.', error: null});
        } else {
          this.setState({message: 'Sorry, we did not find an account matching that email.', error: null});
        }
      }, (error) => {
        this.setState({error: "An unexpected error occurred. Try again later."});
    });
    event.preventDefault();
    event.stopPropagation();
    return;
  }

  render() {
    if (this.state.success === true) {
      return (
        <div>
          <MobileHeader to="Change Password" />
          <ContentWrapper>
            <Row className="profileFormRow">
              <Col style={{color: "#94a1ab"}}>Password changed.</Col>
            </Row>
          </ContentWrapper>
        </div>
      )
    }
    return (
      <div>
        <MobileHeader to="Change Password" />
        <ContentWrapper>
          <Row className="profileFormRow pb-3 pb-sm-0">
            <Col sm="4" md="3" className="profileFormRow_label">
              {'Current Password:'}
            </Col>
            <Col sm="8" md="6" className="profileFormRow_content">
              <input type="password" name="oldPass" value={this.state.oldPass} onChange={this.handleChange} className="profileFormRow_input form-control"/>
            </Col>
          </Row>
          <Row className="profileFormRow pb-3 pb-sm-0">
            <Col sm="4" md="3" className="profileFormRow_label">
              {'New Password:'}
            </Col>
            <Col sm="8" md="6" className="profileFormRow_content">
              <input type="password" name="newPass" value={this.state.newPass} onChange={this.handleChange} className="profileFormRow_input form-control"/>
            </Col>
          </Row>
          <Row className="profileFormRow pb-3 pb-sm-0">
            <Col sm="4" md="3" className="profileFormRow_label">
              {'Confirm New Password:'}
            </Col>
            <Col sm="8" md="6" className="profileFormRow_content">
              <input type="password" name="confirmNewPass" value={this.state.confirmNewPass} onChange={this.handleChange} className="profileFormRow_input form-control"/>
            </Col>
          </Row>
          <Row className="profileFormRow pt-3">
            <Col sm="4" md="3">
              <button className="btn btn-success" onClick={this.handleSubmit}>Change Password</button>
            </Col>
            { this.state.success === false && this.state.error && <Col style={{color: "#94a1ab"}}>{this.state.error}</Col>}
            { this.state.success === false && !this.state.error && <Col style={{color: "#94a1ab"}}>An error occurred.</Col> }
          </Row>
          <Row className="profileFormRow pt-3">
            <Col sm="8" md="6" >
              <span onClick={this.handleResetPassword} style={{color: "#157EFB", fontSize: ".95em", cursor: "pointer"}} >
                Send reset password email instead
              </span>
              { this.state.message && <div style={{color: "#28a745", fontSize: ".85em"}}> {this.state.message}</div> }
            </Col>
          </Row>
        </ContentWrapper>
      </div>
    )
  }
}
