import React from 'react';

import { EditorState, convertToRaw, ContentState} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
//import draftToMarkdown from 'draftjs-to-markdown';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';


import {
  Button,
  Card,
  Input,
} from 'reactstrap';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import FontAwesome from 'react-fontawesome';

import {
  MobileHeader,
  ContentWrapper
} from 'common/CommissionerComponents.js';

import LeagueBalanceWarning from 'common/LeagueBalanceWarning';

import {
  get_motd,
  set_motd,
  set_cup_fld_value,
} from 'utils/api.js';

export default class Motd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: EditorState.createEmpty(),
      orig_motd: '',
      motd: '',
      clubSlug: null,
      isCup:false,
      cupMsg:'',
      orig_cupMsg:'',
    };

    this.fetch = this.fetch.bind(this);
    this.save = this.save.bind(this);
    this.clear = this.clear.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.saveCupMsg = this.saveCupMsg.bind(this);
    this.clearCupMsg = this.clearCupMsg.bind(this);
    this.handleCupMsgChange = this.handleCupMsgChange.bind(this);


  }

  onEditorStateChange: Function = (editorState) => {
    this.setState({
      editorState,
    });
  };

  componentDidMount() {
    this.fetch(this.props.activeLeague);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.activeLeague === nextProps.activeLeague) {
      return
    }

    this.fetch(nextProps.activeLeague);
  }

  fetch(league) {
    if (!league)
      return;

    get_motd(league,
    ({success, motd, clubSlug,isCup, cupMsg, error}) => {
      if (success) {
        //const html = '<p>Hey this <strong>editor</strong> rocks 😀</p>';
        const contentBlock = htmlToDraft(motd);
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
          const editorState = EditorState.createWithContent(contentState);
          this.setState({editorState});
        }

        this.setState({
          motd,
          orig_motd: motd,
          clubSlug,
          isCup,
          cupMsg:(cupMsg?cupMsg:''),
          orig_cupMsg:(cupMsg?cupMsg:''),

        });
      } else {
        console.log(error);
      }
    }, (error) => {
      console.log(error);
    });
  }

  save() {
    if (!this.props.activeLeague)
      return;

    //const motd = this.state.motd;
    const motd = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()));


    set_motd(this.props.activeLeague, motd,
    ({success, error}) => {
      if (success) {
        this.setState({
          orig_motd: motd,
        })
      } else {
        console.log(error);
      }
    }, (error) => {
      console.log(error);
    });
  }

  clear() {
    this.setState({
      motd: '',
    });
  }

  handleChange(event) {
    const motd = event.target.value;
    this.setState({ motd });
  }

  saveCupMsg() {
    if (!this.props.activeLeague || !this.state.isCup)
      return;

    const cupMsg = this.state.cupMsg;

    set_cup_fld_value(this.props.activeLeague,'msg', cupMsg,
    ({success, error}) => {
      if (success) {
        this.setState({
          orig_cupMsg: cupMsg,
        })
      } else {
        console.log(error);
      }
    }, (error) => {
      console.log(error);
    });
  }

  clearCupMsg() {
    this.setState({
      cupMsg: '',
    });
  }

  handleCupMsgChange(event) {
    const cupMsg = event.target.value;
    this.setState({ cupMsg });
  }

  render() {
    return (
      <div>
        <MobileHeader to="Members" leagueid={this.props.activeLeague} />
        <ContentWrapper>
          <div className="mb-2" style={{fontSize: "1.75em", fontWeight: 300}}>
            {'League Announcement'}
          </div>
          {this.props.balancewarning && <LeagueBalanceWarning {...this.props} />}
          <Card className="p-3 mb-4">
            {/*<Input type="textarea" placeholder="Message to display on the League homepage." value={this.state.motd} onChange={this.handleChange} rows="6"/>*/}
            <Editor
              editorState={this.state.editorState}
              wrapperClassName="wrapper-class"
              editorClassName="editor-class"
              toolbarClassName="toolbar-class"
              editorStyle={{border:'1px solid #F1F1F1', height:'200px'}}
              onEditorStateChange={this.onEditorStateChange}
            />
            <div style={{fontSize: "0.75em", color: "#888"}} className="my-2">
              {'An empty message will hide the commissioner announcement on the League home page.'}
            </div>
            <div>
              <Button color="secondary" className="mx-1" onClick={()=>this.clear()} disabled={!this.props.activeLeague || this.state.motd === ''}>
                {'Clear'}
              </Button>
              <Button color="primary"  className="mx-1" onClick={()=>this.save()} >
                {'Save'}
              </Button>
            </div>
          </Card>

          {this.state.clubSlug !== null &&
            <Card className="p-3 mb-4">
              <div className="pb-2" style={{color: "#999"}}>
                <p>Your league has a dedicated web address. Share this address in emails, newsletters, online posts or where ever else you communicate with your members.</p>
                <p>
                  <span className="mr-2" style={{color: "#555"}}> {window.location.host + '/club/'+this.state.clubSlug} </span>
                  <CopyToClipboard text={window.location.origin + '/club/'+this.state.clubSlug}>
                    <Button color="primary" size="sm" outline><FontAwesome name="copy" /> Copy</Button>
                  </CopyToClipboard>
                </p>
              </div>
            </Card>
          }

          {this.state.isCup &&
            <div className="mb-2" style={{fontSize: "1.75em", fontWeight: 300}}>
              {'Cup Announcement'}
            </div>
          }
          {this.state.isCup &&
            <Card className="p-3 mb-4">
              <Input type="textarea" placeholder="Message to display on the League homepage."
                value={this.state.cupMsg} onChange={this.handleCupMsgChange} rows="6"/>
              <div style={{fontSize: "0.75em", color: "#888"}} className="my-2">
                {'An empty message will hide the commissioner announcement on the Cup home page.'}
              </div>
              <div>
                <Button color="secondary" className="mx-1" onClick={()=>this.clearCupMsg()} disabled={!this.props.activeLeague || this.state.cupMsg === ''}>
                  {'Clear'}
                </Button>
                <Button color="primary"  className="mx-1" onClick={()=>this.saveCupMsg()} disabled={!this.props.activeLeague || this.state.orig_cupMsg === this.state.cupMsg}>
                  {'Save'}
                </Button>
              </div>
            </Card>
          }
        </ContentWrapper>
      </div>
    )
  }
}
