import React from 'react';

import LandingWrapper from 'common/LandingWrapper.js';

import {
  Container
} from 'reactstrap';

export class LandingPrivacy extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <LandingWrapper {...this.props}>
        <PrivacyContent />
      </LandingWrapper>
    )
  }
}

export class AuthedPrivacy extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <Container className="py-5">
        <PrivacyContent />
      </Container>
    )
  }
}

export const PrivacyContent = (props) => (
  <div className="landingCard text-justify mt-4" style={{fontSize: ".85em"}}>
    <h1 className="text-center">MAJORS CHALLENGE™ PRIVACY POLICY</h1>

    <h5>1. SCOPE OF PRIVACY POLICY</h5>

    <p>
    Majors Challenge™ is committed to protecting your privacy in conjunction with the use of its
website. This privacy policy describes, among other things, the types of information collected
about you when you visit our website, how your information may be used and disclosed, how
you can control the use of your information, and how your information is protected. It shall be
interpreted in conjunction with our Terms of Service.
    </p>

    <p>
    By using our website and/or service, you consent to the worldwide, perpetual collection, transfer,
manipulation, storage, disclosure and other use of your information as described in this Privacy
Policy.
    </p>

    <p>
    Please note that any content you put on our webpage is considered personally identifiable
information pursuant to our policy and may be republished by us.
    </p>

    <h5>2. INFORMATION COLLECTED BY US AND ITS USE</h5>

    <p>
    You are not generally required to provide information about yourself when you visit our website.
However, should you wish to register for certain services, access certain content, or directly
contact the website, you may be asked for some or more of the following information:
    </p>
    <ul>
      <li>Contact information, such as your name, email address, postal address, zip code, phone
number, and other related information</li>
      <li>User name, password, and password hint questions</li>
      <li>Location/domicile</li>
      <li>Age and date of birth</li>
      <li>Other demographic information such as gender and job information</li>
      <li>Information for maintaining a public profile</li>
      <li>Preferred means of communication</li>
      <li>Information related to any other correspondence you send to us</li>
    </ul>
    <p>
    In addition, should you wish to use certain of our services, you may be asked to provide the
following:
    </p>
    <ul>
      <li>Payment verification information, such as credit card number or PayPal account number
and the email address linked to your PayPal account</li>
      <li>Social security number or comparable national identifier</li>
      <li>Other information as related to processing your payment and confirming your payment
information, age and location</li>
    </ul>
    <p>
    Finally, should you win any of our contests, you may be asked to provide the following:
    </p>
    <ul>
      <li>Relevant information about yourself needed to assist us in marketing future contests</li>
      <li>Information required to provide the proper tax forms related to your winnings</li>
      <li>Relevant information as requested by our insurance provider, Hedgehog Risk Solutions
Ltd., or any subsequent contest insurance provider, as requested to confirm your identity
and confirm your eligibility to collect a prize.</li>
    </ul>

    <h5>3. INFORMATION COLLECTED AUTOMATICALLY IN CONJUNCTION WITH
THIRD PARTIES</h5>

    <p>
    The following information may be collected automatically from all visitors to the website by
either us or third parties acting on our behalf and may be used for internal customer tracking and
business purposes, as well as any other purposes to support our business and comply with
matters of law:
    </p>
    <ul>
      <li>Your browser type and operating system</li>
      <li>Your Internet Protocol (“IP”) address</li>
      <li>Sites you visited before and after visiting our site</li>
      <li>Web pages and advertisements you view and links you click on from our site</li>
      <li>Other unique identifiers, including mobile device identification numbers that can identify
the physical location of your device in accordance with applicable law</li>
      <li>Information collected through cookies, web beacons and other technologies</li>
      <li>Standard server log information</li>
      <li>Other information collected naturally by Google Analytics and similar programs</li>
    </ul>

    <h5>4. HOW WE SHARE AND DISCLOSE YOUR INFORMATION</h5>

    <p>We only use payment and identity verification information (such as credit card numbers, driver’s
    license numbers and social security numbers, or comparable national identifiers as necessary):
    for completing particular transactions; if we believe that it is reasonably necessary to comply
    with a law, regulation or legal request; to protect the safety of any person; to protect the
    intellectual property rights of others; to address fraud, security or technical issues; or to protect
    our rights or property.</p>

    <p>All other information collected about you may be used for a wide range of other purposes,
    including to fulfill requests for services, to enable you to participate in contests and polls, to
    analyze the demographics of our customer base, to customize content and advertising to your
    needs, to market our brand, contests and winners, to share with actual and potential sponsors for
    joint marketing purposes, to share with, or sell to, third parties to the extent permitted by law, to
    prevent potentially prohibited or illegal use of our site and services or use of our site and services
    in a manner that would violate the Terms of Service, and for any other purpose disclosed to you,
    or reasonably known by you, at the time we collect the information.</p>

    <h5>5. CHANGING AND REMOVING INFORMATION</h5>

    <p>If you choose to share information with us, you may have the opportunity to update that
    information on our website or by contacting us at the following address:
    Legal@MajorsChallenge.com. If you ask us to shut down your account or delete your
    information, we will disable your account within a reasonable period of time. Please note,
    however, that we may retain certain information about you to satisfy both our legal and security
    obligations. For example, some of your information may remain in backup storage even if you
    ask for it to be deleted. In some cases you may be entitled under local laws to access or object to
    the processing of information that we hold relating to you.</p>

    <h5>6. PASSWORD PROTECTION</h5>

    <p>We are committed to protecting your information. All password protected sections of the
    website are encrypted with standard security measures. All user profiles and payment screens are
    password protected.</p>

    <h5>7. CALIFORNIA USERS</h5>

    <p>Residents of the State of California may request a list of all third parties to which Majors
    Challenge™ has disclosed certain information during the previous year for direct marketing
    purposes. If you are a California resident and want such a list, please contact us at the following
    address: legal@majorschallenge.com. For all requests, you must put the statement “Your
    California Privacy Rights” in the body of the request, as well as include your name, street
    address, city, state and zip code. Please note that we will not accept requests via the telephone,
    mail or facsimile, and we are not responsible for notices that are not labeled or otherwise
    improperly sent, or which have other incorrect information.</p>

    <h5>8. USERS IN THE EUROPEAN ECONOMIC AREA</h5>

    <p>The European Union’s General Data Privacy Regulation took effect on May 25, 2018, and it is
    intended to protect the data of European Union data subjects. If you are a resident of the
    European Economic Area (“EEA”), or are accessing this site from within the EEA, you may
    have the right to request: access; correction; deletion; portability; and restriction or objection to
    processing, of your personal data, from us. To make any of these requests, please contact
    legal@majorschallenge.com.</p>

    <h5>9. INTERNATIONAL USERS</h5>

    <p>Our website is hosted in the United States and is intended for and directed to users in North
    America. If you are a user accessing the website from the European Union, Asia, or any other
    region with laws or regulations governing personal data collection, use, and disclosure that differ
    from the laws of the United States, please be advised that through your continued use of our
    website, you consent to jurisdiction in the United States exclusively and exclusively United
    States choice of law pursuant to our Terms of Service. Further, pursuant to U.S. law, this notice
    and our Terms of Service, you are hereby transferring your personal information to the United
    States and you consent to that transfer.</p>

    <h5>10. OUR POLICY TOWARD MINORS</h5>

    <p>Our website is not for the use of minors. If we learn that we have collected personal information
    from anyone under the age of majority (this is at least 18 in most states, 19 years of age in
    Nebraska and Alabama, and 21 years of age for activities related to our services in
    Massachusetts), we will delete that information as quickly as possible. If you believe that we
    might have any information from someone under the age of majority, please contact us at
    legal@majorschallenge.com. In those instances where Majors Challenge™ has actual
    knowledge that it has collected information from a minor, it will comply with all applicable laws
    including the U.S. Children’s Online Privacy Protection Act, which applies to the collection of
    information from children under the age of 13.</p>

    <h5>11. USER GENERATED CONTENT</h5>

    <p>Certain aspects of our site may enable users to submit their own content for contests, blogs,
    message boards and other function. Publication of any such content is subject to our Terms of
    Service. Please remember that any information you disclose, as well as any video and audio
    images you provide, becomes public information and thus you should exercise caution when
    considering what information to disclose in this manner. We cannot necessarily prevent such
    information from being used in a manner that may violate this Privacy Policy, the law or your
    personal privacy and safety.</p>

    <h5>12. THIRD PARTY SITES</h5>

    <p>When you select a link to a third party website (for example, if you link to PayPal in order to pay
    for a service or product), you will be leaving our site. Any information that you disclose to a
    third party site will be governed by that site’s privacy policy.</p>

    <h5>13. MERGER, ACQUISITION SALE OR BANKRUPTCY</h5>

    <p>In the event that Majors Challenge™ is involved in a merger, acquisition, sale, bankruptcy, or
    other reorganization related transaction (e.g., insolvency, reorganization, assignment for the
    benefit of creditors, or any other transaction changing either ownership or control of the
    company), there may be a disclosure of your personally identifiable information to another entity
    that is related to that transaction or event. We cannot control the manner in which that
    information is treated, transferred, protected, or used by such other entity.</p>

    <h5>14. CHANGES TO THIS POLICY</h5>

    <p>We may change this Privacy Policy pursuant to the procedures outlined in our Terms of Service.
    Unless otherwise noted, our current Privacy Policy applies to all information that we have about
    you and your account. If we make changes to this Privacy Policy we will notify you by
    publication here. By continuing to access or use the Service after such changes take effect, you
    agree to be bound by the revised Privacy Policy.</p>

    <h5>15. QUESTIONS ABOUT THIS POLICY</h5>
    <p>If you have any questions regarding this Privacy Policy please contact us at
    legal@majorschallenge.com and we will make an effort to respond within a reasonable time
    period. In addition, you may submit questions or comments using the postal address below:
    Majors Challenge, 95 West Main Street, Suite 5-171, Chester, NJ 07930.</p>
  </div>
)
