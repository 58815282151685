import React from 'react';
import {
  Modal,ModalBody,Spinner,
} from 'reactstrap';

//import FontAwesome from 'react-fontawesome';

import 'common/McModal.css'
import 'common/FeedbackModal.css';

export default class LoadingModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: this.props.isLoading,
    };
    this.toggle = this.toggle.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isLoading !== this.props.isLoading) {
      //this.toggle();

    }
    if (this.props.modalScroll){

    }else{
      if (!this.props.isLoading){
        document.querySelector('body').classList.remove('modal-open');
      }else{
        document.querySelector('body').classList.add('modal-open');
      }
    }
  }

  toggle(event) {
    this.setState({
      modal: !this.state.modal,
    });

    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  render() {
    return (
      <Modal isOpen={this.props.isLoading} className="loading">
        <ModalBody>
        <div className=" text-center py-2">
        <Spinner
          color="light"
          style={{
            height: '3rem',
            width: '3rem'
          }}
        >
          Loading...
        </Spinner>
        </div>
        </ModalBody>
      </Modal>
    )
  }
}
