import React from 'react';
import {
  Row,
} from 'reactstrap';

import FontAwesome from 'react-fontawesome';

import { get_app_page_configs, add_user_clicks } from 'utils/api.js';

import 'common/Banner.css';

export default class Banner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      leagueId: props.leagueId,
      configGrp: props.configGrp,
      appPageConfig: {},
      loaded: false,
    };

    this.fetchBanngerConfig = this.fetchBanngerConfig.bind(this);
    this.addUserClicks = this.addUserClicks.bind(this);
  }

  componentDidMount() {
    this.fetchBanngerConfig();
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      leagueId: nextProps.leagueId,
      configGrp: nextProps.configGrp,
    });
    this.fetchBanngerConfig();
  }

  fetchBanngerConfig() {
    if (!this.state.configGrp) {
      return;
    }

    get_app_page_configs(this.state.configGrp, this.state.leagueId,
    ({appPageConfig}) => {
      this.setState({
        appPageConfig,
        loaded: true,
      });
    }, (error) => {
      console.log(error);
    });

  }

  addUserClicks() {
    if (!this.state.configGrp) {
      return;
    }

    let appPageConfig = this.state.appPageConfig;
    window.open(appPageConfig['BANNER_CLICK_URL'], "_blank")
    add_user_clicks(this.state.leagueId, appPageConfig['BANNER_DSKTP_IMG_URL'], appPageConfig['BANNER_CLICK_URL'],
    ({}) => {

    }, (error) => {
      console.log(error);
    });

  }

  stillLoading() {
    return (
        <div className=" text-center py-2">
          <FontAwesome name="spinner" size="4x" spin />
        </div>
    )
  }

  errorOccured() {
    return (
        <div className=" text-center py-5">
          {this.state.error}
        </div>
    )
  }


  render() {
    let appPageConfig = this.state.appPageConfig;
    console.log('test');
    if (!appPageConfig || !appPageConfig['ADV_SHOW'] || appPageConfig['ADV_SHOW'] === 'N' || appPageConfig['BANNER_SHOW'] === 'N'){
      return <></>;
    }

    if (appPageConfig['ADV_TYPE'] && appPageConfig['ADV_TYPE'] === 'TEXT'){
      let msgTxt = '';
      if (appPageConfig['MSG_TXT']){
        msgTxt = appPageConfig['MSG_TXT'];
      }

      let msgClickUrl = '';
      if (appPageConfig['MSG_CLICK_URL']){
        msgClickUrl = appPageConfig['MSG_CLICK_URL'];
      }

      let msgTxtCssCls = '';
      if (appPageConfig['MSG_TXT_CSS_CLS']){
        msgTxtCssCls = appPageConfig['MSG_TXT_CSS_CLS'];
      }
      let msgContainerCls = 'p-1 ';
      msgContainerCls = msgContainerCls + ' ' + msgTxtCssCls;
      let msgStrs = msgTxt.split('**');

      let rowClass = 'd-flex justify-content-center';
      if (this.props.textAlign === 'left'){
        rowClass = 'd-flex justify-content-start';
      }

      return (
        <Row noGutters className={rowClass}>
          <div className={msgContainerCls}>
            <span dangerouslySetInnerHTML={{ __html: msgStrs[0] }}></span>
            {
              msgStrs.length > 1 &&
              <span onClick={()=> (msgClickUrl.length>3?window.open(msgClickUrl, "_blank"):undefined)}
                className="linkComponent">
                {msgStrs[1]}
              </span>
            }
            {
              msgStrs.length > 2 &&
                <span>{msgStrs[2]}</span>
            }
          </div>
        </Row>
      )
    }

    console.log(appPageConfig['BANNER_SHOW']);
    if ((appPageConfig['ADV_TYPE'] && appPageConfig['ADV_TYPE'] === 'BANNER') || appPageConfig['BANNER_SHOW'] === 'Y'){
      console.log(appPageConfig['BANNER_SHOW']);
      let baseURL = 'https://media.majorschallenge.com/leaderboardAdvBanner/';
      let bannerSrcFileNmDesktop = baseURL + 'regularLeagueDesktop.png';
      let bannerSrcFileNmMobile = baseURL + 'regularLeagueMobile.png';

      if (appPageConfig['BANNER_DSKTP_IMG_URL']){
        bannerSrcFileNmDesktop = baseURL + appPageConfig['BANNER_DSKTP_IMG_URL'];
      }
      if (appPageConfig['BANNER_MOB_IMG_URL']){
        bannerSrcFileNmMobile = baseURL + appPageConfig['BANNER_MOB_IMG_URL'];
      }
      let tm = new Date().getTime();
      const bannerStyleDesktop = {backgroundImage: 'url(' + bannerSrcFileNmDesktop + '?timestamp='+tm+ ')'};
      const bannerStyleMobile = {backgroundImage: 'url(' + bannerSrcFileNmMobile + '?timestamp='+tm+ ')'};

      if (appPageConfig['BANNER_HEIGHT']){
        bannerStyleDesktop['height'] = appPageConfig['BANNER_HEIGHT'] + 'px';
        bannerStyleMobile['height'] = appPageConfig['BANNER_HEIGHT'] + 'px';
      }
      if (appPageConfig['BANNER_SPACE_COVERAGE']){
        bannerStyleDesktop['backgroundSize'] = appPageConfig['BANNER_SPACE_COVERAGE'];
        bannerStyleMobile['backgroundSize'] = appPageConfig['BANNER_SPACE_COVERAGE'];
      }

      let bannerClickURL = '';
      if (appPageConfig['BANNER_CLICK_URL']){
        bannerClickURL = appPageConfig['BANNER_CLICK_URL'];
        bannerStyleDesktop['cursor'] = 'pointer';
      }

      return (
        <Row noGutters className="d-flex justify-content-center">
          <div className="d-sm-none promoBannerMobile"
            style={bannerStyleMobile}
            onClick={()=> (bannerClickURL.length>3?this.addUserClicks():undefined)}>

          </div>
          <div className="d-none d-sm-block promoBannerDesktop"
            style={bannerStyleDesktop}
            onClick={()=> (bannerClickURL.length>3?this.addUserClicks():undefined)}>

          </div>
        </Row>
      )
    }

  }
}
