import React from 'react';
import {
  Modal, ModalHeader, ModalBody, ModalFooter,
  Button,
  Input,
} from 'reactstrap';

import {
  share_feedback,
} from 'utils/api.js';

import 'common/McModal.css'
import 'common/FeedbackModal.css';

export default class FeedbackModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      type: '',
      details: '',
      error: null
    };

    this.toggle = this.toggle.bind(this);
    this.send = this.send.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  toggle() {
    if (!this.state.modal) {
      // this.fetchEventSettings(this.props.activeLeague, this.props.event.eventid)
    }

    this.setState({
      modal: !this.state.modal,
    });
  }

  send() {
    share_feedback(this.state.type, this.state.details,
    ({success, error}) => {
      if (success) {
        this.setState({
          type: '',
          details: '',
          modal: false,
          error: null,
        });
      } else {
        this.setState({error: 'An unexpected error occurred. Please try again later.'});
      }
    }, (error) => {
      this.setState({error: 'An unexpected error occurred. Please try again later.'});
    });
  }

  handleChange(event) {
    const details = event.target.value;
    this.setState({ details });
  }

  render() {
    return <span>
      <span onClick={this.toggle} className="feedbackRenderComponent">
        {this.props.component}
      </span>
      <Modal isOpen={this.state.modal} toggle={this.toggle} className="rulesModal">
        <ModalHeader toggle={this.toggle} className="mcModalHeader">{'Contact Us'}</ModalHeader>
        <ModalBody>
          <div className="feedbackTitle my-1">
            {'Feedback Type'}
          </div>
          <div className="d-flex justify-content-between text-center">
            <Button color="success" outline={this.state.type !== 'Service Request'} className="typeButton m-1" onClick={()=>{this.setState({type: 'Service Request'})}}>
              <div className="typeTitle">{'Divot'}</div>
              <div className="typeDesc">{'(Service Request)'}</div>
            </Button>
            <Button color="success" outline={this.state.type !== 'Suggestion'} className="typeButton m-1" onClick={()=>{this.setState({type: 'Suggestion'})}}>
              <div className="typeTitle">{'Caddie'}</div>
              <div className="typeDesc">{'(Suggestion)'}</div>
            </Button>
            <Button color="success" outline={this.state.type !== 'Question'} className="typeButton m-1" onClick={()=>{this.setState({type: 'Question'})}}>
              <div className="typeTitle">{'Eagle'}</div>
              <div className="typeDesc">{'(Question)'}</div>
            </Button>
          </div>
          <div className="feedbackTitle mt-4 mb-1">
            {'Details'}
          </div>
          <Input type="textarea" rows={6} placeholder="Please tell us the details." value={this.state.details} onChange={this.handleChange} />
        </ModalBody>
        <ModalFooter>
          {
            this.state.error && <div style={{color: "red", textAlign: "left", paddingBottom: "1rem"}}>{this.state.error}</div>
          }
          <Button color="primary" disabled={this.state.details === ''} onClick={this.send}>
            {'Share Feedback'}
          </Button>
        </ModalFooter>
      </Modal>
    </span>
  }
}
