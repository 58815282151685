import React from 'react';

import {
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';

import PastEntries from 'common/PastEntries.js';

import {
  get_past_entries
} from 'utils/api.js';
import { CURRENT_SEASON } from 'utils/constants';

import {
  MobileHeader,
  ContentWrapper
} from 'common/ProfileComponents.js';

export default class ProfilePastEntries extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      pastEntries: [],
      season: CURRENT_SEASON+"",
      seasonDropdownOpen: false,
    }
    this.fetchPastResults = this.fetchPastResults.bind(this);
    this.renderHeader = this.renderHeader.bind(this);
    this.renderLoading = this.renderLoading.bind(this);
    this.toggleSeasonDropdown = this.toggleSeasonDropdown.bind(this);
  }
  componentDidMount() {
    this.fetchPastResults(this.state.season);
  }

  fetchPastResults(season) {
    this.setState({season});
    get_past_entries(season,
      ({past}) => {
      this.setState({loading: false, pastEntries: past});
    }, (error) => {
      console.log(error);
    });
  }

  toggleSeasonDropdown() {
    this.setState({
      seasonDropdownOpen: !this.state.seasonDropdownOpen
    });
  }


  renderHeader() {
    return <MobileHeader to="Completed Events" />
  }
  renderLoading() {
    return (
      <div>
        {this.renderHeader()}
      </div>
    )
  }
  render() {
    if (this.state.loading) {
      return this.renderLoading();
    }

    const seasons = ['2021','2022','2023','2024'].reverse().map((season) =>
      <DropdownItem key={season} onClick={() => this.fetchPastResults(season)}>
        {season}
      </DropdownItem>
    )

    return (
      <div>
        {this.renderHeader()}
        <ContentWrapper>
          <div className="d-flex justify-content-end">
            <div className="mr-2 mt-1" style={{fontSize:'1em'}}>Season:</div>
            <div>
              <Dropdown isOpen={this.state.seasonDropdownOpen} toggle={this.toggleSeasonDropdown} size="sm"
              className="tournamentSeason_dropdown">
                <DropdownToggle caret className="golferModalOptionRow_dropdowntoggle">
                  {this.state.season}
                </DropdownToggle>
                <DropdownMenu>
                  {seasons}
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
          {
            this.state.pastEntries && this.state.pastEntries.length ?
            <div className="d-flex justify-content-center pt-3" style={{fontSize: ".85em"}}>
            <PastEntries events={this.state.pastEntries} />
            </div>:
            <div className="mr-auto text-left" style={{fontSize: "1.2em", fontWeight: 300}}>We didn't find any completed events that you have participated in.</div>
          }
        </ContentWrapper>
      </div>
    )
  }
}
