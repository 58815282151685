import React from 'react';


import {
  Button,
  Modal,ModalHeader,ModalBody,ModalFooter,
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';

import { Bootstrap2Toggle } from 'react-bootstrap-toggle';
import "utils/bootstrap2-toggle.css";

import FontAwesome from 'react-fontawesome';

import {
  add_sr_tournaments,
} from 'utils/api.js';
import { CURRENT_SEASON } from 'utils/constants';


import 'common/McModal.css'


export default class AddTournamentsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: props.modal,
      srseason: '',
      mcseason: '',
      srIds: '',
      major: false,
      seasonDropdownOpen: false,
      submitInProgress: false,
      settings: {loading: true,},
    };

    this.toggle = this.toggle.bind(this);
    this.toggleSeasonDropdown = this.toggleSeasonDropdown.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {

  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      modal: nextProps.modal,
      srseason: nextProps.srseason,
      mcseason: nextProps.mcseason,
      srIds: nextProps.srIds,
    });
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
    this.props.openAddEventModal(null);
  }

  toggleSeasonDropdown() {
    this.setState({
      seasonDropdownOpen: !this.state.seasonDropdownOpen
    });
  }

  handleSubmit(event){
      this.setState({submitInProgress:true});
      add_sr_tournaments(this.props.tour, this.state.srseason, this.state.mcseason, this.state.srIds, this.state.major,
      ({msg}) => {
        this.setState({submitInProgress:false});
        this.toggle();
        this.props.setSeason(this.state.mcseason);
      }, (error) => {
        console.log(error);
      });
      event.preventDefault();
    }

  render() {
    const seasons = [(CURRENT_SEASON-1),CURRENT_SEASON].reverse().map((season) =>
      <DropdownItem key={season} onClick={() => this.setState({mcseason:season})}>
        {season}
      </DropdownItem>
    )

    return <>

      <Modal isOpen={this.state.modal} toggle={this.toggle} style={{maxWidth:'300px'}}>
        <ModalHeader toggle={this.toggle} >Fix Tournament Details</ModalHeader>
        <ModalBody >
          <div className="pt-2 pb-2 d-flex justify-content-between">
            <div>MC Season:</div>
            <div>
              <Dropdown isOpen={this.state.seasonDropdownOpen} toggle={this.toggleSeasonDropdown} size="sm"
              className="tournamentSeason_dropdown">
                <DropdownToggle caret className="golferModalOptionRow_dropdowntoggle">
                  {this.state.mcseason}
                </DropdownToggle>
                <DropdownMenu>
                  {seasons}
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
          <div className="pt-2 pb-2 d-flex justify-content-between">
            <div>Major:</div>
            <div>
              <Bootstrap2Toggle
                onClick={(state)=>{this.setState({major:state})}}
                on={'YES'}
                off={'NO'}
                size="sm"
                offstyle="danger"
                active={this.state.major}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.handleSubmit} >
              {this.state.submitInProgress === true && <FontAwesome name="spinner" className="mr-2" spin />}
              Add
          </Button>
        </ModalFooter>
      </Modal>
    </>
  }
}
