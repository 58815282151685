import React from 'react';

import {
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';

export default class CutCountsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };

    this.toggle = this.toggle.bind(this);
    this.computeCutCounts = this.computeCutCounts.bind(this);
  }

  toggle() {
    if (!this.state.modal) {
      this.computeCutCounts()
    }

    this.setState({
      modal: !this.state.modal,
    });
  }

  computeCutCounts() {
    console.log('compute cut counts');
    //console.dir(this.props.mcData);
    /*let cutCounts = {
      "0": 0
    };
    for (const row of this.props.mcData.leaderboard) {
      const cuts = row.cuts;
      if (!cutCounts.hasOwnProperty(cuts)) {
        cutCounts[cuts] = 1;
      } else {
        cutCounts[cuts] += 1;
      }
    }*/
    this.setState({cutCounts: this.props.mcData.cut_counts});
  }

  render() {
    return <span>
      <span onClick={this.toggle} className="renderComponent">
        Cut Counts
      </span>
      <Modal isOpen={this.state.modal} toggle={this.toggle}>
        <ModalHeader toggle={this.toggle} className="mcModalHeader">{'Cut Counts'}</ModalHeader>
        <ModalBody className="px-5 pt-3 pb-4">
          <CutCounts cutCounts={this.state.cutCounts} />
        </ModalBody>
      </Modal>
    </span>
  }
}

const CutCounts = ({cutCounts}) => {
  if (!cutCounts) {
    return null;
  }
  console.log(cutCounts);

  const items = Object.keys(cutCounts).map((numcuts) => (
    <div style={{paddingLeft: "5rem", paddingRight: "5rem"}} className="d-flex justify-content-between" key={numcuts}>
      <div>{numcuts}</div>
      <div>{cutCounts[numcuts]}</div>
    </div>
  ))
  return (
    <div>
      <div style={{paddingLeft: "4rem", paddingRight: "4rem"}} className="d-flex justify-content-between">
        <h6 style={{color: "#a1a1a1"}}># Cuts</h6>
        <h6 style={{color: "#a1a1a1"}}># of Teams</h6>
      </div>
      {items}
    </div>
  )
}
