import React from 'react';

import {
  Card,
  Table,
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
  Input
} from 'reactstrap';

import {
  MobileHeader,
  ContentWrapper
} from 'common/AdminComponents.js';


import {
  get_charity_leagues,
  update_charity_league,
} from 'utils/api.js';

import 'pages/admin/Admin.css'
import 'common/McModal.css';

export default class CharityLeague extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      actionsOpen: [],
      charityLeagues: [],
      statusFilter:'IN_REVIEW',
    };


  }

  componentDidMount() {
    this.fetchCharityLeagues(this.state.statusFilter);
  }

  toggleActions = (id) => {
    let actionsOpen = this.state.actionsOpen;
    actionsOpen[id] = !actionsOpen[id];
    this.setState({actionsOpen: actionsOpen});
  }

  fetchCharityLeagues = (status) => {
    get_charity_leagues(status,
    ({charityLeagues}) => {
      this.setState({
        charityLeagues,
      });
    }, (error) => {
      console.log(error);
    });
  }

  handleFilterChange = (status) => {
    this.setState({status});
    this.fetchCharityLeagues(status);
  }

  handleChange = (event) => {
    let val = event.target.value;
    this.setState({[event.target.name]: val});
    if(event.target.name === 'statusFilter'){
      this.fetchCharityLeagues(val);
    }
  }


  handleStatusChange = (charityid, newStatus) => {
    update_charity_league(charityid,'status', newStatus,
    ({success, error, charity}) => {
      if (success) {
        this.fetchCharityLeagues(this.state.statusFilter);
      } else {
        console.log(error);
      }
    }, (error) => {
      console.log(error);
    });
  }


  render() {

    const rows = this.state.charityLeagues.map((charity, index) => {
      let action = null;
      let actionOpen = (this.state.actionsOpen[charity.id]);

      let stsChangeApprove = ['IN_REVIEW','REJECTED'].indexOf(charity.status) > -1  &&
                          <DropdownItem onClick={()=>{this.handleStatusChange(charity.id,'APPROVED')}}>Approve</DropdownItem>

      let stsChangeReject = ['IN_REVIEW','APPROVED'].indexOf(charity.status) > -1  &&
                          <DropdownItem onClick={()=>{this.handleStatusChange(charity.id,'REJECTED')}}>Reject</DropdownItem>
      let stsChangeReview = ['REJECTED','APPROVED'].indexOf(charity.status) > -1  &&
                          <DropdownItem onClick={()=>{this.handleStatusChange(charity.id,'IN_REVIEW')}}>Review</DropdownItem>

      action =
        <td className="text-center">
          <Dropdown isOpen={actionOpen} toggle={()=>{this.toggleActions(charity.id)}}>
            <DropdownToggle caret color="secondary" disabled={!(stsChangeApprove || stsChangeReject)}>
              Actions
            </DropdownToggle>
            <DropdownMenu positionFixed={true}>
              {stsChangeApprove}
              {stsChangeReject}
              {stsChangeReview}
            </DropdownMenu>
          </Dropdown>
        </td>

      return (
        <tr key={index}>
          <td>
              <div className="text-nowrap">
                {charity.name}
              </div>
          </td>
          <td>
              <div className="">
                <div>{charity.contact_name}</div>
                <div>{charity.contact_email}</div>
                <div>{charity.contact_ph_no}</div>
              </div>
          </td>
          <td>
              <div className="">
                <div>{charity.address_lines}</div>
                {charity.city}{', '}{charity.state}{', '}{charity.postal}
              </div>
          </td>
          <td>
              <div className="text-nowrap">
                {charity.tax_id}
              </div>
          </td>
          <td>
              <div className="text-nowrap">
                {charity.consent_name}
              </div>
          </td>
          {action}
        </tr>
      )
    });

    const stsFilterOptions = [{cd:'IN_REVIEW',label:'NEW'},{cd:'APPROVED',label:'APPROVED'},{cd:'REJECTED',label:'REJECTED'}].reverse().map((sts, index) =>
      <option value={sts.cd} key={index}>{sts.label}</option>
    );

    const header =
      <thead>
        <tr style={{backgroundColor: "#f6f6f6", color: "#aaa", "fontSize": "0.7rem", textTransform: "uppercase"}}>
          <th>Foundation Name</th>
          <th>Contact</th>
          <th>Address</th>
          <th>Tax Id</th>
          <th>Consent Name</th>
          <th className="text-center">Action</th>
        </tr>
      </thead>

    return (
      <div>
        <MobileHeader to="Charity"/>
        <ContentWrapper>
          <Card className="pb-4">
            <Title />

            <div className="d-flex justify-content-end px-3 pb-2">
              <Input type="select"  value={this.state.statusFilter} name="statusFilter" style={{width:'200px'}}
                onChange={this.handleChange}>
                <option value={''}>{'Select'}</option>
                {stsFilterOptions}
              </Input>
            </div>

            <Table responsive>
              {header}
              <tbody style={{fontSize:'0.8rem'}}>
                {rows}
              </tbody>
            </Table>
          </Card>
        </ContentWrapper>
      </div>
    );
  }
}

const Title = (props) =>
  <h2 className="text-center p-2 pt-4" style={{color:"#212121"}}>Charity Console</h2>
