import React from 'react';

import {
  Link
} from 'react-router-dom';

import {
  // Alert,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';

import numeral from 'numeral';
import FontAwesome from 'react-fontawesome';

import {
  get_leagueeventsettings,
} from 'utils/api.js';
import { get_promo_content } from '../../utils/promoContent.js';

import 'common/McModal.css'
import 'pages/user/EventRulesModal.css';

export default class EventRulesModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      settings: {loading: true,},
    };

    this.toggle = this.toggle.bind(this);
    this.fetchEventSettings = this.fetchEventSettings.bind(this);
  }
  componentDidMount() {
    get_promo_content().then(({eventrules}) => {
      this.setState({eventrules});
    });
  }
  toggle() {

    if (!this.state.modal) {
      if (this.props.eventSettings){
        this.setState({settings:this.props.eventSettings,});
      }else{
        this.fetchEventSettings(this.props.activeLeague, this.props.event.eventid);
      }
    }

    this.setState({
      modal: !this.state.modal,
    });
  }

  fetchEventSettings(league, event) {
    get_leagueeventsettings(league, event,
    ({success, settings, error}) => {
      if (success) {
        this.setState({
          settings,
        });
      } else {
        console.log(error);
      }
    }, (error) => {
      console.log(error);
    });
  }

  render() {
    return <span>
      <span onClick={this.toggle} className="renderComponent">
        {this.props.component}
      </span>
      <Modal isOpen={this.state.modal} toggle={this.toggle} className="rulesModal">
        <ModalHeader toggle={this.toggle} className="mcModalHeader">Event Rules</ModalHeader>
        <ModalBody>
          {this.state.settings.promo && this.state.eventrules &&
            <div className="mx-4">
              <div className="rulesPromoImageContainer">
                <img src={this.state.eventrules} style={{maxWidth: "100%"}} alt="Promo banner."/>
              </div>
            </div>
            // <Alert color="success" className="mx-4" style={{font: "Roboto"}}>
            //   <h4>This is a free promotional contest</h4>
            //   <div className="my-3 d-flex justify-content-between">
            //     <div>
            //       <div className="font-weight-bold">Place</div>
            //       <div>
            //         <span>1</span>
            //         <span className="align-text-top" style={{fontSize: ".5em"}}>st</span>
            //       </div>
            //       <div>
            //         <span>2</span>
            //         <span className="align-text-top" style={{fontSize: ".5em"}}>nd</span>
            //         <span>-5</span>
            //         <span className="align-text-top" style={{fontSize: ".5em"}}>th</span>
            //       </div>
            //     </div>
            //     <div>
            //       <div className="font-weight-bold">Prize</div>
            //       <div>Bushnell Neo Ghost Golf GPS</div>
            //       <div>Dozen MajorsChallenge golf balls</div>
            //     </div>
            //   </div>
            //   <div style={{fontSize: ".85em"}}>* Winning points are for demonstration only</div>
            // </Alert>
          }
          <div className="pt-2 pb-3 d-flex justify-content-around">
            {this.state.settings.whiteLabel &&
            <Link to={`/league/${this.props.activeLeague}/help`}>Scoring and Tie Breaker Rules <FontAwesome name="external-link" /></Link>
            }
            {!this.state.settings.whiteLabel &&
            <Link to="/rules" target="_blank">Scoring and Tie Breaker Rules <FontAwesome name="external-link" /></Link>
            }
          </div>
          <SettingsContent {...this.state.settings} />
          {this.state.settings.leagueType === 'CHARITY' &&
            <div className="pt-2 pb-2 mx-3">
              <p style={{fontSize:'0.8em', color:'#999'}}>
                This is a free golf pool. A donation does not increase your chance of winning. No prize, cash or otherwise, is awarded by Majors Challenge to any participant. Proceeds from this golf pool will be made as a charitable donation to <strong>{this.state.settings.charityName}</strong> or any such predetermined similar charity at the sole discretion of Majors Challenge, in Majors Challenge's own name.
              </p>
            </div>
          }
        </ModalBody>
      </Modal>
    </span>
  }
}

const SettingsContent = (props) => {
  if (props.loading) {
    return null;
  }

  const keys = <div className="font-weight-bold">
    {props.showCup &&
      <div>{'Cup Participant'}</div>
    }
    {props.noMoreEntries &&
      <div>{'New Entries Locked'}</div>
    }
    <div>{'Maximum Total Entries'}</div>
    <div>{'Maximum Entries per User'}</div>
    {props.maxLoss && props.variablePool &&
      <div>{'Maximum Loss per Entry'}</div>
    }
    <div>{'Pool Type'}</div>
    {props.leagueType === 'CHARITY' ? '' : props.variablePool ?
      <div>{'Stakes'}</div> :
      props.classicFee > 0 ?
      <div>{'Entry Fee'}</div>:''
    }
    {props.leagueType !== 'CHARITY' &&
    <div>{'Winners\' Shares'}</div>
    }
    {props.leagueType === 'CHARITY'? '' : props.usePrizeWinnings?<div className="text-right"></div>
      :props.useFlatWinnings
      ? props.winningFlat.map((flat, index) => (<div className="text-right" key={index}>{numeral(index+1).format('0o') + ' Place'}</div>))
      : props.winningPercentages.map((percentage, index) => (<div className="text-right" key={index}>{numeral(index+1).format('0o') + ' Place'}</div>))
    }

    <div>{'Tiebreaker'}</div>
    <div>{'Leaderboard Calculation'}</div>
    <div>{'Withdraw Rule'}</div>
  </div>

  //const yesNo = (boolean) => (props.boolean ? "Yes" : "No")
  const yesNo = (boolean) => (boolean ? "Yes" : "No")

  const leaderboardSettings = [
    {code:12,val:'All 12 Golfers'},
    {code:11,val:'Top 11 Golfers'},
    {code:10,val:'Top 10 Golfers'},
    {code:9,val:'Top 9 Golfers'},
    {code:8,val:'Top 8 Golfers'},
    {code:7,val:'Top 7 Golfers'},
  ];
  let noBestGolfersScore = leaderboardSettings.find(obj => {
    return obj.code === props.noBestGolfersScore;
  });

  const withdrawAltRuleSettings = {
    'ALL_WD_CUT':'All Treated as Cut',
    'USE_ALT_IF_WD':'Sub Alt Round 1/2',
  };

  const values = <div>
    {props.showCup &&
      <div>{yesNo(props.cup)}</div>
    }
    {props.noMoreEntries &&
      <div>{yesNo(props.noMoreEntries)}</div>
    }
    <div>{props.maxEntries === 0 || props.maxEntries === '' ? "Unlimited" : props.maxEntries}</div>
    <div>{props.maxUserEntries === 0 || props.maxUserEntries === '' ? "Unlimited" : props.maxUserEntries}</div>
    {props.maxLoss && props.variablePool &&
      <div>{props.maxLoss > 0 ? numeral(props.maxLoss).format('0,0[.]00') : "Unlimited"}</div>
    }
    <div>{props.variablePool ? "Variable" : "Classic (Fixed)"}</div>
    {props.leagueType === 'CHARITY' ? '' : props.variablePool  ?
      <div>{'$' + props.variableMultiplier + '/stroke'}</div> :
      props.classicFee > 0 ?
      <div>{numeral(props.classicFee).format('$0,0[.]00')}</div>:''
    }
    {props.leagueType === 'CHARITY'? '' :
      props.usePrizeWinnings?<div>{props.noPayoutPrizes + ' Prizes'}</div>:
      <div>{'-'}</div>
    }
    {props.leagueType === 'CHARITY'? '' :
      props.usePrizeWinnings?'':
      props.useFlatWinnings
      ? props.winningFlat.map((flat, index) => (<div key={index}>{'$'+flat}</div>))
      : props.winningPercentages.map((percentage, index) => (<div key={index}>{percentage + '%'}</div>))
    }
    <div>{props.tieBreakRule==='SPLIT_POINT_CUT' ? "Split with cuts" : (props.tieBreakRule==='SPLIT_POINT_WITHOUT_CUT'? "Split without cuts" : "Match Cards")}</div>
    <div>{noBestGolfersScore.val}</div>
    <div>
      {withdrawAltRuleSettings[props.withdrawAltRule]}
    </div>
  </div>

  return <>
    <span className="d-flex justify-content-around">
      {keys} {values}
    </span>
    {
      props.addlContstRuleTxt &&
      <>
        <div className="d-flex justify-content-start font-weight-bold pt-4">{'Contest Rules'}</div>
        {/*<div className="d-flex justify-content-start"><span>{props.addlContstRuleTxt}</span></div>*/}
        <span style={{"fontSize": "0.80rem", whiteSpace: "pre-line"}}>{props.addlContstRuleTxt}</span>
      </>
    }
  </>

}
