import React, {Component} from 'react';

import LoginModal from 'common/LoginModal'

import {
  Col,
  Container,
  Navbar,
  NavbarBrand,
  Button
} from 'reactstrap';

import {
  Link
} from 'react-router-dom';

import {HashLink} from 'react-router-hash-link';

import brand from 'img/brand.png';

import 'common/LandingHeader.css';


export class LandingHeader extends Component {
  render() {
    let classes = "landingHeader";
    if (this.props.headerTransparent) {
      classes += " landingHeaderTransparent";
    }
    if (this.props.headerFixedTop) {
      classes += " fixed-top";
    }

    return (
      <Navbar light expand color="faded" className={classes}>
        <Container>
          <Col xs="3">
            <NavbarBrand href="/">
              <img src={brand} alt="Majors Challenge brand" style={{height: "42px"}}/>
            </NavbarBrand>
          </Col>
          <Col xs="0" md="4" className="d-none d-md-flex pl-md-3" style={{"zIndex": 50}}>
            <div className="px-3">
              <HashLink smooth to="/#features" className="landingHeaderLink">Features</HashLink>
            </div>
            <div className="px-3">
              <HashLink smooth to="/#howitworks" className="landingHeaderLink">How it Works</HashLink>
            </div>
            <div className="px-3">
              <Link to="/rules" className="landingHeaderLink">Rules</Link>
            </div>
            <div className="px-3">
              <a className="landingHeaderLink" href="mailto:support@majorschallenge.com">
                <span>Contact</span>
              </a>
            </div>
          </Col>
          <Col className="text-right" xs="9" md="5">
            <LoginModal {...this.props} />
            <Link to="/signup" className="d-none d-lg-none">
              <Button color="success" className="landingHeaderSignup">Create a Golf Pool</Button>
            </Link>
            <Link to="/ileague" className="d-none d-lg-inline">
              <Button color="success" className="landingHeaderSignup">Join a Golf Pool</Button>
            </Link>
          </Col>
        </Container>
      </Navbar>
    )
  }
}

export const MinimalLandingHeader = (props) => (
  <Navbar light expand color="faded" className="landingHeader landingHeaderTransparent">
    <Container>
      <Col xs="3">
        <NavbarBrand href="/">
          <img src={brand} alt="Majors Challenge brand" style={{height: "42px"}}/>
        </NavbarBrand>
      </Col>
      <Col xs="0" md="4" className="d-none d-md-flex pl-md-3">
        <div className="px-3">
          <HashLink smooth to="/#features" className="landingHeaderLink">Features</HashLink>
        </div>
        <div className="px-3">
          <HashLink smooth to="/#howitworks" className="landingHeaderLink">How it Works</HashLink>
        </div>
        <div className="px-3">
          <Link to="/rules" className="landingHeaderLink">Rules</Link>
        </div>
        <div className="px-3">
          <a className="landingHeaderLink" href="mailto:support@majorschallenge.com">
            <span>Contact</span>
          </a>
        </div>
      </Col>
      <Col className="text-right" xs="9" md="5">
        <LoginModal {...props} />
      </Col>
    </Container>
  </Navbar>
)

export const ClubLandingHeader = (props) => (
  <Navbar light expand color="faded" className="landingHeader landingHeaderTransparent">
    <Container>
      <Col xs="3">
        <NavbarBrand href="/">
          <img src={brand} alt="Majors Challenge brand" style={{height: "42px"}}/>
        </NavbarBrand>
      </Col>
    </Container>
  </Navbar>
)

export const DeepURLLandingHeader = (props) => (
  <Navbar light expand color="faded" className="landingHeader landingHeaderTransparent">
    <Container>
      <Col xs="3">
        <NavbarBrand href="/">
          <img src={brand} alt="Majors Challenge brand" style={{height: "42px"}}/>
        </NavbarBrand>
      </Col>
      <Col xs="0" md="4" className="d-none d-md-flex pl-md-3">
        <div className="px-3">
          <HashLink smooth to="/#features" className="landingHeaderLink">Features</HashLink>
        </div>
        <div className="px-3">
          <HashLink smooth to="/#howitworks" className="landingHeaderLink">How it Works</HashLink>
        </div>
        <div className="px-3">
          <Link to="/rules" className="landingHeaderLink">Rules</Link>
        </div>
        <div className="px-3">
          <a className="landingHeaderLink" href="mailto:support@majorschallenge.com">
            <span>Contact</span>
          </a>
        </div>
      </Col>
      <Col className="text-right" xs="9" md="5">
        <LoginModal autoOpen={true} {...props} />
      </Col>
    </Container>
  </Navbar>
)
