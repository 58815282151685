import React from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';

import {
  Alert,
  Badge,
  Card,
  Collapse,
  Row, Col,
  Input,
  Button,
  Table,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Progress
} from 'reactstrap';

import LoadingModal from 'common/LoadingModal.js';

import FontAwesome from 'react-fontawesome';
import { isMobPortrait, MobilePortrait, NotMobilePortrait } from 'utils/responsive.js';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'common/ConfirmAlert.css';

import CsvDownloader from 'react-csv-downloader';

import jsPDF from "jspdf";
import "jspdf-autotable";

import {SeasonDraftGameRuleAuth} from 'pages/user/Games.js';

import { Bootstrap2Toggle } from 'react-bootstrap-toggle';
import "utils/bootstrap2-toggle.css";

import {
  MobileHeader,
  ContentWrapper
} from 'common/CommissionerComponents.js';


import {
  get_lc_data, league_in_contest, set_lc_fld_value, event_in_lc, save_lc_team_golfers,
  remove_lc_team_golfers, cancel_lc_team_golfers_substitute,search_members, set_lc_team_owner
} from 'utils/api.js';

export default class SeasonDraft extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      enrolled: false,
      howToOpen: false,
      contestType: 'SEASON_DRAFT',
      eventsModal: false,
      addEditTeamModal: false,
      chooseGolferModal: false,
      selectedTeamGolferIndx:0,
      selectedGolfer: null,
      selectedGolferId: 0,
      selectedGolferIds: [],
      choosePrevTeamModal: false,
      chooseFromPrevTeam: true,
      chooseTeamOwnerModal: false,
      ruleOpen: false,
    };


  }

  componentDidMount() {
    if (this.props.activeLeague === null) { return; }

    this.fetchEnrolledData(this.props.activeLeague);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.activeLeague === nextProps.activeLeague) { return; }

    this.fetchEnrolledData(nextProps.activeLeague);
  }


  fetchEnrolledData = (league) => {
    if (!league) { return; }
    this.setState({isLoading:true});
    get_lc_data(league, this.state.contestType,
    ({success, enrolled, lc, events, prevteams, availableGolfers, golferMetrics, error}) => {
      if (success) {
        if (events){
          events.map((x) => x.actionsOpen = false);
        }
        let selectedGolferIds = [];
        if (lc && lc.teamGolfers){
          lc.teamGolfers.forEach((tg, indx1) => {
            const {golfers} = tg;
            golfers.forEach((g, indx2) => {
              selectedGolferIds.push(g.golfer_id);
            });

          });
        }
        this.setState({isLoading:false, enrolled, howToOpen: !enrolled, lc, events, prevteams, availableGolfers, selectedGolferIds, golferMetrics});
      } else {
        console.log(error);
      }
    }, (error) => {
      console.log(error);
    });
  }


  handleLCEnroll = (enrolled) => {
    if (enrolled === this.state.enrolled) { return; }

    const leaguein = (name) => {
      const league = this.props.activeLeague;
      league_in_contest(league, this.state.contestType, name, enrolled,
        (success, error) => {
          if (success) {
            this.fetchEnrolledData(league);
          } else {
            console.log(error);
          }
      }, (error) => {
        console.log(error);
      });
    }

    if (!enrolled){
      confirmAlert({
        title: 'Confirmation',
        message: 'Are you sure you want to cancel the Season Draft?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => { leaguein();}
          },
          {
            label: 'Cancel',
            onClick: () => { }
          }
        ]
      });
    }else{
      leaguein();
    }
  }


  toggleHowTo = () => {
    this.setState({
      howToOpen: !this.state.howToOpen, ruleOpen: this.state.howToOpen ? false: this.state.ruleOpen
    })
  }


  handleInputValueChange = (event) => {
    //this.setState({[event.target.name]: event.target.value});
    this.saveLCFieldValue(event.target.name,event.target.value);
  }


  saveLCFieldValue = (fldNm, fldVal) => {
    let lc = this.state.lc;
    lc[fldNm] = fldVal;
    this.setState({lc});
    set_lc_fld_value(this.props.activeLeague, this.state.lc.id, fldNm, fldVal,
    ({success, error}) => {
      if (success) {

      } else {
        console.log(error);
      }
    }, (error) => {
      console.log(error);
    });
  }


  toggleEventsModal = () => {
    this.setState({eventsModal : !this.state.eventsModal});
  }

  toggleEventRowActions = (eventid) => {
    let events = this.state.events;
    if (!events) { return; }
    const mtchEvent = events.find(({id}) => id === eventid);
    mtchEvent.actionsOpen = !mtchEvent.actionsOpen;
    this.setState({
      events:events,
    });
  }

  handleEventToggle = (eventid, enrolled) => {
    if (!this.state.events) { return; }
    //if (this.state.events.find(({id, enrolled:currEnrolled}) => id === eventid && enrolled === currEnrolled)) { return; }

    const league = this.props.activeLeague;
    this.setState({isLoading:true});
    event_in_lc(league, this.state.lc.id, eventid, enrolled,
      ({success, error}) => {
        this.setState({isLoading:false});
        if (success) {
          let events = this.state.events;
          if (eventid > 0){
            let mEvent = events.find(({id}) => id === eventid);
            mEvent.enrolled = enrolled;
          }else{
            events.map((e)=>e.enrolled=enrolled);
          }
          this.setState(events);
          this.fetchEnrolledData(league);
        } else {
          confirmAlert({
            title: 'Alert',
            message: error,
            buttons: [
              {
                label: 'Ok',
                onClick: () => { }
              }
            ]
          });
          console.log(error);
        }
    }, (error) => {
      console.log(error);
    });
  }

  toggleAddEditTeamModal = (show, teamid) => {
    this.setState({error : null});
    if (this.state.lc.eventsCnt ===0){
      confirmAlert({
        title: 'Stop',
        message: 'Please choose tournaments before adding teams',
        buttons: [
          {
            label: 'Ok',
            onClick: () => { }
          }
        ]
      });
      return;
    }

    if (this.state.addEditTeamModal === show){return;}
    if (show === true){
      this.setState({addEditTeamModal : true});
      if (teamid){ //edit existing team
        this.editTeamGolfer(teamid);
      }else{
        this.addTeamGolfer();
      }
      return;
    }
    if (this.state.addEditTeamModal && this.state.teamid > 0){
      let team = this.state.lc.teamGolfers.filter(x => x.teamid===this.state.teamid)[0];
      let selectedGolferList = []
      team.golfers.forEach((g, index) => {
        selectedGolferList.push({teamgolfer_id:g.teamgolfer_id, golfer_id: g.golfer_id,golfer_name:g.golfer_name,image:'', alt:g.alt,
                            first_contestevent_id: g.first_contestevent_id, last_contestevent_id: g.last_contestevent_id
                              });
      });
      /*if (JSON.stringify(selectedGolferList) !== JSON.stringify(this.state.selectedGolferList)){
        confirmAlert({
          title: 'Confirm',
          message: 'Unsaved data will be lost. Continue?',
          buttons: [
            {
              label: 'Continue',
              onClick: () => {this.setState({addEditTeamModal : !this.state.addEditTeamModal});}
            },
            {
              label: 'Save',
              onClick: () => {this.saveTeamGolfers();}
            }
          ]
        });
        return;
      }*/
    }
    this.setState({addEditTeamModal : !this.state.addEditTeamModal});
  }


  handleInputValueChange = (event) => {
    //this.setState({[event.target.name]: event.target.value});
    this.saveLCFieldValue(event.target.name,event.target.value);
  }

  toggleChoosePrevTeamModal = (show) =>{
    this.setState({choosePrevTeamModal: !this.state.choosePrevTeamModal});
  }

  setTeamName = (event) => {
    if (event['target']){
      this.setState({teamname: event.target.value});
      return;
    }
    if (event === 0){
        this.setState({teamname: ''});
    }else{
      this.setState({teamname: event.trim()});
    }
  }

  addTeamGolfer = () =>{
    let selectedGolferList = [
      {teamgolfer_id:0, golfer_id: 0,golfer_name:'Choose Golfer',image:'', alt:false, first_contestevent_id: null, last_contestevent_id: null},
      {teamgolfer_id:0, golfer_id: 0,golfer_name:'Choose Golfer',image:'', alt:false, first_contestevent_id: null, last_contestevent_id: null},
      {teamgolfer_id:0, golfer_id: 0,golfer_name:'Choose Golfer',image:'', alt:false, first_contestevent_id: null, last_contestevent_id: null},
      {teamgolfer_id:0, golfer_id: 0,golfer_name:'Choose Golfer',image:'', alt:false, first_contestevent_id: null, last_contestevent_id: null},
      {teamgolfer_id:0, golfer_id: 0,golfer_name:'Choose Golfer',image:'', alt:false, first_contestevent_id: null, last_contestevent_id: null},
      {teamgolfer_id:0, golfer_id: 0,golfer_name:'Choose Golfer',image:'', alt:true, first_contestevent_id: null, last_contestevent_id: null}
    ];

    this.setState({selectedTeam: {}, teamname: '', teamid:0, chooseFromPrevTeam:true, selectedGolferList,
    origSelectedGolferList: JSON.parse(JSON.stringify(selectedGolferList)), altActivated:false });
  }

  editTeamGolfer = (teamid) =>{
    let team = this.state.lc.teamGolfers.filter(x => x.teamid===teamid)[0];
    let selectedGolferList = []
    team.golfers.forEach((g, index) => {
      selectedGolferList.push({teamgolfer_id:g.teamgolfer_id, golfer_id: g.golfer_id,golfer_name:g.golfer_name,image:'', alt:g.alt,
      first_contestevent_id: g.first_contestevent_id, last_contestevent_id: g.last_contestevent_id
    });
    });
    this.setState({selectedTeam: team, teamname: team.teamname, teamid:team.teamid, chooseFromPrevTeam:false, selectedGolferList,
      origSelectedGolferList: JSON.parse(JSON.stringify(selectedGolferList)), altActivated: team.alt_activated});
  }

  toggleChooseGolferModal = (show, selectedTeamGolferIndx, selectedGolferId) => {
    if (show === true){
      this.setState({error: null, chooseGolferModal: show, selectedTeamGolferIndx, selectedGolferId, activateAlternate:false, selectedGolfer:this.state.selectedGolferList[selectedTeamGolferIndx]});
      return;
    }
    if (show === false){ // canceled the dialog
      let selectedGolferList = this.state.selectedGolferList;
      let tg = selectedGolferList[this.state.selectedTeamGolferIndx];
      if (this.state.teamid > 0){
        let team = this.state.lc.teamGolfers.filter(x => x.teamid===this.state.teamid)[0];
        let g = team.golfers.filter(x => x.golfer_id===this.state.selectedGolfer.golfer_id)[0];
        if (g){
          tg = {teamgolfer_id:g.teamgolfer_id, golfer_id: g.golfer_id,golfer_name:g.golfer_name,image:'', alt:g.alt,
                first_contestevent_id: g.first_contestevent_id, last_contestevent_id: g.last_contestevent_id
               };
        }
      }else{
        tg.golfer_id = 0;
        tg.golfer_name = 'Choose Golfer';
        tg.first_contestevent_id = null;
        tg.last_contestevent_id = null;
      }
      //selectedGolferList[this.state.selectedTeamGolferIndx] = tg;
      //selectedGolferList[this.state.selectedTeamGolferIndx] = {...this.state.origSelectedGolferList[this.state.selectedTeamGolferIndx]};
      selectedGolferList = JSON.parse(JSON.stringify(this.state.origSelectedGolferList));
      this.setState({chooseGolferModal: false, selectedTeamGolferIndx:0, selectedGolferId:0, activateAlternate:false, selectedGolferList});
      return;
    }
    //rest cases
    let selectedGolfer = this.state.selectedGolfer;
    if (selectedGolfer.alt && selectedGolfer.first_contestevent_id){
      let inactiveGolfer = this.state.selectedGolferList.filter(x => x.last_contestevent_id === 0);

      if (inactiveGolfer.length > 1){ //something is wrong
        return;
      }else if (inactiveGolfer.length === 0){
        inactiveGolfer = this.state.selectedGolferList.filter(x => x.last_contestevent_id > 0);
        if (inactiveGolfer.length === 0){
          this.setState({activateAlternate: true, error:{'inactive_golfer':'Please choose Golfer to Inactivate.'}});
          return;
        }
      }else{
        this.setState({activateAlternate: true, error:{'last_contestevent_id':'Please choose Inactive Golfer’s Last Tournament.'}});
        return;
      }
    }
    this.setState({chooseGolferModal: !this.state.chooseGolferModal, selectedTeamGolferIndx:0, selectedGolferId:0, activateAlternate:false});
  }

  selectGolfer = (golgerId) => {

    const setGolfer = (golgerId) => {
      let availableGolfers = JSON.parse(JSON.stringify(this.state.availableGolfers));
      let selectedGolfer = availableGolfers.filter(x => x.golfer_id===golgerId)[0];
      let selectedGolferList = this.state.selectedGolferList;
      selectedGolfer['teamgolfer_id'] = selectedGolferList[this.state.selectedTeamGolferIndx].teamgolfer_id;
      selectedGolfer['alt'] = selectedGolferList[this.state.selectedTeamGolferIndx].alt;
      selectedGolfer['first_contestevent_id'] = selectedGolferList[this.state.selectedTeamGolferIndx].first_contestevent_id;
      selectedGolfer['last_contestevent_id'] = selectedGolferList[this.state.selectedTeamGolferIndx].last_contestevent_id;
      selectedGolferList[this.state.selectedTeamGolferIndx] = selectedGolfer;
      this.setState({selectedGolferId:golgerId, selectedGolferList:selectedGolferList, chooseGolferModal:selectedGolfer.alt, selectedGolfer});
    }

    if (this.state.teamid > 0 && this.state.selectedGolferList[this.state.selectedTeamGolferIndx].golfer_id !== golgerId){
      confirmAlert({
        title: 'Confirmation',
        message: 'Replacing a golfer also removes all their season earnings. Continue?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              setGolfer(golgerId);
            }
          },
          {
            label: 'Cancel',
            onClick: () => { }
          }
        ]
      });
    }else{
      setGolfer(golgerId);
    }

  }

  unselectGolfer = (golgerId) => {
    let selectedGolferList = this.state.selectedGolferList;
    let selectedGolfer = {golfer_id: 0,golfer_name:'Choose Golfer',image:''};
    selectedGolfer['teamgolfer_id'] = selectedGolferList[this.state.selectedTeamGolferIndx].teamgolfer_id;
    selectedGolfer['alt'] = selectedGolferList[this.state.selectedTeamGolferIndx].alt;
    selectedGolfer['first_contestevent_id'] = selectedGolferList[this.state.selectedTeamGolferIndx].first_contestevent_id;
    selectedGolfer['last_contestevent_id'] = selectedGolferList[this.state.selectedTeamGolferIndx].last_contestevent_id;
    selectedGolferList[this.state.selectedTeamGolferIndx] = selectedGolfer;
    this.setState({selectedGolferId:0, selectedGolferList:selectedGolferList});
  }


  toggleActivateAlternate = () =>{
    this.setState({activateAlternate: !this.state.activateAlternate});
  }

  selectEventForGolfer = (val,pos) => {
    let selectedGolferList = this.state.selectedGolferList;
    if (pos === 'F'){
      let selectedGolfer = this.state.selectedGolfer;
      selectedGolfer['first_contestevent_id'] = val;
      selectedGolferList[this.state.selectedTeamGolferIndx] = selectedGolfer;
      if (!val){
        let inactiveGolfer = this.state.selectedGolferList.filter(x => x.last_contestevent_id === 0 || x.last_contestevent_id >0)[0];
        if (inactiveGolfer){
          inactiveGolfer.last_contestevent_id = null;
        }
      }
      this.setState({selectedGolferList, selectedGolfer});
    }else if (pos === 'L'){
      let inactiveGolfer = this.state.selectedGolferList.filter(x => x.last_contestevent_id === 0 || x.last_contestevent_id >0)[0];
      if (inactiveGolfer){
        inactiveGolfer['last_contestevent_id'] = val;
        this.setState({selectedGolferList});
      }
    }
  }

  selectInactiveGolfer = (val) => {
    let selectedGolferList = this.state.selectedGolferList;
    selectedGolferList.forEach((g, index) => {
      g.last_contestevent_id = null;
      if (val === g.golfer_id){
        g.last_contestevent_id = 0;
      }
    });
    this.setState({selectedGolferList});
  }

  saveTeamGolfers = () =>{
    this.setState({error : null});
    save_lc_team_golfers(this.props.activeLeague, this.state.lc.id, this.state.teamid, this.state.teamname, this.state.selectedGolferList,
    ({success, error}) => {
      if (success) {
        this.setState({addEditTeamModal : false});
        this.fetchEnrolledData(this.props.activeLeague);
      } else {
        this.setState({error : error});
        console.log(error);
      }
    }, (error) => {
      console.log(error);
    });
  }

  removeTeamGolfers = () =>{
    this.setState({error : null});
    confirmAlert({
      title: 'Confirmation',
      message: 'Are you sure you want to remove the team?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            remove_lc_team_golfers(this.props.activeLeague, this.state.lc.id, this.state.teamid,
            ({success, error}) => {
              if (success) {
                this.setState({addEditTeamModal : false});
                this.fetchEnrolledData(this.props.activeLeague);
              } else {
                this.setState({error : error});
                console.log(error);
              }
            }, (error) => {
              console.log(error);
            });
          }
        },
        {
          label: 'Cancel',
          onClick: () => { }
        }
      ]
    });

  }

  cancelSubstitute = () =>{
    this.setState({error : null});

    confirmAlert({
      title: 'Confirmation',
      message: 'Are you sure you want to cancel this substitution?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            cancel_lc_team_golfers_substitute(this.props.activeLeague, this.state.lc.id, this.state.teamid,
            ({success, error}) => {
              if (success) {
                this.setState({addEditTeamModal : false});
                this.fetchEnrolledData(this.props.activeLeague);
              } else {
                this.setState({error : error});
                console.log(error);
              }
            }, (error) => {
              console.log(error);
            });
          }
        },
        {
          label: 'Cancel',
          onClick: () => { }
        }
      ]
    });

  }

  toggleTeamOwnerModal = (show) =>{
    if (show === true){
      this.setState({chooseTeamOwnerModal: show});
      return;
    }
    this.setState({chooseTeamOwnerModal: !this.state.chooseTeamOwnerModal, ownermemberid:null, ownermembername:null, ownermemberaction:null});
  }

  searchMembers = (value, callback)=> {
    let filterOptions = [];
    if (value.trim().length < 3){
      return callback(filterOptions);
    }
    search_members(this.props.activeLeague, value.trim(),100,
     ({members, owner}) => {
       //this.setState({members});
       members.forEach((m) =>{
         filterOptions.push({
         value: m.id,
         label: m.name
         });
       });
       callback(filterOptions);

    }, (error) => {
      console.log(error);
    });
  }

  selectTeamOwner = (memberid, membername, action) =>{
    this.setState({ownermemberid:memberid, ownermembername:membername, ownermemberaction:action});
  }

  saveTeamOwner = () =>{
    const setTeamOwner = ()=>{
      set_lc_team_owner(this.props.activeLeague, this.state.lc.id, this.state.teamid,this.state.ownermemberid, this.state.ownermemberaction,
      ({success, error}) => {
        if (success) {
          let selectedTeam = this.state.selectedTeam;
          if (this.state.ownermemberaction === 'add'){
            selectedTeam.ownermembers.push({ownermemberid:this.state.ownermemberid, ownermembername: this.state.ownermembername});
          }else if (this.state.ownermemberaction === 'remove'){
            selectedTeam.ownermembers = selectedTeam.ownermembers.filter((x)=>{return x.ownermemberid !== this.state.ownermemberid;});
          }

          this.setState({chooseTeamOwnerModal : false, selectedTeam: selectedTeam});
        } else {
          this.setState({error : error});
          console.log(error);
        }
      }, (error) => {
        console.log(error);
      });
    }

    this.setState({error : null});
    if (!this.state.ownermemberid){return;}


    if (this.state.ownermemberaction === 'remove'){ // want to remove the owner
      confirmAlert({
        title: 'Confirmation',
        message: 'Are you sure you want to remove the team owner?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              setTeamOwner();
            }
          },
          {
            label: 'Cancel',
            onClick: () => { }
          }
        ]
      });
    }else if (this.state.ownermemberaction === 'add'){ // want to assign new
      let mtchMember = this.state.selectedTeam.ownermembers.filter((x)=>{return x.ownermemberid === this.state.ownermemberid;});
      if (mtchMember.length > 0){
        this.setState({error : {'teamowner': this.state.ownermembername + ' is already owner of this team.'}});
        return;
      }
      setTeamOwner();
    }




  }

  createGolferMetricsPDF = async () =>{
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Golfers";
    let headers = [];

    if (this.state.golferMetrics && this.state.golferMetrics.length >0){
      let cols = Object.getOwnPropertyNames(this.state.golferMetrics[0]);
      headers.push(cols.filter((x)=>{return x !== 'season'}));
    }
    console.log(headers);

    const data = this.state.golferMetrics.map(elt=>{
      let d = [];
      headers[0].forEach((c)=>{
        d.push(elt[c]);
      });
      return d;
    });
    console.log(data);


    let content = {
      startY: 50,
      head: headers,
      body: data
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("Draft Kit.pdf")
  }

  toggleRuleOpen = () =>{
    this.setState({ruleOpen: !this.state.ruleOpen});
  }

  render() {
    return (
      <div className="commissionerMembers">

        <MobileHeader to="Season Draft" leagueid={this.props.activeLeague} />
        <ContentWrapper>

          <LCHeader />
          <LCSubheader howToOpen={this.state.howToOpen} ruleOpen={this.state.ruleOpen} toggle={this.toggleHowTo} toggleRuleOpen={this.toggleRuleOpen}/>

          <LCParticipation {...this.state} leagueid={this.props.activeLeague}
            handleLCEnroll={this.handleLCEnroll}  handleInputValueChange={this.handleInputValueChange}
            saveLCFieldValue={this.saveLCFieldValue}
            toggleEventsModal={this.toggleEventsModal}
            handleEventToggle={this.handleEventToggle}
            toggleEventRowActions={this.toggleEventRowActions}
            toggleAddEditTeamModal={this.toggleAddEditTeamModal}
            toggleChoosePrevTeamModal={this.toggleChoosePrevTeamModal}
            setTeamName={this.setTeamName}
            selectGolfer={this.selectGolfer}
            unSelectGolfer={this.unSelectGolfer}
            toggleChooseGolferModal={this.toggleChooseGolferModal}
            saveTeamGolfers={this.saveTeamGolfers}
            removeTeamGolfers={this.removeTeamGolfers}
            cancelSubstitute={this.cancelSubstitute}
            toggleTeamOwnerModal={this.toggleTeamOwnerModal}
            searchMembers={this.searchMembers}
            selectTeamOwner={this.selectTeamOwner}
            saveTeamOwner={this.saveTeamOwner}
            createGolferMetricsPDF={this.createGolferMetricsPDF}
            />

          <PickGolfer teamid={this.state.teamid}
             chooseGolferModal={this.state.chooseGolferModal}
              golfers={this.state.availableGolfers}
              cEvents={this.state.events}
              selectedGolfer={this.state.selectedGolfer}
              selectedGolferId={this.state.selectedGolferId}
              selectedGolferIds={this.state.selectedGolferIds}
              selectedGolferList={this.state.selectedGolferList}
              altActivated={this.state.altActivated}
              toggleChooseGolferModal={this.toggleChooseGolferModal}
              selectGolfer={this.selectGolfer}
              unselectGolfer={this.unselectGolfer}
              selectEventForGolfer={this.selectEventForGolfer}
              selectInactiveGolfer={this.selectInactiveGolfer}
              activateAlternate={this.state.activateAlternate}
              toggleActivateAlternate={this.toggleActivateAlternate}
              error={this.state.error}
              firstLCEventId={this.state.lc && this.state.lc.firstLCEventId}/>

            <LCFooter />
            <LoadingModal modalScroll={true} isLoading={this.state.isLoading} />
        </ContentWrapper>
      </div>
    );
  }
}

const LCHeader = () =>
  <div className="d-flex mb-4">
    <div className="d-none d-lg-block mr-auto" style={{fontSize: "1.75em", fontWeight: 300}}>Season Draft</div>
  </div>

const LCSubheader = (props) =>
  <Alert color="success" className="my-4">
    <div className="d-flex justify-content-between py-1 font-weight-bold" onClick={props.toggle}>
      Season Draft Instructions
      <FontAwesome size="lg" name={props.howToOpen ? "angle-up" : "angle-down"} />
    </div>
    <Collapse isOpen={props.howToOpen}>
      <span style={{"fontSize": "0.80rem", whiteSpace: "pre-line"}}>
      Welcome to Season Draft Fantasy Golf! In this competition, teams select 5 PGA TOUR golfers and 1 alternate through an offline draft, typically following a snake-draft format. Over the PGA season, the earnings of each team's active golfers contribute to their respective team's total. Once per season, each team can replace their alternate with an active golfer. The team with the highest earnings at the end of the season is the winner. After completing your draft, you can add teams, owners, and golfers below. In this competition the same set of golfers remain with each team throughout the season.
      &nbsp;<span style={{color: "#157EFB", cursor: "pointer"}} onClick={props.toggleRuleOpen}>Click here </span>for full rules.
      </span>
    </Collapse>
    <Collapse isOpen={props.ruleOpen}  className="my-n4">
      <SeasonDraftGameRuleAuth embedIn={'COMMISS'}/>
    </Collapse>
  </Alert>

const LCFooter = () =>
  <div className="d-flex justify-content-center py-1 font-weight-bold ml-2 text-danger" >
        {/*New Season Starting in 2024.*/}
  </div>

const LCParticipation = (props) =>{

  let reportResultCols = [];
  if (props.golferMetrics && props.golferMetrics.length >0){
    let cols = Object.getOwnPropertyNames(props.golferMetrics[0]);
    reportResultCols = cols.filter((x)=>{return x !== 'season'}).map(a => {
      let dateformat = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/;
      let className = '';
      if(props.golferMetrics[0][a] && props.golferMetrics[0][a].toString().match(dateformat)){
        className = 'text-nowrap'
      }
      return {
        'Header': a,
        'accessor': a,
        'className': className,
      }
    });
  }
  const csvExprtColumns = reportResultCols.map(a => {
    let displayName = a.Header.replace(/\w\S*/g,
      function (txt) {
          return txt.charAt(0).toUpperCase() +
              txt.substr(1).toLowerCase();
      });

    return {
      'id': a.Header,
      'displayName': displayName,
    }
  });

  return(
  <Card body className="p-2 my-4">
    <div className="pl-2 py-2 d-flex align-items-center border-bottom">

        <div className="col-sm-8 pt-1">
          {'Participate in the Season Draft?'}
        </div>
        <div className="col-sm-4 d-flex justify-content-end">
          <Bootstrap2Toggle
            onClick={(state)=>{props.handleLCEnroll(state)}}
            on={'YES'}
            off={'NO'}
            size="sm"
            offstyle="danger"
            onstyle="success"
            active={props.enrolled}
          />
        </div>

    </div>
    {props.enrolled &&
        <div className="pl-2 pt-1">
          <div className="py-2 d-flex align-items-center border-bottom">
              <div className="col-sm-8 pt-1">
                You have <strong>{props.lc.eventsCnt}</strong> tournaments in your season
              </div>
              <div className="col-sm-4 d-flex justify-content-end">
                <Button outline className="btn-sm mt-2" color="primary" onClick={props.toggleEventsModal} >Manage Tournaments
                  <Modal isOpen={props.eventsModal} toggle={props.toggleEventsModal} className="inviteModal">
                    <ModalHeader toggle={props.toggleEventsModal} className="mcModalHeader">Tournaments</ModalHeader>
                        <ModalBody>
                          <EventsTable {...props} handleEventToggle={props.handleEventToggle}
                            toggleEventRowActions={props.toggleEventRowActions}/>

                        </ModalBody>
                    <ModalFooter>
                    </ModalFooter>
                  </Modal>
                </Button>
              </div>
          </div>

          <div className="py-2 d-flex align-items-center border-bottom">
              <div className="col-sm-8 pt-1">
                Draft Kit
              </div>
              <div className="col-sm-4 d-flex justify-content-end">
                <div className="btn-group">
                  <CsvDownloader
                    filename={'Draft Kit'}
                    separator=","
                    wrapColumnChar='"'
                    columns={csvExprtColumns}
                    datas={props.golferMetrics}>
                    {
                      props.golferMetrics.length > 0 ?
                      <Button size="sm" color="success" outline className="ml-3 ml-md-none text-nowrap"
                        style={{minWidth:'60px'}}>
                        <FontAwesome name="file-excel-o" />&nbsp;&nbsp;<FontAwesome name="download" />
                      </Button> :
                      <Button size="sm" color="secondary" outline disabled className="ml-3 ml-md-none text-nowrap"
                        style={{minWidth:'60px'}}>
                        <FontAwesome name="file-excel-o" />&nbsp;&nbsp;<FontAwesome name="download" />
                      </Button>
                    }
                  </CsvDownloader>
                  {
                    props.golferMetrics.length > 0 ?
                  <Button size="sm" color="success" outline className="ml-2 ml-md-none text-nowrap" onClick={()=>{props.createGolferMetricsPDF()}}
                    style={{minWidth:'60px'}}>
                    <FontAwesome name="file-pdf-o" />&nbsp;&nbsp;<FontAwesome name="download" />
                  </Button> :
                  <Button size="sm" color="secondary" outline className="ml-2 ml-md-none text-nowrap"
                    style={{minWidth:'60px'}}>
                    <FontAwesome name="file-pdf-o" />&nbsp;&nbsp;<FontAwesome name="download" />
                  </Button>
                  }

                </div>
              </div>
          </div>


          <Row noGutters className=" pl-3 py-1 mr-1">
            <Col xs="12" className="">
              <div className="mb-2" style={{fontSize: ".95rem"}}>
                {'Season Draft Announcement'}
              </div>
              <Input type="textarea" placeholder="Message to display." style={{fontSize:'.8rem'}} name="msg"
                value={props.lc.msg} onChange={props.handleInputValueChange} rows="6"/>
              <div style={{fontSize: "0.75em", color: "#888"}} className="my-2">
                {''}
              </div>

              <Button outline className="btn-sm mt-2" color="primary" onClick={() => {props.toggleAddEditTeamModal(true);}} >Add Team</Button>
              <AddEditTeamGolfer {...props}/>
              <TeamGolfersTable enrolled={props.enrolled} teamGolfers={props.lc.teamGolfers} firstLCEventId={props.lc.firstLCEventId} toggleAddEditTeamModal={props.toggleAddEditTeamModal}/>
            </Col>
          </Row>
        </div>
    }
  </Card>
  );
}


const EventsTable = ({enrolled, events, handleEventToggle, toggleEventRowActions}) => {

  if (!enrolled) { return null; }

  const rows = events.map((event, index) => <EventRow {...event} key={event.id} indx={index} handleEventToggle={handleEventToggle} toggleEventRowActions={toggleEventRowActions}/>)
  const eventsCnt = events.length;
  const enrolledEveCnt = events.filter((e)=> {return e.enrolled}).length;
  const allEventsEnrolled = eventsCnt>enrolledEveCnt?false:true;
  return (
    <Card body className="p-0 mt-4">
      <div className="p-2 d-flex justify-content-between">
        <div className="mt-1" style={{color: "#555", fontWeight:700}}>Tournaments this Season</div>
        <div className="">
          <Button block color="success"  onClick={()=>{handleEventToggle(0, !allEventsEnrolled);}}
                  style={{fontWeight: 600, fontSize: ".85em", textTransform:"uppercase", minWidth: "138px"}} className="pt-2 pb-2">
                  {allEventsEnrolled ? 'Unregister All' : 'Register All'}
          </Button>
        </div>
      </div>


      <Table responsive>
        <thead>
          <tr style={{backgroundColor: "#f6f6f6", color: "#7c7c7c", "fontSize": "0.7rem", textTransform: "uppercase"}}>
            <th>Tournament</th>
            <th className="text-center text-nowrap">Season Draft Event</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </Table>
    </Card>
  )
}

const EventRow = (props) => {
  const {name, dates, course, major, status} = props;

  const tournament =
    <td>
        <div style={{color: "#555", fontWeight:700}}>
          <span className="text-nowrap">{name}</span>
          { major &&
            <Badge color="success" style={{marginLeft: "0.5rem"}}>Major</Badge>
          }
          { status === "open" &&
            <Badge color="warning" style={{marginLeft: "0.5rem"}}> Open </Badge>
          }
          { ["round1", "round2", "round3", "round4"].indexOf(status) !== -1 &&
            <Badge color="info" style={{marginLeft: "0.5rem"}}> Live </Badge>
          }
        </div>
        <div style={{color: "#aaa", fontSize: ".75em"}}>{dates} &bull; {course}</div>
    </td>

    return (
      <tr>
        {tournament}
        <td><IncludeOrRemove {...props} /></td>
      </tr>
    )
}

const IncludeOrRemove = ({enrolled, status, id, actionsOpen, handleEventToggle, hasEntries, indx, toggleEventRowActions}) => {
  //const toggle = () => {toggleEventRowActions(id);};

  return <Button block color="success" outline={enrolled} onClick={()=>{handleEventToggle(id, !enrolled);}}
                  style={{fontWeight: 600, fontSize: ".85em", textTransform:"uppercase"}} className="pt-2 pb-2">
                  {enrolled ? 'Remove' : 'Register'}
          </Button>;
}

const getGolferShortName = (g) => {
  if (!g){return '';}
  var names = g.golfer_name.split(' '),
    initials = names[0].substring(0, 1).toUpperCase(),
    shortName = initials + '. ';

  if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
      shortName += names[names.length - 1];
  }
  return shortName;
}

const AddEditTeamGolfer = (props) => {
  let altGolfer = null;
  let altFirstEvent = null;
  let inctvGolfer = null;
  let inctvLastEvent = null;

  if (props.selectedGolferList && props.events && props.lc.firstLCEventId){
    altGolfer = props.selectedGolferList.filter(x => x.first_contestevent_id !== props.lc.firstLCEventId)[0];
    if (altGolfer){
      altFirstEvent = props.events.filter(x => x.lc_event_id === altGolfer.first_contestevent_id)[0];
    }
    inctvGolfer = props.selectedGolferList.filter(x => x.last_contestevent_id > 0)[0];
    if (inctvGolfer){
      inctvLastEvent = props.events.filter(x => x.lc_event_id === inctvGolfer.last_contestevent_id)[0];
    }
  }
  let addEditTeamModalMaxWidth = isMobPortrait() ? '500px' : '700px';
  return (
    <Modal isOpen={props.addEditTeamModal} className="inviteModal" style={{maxWidth: addEditTeamModalMaxWidth }}>
      <ModalHeader toggle={props.toggleAddEditTeamModal} className="mcModalHeader">Team Golfers</ModalHeader>
          <ModalBody>
            <Row>
              <Col sm="3" className="">
                <div className="mt-2 font-weight-bold">
                  {'Team Name'}
                </div>
              </Col>
              <Col>
                <div className="d-flex justify-content-start">
                  <div style={{width:'70%'}}>
                    <Input type="text" name='teamname' value={props.teamname} maxlength="25"
                      onChange={props.setTeamName}  placeholder="Enter team name"/>
                  </div>
                  {props.chooseFromPrevTeam &&
                  <PickPrevTeam prevteams={props.prevteams}
                    setTeamName={props.setTeamName}
                    choosePrevTeamModal={props.choosePrevTeamModal}
                    toggleChoosePrevTeamModal={props.toggleChoosePrevTeamModal}/>
                  }
                </div>
                {
                  (props.error && props.error.teamname) && <div style={{color: "red", fontSize:'.8rem'}} className="pt-1">{props.error.teamname}</div>
                }
              </Col>
            </Row>
            {props.teamid > 0 &&
              <>
            <Row>
              <Col sm="3" className="">
                <div className="mt-2 font-weight-bold">
                  {'Team Owner'}
                </div>
              </Col>
              <Col>
                <div className="d-flex justify-content-start">
                  <div style={{width:'70%'}} className="mt-2">
                    {props.selectedTeam.ownermembers.map((member) =>
                      <div className="d-flex justify-content-start">
                      <div>{member.ownermembername}</div>
                      {member.ownermembername &&
                        <FontAwesome name="close" className="ml-2 mt-1" onClick={() => {props.selectTeamOwner(member.ownermemberid, member.ownermembername, 'remove');props.saveTeamOwner();}}
                         style={{cursor:'pointer', lineHeight:'15px', color:'red'}}/>
                      }
                      </div>
                    )
                    }
                  </div>
                  <div className="d-none d-md-flex">
                    <ChooseTeamOwner chooseTeamOwnerModal={props.chooseTeamOwnerModal}
                    toggleTeamOwnerModal={props.toggleTeamOwnerModal} searchMembers={props.searchMembers}
                    selectTeamOwner={props.selectTeamOwner}
                    saveTeamOwner={props.saveTeamOwner}
                    error={props.error}/>
                  </div>
                </div>

              </Col>

            </Row>
            <Row>
              <div className="d-md-none mt-n1 ml-n2">
                <ChooseTeamOwner chooseTeamOwnerModal={props.chooseTeamOwnerModal}
                toggleTeamOwnerModal={props.toggleTeamOwnerModal} searchMembers={props.searchMembers}
                selectTeamOwner={props.selectTeamOwner}
                saveTeamOwner={props.saveTeamOwner}
                error={props.error}/>
              </div>
            </Row>
            </>
            }
            <Row>
              <Col sm="12" className="mt-4">
                <LCSelectedGolfersRow golferList={props.selectedGolferList} firstLCEventId={props.lc.firstLCEventId}
                toggleChooseGolferModal={props.toggleChooseGolferModal}/>

                {(props.altActivated || inctvLastEvent) &&
                  <div style={{ fontSize:'.9rem'}} className="pt-3">
                    <div className="d-flex">
                    <strong>{"Alternate Substitution Used"}</strong>
                    {props.altActivated &&
                      <Badge className="mx-2 text-white bg-danger" style={{marginTop:'2px',paddingTop:'4px', cursor:'pointer'}} color="denger" onClick={() => {props.cancelSubstitute();}}>Cancel</Badge>
                    }
                    </div>
                    <div>{getGolferShortName(inctvGolfer)} ends with {inctvLastEvent && inctvLastEvent.name}</div>
                    <div>{getGolferShortName(altGolfer)} starts with {altFirstEvent && altFirstEvent.name}</div>
                  </div>
                }

                {(!props.altActivated && !inctvLastEvent) &&
                <div style={{ fontSize:'.8rem'}} className="pt-3 d-flex justify-content-center">{"Click alternate to activate"}</div>
                }
                {
                  (props.error && props.error.golfer_id) && <div style={{color: "red", fontSize:'.8rem'}} className="pt-1 d-flex justify-content-center">{props.error.golfer_id}</div>
                }
              </Col>
            </Row>
          </ModalBody>
      <ModalFooter>
        <div className="d-flex" style={{width:'100%'}}>

          <div className="d-flex justify-content-start" style={{width:'50%'}}>
            {!props.chooseFromPrevTeam &&
            <div  style={{color: "red", fontSize: ".85em", cursor: "pointer"}} className="mt-2 mx-2" onClick={() => {props.removeTeamGolfers();}}>
              {'Remove Team'}
            </div>
            }
          </div>

          <div className="d-flex justify-content-end" style={{width:'50%'}}>
            <Button className="btn-sm mt-2" color="primary" onClick={() => {props.saveTeamGolfers();}} >Save Team</Button>
            <Button className="btn-sm mt-2 ml-2" color="primary" onClick={() => {props.toggleAddEditTeamModal(false);}} >Cancel</Button>
          </div>
        </div>
      </ModalFooter>
    </Modal>

  )
}

const ChooseTeamOwner = (props) => {

  const loadOptions = (
    inputValue: string,
    callback: (options: memberOption[]) => void
  ) => {
    setTimeout(() => {
      props.searchMembers(inputValue, callback);
    }, 10);
  };


  return (
    <Button className="btn-sm mt-1 ml-3 btn-link" style={{height: '30px', backgroundColor:'white', border: 'none'}}  onClick={() => {props.toggleTeamOwnerModal(true);}} >Assign
    <Modal isOpen={props.chooseTeamOwnerModal} className="inviteModal" style={{maxWidth: '500px' }}>
      <ModalHeader toggle={props.toggleTeamOwnerModal} className="mcModalHeader">Team Owner</ModalHeader>
          <ModalBody>
            <Row>
              <Col sm="12">
                <div >
                  <AsyncSelect cacheOptions loadOptions={loadOptions} defaultOptions isClearable
                  placeholder = {"Type 3 characters to get suggestions"}
                  onChange={(selectedOption) => {
                      console.log(selectedOption);
                      if (selectedOption){
                        props.selectTeamOwner(selectedOption.value, selectedOption.label, 'add');
                      }else{
                          props.selectTeamOwner(null, null, null);
                      }
                    }
                  }/>
                  <div  style={{fontSize: ".85em"}} className="mt-2">
                    {'Add or invite new members from Members section.'}
                  </div>
                </div>
                {
                  (props.error && props.error.teamowner) && <div style={{color: "red", fontSize:'.8rem'}} className="pt-1">{props.error.teamowner}</div>
                }
              </Col>
            </Row>

          </ModalBody>
      <ModalFooter>
        <div className="d-flex justify-content-end" style={{width:'50%'}}>
          <Button className="btn-sm mt-2" color="primary" onClick={() => {props.saveTeamOwner();}} >Save</Button>
          <Button className="btn-sm mt-2 ml-2" color="primary" onClick={() => {props.toggleTeamOwnerModal();}} >Cancel</Button>
        </div>
      </ModalFooter>
    </Modal>
  </Button>
  )
}

const PickGolfer = (props) => {
  const {teamid, selectedGolfer, golfers, selectedGolferId, selectedGolferIds, selectedGolferList, cEvents, activateAlternate, altActivated, firstLCEventId} = props;
  let selectedValue = null;
  let golferOptions = [];
  let tempSelectedGolferIds = [];
  if (selectedGolferList){
    selectedGolferList.forEach((golfer, index) => {
      tempSelectedGolferIds.push(golfer.golfer_id);
    });
  }

  if (golfers){
    golfers.forEach((golfer, index) => {
      let option = { value: golfer.golfer_id,
        isDisabled: (selectedGolferIds.indexOf(golfer.golfer_id) > -1 || tempSelectedGolferIds.indexOf(golfer.golfer_id) > -1 ? true : false ),
        label: golfer.golfer_name
      };
      if (golfer.golfer_id === selectedGolferId){
        selectedValue = { value: golfer.golfer_id,
          label: golfer.golfer_name
        };
      }
      golferOptions.push(
          option
      );
    });
  }

  //setting default values -
  //pre-populate the alternate's first tournament with the open or roundX tournament? If none,
  //then the next scheduled. If still none then leave blank.
  if (activateAlternate && selectedGolfer.alt && !selectedGolfer.first_contestevent_id){
    let x = cEvents.filter(x => x.enrolled===true && ["open"].indexOf(x.status) > -1);
    if (x.length > 0){
      selectedGolfer.first_contestevent_id = x[0].lc_event_id;
    }else{
      x = cEvents.filter(x => x.enrolled===true && ["round1", "round2", "round3", "round4"].indexOf(x.status) > -1);
      if (x.length > 0){
        selectedGolfer.first_contestevent_id = x[0].lc_event_id;
      }else{
        x = cEvents.filter(x => x.enrolled===true && ["scheduled"].indexOf(x.status) > -1);
        if (x.length > 0){
          selectedGolfer.first_contestevent_id = x[0].lc_event_id;
        }
      }
    }
  }
  let cEventOptions = [];
  let selectedFirstEvent = null;
  let selectedLastEvent = null;
  if (cEvents && selectedGolfer){
    cEvents.filter(x => x.enrolled===true && x.lc_event_id!==firstLCEventId).forEach((event, index) => {
      const {name, dates, course, major, status, lc_event_id} = event;
      let option = { value: lc_event_id,
        isDisabled: false,
        label: <div>
            <div style={{color: "#555", fontWeight:700}}>
              <span className="text-nowrap">{name}</span>
              { major &&
                <Badge color="success" style={{marginLeft: "0.5rem"}}>Major</Badge>
              }
              { status === "open" &&
                <Badge color="warning" style={{marginLeft: "0.5rem"}}> Open </Badge>
              }
              { ["round1", "round2", "round3", "round4"].indexOf(status) !== -1 &&
                <Badge color="info" style={{marginLeft: "0.5rem"}}> Live </Badge>
              }
            </div>
            <div style={{color: "#aaa", fontSize: ".75em"}}>{dates} &bull; {course}</div>
        </div>
      };
      if (selectedGolfer.first_contestevent_id && lc_event_id === selectedGolfer.first_contestevent_id){
        selectedFirstEvent = option;
      }


      cEventOptions.push(
          option
      );
    });
  }

  let lastEventOptions = [];
  let inActGolferOptions = [];
  let selectedInActGolfer = null;
  if (selectedGolferList && selectedGolfer && selectedFirstEvent){
    selectedGolferList.filter(x => x.alt===false && x.golfer_id > 0).forEach((g, index) => {
      let option = { value: g.golfer_id,
        label: g.golfer_name
      };
      if (g.last_contestevent_id === 0 || g.last_contestevent_id > 0){
        selectedInActGolfer = { value: g.golfer_id,
          label: g.golfer_name
        };

        cEvents.filter(x => x.enrolled===true && ["closed"].indexOf(x.status) > -1).forEach((event, index) => {
          const {name, dates, course, major, status, lc_event_id} = event;
          let option = { value: lc_event_id,
            isDisabled: false,
            label: <div>
                <div style={{color: "#555", fontWeight:700}}>
                  <span className="text-nowrap">{name}</span>
                  { major &&
                    <Badge color="success" style={{marginLeft: "0.5rem"}}>Major</Badge>
                  }
                  { status === "open" &&
                    <Badge color="warning" style={{marginLeft: "0.5rem"}}> Open </Badge>
                  }
                  { ["round1", "round2", "round3", "round4"].indexOf(status) !== -1 &&
                    <Badge color="info" style={{marginLeft: "0.5rem"}}> Live </Badge>
                  }
                </div>
                <div style={{color: "#aaa", fontSize: ".75em"}}>{dates} &bull; {course}</div>
            </div>
          };
          lastEventOptions.push(option);
        });

        //setting default values -
        //pre-populate the inactive golfers last tournament with the most recent closed tournament
        if (g.last_contestevent_id === 0){
          let y = cEvents.filter(x => x.enrolled===true && ["closed"].indexOf(x.status) > -1);
          if (y.length > 0){
            g.last_contestevent_id = y[y.length-1].lc_event_id;
          }
        }
        let lastEvent = cEvents.filter(x => x.enrolled===true && x.lc_event_id === g.last_contestevent_id)[0];
        if (lastEvent){
          selectedLastEvent =
          { value: lastEvent.lc_event_id,
            label: <div>
                <div style={{color: "#555", fontWeight:700}}>
                  <span className="text-nowrap">{lastEvent.name}</span>
                  { lastEvent.major &&
                    <Badge color="success" style={{marginLeft: "0.5rem"}}>Major</Badge>
                  }
                  { lastEvent.status === "open" &&
                    <Badge color="warning" style={{marginLeft: "0.5rem"}}> Open </Badge>
                  }
                  { ["round1", "round2", "round3", "round4"].indexOf(lastEvent.status) !== -1 &&
                    <Badge color="info" style={{marginLeft: "0.5rem"}}> Live </Badge>
                  }
                </div>
                <div style={{color: "#aaa", fontSize: ".75em"}}>{lastEvent.dates} &bull; {lastEvent.course}</div>
            </div>
          };
        }
      }
      inActGolferOptions.push(
          option
      );
    });
  }


  return (
    <Modal isOpen={props.chooseGolferModal} className="inviteModal" style={{maxWidth: '600px' }}>
      <ModalHeader toggle={props.toggleChooseGolferModal} className="mcModalHeader">Choose Golfer</ModalHeader>
          <ModalBody>
            <Row>
              <Col sm="12" className="">
                <Select className="no-border my-2" isClearable isSearchable options={golferOptions}
                value={selectedValue}
                placeholder = {"Choose Golfer"}
                onChange={(selectedOption) => {
                    console.log(selectedOption);
                    if (selectedOption){
                      props.selectGolfer(selectedOption.value);
                    }else{
                      props.unselectGolfer(0);
                    }
                  }
                } />
              </Col>
            </Row>

            {(selectedGolfer && selectedGolfer.alt && !!selectedGolfer.golfer_id && selectedGolfer.golfer_id >0 && teamid > 0) &&
            <>
            {!altActivated &&
            <Button outline className="btn-sm mt-2" color="primary" onClick={props.toggleActivateAlternate} >
                Activate Alternate
            </Button>
            }
            {altActivated &&
              <div style={{ fontSize:'.9rem'}} className="pt-2">{"Only one alternate substitution allowed."}</div>
            }
            <Collapse isOpen={activateAlternate}>
              <Row className="mt-2">
                <Col sm="4" className="mt-2">
                  {"Alternate’s First Tournament"}
                </Col>
                <Col sm="8" className="">
                  <Select className="no-border my-2" isClearable isSearchable options={cEventOptions}
                  value={selectedFirstEvent}
                  placeholder = {"Choose Event"}
                  onChange={(selectedOption) => {
                      console.log(selectedOption);
                      if (selectedOption){
                        props.selectEventForGolfer(selectedOption.value,'F');
                      }else{
                        props.selectEventForGolfer(null,'F');
                      }
                    }
                  } />
                </Col>
              </Row>
              {inActGolferOptions.length > 0  &&
              <Row>
                <Col sm="4" className="mt-3">
                  {"Golfer to Inactivate"}
                </Col>
                <Col sm="8" className="">
                  <Select className="no-border my-2" isClearable isSearchable options={inActGolferOptions}
                  value={selectedInActGolfer}
                  placeholder = {"Choose Golfer"}
                  onChange={(selectedOption) => {
                      if (selectedOption){
                        props.selectInactiveGolfer(selectedOption.value);
                      }
                    }
                  } />
                  {
                    (props.error && props.error.inactive_golfer) && <div style={{color: "red", fontSize:'.8rem'}} className="pt-1">{props.error.inactive_golfer}</div>
                  }
                </Col>
              </Row>
              }
              {lastEventOptions.length > 0 &&
              <Row>
                <Col sm="4" className="mt-2">
                  {"Inactive Golfer’s Last Tournament"}
                </Col>
                <Col sm="8" className="">
                  <Select className="no-border my-2" isClearable isSearchable options={lastEventOptions}
                  value={selectedLastEvent}
                  placeholder = {"Choose Event"}
                  onChange={(selectedOption) => {
                      console.log(selectedOption);
                      if (selectedOption){
                        props.selectEventForGolfer(selectedOption.value,'L');
                      }
                    }
                  } />
                  {
                    (props.error && props.error.last_contestevent_id) && <div style={{color: "red", fontSize:'.8rem'}} className="pt-1">{props.error.last_contestevent_id}</div>
                  }
                </Col>
              </Row>
              }
            </Collapse>
            </>
            }
          </ModalBody>
          <ModalFooter>
            <Button className="btn-sm mt-2" color="primary" onClick={props.toggleChooseGolferModal} > Accept
            </Button>
            <Button className="btn-sm mt-2" color="primary" onClick={() => {props.toggleChooseGolferModal(false);}} > Cancel
            </Button>
          </ModalFooter>
      </Modal>

  )
}

const PickPrevTeam = (props) => {
  const {prevteams,} = props;
  let selectedValue = null;
  let teamOptions = [{
    value: 0,
    label:
      <div>
          <div style={{color: "#555", fontWeight:700}}>
            <span className="text-nowrap">{'Previous Teams'}</span>
          </div>
      </div>,
    isDisabled: true
  }];

  if (prevteams){
    prevteams.sort((a,b) => {
      return a.teamname.toUpperCase() < b.teamname.toUpperCase() ? -1 : 1;
    })
    prevteams.forEach((team, index) => {
      const {teamid, teamname} = team;
      let option = { value: teamname,
                    label:
                      <div>
                          <div style={{color: "#555", fontWeight:400}}>
                            <span className="text-nowrap ml-3">{teamname}</span>
                          </div>
                      </div>
                    };
      teamOptions.push(option);
    });
  }


  return (

    <Button className="btn-sm mt-1 ml-3 btn-link" style={{height: '30px', backgroundColor:'white', border: 'none'}} onClick={() => {props.toggleChoosePrevTeamModal(true);}} >Previous Team
    <Modal isOpen={props.choosePrevTeamModal} className="inviteModal">
      <ModalHeader toggle={props.toggleChoosePrevTeamModal} className="mcModalHeader">Choose From Previous Teams</ModalHeader>
          <ModalBody>
            <Row>
              <Col sm="12" className="">
                <Select className="no-border my-2" isClearable isSearchable options={teamOptions}
                value={selectedValue}
                placeholder = {"Choose from Previous team"}
                onChange={(selectedOption) => {
                    console.log(selectedOption);
                    if (selectedOption){
                      props.setTeamName(selectedOption.value);
                    }else{
                      props.setTeamName(0);
                    }
                  }
                } />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
          </ModalFooter>
      </Modal>
    </Button>
  )
}

const checkIfImageExists = (url, callback) => {
  const img = new Image();
  img.src = url;

  if (img.complete) {
    callback(true);
  } else {
    img.onload = () => {
      callback(true);
    };

    img.onerror = () => {
      callback(false);
    };
  }
}

class LCSelectedGolfersRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imageVerified: false,
      golferList: JSON.parse(JSON.stringify(props.golferList)),

      grpG1Cls: 'golfer-headshot-big',
      grpG2Cls: 'golfer-headshot-big',
      grpG3Cls: 'golfer-headshot-big',
      grpG4Cls: 'golfer-headshot-big',
      grpG5Cls: 'golfer-headshot-big',
      grpG6Cls: 'golfer-headshot-big',

    }
  }


  componentWillReceiveProps(nextProps) {
    const prevGolferList = nextProps.golferList;
    const golferList = this.state.golferList;
    const prevNames = prevGolferList.map(({ golfer_name }) => golfer_name).join('|');
    const names = golferList.map(({ golfer_name }) => golfer_name).join('|');

    if (prevNames !== names){
      this.setState({imageVerified: false, golferList:JSON.parse(JSON.stringify(nextProps.golferList))});
    }
  }


  render() {
    const details = this.props.golferList;
    const IMAGE_BASE_URL = 'https://media.majorschallenge.com/headshots/';

    if (!this.state.imageVerified && details.length > 0){
      this.setState({'imageVerified':true});

      if (details.length > 0){
        checkIfImageExists(IMAGE_BASE_URL + details[0].golfer_name.replace(/[\W_]+/g,'').toLowerCase() + '.png', (exists) => {
          if (!exists) {this.setState({grpG1Cls: 'golfer-headshot-big golfer-name-initial'});}
          else{this.setState({grpG1Cls: 'golfer-headshot-big'});}
        });
      }
      if (details.length > 1){
        checkIfImageExists(IMAGE_BASE_URL + details[1].golfer_name.replace(/[\W_]+/g,'').toLowerCase() + '.png', (exists) => {
          if (!exists) {this.setState({grpG2Cls: 'golfer-headshot-big golfer-name-initial'});}
          else{this.setState({grpG2Cls: 'golfer-headshot-big'});}
        });
      }
      if (details.length > 2){
        checkIfImageExists(IMAGE_BASE_URL + details[2].golfer_name.replace(/[\W_]+/g,'').toLowerCase() + '.png', (exists) => {
          if (!exists) {this.setState({grpG3Cls: 'golfer-headshot-big golfer-name-initial'});}
          else{this.setState({grpG3Cls: 'golfer-headshot-big'});}
        });
      }
      if (details.length > 3){
        checkIfImageExists(IMAGE_BASE_URL + details[3].golfer_name.replace(/[\W_]+/g,'').toLowerCase() + '.png', (exists) => {
          if (!exists) {this.setState({grpG4Cls: 'golfer-headshot-big golfer-name-initial'});}
          else{this.setState({grpG4Cls: 'golfer-headshot-big'});}
        });
      }
      if (details.length > 4){
        checkIfImageExists(IMAGE_BASE_URL + details[4].golfer_name.replace(/[\W_]+/g,'').toLowerCase() + '.png', (exists) => {
          if (!exists) {this.setState({grpG5Cls: 'golfer-headshot-big golfer-name-initial'});}
          else{this.setState({grpG5Cls: 'golfer-headshot-big'});}
        });
      }
      if (details.length > 5){
        checkIfImageExists(IMAGE_BASE_URL + details[5].golfer_name.replace(/[\W_]+/g,'').toLowerCase() + '.png', (exists) => {
          if (!exists) {this.setState({grpG6Cls: 'golfer-headshot-big golfer-name-initial'});}
          else{this.setState({grpG6Cls: 'golfer-headshot-big'});}
        });
      }
    }

    const golfers = details.map(({golfer_id, golfer_name, alt, winnings, first_contestevent_id}, index) => {
      var names = golfer_name.split(' '),
        initials = names[0].substring(0, 1).toUpperCase(),
        shortName = initials + '. ';

      if (names.length > 1) {
          initials += names[names.length - 1].substring(0, 1).toUpperCase();
          shortName += names[names.length - 1];
          if (first_contestevent_id && this.props.firstLCEventId && !alt && first_contestevent_id !== this.props.firstLCEventId){
            shortName += ' *';
          }
      }

      if (golfer_id === 0){
        initials = '';
        shortName = golfer_name;
      }

      let conCls =  'golfer-headshot-container-big';
      if (!isMobPortrait() && index > 0){ conCls = conCls + ' ml-3';}
      if (isMobPortrait() && (index > 0 && index !== 3)){ conCls= conCls + ' ml-4';}

      const bgCls = {backgroundImage: 'url(' + IMAGE_BASE_URL + golfer_name.replace(/[\W_]+/g,'').toLowerCase() + '.png)'};
      return (
        <div className={conCls} style={{zIndex:index}} onClick={() => {if (this.props.toggleChooseGolferModal){this.props.toggleChooseGolferModal(true, index, golfer_id);}}}>
          {index === 0 && <div className={this.state.grpG1Cls} style={bgCls}><div>{initials}</div></div>}
          {index === 1 && <div className={this.state.grpG2Cls} style={bgCls}><div>{initials}</div></div>}
          {index === 2 && <div className={this.state.grpG3Cls} style={bgCls}><div>{initials}</div></div>}
          {index === 3 && <div className={this.state.grpG4Cls} style={bgCls}><div>{initials}</div></div>}
          {index === 4 && <div className={this.state.grpG5Cls} style={bgCls}><div>{initials}</div></div>}
          {index === 5 && <div className={this.state.grpG6Cls} style={bgCls}><div>{initials}</div></div>}
          {alt && <div className="golfer-headshot-alt"><div className="alt">{'ALT'}</div></div>}
          <div className="roster-headshots-name" style={{fontWeight:'400'}}>{shortName}</div>
          {/*<div className="roster-headshots-name" style={{fontWeight:'400'}}>$ {numeral(winnings).format('0,0')}</div>*/}
        </div>
      )
    });

    let first = golfers.slice(0, 3);
    let second = golfers.slice(3);
    //const mobMortrait = isMobPortrait();
    return (
      <div>
      <MobilePortrait>
        <div>
          <div className="d-flex justify-content-center" style={{height:'120px'}}>
            {first}
          </div>
          <div className="d-flex justify-content-center" style={{height:'120px'}}>
            {second}
          </div>
        </div>
      </MobilePortrait>
      <NotMobilePortrait>
        <div className="d-flex justify-content-center" style={{height:'110px'}}>
          {golfers}
        </div>
      </NotMobilePortrait>
    </div>
    );
  }
}

const TeamGolfersTable = ({enrolled, teamGolfers, firstLCEventId, toggleAddEditTeamModal}) => {

  if (!enrolled) { return null; }

  const rows = teamGolfers.map((teamGolfer, index) => <TeamGolferRow {...teamGolfer} firstLCEventId={firstLCEventId} key={teamGolfer.teamid} indx={index} toggleAddEditTeamModal={toggleAddEditTeamModal}/>)

  return (
    <Card body className="p-0 mt-4">
      <div className="p-2 d-flex commissionerMembersHeader">
        Teams
      </div>
      <Table responsive>
        <thead>
          <tr style={{backgroundColor: "#f6f6f6", color: "#7c7c7c", "fontSize": "0.7rem", textTransform: "uppercase"}}>
            <th className="text-center text-nowrap">Golfers</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </Table>
    </Card>
  )
}

const TeamGolferRow = (props) => {
  const {teamid, teamname, golfers, firstLCEventId, ownermembers} = props;


    return (
      <tr>
        <td className="text-center text-nowrap">
          <div onClick={()=>{props.toggleAddEditTeamModal(true, teamid)}}>
            <div  style={{color: "#157EFB", fontSize: "1em", cursor: "pointer", fontWeight:500}} className="mb-2">
              {teamname}
            </div>
            <LCSelectedGolfersRow golferList={golfers} firstLCEventId={firstLCEventId}/>
            {ownermembers.length === 0 &&
            <Button className="btn-sm mt-3" color="danger" >
              {'Assign Owners'}
            </Button>
            }
          </div>
        </td>
      </tr>
    )
}
