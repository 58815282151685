import React from 'react';

import {
  Row, Col,
  Card,
  Table,
  UncontrolledTooltip,
} from 'reactstrap';

import FontAwesome from 'react-fontawesome';

import {
  get_commissleagues,
  set_leaguestatus,

} from 'utils/api.js';

import {
  MobileHeader,
  ContentWrapper
} from 'common/ProfileComponents.js';

export default class ProfileMyLeagues extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      leagues: [],
      inactvLeaguesCnt: 0,
    }
    this.fetchLeagues = this.fetchLeagues.bind(this);
    this.reactivateLeague = this.reactivateLeague.bind(this);
    this.renderHeader = this.renderHeader.bind(this);
    this.renderLoading = this.renderLoading.bind(this);
  }

  componentDidMount() {
    this.fetchLeagues(this.props.activeLeague);
  }

  fetchLeagues(league) {
    get_commissleagues(({leagues, inactvLeaguesCnt}) => {
      this.setState({loading: false, leagues: leagues, inactvLeaguesCnt:inactvLeaguesCnt});
    }, (error) => {
      console.log(error);
    });
  }

  reactivateLeague(leagueId){
    set_leaguestatus(leagueId,'active',
    ({success, refresh, me, error}) => {
      if (success) {
        this.fetchLeagues(this.props.activeLeague);
        this.props.updateMe(me);
      } else {
        console.log(error);
      }
    }, (error) => {
      console.log(error);
    });

  }

  renderHeader() {
    return <MobileHeader to="My Leagues" />
  }

  renderLoading() {
    return (
      <div>
        {this.renderHeader()}
      </div>
    )
  }

  render() {
    if (this.state.loading) {
      return this.renderLoading();
    }
    return (
      <div>
        {this.renderHeader()}
        <ContentWrapper>
          {
            this.state.leagues && this.state.leagues.length ?
            <div className="d-flex justify-content-center pt-4" style={{fontSize: ".85em"}}>
              <LeagueTable leagues={this.state.leagues} inactvLeaguesCnt={this.state.inactvLeaguesCnt} reactivateLeague={this.reactivateLeague}/>
            </div>:
            undefined
          }
        </ContentWrapper>
      </div>
    )
  }

}

const LeagueTable = (props) => {
  if (props.leagues.length === 0) {
    return null;
  }

  const eventRows = [];
  props.leagues.forEach((item, index) => {
    let actionid = 'rinfo_' + item.leagueid;
    let actionhref = '#rinfo_' + item.leagueid;
    eventRows.push(
      <tbody>
      <tr style={{}}>
        <td>{item.league}</td>
        <td>{item.status}</td>
        <td style={{textAlign: "center"}}>{item.memberActCnt}</td>
        <td style={{textAlign: "center"}}>{item.memberInviteCnt}</td>
        <td style={{textAlign: "center"}}>{item.memberInactCnt}</td>
        <td className="text-nowrap">
           {(item.status === 'Inactive' && item.am_i_owner === true) &&
              <span onClick={() => props.reactivateLeague(item.leagueid)} style={{color: "#157EFB", fontSize: ".85em", cursor: "pointer"}} >
                Re-activate
              </span>
            }
            {(item.status === 'Inactive' && item.am_i_owner === false) &&
              <>
               <span style={{color: "grey", fontSize: ".85em",}} >
                 Re-activate
                 <a href={actionhref} id={actionid}>
                    <FontAwesome name="question-circle" className="ml-2" />
                 </a>
                </span>
                <UncontrolledTooltip placement="right" target={actionid}>
                   Only the league owner can re-activate this league
                </UncontrolledTooltip>
              </>
             }
        </td>
      </tr>
      </tbody>
    );
  });

  return (
    <Card body className="p-0">
      <Row className="justify-content-between p-3">
        <Col style={{paddingLeft: "0.75rem"}}>
          <h5>
            Leagues
          </h5>
        </Col>
      </Row>
      <Table responsive>
        <thead>
          <tr style={{backgroundColor: "#fafafa", color: "gray", "fontSize": "0.85rem"}}>
            <th>League Name</th>
            <th>Status</th>
            <th style={{textAlign: "center"}}>Members</th>
            <th style={{textAlign: "center"}}>Invites</th>
            <th style={{textAlign: "center"}}>Inactive</th>
            <th style={{textAlign: "center"}}></th>
          </tr>
        </thead>
        {eventRows}
      </Table>
      {
        props.inactvLeaguesCnt > 0 &&
        <Row className="justify-content-between px-3" style={{ color: "gray", "fontSize": "0.8rem", paddingTop:"0px", marginTop:"-10px"}}>
          <Col style={{paddingTop:"5px", paddingBottom:"5px", borderTopWidth: "2px", borderTopStyle: "solid", borderTopColor: "#dee2e6"}}>
            Inactivate a league from League Settings in Commissioner Console
          </Col>
        </Row>
      }

    </Card>
  )
}
