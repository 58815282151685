import React from 'react';

import {
  Container,
  Row,
  Col,
  Button
} from 'reactstrap';

import {
  Link
} from 'react-router-dom';

import pickTeam from 'img/pickteam.png';
import FontAwesome from 'react-fontawesome';

import { get_next_league_event } from 'utils/api.js';

import './Rules.css';

export class WhiteLabelHowToPlay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {whiteLabel:null, league:null};
    this.getNextLeagueEvent = this.getNextLeagueEvent.bind(this);
  }
  componentDidMount() {
    this.getNextLeagueEvent();
    const { league } = this.props.match.params;
    if (league) {
      const paramLeague = parseInt(league, 10);
      if (this.props.activeLeague !== paramLeague) {
        this.props.selectLeague(paramLeague);
      }
      this.props.me.leagues.forEach((lg)=>{
        if (lg.leagueid === paramLeague && lg.whiteLabel) { this.setState({league:lg, whiteLabel:lg.whiteLabel}); return; }
      });
    }
  }
  getNextLeagueEvent() {
    const { league } = this.props.match.params;
    if (league) {
      get_next_league_event(league, ({success, error, nextEvent}) => {
        this.setState({
          success,
          nextEvent
        });
      }, (error) => {
        console.log(error);
        this.setState({success: false});
      })
    }
  }
  render() {
    const leageid = this.props.activeLeague;
    const eventid = this.state.nextEvent ? this.state.nextEvent.eventid : null;
    const intro = (this.state.whiteLabel && this.state.whiteLabel.introduction?this.state.whiteLabel.introduction.replaceAll('<p></p>','<br>'):'<h6>Welcome to the ' + (this.state.league?this.state.league.name:'') + '</h6>');
    const prize = (this.state.whiteLabel && this.state.whiteLabel.prize?this.state.whiteLabel.prize.replaceAll('<p></p>','<br>'):'');
    return (
      <Container>
        <div className="rulesContent">
          <div className="rulesContentTitle"></div>
            <div className="richTextEditor" dangerouslySetInnerHTML={{__html: intro}} />
          <p>
            To get started:
          </p>
          <Row>
            <Col xs="1" className="text-right">
              <div className="ruleStepNumberCircle">1</div>
            </Col>
            <Col xs="11">
              <h5>Enter a Team of 12 golfers.</h5>
              <p>Golfers are segmented into four groups, by their world rank.</p>
            </Col>
            <Col md={{size: 6, offset: 1}} className="d-none d-md-block">
              <img src={pickTeam} style={{height: "200px"}} alt="Pick team."/>
            </Col>
          </Row>
          <Row>
            <Col xs="1" className="text-right">
              <div className="ruleStepNumberCircle">2</div>
            </Col>
            <Col>
              <h5>Pick one alternate for each group</h5>
              <p>An alternate is only used if your starter withdraws before the start of Round 3.</p>
            </Col>
          </Row>
          <Row>
            <Col xs="1" className="text-right">
              <div className="ruleStepNumberCircle">3</div>
            </Col>
            <Col>
              <h5>Save Your Team</h5>
              <p></p>
            </Col>
          </Row>
          <Row>
            <Col xs="1" className="text-right">
              <div className="ruleStepNumberCircle">4</div>
            </Col>
            <Col>
              <h5>Follow your friends</h5>
              <p>Easily track how your friends are doing by tagging them in the&nbsp;
              <Link to={`/members/${leageid}`}>
                <span>Members</span>
              </Link> section. Choose the  &nbsp;
              <Button className="px-1 py-0" style={{lineHeight: "1em", boxShadow: "none" }}
                outline={true} color="primary">
                <span style={{fontSize: ".7em", paddingBottom:'2px'}}>
                  <FontAwesome name="users" />
                  <span style={{marginLeft:'2px'}}>Filter</span>
                </span>
              </Button>
               &nbsp; button to collapse the Leaderboard into only the teams you care about.</p>
            </Col>
          </Row>
          <Row>
            <Col xs="1" className="text-right">
              <div className="ruleStepNumberCircle">5</div>
            </Col>
            <Col>
              <h5>Follow the action live</h5>
              <p>The leaderboard is basic stroke play. Add up your golfers scores and compare it to the competition.</p>
            </Col>
          </Row>
          { eventid && (
            this.state.nextEvent.status === 'open' ?
            <Link to={`/events/${eventid}`}>
              <Button color="primary" size="lg">Enter your Team</Button>
            </Link>
            :
            <Link to={`/events/${eventid}`}>
              <Button color="secondary" size="lg">Preview the Next Event</Button>
            </Link>
          )
          }
          <Row className="mt-4">
            <Col>
              <h5>Scoring</h5>
              <p>During the tournament, the Majors Challenge leaderboard updates in real-time with the PGA. A teams position on the leaderboard is based on the sum of its golfer scores.</p>
              <p style={{textDecoration: "underline", marginBottom:'2px'}}>Cut, Withdraw and MDF Rules:</p>
              <ul>
                <li>If one of your golfers withdraws before the tournament starts then that golfer will be replaced with an alternate.</li>
                <li>If one of your golfers withdraws during round 1 or 2 then that golfer will be replaced with an alternate.</li>
                <li>If one of your golfers withdraws after round 2 then that golfer is treated the same as if he were CUT.</li>
                <li>If more than one golfer in a group is to receive an alternate due to withdrawing then your team will receive an alternate for the first WD and a CUT for any additional WD.</li>
              </ul>
              <p>You will receive the worst four round score on the PGA leaderboard in place of every golfer on your team that is CUT, MDF, DQ or withdraws without an alternate replacement</p>


              <p style={{textDecoration: "underline" , marginBottom:'2px'}}>Tie Breaker Rule</p>
              <p>
                In the event two teams have the same score then tie breaker rules are in effect. Ties are first broken by looking at the cut count, where the roster with the fewest number of cuts breaks the tie. Any golfer that does not finish the fourth round of an event is counted toward your cut count. If teams have the same amount of cuts then the team containing the lowest scoring golfers breaks the tie. For example, if Team A and Team B have the same score and cut count, while Team B's best golfer shot -5 and Team A's best golfer shot -3, then Team B places ahead of Team A. In the event both teams have the same best golfer score, then each team's second best golfer will determine the tie breaker. This continues until the tie has broken. If both teams have identical golfer scores then the tie remains.
              </p>

            </Col>
          </Row>
          {prize &&
          <Row className="mt-n2">
            <Col>
              <h5>Prizes</h5>
              <p>
              <div className="richTextEditor" dangerouslySetInnerHTML={{__html: prize}} />
              </p>
            </Col>
          </Row>
        }

        </div>
      </Container>
    )
  }
}
