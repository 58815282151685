import React from 'react';
import {
  Row, Col,
  Card, CardTitle,
  Table,
  Input
} from 'reactstrap';

import 'pages/user/Member.css';

import Autosuggest from 'react-autosuggest';
import 'pages/user/Autosuggest.css';

import FontAwesome from 'react-fontawesome';

import InviteModal from 'common/InviteModal';
import {
  get_user_members,
  set_friendship,
  quit_league,
  search_members,
} from 'utils/api.js';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'common/ConfirmAlert.css';

export default class Members extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pageSize: 200,
      canInvite: false,
      members: [],
      copyMembers: [],
      searchMember: '',
      totalMembers: 0,
      owner: {
        name: "",
        email: "",
        is_user_owner: "",
      },
      leagueTitle: "",
    };

    this.setLeagueInfo = this.setLeagueInfo.bind(this);
    this.fetchMembersData = this.fetchMembersData.bind(this);
    this.searchMembersData = this.searchMembersData.bind(this);
    this.setFriend = this.setFriend.bind(this);
    this.quitLeague = this.quitLeague.bind(this);

    this.setSeachedMember = this.setSeachedMember.bind(this);
    this.resetSearchMember = this.resetSearchMember.bind(this);
  }

  setLeagueInfo(props) {
    let canInvite = false;
    let leagueTitle = "";

    for (var { leagueid, invite_access, name } of props.me.leagues) {
      if (leagueid === props.activeLeague) {
        canInvite = invite_access;
        leagueTitle = name;
        break;
      }
    }

    this.setState({
      canInvite,
      leagueTitle,
    })
  }

  componentDidMount() {
    let paramLeague = parseInt(this.props.match.params.league, 10);

    if (this.props.activeLeague !== paramLeague) {
      this.props.selectLeague(paramLeague);
    } else {
      this.fetchMembersData(this.props.activeLeague);
    }
    this.setLeagueInfo(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.activeLeague !== this.props.activeLeague) {
      this.setLeagueInfo(nextProps);
      this.fetchMembersData(nextProps.activeLeague);
    }
  }

  fetchMembersData(league) {
    get_user_members(league, this.state.pageSize,
     ({members, owner, totalMembers}) => {
      this.setState({
        members,
        owner,
        totalMembers,
        copyMembers:members,
        searchMember:'',
      });
    }, (error) => {
      console.log(error);
      this.setState({
        members: [],
        owner: {name:"", email:"", is_user_owner:""},
        searchMember: ''
      });
    });
  }

  searchMembersData(event) {
    this.setState({searchMember:event.target.value});
    if (event.target.value.trim().length < 3){
      this.setState({members:this.state.copyMembers});
      return;
    }
    search_members(this.props.activeLeague, event.target.value.trim(),this.state.pageSize,
     ({members, owner}) => {
       this.setState({members});
    }, (error) => {
      console.log(error);
    });
  }

  setSeachedMember(srchmembers){
    this.setState({members:srchmembers});
  }

  resetSearchMember(){
    if (this.state.copyMembers.length === 0){
      this.fetchMembersData(this.props.activeLeague);
    }else{
      this.setState({members:this.state.copyMembers, searchMember:''});
    }

  }

  setFriend(id, value) {
    set_friendship(this.props.activeLeague, id, value,
      () => {
        if (this.state.searchMember && this.state.searchMember.trim().length >= 3){
          this.setState({copyMembers:[]});
          search_members(this.props.activeLeague, this.state.searchMember.trim(),this.state.pageSize,
           ({members, owner}) => {
             this.setState({members});
          }, (error) => {
            console.log(error);
          });
        }else{
          this.setState({copyMembers:[], searchMember:''});
          this.fetchMembersData(this.props.activeLeague);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  quitLeague() {
    confirmAlert({
      title: 'Confirm to Quit',
      message: 'Are you sure you want to quit this league?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => quit_league(this.props.activeLeague,
                            () => {
                              this.fetchMembersData(this.props.activeLeague);
                              this.props.history.push("/");
                            },
                            (error) => {
                              console.log(error);
                            }
                        )
        },
        {
          label: 'No',
          onClick: () => {  }
        }
      ]
    });

  }

  render() {
    return (
      <Row className="pt-4">
        <Col md="12" lg={{size:"8", offset:"2"}}>
          <LeagueHeader {...this.state} {...this.props} quitLeague={this.quitLeague}/>
          {/*<SearchMembers leagueid={this.props.activeLeague} setSeachedMember={this.setSeachedMember} resetSearchMember={this.resetSearchMember}/>*/}
          <MembersList {...this.state} activeLeague={this.props.activeLeague} setFriend={this.setFriend}
            searchMembersData={this.searchMembersData} resetSearchMember={this.resetSearchMember}/>
          <LeagueFooter />
        </Col>
      </Row>
    );
  }
}

const LeagueHeader = (props) =>
  <Row className="d-flex justify-content-center px-4">
    <Col className="d-flex justify-content-between align-items-start flex-wrap">
      <span>
        <h3 style={{fontWeight:"bolder", whiteSpace: "nowrap"}}>{props.leagueTitle}</h3>
        {props.owner.is_user_owner ? undefined :
        <div class="pb-2" style={{color: "blue", fontSize: ".85em", cursor: "pointer"}} onClick={() => {props.quitLeague()}}>
          Quit This League {props.owner.is_user_owner}
        </div>
        }
        {!props.owner.name ? undefined :
        <div style={{color:"#aaa", fontWeight:"200", fontSize: "0.9rem"}}>
          {'Commissioner ' + props.owner.name}
        </div>
        }
        {!props.owner.email ? undefined :
        <a href={"mailto:"+props.owner.email}>
          <span style={{whiteSpace: "nowrap", fontSize: "0.85rem"}}>
            <FontAwesome name="envelope" className="mr-2 mb-2" />
            <span>{props.owner.email}</span>
          </span>
        </a>
        }

      </span>
      { props.canInvite &&
        <span>
          <InviteModal league={ props.activeLeague } />
        </span>
      }
    </Col>
  </Row>

const LeagueFooter = (props) =>
    <Row className="d-flex justify-content-center px-1 py-2">
      <Col className="d-flex justify-content-between align-items-start flex-wrap">
        <div style={{fontWeight:"200", fontSize: "0.9rem"}}>
            To track a friend on the leaderboard click the + next to their name.
        </div>
      </Col>
    </Row>

const MembersList = (props) => {

  const row = (name, commissioner, status, isFriend, id, isUser) => {
    let style={fontSize: "0.85em"}
    if (isUser) {
      style['backgroundColor'] = "rgba(52, 152, 219, 0.2)";
    } else if (isFriend) {
      style['backgroundColor'] = "rgba(52, 152, 219, 0.3)";
    }

    const friend = status !== 'active' || isUser ?
                      undefined :
                      <FontAwesome name={isFriend ? "user-times" : "user-plus"} onClick={()=>{ props.setFriend(id, !isFriend) }} />

    return <tr key={id} style={style}>
      <td className="text-capitalize">{name}</td>
      <td className="text-capitalize">{status}</td>
      <td style={{fontSize: "1.5em", cursor: "pointer"}} className="py-1">{friend}</td>
    </tr>
  }

  let rows = [];
  let newUsers = 0;
  let activeUsers = 0;
  for (var member of props.members) {
    if (member.status === "invited" && member.name === "") {
      newUsers++;
    } else {
      rows.push( row(member.name, member.commissioner, member.status, member.is_friend, member.id, member.is_user) );
      if (member.status === 'active') {
        activeUsers++;
      }
    }
  };

  return (
    <div className="pt-4">
      <Card body className="p-0">
        <CardTitle className="d-flex justify-content-between mb-0">
          <div className="m-2">
            {'Members (' + activeUsers + '/' + props.totalMembers + ')'}
          </div>
          <div className="m-2 d-flex justify-content-between">

                <FontAwesome name="search" style={{fontSize: "1.3rem", lineHeight: "35px", position:'relative', left:'26px', color:'dimgrey', zIndex:'100'}} />

                <Input placeholder="Search For Members" value={props.searchMember} style={{width:'200px', paddingLeft:'30px'}}
                name="memberSearch" onChange={props.searchMembersData} className="memberSearchInput"/>

                <FontAwesome name="close"
                style={{fontSize: "1.3rem", lineHeight: "35px", color:'dimgrey', zIndex:'100', marginLeft:'6px', cursor:'pointer'}}
                onClick={props.resetSearchMember}/>
          </div>
        </CardTitle>
        <Table>
          <thead>
            <tr style={{backgroundColor: "#fafafa", color: "gray", fontSize: "0.85rem"}}>
              <th>Name</th>
              <th>Status</th>
              <th>Friend</th>
            </tr>
          </thead>
          <tbody>
            {rows}
            {newUsers > 0 &&
              row("New Users ("+newUsers+")", false, "invited")
            }
          </tbody>
        </Table>
      </Card>
    </div>
  )
}

class SearchMembers extends React.Component {
  constructor() {
    super();

    this.state = {
      value: '',
      suggestions: []
    };

    this.getSuggestions = this.getSuggestions.bind(this);
    this.getSuggestionValue = this.getSuggestionValue.bind(this);
    this.renderSuggestion = this.renderSuggestion.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
    this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
    this.onChange = this.onChange.bind(this);

  }

  getSuggestions(value) {
    search_members(this.props.leagueid, value,
     ({members, owner}) => {
       let suggestions = [];
       for (var member of members) {
       }
       this.setState({
        suggestions: members,
       });
       this.props.setSeachedMember(members);
    }, (error) => {
      console.log(error);
    });
  }

  getSuggestionValue (suggestion){
    return suggestion.name;
  }

  renderSuggestion (suggestion){
    return (<div>
      {suggestion.name}
    </div>);
  }

  onChange(event, { newValue }) {
    this.setState({
      value: newValue
    });
  }

  onSuggestionsFetchRequested({ value }) {
    this.getSuggestions(value);
  }

  onSuggestionsClearRequested(){
    this.setState({
      suggestions: []
    });
    this.props.resetSearchMember();
  }


  render(){
    const { value, suggestions } = this.state;

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder: 'Type member name',
      value,
      onChange: this.onChange
    };


    return (
      <div className="pt-4">
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        inputProps={inputProps}
      />
      </div>
    );
  }
}
