import React from 'react';

import {
  Row, Col,
  Card,
  Table,
  Collapse
} from 'reactstrap';

import {NavLink} from 'react-router-dom';
import numeral from 'numeral';
import FontAwesome from 'react-fontawesome';

import './PastEntries.css';

const PastEntries = (props) => {
  if (props.events.length === 0) {
    return null;
  }

  let totalEvents = props.events.length;
  let totalWon = 0;
  let totalLoss = 0;
  const eventRows = [];
  let lastSeason = null;
  props.events.forEach((item, index) => {
    if (!props.embededIn || props.embededIn !== 'commissMemberProfile') {
      if (item.season !== lastSeason) {
        eventRows.push(
          <tbody key={'season'+item.season}>
            <tr style={{backgroundColor: "#dee2e6", color: "gray", "fontSize": "0.85rem"}}>
              <td colSpan="5" style={{paddingTop: "0.25rem", paddingBottom: "0.25rem"}}>{item.season}</td>
            </tr>
          </tbody>
        );
        lastSeason = item.season;
      }
    }
    totalWon += item.winning && parseFloat(item.winning)>0?parseFloat(item.winning):0;
    totalLoss += item.winning && parseFloat(item.winning)<0?Math.abs(parseFloat(item.winning)):0;
    eventRows.push(<CompletedEventRow item={item} key={item.eventid} embededIn={props.embededIn}/>);
  });
  let eventHdr = 'Events (' + totalEvents + ')';
  let eventSumm = 'Won: ' + numeral(totalWon).format('0,0[.]00') + ' | ' + 'Lost: ' + numeral(totalLoss).format('0,0[.]00') +
    ' | ' + 'Total: ' +
    (totalWon - totalLoss >= 0?numeral(totalWon - totalLoss).format('0,0[.]00'):'-' + numeral(totalLoss-totalWon).format('0,0[.]00'));

  return (
    <Card body className="p-0">
      <Row className="justify-content-between p-3">
        <Col style={{paddingLeft: "0.75rem"}}>
          <h5>
          {
            (!props.embededIn || props.embededIn !== 'commissMemberProfile') &&
            'Completed '
          }
          {eventHdr}
          </h5>
          <div style={{color: "gray", "fontSize": "0.85rem"}}>
            {eventSumm}
          </div>
        </Col>
      </Row>
      <Table responsive>
        <thead>
          <tr style={{backgroundColor: "#fafafa", color: "gray", "fontSize": "0.85rem"}}>
            {
              (!props.embededIn || props.embededIn !== 'commissMemberProfile') &&
              <th>League</th>
            }
            <th>Event</th>
            <th style={{textAlign: "center"}}>Win/Loss</th>
            <th style={{textAlign: "center"}}>Purse</th>
            <th>{/* placeholder for caret */ }</th>
          </tr>
        </thead>
        {eventRows}
      </Table>
      {
        props.pastSesnEntry &&
        <Card body className="p-0 mt-0" style={{border:"0px solid",backgroundColor:"inherit"}}>
          <div className="pl-2 align-items-center" style={{fontSize: '1em', textAlign: 'center'}}>
          <NavLink
            to={"/profile/results"}
            exact={true}
            className="pt-2 pb-2"
            activeClassName="active">
            <div className="pt-2 pb-2">Past Seasons</div>
          </NavLink>
          </div>
        </Card>
      }
    </Card>
  )
}

class CompletedEventRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = { collapsed: true };
    this.toggle = this.toggle.bind(this);
  }
  toggle() {
    this.setState({collapsed: !this.state.collapsed});
  }
  render() {
    const item = this.props.item;
    let event =
      <NavLink to={"/events/"+item.eventid}>
        {item.title}
      </NavLink>

    // let cup = item.cup ?
    //   <FontAwesome name="trophy" /> :
    //   undefined

    let league =
      <NavLink to={"/league/"+item.leagueid}>
        {item.league}
      </NavLink>

    let bodyrowclass = "completedEventRowBody";
    if (!this.state.collapsed) {
      bodyrowclass += " expanded";
    }

    return (
      <tbody>
        <tr onClick={this.toggle} className="completedEventRowBody">
          {
            (!this.props.embededIn || this.props.embededIn !== 'commissMemberProfile') &&
            <td className="text-center align-middle">{league}</td>
          }
          <td>
            <div style={{whiteSpace: "nowrap"}}>{event}</div>
            <div style={{whiteSpace: "nowrap", color: "gray", fontSize: ".85em"}}>{item.dates}</div>
          </td>
          <td className="text-center align-middle">{numeral(item.winning).format('0,0[.]00')}</td>
          <td className="text-center align-middle">{numeral(item.pointpool).format('0,0[.]00')}</td>

          <td className="align-middle"><FontAwesome size="lg" name={this.state.collapsed ? "angle-down" : "angle-up"} /></td>
        </tr>
        <tr className={bodyrowclass}>
          <td colSpan="12" style={{padding: 0, borderTop: "none"}}>
            <Collapse isOpen={!this.state.collapsed} className="completedEntryCollapse">
              <CompletedEntries {...this.props.item} />
            </Collapse>
          </td>
        </tr>
      </tbody>
    )
  }
}

const CompletedEntries = (props) => {
  const rows = props.entries.map((entry, index) => {

    let rankClassName = "mcLeaderboardTeamRowRank";
    if (entry.winner) {
      rankClassName += " winner"
    }

    const cup = entry.cup && <FontAwesome name="trophy" />
    const exclueFromPool = entry.inPool === false && <FontAwesome name="asterisk" size="xs" />

    return (
      <tr key={entry.entry_id} className="completedEntryRow">
        <td className="completedEntryRow_cell text-center">
          <span className={rankClassName}>{entry.rank}</span>
        </td>
        <td className="completedEntryRow_cell">{entry.team} {exclueFromPool} {cup}</td>
        <td className="completedEntryRow_cell">{numeral(entry.won).format('0,0[.]00')}</td>
        <td className="completedEntryRow_cell">{entry.paid ? "Paid" : "Unpaid"}</td>
      </tr>
    )
  });

  return (
    <Table className="completedEntriesTable">
      <thead>
        <tr className="completedEntriesTableHeader">
          <th className="text-center">POS</th>
          <th className="">Name</th>
          <th className="">Won</th>
          <th className="">Status</th>
        </tr>
      </thead>
      <tbody>
        {rows}
      </tbody>
    </Table>
  )
}

export default PastEntries;
