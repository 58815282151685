import React from 'react';
import {
  Alert,
  Badge,
  Button,ButtonDropdown,ButtonGroup,
  Card,
  Collapse,
  Table,
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
  Row, Col,
  Input,
} from 'reactstrap';

import {
  NavLink
} from 'react-router-dom';

import CsvDownloader from 'react-csv-downloader';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import FontAwesome from 'react-fontawesome';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'common/ConfirmAlert.css';

import { Bootstrap2Toggle } from 'react-bootstrap-toggle';
import "utils/bootstrap2-toggle.css";

import {
  MobileHeader,
  ContentWrapper
} from 'common/CommissionerComponents.js';
import CupScoringInfoModal from 'common/CupScoringInfoModal';

import { get_cup_data, league_in_cup, event_in_cup, set_cup_fld_value, get_cup_manage_field, in_out_team_in_cup} from 'utils/api.js';

export default class Cup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      howToOpen: false,
      isCupWinningPosDDOpen: false,
      isCupScoringMethodDDOpen: false,
      isAllowNewTeamsDDOpen: false,
      showCupManageFieldPage: false,
    };

    this.fetchCupData = this.fetchCupData.bind(this);
    this.fetchCupManageFieldData = this.fetchCupManageFieldData.bind(this);
    this.handleCupEnroll = this.handleCupEnroll.bind(this);
    this.handleEventToggle = this.handleEventToggle.bind(this);
    this.toggleHowTo = this.toggleHowTo.bind(this);
    this.handleInputValueChange = this.handleInputValueChange.bind(this);
    this.toggleCupWinningPosDDOpen = this.toggleCupWinningPosDDOpen.bind(this);
    this.toggleCupScoringMethodDDOpen = this.toggleCupScoringMethodDDOpen.bind(this);
    this.toggleAllowNewTeamsDDOpen = this.toggleAllowNewTeamsDDOpen.bind(this);
    this.saveCupFieldValue = this.saveCupFieldValue.bind(this);
    this.setTeamCupEnrolment = this.setTeamCupEnrolment.bind(this);
    this.toggleShowCupManageFieldPage = this.toggleShowCupManageFieldPage.bind(this);
    this.toggleEventRowActions = this.toggleEventRowActions.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
  }

  componentDidMount() {
    if (this.props.activeLeague === null) { return; }

    this.fetchCupData(this.props.activeLeague);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.activeLeague === nextProps.activeLeague) { return; }

    this.fetchCupData(nextProps.activeLeague);
  }


  fetchCupData(league) {
    if (!league) { return; }

    get_cup_data(league,
    ({success, cup, numOfWinningPlaces, scoringMethod, allowNewTeams, events, members, memberEmails, memberCnt, cupScoring2ndPhase,cupScorePoints, doesHaveFutureEventEntries, msg, error}) => {
      if (success) {
        if (events){
          events.map((x) => x.actionsOpen = false);
        }
        this.setState({cup, howToOpen: !cup, numOfWinningPlaces, scoringMethod, allowNewTeams, events, members, memberEmails, memberCnt, cupScoring2ndPhase, cupScorePoints, doesHaveFutureEventEntries, msg, });
      } else {
        console.log(error);
      }
    }, (error) => {
      console.log(error);
    });
  }

  fetchCupManageFieldData(leagueevent) {
    get_cup_manage_field(this.props.activeLeague,leagueevent,
    ({success, cupid, cupEvent, newTeams, commonTeams, error}) => {
      if (success) {
        this.setState({showCupManageFieldPage:true,cupid, cupEvent, newTeams, commonTeams});
      } else {
        console.log(error);
      }
    }, (error) => {
      console.log(error);
    });
  }

  handleCupEnroll(cup) {
    if (cup === this.state.cup) { return; }

    const leaguein_cup = () => {
      const league = this.props.activeLeague;
      league_in_cup(league, cup,
        (success, error) => {
          if (success) {
            this.fetchCupData(league);
          } else {
            console.log(error);
          }
      }, (error) => {
        console.log(error);
      });
    }

    if (!cup){
      confirmAlert({
        title: 'Confirmation',
        message: 'Are you sure you want to cancel Majors Challenge Cup?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => { leaguein_cup();}
          },
          {
            label: 'Cancel',
            onClick: () => { }
          }
        ]
      });
    }else{
      leaguein_cup();
    }



  }

  toggleEventRowActions(eventid){
    //alert('eventid : ' + eventid);
    let events = this.state.events;
    if (!events) { return; }
    const mtchEvent = events.find(({id}) => id === eventid);
    mtchEvent.actionsOpen = !mtchEvent.actionsOpen;
    this.setState({
      events:events,
    })
  }


  handleEventToggle(eventid, cup) {
    if (!this.state.events) { return; }
    if (this.state.events.find(({id, cup:currCup}) => id === eventid && cup === currCup)) { return; }

    const league = this.props.activeLeague;

    event_in_cup(league, eventid, cup,
      (success, error) => {
        if (success) {
          this.fetchCupData(league);
        } else {
          console.log(error);
        }
    }, (error) => {
      console.log(error);
    });
  }

  toggleHowTo() {
    this.setState({
      howToOpen: !this.state.howToOpen,
    })
  }

  handleTextChange(event){
    const msg = event.target.value;
    this.setState({ msg });
    this.saveCupFieldValue('msg',msg);
  }

  handleInputValueChange(fld, dbFld, fldVal) {
    //Gross Score method can only exist with First Event Only.
    if ((fld === 'scoringMethod' && fldVal === 'Gross Score' && this.state.allowNewTeams === 'All Season Long') ||
    (fld === 'allowNewTeams' && fldVal === 'All Season Long' && this.state.scoringMethod === 'Gross Score')){
      confirmAlert({
        title: 'Alert',
        message: 'Gross Score method requires play in every tournament, starting with the first. It is only possible when Allow New Teams is set to First Event Only.',
        buttons: [
          {
            label: 'OK',
            onClick: () => { }
          }
        ]
      });
      return;
    }
    //alert(fldVal  + ';' + this.state.allowNewTeams);
    let waitForConirmation = false;
    if (fld === 'allowNewTeams' && fldVal === 'First Event Only' &&
      (this.state.allowNewTeams === 'All Season Long' || this.state.allowNewTeams === 'Locked') &&
      this.state.doesHaveFutureEventEntries === true){
      waitForConirmation = true;
      confirmAlert({
        title: 'Confirmation',
        message: 'Switching to First Event Only will remove any teams that did not complete in the first tournament. Continue?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              let stateVal = {};
              stateVal[fld] = fldVal;
              this.setState(stateVal);
              this.saveCupFieldValue(dbFld, fldVal);
            }
          },
          {
            label: 'No',
            onClick: () => { }
          }
        ]
      });
    }
    if (!waitForConirmation){
      let stateVal = {};
      stateVal[fld] = fldVal;
      this.setState(stateVal);
      this.saveCupFieldValue(dbFld, fldVal);

    }
  }

  toggleCupWinningPosDDOpen(){
    this.setState({isCupWinningPosDDOpen: !this.state.isCupWinningPosDDOpen});
  }

  toggleCupScoringMethodDDOpen(){
    this.setState({isCupScoringMethodDDOpen: !this.state.isCupScoringMethodDDOpen});
  }

  toggleAllowNewTeamsDDOpen(){
    this.setState({isAllowNewTeamsDDOpen: !this.state.isAllowNewTeamsDDOpen});
  }

  saveCupFieldValue(dbFld, fldVal){
    set_cup_fld_value(this.props.activeLeague, dbFld, fldVal,
    ({success, error}) => {
      if (success) {

      } else {
        console.log(error);
      }
    }, (error) => {
      console.log(error);
    });
  }

  emailCopyConfirmation(cnt){
    confirmAlert({
      title: 'Email',
      message: cnt + ' Emails Copied to Clipboard',
      buttons: [
        {
          label: 'OK',
          onClick: () => { }
        }
      ]
    });
  }

  setTeamCupEnrolment(state, cupid, teamid){
    let newTeams = this.state.newTeams;
    let found = newTeams.find(team => team.id === teamid);
    if (state){
      found.cupid = cupid;
      found.incup = true;
    }else{
      found.cupid = null;
      found.incup = false;
    }
    this.setState({newTeams})

    in_out_team_in_cup(this.props.activeLeague, teamid, cupid, state,
    ({success, error}) => {
      if (success) {

      } else {
        console.log(error);
      }
    }, (error) => {
      console.log(error);
    });
  }

  toggleShowCupManageFieldPage(){
    this.fetchCupData(this.props.activeLeague);
    this.setState({showCupManageFieldPage:!this.state.showCupManageFieldPage});
  }

  render() {
    return (
      <div className="commissionerMembers">
        <MobileHeader to="Majors Challenge Cup" leagueid={this.props.activeLeague} />
        <ContentWrapper>
          <CupHeader />
          {this.state.showCupManageFieldPage &&
            <div className="ml-2 mt-1">
              <CupManageFields {...this.state} setTeamCupEnrolment={this.setTeamCupEnrolment}
              toggleShowCupManageFieldPage={this.toggleShowCupManageFieldPage}/>
            </div>
          }
          {this.state.showCupManageFieldPage === false &&
            <>
              <CupSubheader howToOpen={this.state.howToOpen} toggle={this.toggleHowTo}/>
              <CupParticipation cup={this.state.cup} numOfWinningPlaces={this.state.numOfWinningPlaces}
                isCupWinningPosDDOpen={this.state.isCupWinningPosDDOpen}
                members={this.state.members}
                memberEmails={this.state.memberEmails} memberCnt={this.state.memberCnt}
                handleCupEnroll={this.handleCupEnroll}  handleInputValueChange={this.handleInputValueChange}
                toggleCupWinningPosDDOpen={this.toggleCupWinningPosDDOpen} saveCupFieldValue={this.saveCupFieldValue}
                emailCopyConfirmation={this.emailCopyConfirmation}
                scoringMethod={this.state.scoringMethod} isCupScoringMethodDDOpen={this.state.isCupScoringMethodDDOpen}
                toggleCupScoringMethodDDOpen={this.toggleCupScoringMethodDDOpen}
                cupScoring2ndPhase={this.state.cupScoring2ndPhase}
                cupScorePoints={this.state.cupScorePoints}
                allowNewTeams={this.state.allowNewTeams} isAllowNewTeamsDDOpen={this.state.isAllowNewTeamsDDOpen}
                toggleAllowNewTeamsDDOpen={this.toggleAllowNewTeamsDDOpen}
                msg={this.state.msg}
                handleTextChange={this.handleTextChange}
                />
              {/*this.state.cup &&
                <MoveToAnnouncement leagueid={this.props.activeLeague}/>
              */}
              <EventsTable {...this.state} handleEventToggle={this.handleEventToggle}
                fetchCupManageFieldData={this.fetchCupManageFieldData}
                toggleEventRowActions={this.toggleEventRowActions}/>
              {this.state.cup &&
              <MoveToTournaments leagueid={this.props.activeLeague}/>
              }
            </>
          }
        </ContentWrapper>

      </div>
    );
  }
}

const CupHeader = () =>
  <div className="d-flex mb-4">
    <div className="d-none d-lg-block mr-auto" style={{fontSize: "1.75em", fontWeight: 300}}>Majors Challenge Cup</div>
  </div>

const CupSubheader = (props) =>
  <Alert color="success" className="my-4">
    <div className="d-flex justify-content-between py-1 font-weight-bold" onClick={props.toggle}>
      Majors Challenge Cup
      <FontAwesome size="lg" name={props.howToOpen ? "angle-up" : "angle-down"} />
    </div>
    <Collapse isOpen={props.howToOpen}>
      <span style={{"fontSize": "0.80rem", whiteSpace: "pre-line"}}>
      The Majors Challenge Cup is a season long leaderboard where your members accumulate points throughout the season. Select which tournaments you’d like to include in your Cup season below. Team names chosen during the first event must be used throughout the season and will appear on The Cup leaderboard. Scoring can mimic the FedEx Cup where points are awarded based on finishing place, or you can choose to simply add gross scores throughout the season. The Cup is a separate competition and independent of results in any specific tournament. The bookkeeping section does not manage any finances you may have related to your Cup season.
      </span>
    </Collapse>
  </Alert>

const CupParticipation = ({cup, numOfWinningPlaces, isCupWinningPosDDOpen,members, memberEmails, memberCnt, handleCupEnroll,
                           handleInputValueChange, toggleCupWinningPosDDOpen, saveCupFieldValue, emailCopyConfirmation,
                           scoringMethod, isCupScoringMethodDDOpen,toggleCupScoringMethodDDOpen, cupScoring2ndPhase, cupScorePoints,
                           allowNewTeams, isAllowNewTeamsDDOpen,toggleAllowNewTeamsDDOpen, msg, handleTextChange}) =>{

   let csvExprtDatas = members;
   let csvExprtFileName = 'Cup Players';

   const csvExprtColumns = [{
       id: 'fname',
       displayName: 'First Name'
     }, {
         id: 'lname',
         displayName: 'Last Name'
     }, {
         id: 'username',
         displayName: 'Username'
     }, {
       id: 'email',
       displayName: 'Email'
     }, {
       id: 'status',
       displayName: 'Status'
     }];

  const numOfWinningPlaceOptions = [1,2,3,4,5,6,7,8,9,10].map((x) =>
    <DropdownItem key={x} onClick={() => handleInputValueChange('numOfWinningPlaces','num_winners',x)}>
      {x}
    </DropdownItem>
  )

  const scoringMethodOptions = ['Short Season','Gross Score','FedEx Weighted','FedEx Equal','Large League'].map((x) =>
    <DropdownItem key={x} onClick={() => handleInputValueChange('scoringMethod','scoring_method',x)}>
      {x}
    </DropdownItem>
  )

  const allowNewTeamsOptions = ['First Event Only','All Season Long','Locked'].map((x) =>
    <DropdownItem key={x} onClick={() => handleInputValueChange('allowNewTeams','allow_new_teams',x)}>
      {x}
    </DropdownItem>
  )



  let memberEmailsArr = (memberEmails?memberEmails.split('; '):[]);
  let filteredEmailsArr = [...new Set(memberEmailsArr)]; // Remove duplicates
  let filteredEmailsCnt = filteredEmailsArr.length;
  let filteredEmails = filteredEmailsArr.join('; ')
  const playersCnt = (members?members.length:0);

  const copyEmailsButton = memberEmails === "" ?
      <div><Button size="sm" color="secondary" outline disabled className="ml-2 ml-md-none text-nowrap"
            style={{minWidth:'60px'}}>
        <FontAwesome name="envelope" />&nbsp;&nbsp;<FontAwesome name="copy" />
      </Button></div> :
      <CopyToClipboard text={filteredEmails}>
        <div>
          <Button size="sm" color="success" outline className="ml-2 ml-md-none text-nowrap"
            style={{minWidth:'60px'}}
            onClick={()=>emailCopyConfirmation(filteredEmailsCnt)}>
            <FontAwesome name="envelope" />&nbsp;&nbsp;<FontAwesome name="copy" />
          </Button>
        </div>
      </CopyToClipboard>

  return(
  <Card body className="p-2 my-4">
    <div className="pl-2 py-2 align-items-center border-bottom">
      <div className="py-2 d-flex">
        <div className=" pt-1">
          {'Participate in the Majors Challenge Cup?'}
        </div>
        <div className=" d-flex justify-content-end ml-2">
          <Bootstrap2Toggle
            onClick={(state)=>{handleCupEnroll(state)}}
            on={'YES'}
            off={'NO'}
            size="sm"
            offstyle="danger"
            onstyle="success"
            active={cup}
          />
        </div>
      </div>
      {cup?
      <div className="text-danger small">Canceling the Cup removes all your results.  This cannot be reversed.</div>:''}
    </div>
    {cup &&
        <div className="pl-2 py-1">
          <Row noGutters className="py-1 mr-1">
            <Col xs="8" className="d-flex justify-content-start">
              <div className="text-nowrap" style={{fontSize:'0.95rem'}}>Number of Winning Places</div>
            </Col>
            <Col xs="4" className="d-flex justify-content-end">
              <Dropdown isOpen={isCupWinningPosDDOpen} toggle={toggleCupWinningPosDDOpen} size="sm">
                <DropdownToggle caret style={{minWidth:'135px',backgroundColor:'white', color:'green', borderColor:'green'}}>
                  {numOfWinningPlaces}
                </DropdownToggle>
                <DropdownMenu style={{maxHeight:'240px',overflow:"auto",fontSize:'.8rem'}}>
                  {numOfWinningPlaceOptions}
                </DropdownMenu>
              </Dropdown>
            </Col>
          </Row>

          {/*RI#177 - The Cup - Phase 2*/}
          {cupScoring2ndPhase ==='ON' &&
          <Row noGutters className="py-1 mr-1">
            <Col xs="8" className="d-flex justify-content-start">
              <div className="text-nowrap" style={{fontSize:'0.95rem'}}>
                Scoring Method<CupScoringInfoModal embedIn={'CommissionerCup'} cupScorePoints={cupScorePoints} scoringMethod={scoringMethod}/>
              </div>
            </Col>
            <Col xs="4" className="d-flex justify-content-end">
              <Dropdown isOpen={isCupScoringMethodDDOpen} toggle={toggleCupScoringMethodDDOpen} size="sm">
                <DropdownToggle caret style={{minWidth:'135px',backgroundColor:'white', color:'green', borderColor:'green'}}>
                  {scoringMethod}
                </DropdownToggle>
                <DropdownMenu style={{maxHeight:'240px',overflow:"auto",fontSize:'.8rem'}}>
                  {scoringMethodOptions}
                </DropdownMenu>
              </Dropdown>
            </Col>
          </Row>
          }
          <Row noGutters className="py-1 mr-1">
            <Col xs="8" className="d-flex justify-content-start">
              <div className="text-nowrap" style={{fontSize:'0.95rem'}}>Entries: <b>{memberCnt}</b> / Players: <b>{playersCnt}</b></div>
            </Col>
            <Col xs="4" className="d-flex justify-content-end">
              <div className="btn-group">
                {copyEmailsButton}
                <CsvDownloader
                  filename={csvExprtFileName}
                  separator=","
                  wrapColumnChar='"'
                  columns={csvExprtColumns}
                  datas={csvExprtDatas}>
                  {
                    csvExprtDatas.length > 0 ?
                    <Button size="sm" color="success" outline className="ml-3 ml-md-none text-nowrap"
                      style={{minWidth:'60px'}}>
                      <FontAwesome name="file-excel-o" />&nbsp;&nbsp;<FontAwesome name="download" />
                    </Button> :
                    <Button size="sm" color="secondary" outline disabled className="ml-3 ml-md-none text-nowrap"
                      style={{minWidth:'60px'}}>
                      <FontAwesome name="file-excel-o" />&nbsp;&nbsp;<FontAwesome name="download" />
                    </Button>
                  }
                </CsvDownloader>
              </div>
            </Col>
          </Row>

          {/*RI#209 - The Cup changes*/}
          {cupScoring2ndPhase ==='ON' &&
          <Row noGutters className="py-1 mr-1">
            <Col xs="8" className="d-flex justify-content-start">
              <div className="text-nowrap" style={{fontSize:'0.95rem'}}>
                Allow New Teams
              </div>
            </Col>
            <Col xs="4" className="d-flex justify-content-end">
              <Dropdown isOpen={isAllowNewTeamsDDOpen} toggle={toggleAllowNewTeamsDDOpen} size="sm">
                <DropdownToggle caret style={{minWidth:'135px',backgroundColor:'white', color:'green', borderColor:'green'}}>
                  {allowNewTeams}
                </DropdownToggle>
                <DropdownMenu style={{maxHeight:'240px',overflow:"auto",fontSize:'.8rem'}}>
                  {allowNewTeamsOptions}
                </DropdownMenu>
              </Dropdown>
            </Col>
          </Row>
          }
          <Row noGutters className="py-1 mr-1">
            <Col xs="12" className="">
              <div className="mb-2" style={{fontSize: ".95rem"}}>
                {'Cup Announcement'}
              </div>
              <Input type="textarea" placeholder="Message to display on the Cup homepage." style={{fontSize:'.8rem'}}
                value={msg} onChange={handleTextChange} rows="6"/>
              <div style={{fontSize: "0.75em", color: "#888"}} className="my-2">
                {'An empty message will hide the commissioner announcement on the Cup home page.'}
              </div>
            </Col>
          </Row>

        </div>
    }
  </Card>
  );
}


const EventsTable = ({cup, allowNewTeams, events, handleEventToggle, fetchCupManageFieldData, toggleEventRowActions}) => {

  if (!cup) { return null; }

  const rows = events.map((event, index) => <EventRow {...event} key={event.id} indx={index} allowNewTeams={allowNewTeams} handleEventToggle={handleEventToggle} fetchCupManageFieldData={fetchCupManageFieldData} toggleEventRowActions={toggleEventRowActions}/>)

  return (
    <Card body className="p-0 mt-4">
      <div className="p-2 d-flex commissionerMembersHeader">
        Scheduled Tournaments this Season
      </div>
      <Table responsive>
        <thead>
          <tr style={{backgroundColor: "#f6f6f6", color: "#7c7c7c", "fontSize": "0.7rem", textTransform: "uppercase"}}>
            <th>Tournament</th>
            <th className="text-center">Cup Event</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </Table>
    </Card>
  )
}

const EventRow = (props) => {
  const {name, dates, course, major, status} = props;

  const tournament =
    <td>
        <div style={{color: "#555", fontWeight:700}}>
          <span className="text-nowrap">{name}</span>
          { major &&
            <Badge color="success" style={{marginLeft: "0.5rem"}}>Major</Badge>
          }
          { status === "open" &&
            <Badge color="warning" style={{marginLeft: "0.5rem"}}> Open </Badge>
          }
          { ["round1", "round2", "round3", "round4"].indexOf(status) !== -1 &&
            <Badge color="info" style={{marginLeft: "0.5rem"}}> Live </Badge>
          }
        </div>
        <div style={{color: "#aaa", fontSize: ".75em"}}>{dates} &bull; {course}</div>
    </td>

    return (
      <tr>
        {tournament}
        <td><IncludeOrRemove {...props} /></td>
      </tr>
    )
}

const IncludeOrRemove = ({cup, status, id, actionsOpen, handleEventToggle, hasEntries, indx, allowNewTeams, isFirstCupEvent, fetchCupManageFieldData, toggleEventRowActions}) => {
  const toggle = () => {toggleEventRowActions(id);};
  let manageFldDisplay = false;
  let regRemoveDisplay = false;

  if ( status !== 'scheduled' &&
      ((isFirstCupEvent && cup && allowNewTeams === "First Event Only") ||
      (cup && allowNewTeams === "All Season Long")) ){manageFldDisplay = true;}
  if (status === 'scheduled' || (status === 'open')){regRemoveDisplay = true;}
  if (manageFldDisplay && regRemoveDisplay){
    return <ButtonGroup style={{width:'100%',}}>
              <Button block color={'success disabled'} onClick={()=>{fetchCupManageFieldData(id);}} className="pt-2 pb-2 disabled"
                      style={{fontWeight: 600, fontSize: ".85em", textTransform:"uppercase",}} >
                {'Manage Field'}
              </Button>
              <ButtonDropdown isOpen={actionsOpen} toggle={toggle} >
                <DropdownToggle caret color={'success disabled'} style={{marginLeft:'3px'}}>
                  &nbsp;
                </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={()=>{handleEventToggle(id, !cup);}}>{cup ? 'Remove' : 'Register'}</DropdownItem>
                  </DropdownMenu>
              </ButtonDropdown>
          </ButtonGroup>;
  }else if (manageFldDisplay){
    return <Button block color={'success disabled'} onClick={()=>{fetchCupManageFieldData(id);}} className="pt-2 pb-2 disabled"
                style={{fontWeight: 600, fontSize: ".85em", textTransform:"uppercase",}} >
                {'Manage Field'}
           </Button>;
  }else if (regRemoveDisplay){
    return <Button block color="success" outline={cup} onClick={()=>{handleEventToggle(id, !cup);}}
                  style={{fontWeight: 600, fontSize: ".85em", textTransform:"uppercase"}} className="pt-2 pb-2">
                  {cup ? 'Remove' : 'Register'}
          </Button>;
  }else{
    return <Button block color={cup ? 'success' : 'secondary'} disabled
            style={{fontWeight: 600, fontSize: ".85em", textTransform:"uppercase"}} className="pt-2 pb-2">
      <FontAwesome name="lock" /> {cup ? 'Included' : 'Excluded'}
    </Button>;
  }

}

const MoveToTournaments = (props) =>
  <Card body className="p-0 mb-4 mt-0" style={{border:"0px solid",backgroundColor:"inherit"}}>
    <div className="pl-2 d-flex align-items-center" style={{fontSize: '0.9em'}}>
    <span>Add more events from </span>&nbsp;
    <NavLink
      to={"/commissioner/" + props.leagueid + "/events"}
      exact={true}
      className="pt-2 pb-2"
      activeClassName="active">
      <div>Tournaments</div>
    </NavLink>
    </div>
  </Card>

  const MoveToAnnouncement = (props) =>
    <Card body className="p-0 mb-4 mt-n4" style={{border:"0px solid",backgroundColor:"inherit"}}>
      <div className="pl-2 d-flex align-items-center" style={{fontSize: '0.9em'}}>
      <span>Create a </span>&nbsp;
      <NavLink
        to={"/commissioner/" + props.leagueid + "/home"}
        exact={true}
        className="pt-2 pb-2"
        activeClassName="active">
        <div>Cup Announcement</div>
      </NavLink>
      </div>
    </Card>


const CupManageFields = ({cupEvent,newTeams,commonTeams, setTeamCupEnrolment, toggleShowCupManageFieldPage}) =>
  <Card body className="p-0 mb-4" style={{border:"0px solid",backgroundColor:"inherit",fontSize: "0.8em"}}>

    <div className="d-flex row mb-4">
      <div className="col-8" style={{fontSize: "1.25em", fontWeight: 300}}>
        <div style={{color: "#555", fontWeight:700}}>
          <span className="text-nowrap">{cupEvent.name}</span>
          { cupEvent.major &&
            <Badge color="success" style={{marginLeft: "0.5rem"}}>Major</Badge>
          }
          { cupEvent.status === "open" &&
            <Badge color="warning" style={{marginLeft: "0.5rem"}}> Open </Badge>
          }
          { ["round1", "round2", "round3", "round4"].indexOf(cupEvent.status) !== -1 &&
            <Badge color="info" style={{marginLeft: "0.5rem"}}> Live </Badge>
          }
        </div>
        <div style={{color: "#aaa", fontSize: ".75em"}}>{cupEvent.dates} &bull; {cupEvent.course}</div>
      </div>
      <div style={{color: "blue", cursor: "pointer", textAlign:"end", paddingRight:"15px"}}
        className="mt-2 col-4" onClick={() => {toggleShowCupManageFieldPage()}}>
        {"<< back"}
      </div>
    </div>
    <div className="d-flex mb-2">
      <CupManageFieldTeamsTable cupid={cupEvent.cupid} teams={newTeams}
        setTeamCupEnrolment={setTeamCupEnrolment} isNewTeam={true}/>
    </div>
    <div className="d-flex mb-4">
      <CupManageFieldTeamsTable cupid={cupEvent.cupid} teams={commonTeams}
        setTeamCupEnrolment={setTeamCupEnrolment} isNewTeam={false}/>
    </div>
  </Card>

const CupManageFieldTeamsTable = ({cupid, teams, setTeamCupEnrolment, isNewTeam}) => {
  const rows = teams.map(team => <CupManageFieldTeamsRow {...team} key={team.id} cupid={cupid} isNewTeam={isNewTeam} setTeamCupEnrolment={setTeamCupEnrolment}/>)

  return (
    <Card body className="p-0 mt-1">
      <div className="p-2 d-flex commissionerMembersHeader">
        {isNewTeam?"New Teams":"Previous Teams"}
      </div>
      <Table responsive>
        <thead>
          <tr style={{backgroundColor: "#f6f6f6", color: "#7c7c7c", "fontSize": "0.7rem", textTransform: "uppercase"}}>
            {/*<th className="text-center text-nowrap">In-The-Cup</th>*/}
            <th>Name</th>
            <th>Team Name</th>
            {isNewTeam &&
              <th className="text-center">Enrolled/Excluded</th>
            }
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </Table>
    </Card>
  )
}

const CupManageFieldTeamsRow = (props) => {

    return (
      <tr>
        <td>{props.name}</td>
        <td>{props.teamname}</td>
        {props.isNewTeam &&
          <td className="text-center">
            <Bootstrap2Toggle
              onClick={(state)=>{props.setTeamCupEnrolment(state, props.cupid, props.id)}}
              on={<span style={{fontSize:"0.8em"}}>ENROLLED</span>}
              off={<span style={{fontSize:"0.8em"}}>EXCLUDED</span>}
              size="sm"
              offstyle="secondary"
              onstyle="success"
              active={props.incup}

            />
          </td>
        }
      </tr>
    )
}
