import React from 'react';

import {
  Container,
  Row,
  Col,
  Button,
} from 'reactstrap';


import FontAwesome from 'react-fontawesome';

import {ClubLandingWrapper} from 'common/LandingWrapper.js';

import iconComms from 'img/icon-communication.png';
import iconConsole from 'img/icon-console.png';
import iconLeaderboard from 'img/icon-leaderboard.png';
import iconPricing from 'img/icon-pricing.png';
import iconPGALogo from 'img/pgalogo.png';
import iconJDMELogo from 'img/JDMELogo1.png';


import 'pages/user/Landing.css';

import {submit_club_intake} from 'utils/api';

const ClubLanding = (props) => {
  return (
    <ClubLandingWrapper {...props}>
      <Hero/>
      <MobileClubIntake />
      <Features />
      <PeopleAreTalking />
    </ClubLandingWrapper>
  )
}

class ClubIntakeForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      phone: '',
      golfcourse: '',
      submitted: false,
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.canSubmit = this.canSubmit.bind(this);
  }
  handleChange(event) {
    this.setState({[event.target.name]: event.target.value});
  }
  handleSubmit(event) {
    event.preventDefault();
    const {name, email, phone, golfcourse} = this.state;
    submit_club_intake(name, email, phone, golfcourse, () => {
      this.setState({submitted: true});
    }, (err) => {
      console.log('Error submitting club intake form: ', err);
    })
  }
  canSubmit() {
    if (!this.state.name || !this.state.email) {
      return false;
    }
    const re = /\S+@\S+\.\S+/;
    return re.test(this.state.email);
  }
  render() {
    return (
      <div className="clubIntakeForm py-4 px-md-5">
        {this.state.submitted
        ? <div>
            <h6 className="clubIntakeForm_title">Request sent</h6>
            <div className="clubIntakeForm_subtitle pt-3">
            Thanks for your inquiry. Out team will reach out to you shortly.
            </div>
          </div>
        : <div>
            <h6 className="clubIntakeForm_title">Contact Us Today</h6>
            <div className="clubIntakeForm_subtitle">
            Let us custom tailor your pool.
            </div>
            <form onSubmit={this.handleSubmit}>
            <input type="text" name='name' value={this.state.name} onChange={this.handleChange} className="form-control my-3" placeholder="your name"/>
            <input type="text" name='email' value={this.state.email} onChange={this.handleChange} className="form-control my-3" placeholder="email address"/>
            <input type="phone" name='phone' value={this.state.phone} onChange={this.handleChange} className="form-control my-3" placeholder="phone number"/>
            <input type="text" name='golfcourse' value={this.state.golfcourse} onChange={this.handleChange} className="form-control my-3" placeholder="club name (optional)"/>
            <Button type="submit" className="clubIntakeForm_submit" onClick={this.handleSubmit} disabled={!this.canSubmit()}>Submit</Button>
            </form>
          </div>
        }
      </div>
    )
  }
}

const Hero = (props) => (
  <div>
    <div className="clubLandingHeroBg"/>
    <Container className="clubLandingHero">
      <Row noGutters>
        <Col xs={{size: 12}} sm={{size: 10, offset: 1}} lg={{size: 6, offset: 0}}>
          <div className="landingHero_title club pb-3 font-weight-bold">
            We can help increase golfer engagement at your club.
          </div>
          <div className="landingHero_subtitle">
            Running a golf pool is a proven way to uniquely engage your membership while driving additional golf shop sales and increased revenue.
            <br/><br/>
            As the premier platform for golf clubs, Majors Challenge simplifies the process of running a golf pool and brings first-in-class live scoring. You get a proven way to boost revenue across golf shop sales, food & beverage, and more. Your members get a fun, unique way to engage with the club and with each other.
            <br/><br/>
            Contact us today. We can customize a pool for your membership in minutes.
            <br/><br/>
            Ready to start right now? <a href="/newgolfclub">Click Here</a> to create your club’s golf pool. Someone from our team will be in touch to answer any questions.
            <br/><br/>
            <img src={iconPGALogo} alt={'PGA'} className="pgaLogo"/>
            <img src={iconJDMELogo} alt={'JDME'} className="jdmeLogo"/>
          </div>
        </Col>
        <Col lg={{size: 5, offset: 1}} className="d-none d-lg-block">
          <div className="clubLandingHeroForm" style={{height:'390px'}}>
            <ClubIntakeForm />
          </div>
        </Col>
      </Row>

    </Container>
  </div>
)

const MobileClubIntake = (props) => (
  <Container className="d-block d-lg-none">
    <ClubIntakeForm />
  </Container>
)

const Features = (props) => (
  <>
  {/*<div className="landingSection1">
    <Container className="landingContainer" id="features">
      <Row>
        <Col xs="12" sm="9" lg="6" className="pb-3">
        <div className="">
          <iframe width="392" height="240" style={{border:'1px solid'}}
            src="https://www.youtube.com/embed/-38EZkxK1XU?autoplay=0"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen>
            </iframe>
        </div>
        </Col>
      </Row>
    </Container>
  </div>*/}
  <div className="landingSection4">
    <Container className="landingContainer" id="features">
      {/* <Row>
        <Col xs="12" lg="6" className="d-flex justify-content-center align-items-center order-12 order-lg-1 pb-4 pb-lg-none">
          <div className="embed-responsive embed-responsive-16by9">
            <iframe title="majorschallenge-for-clubs" className="embed-responsive-item" src="https://www.youtube.com/embed/ZtKk5tGk1ew?rel=0" allowFullScreen></iframe>
          </div>
        </Col>
        <Col xs="12" lg="6" className="order-1 order-lg-12">*/}
          <Row>
            <FeaturesCell
              image={iconPricing}
              title="Simple Pricing"
              text="Yearly subscriptions cost $500. You get unlimited users for every tournament throughout the season, not just The Majors."
            />
            <FeaturesCell
              image={iconLeaderboard}
              title="Live Leaderboards"
              text="Follow the tour action as it unfolds right beside your league's leaderboard. See who has which players and who’s moving up and down the leaderboard."
            />
            <FeaturesCell
              image={iconConsole}
              title="Commissioner's Console"
              text="As commissioner you have tools for managing every aspect of your league. Change the payout structure, manage collections, communicate with members. It's all a few clicks away."
            />
            <FeaturesCell
              image={iconComms}
              title="Engage Your Members"
              text="Clubs are using Majors Challenge to keep the golf conversation going all season long. It's an ideal platform to engage members, move merchandise or just have some fun."
            />
          </Row>
        {/*</Col>
      </Row>*/}
    </Container>
  </div>
  </>
)

const FeaturesCell = (props) => (
  <Col xs="12" sm="6" lg="3" className="pb-3">
    <img src={props.image} alt={props.title} className="landingBD_image"/>
    <h6 className="landingBD_title">{props.title}</h6>
    <p className="landingBD_text">{props.text}</p>
  </Col>
)

const PeopleAreTalking = (props) => (
  <div className="landingSection1">
    <Container className="landingContainer text-center pb-4">
      <h6 className="landingSection_smalltitle">People are talking</h6>
      <Row className="justify-content-center" noGutters>
        <Col xs="12" md={{size: 1, offset: 0}}>
          <FontAwesome name="quote-left" className="landingJumboQuotes"/>
        </Col>
        <Col xs="12" md={{size: 6}}>
          <p className="landingQuote">
            We used to run a paper pool out of the pro shop.
            It was difficult to administer and engagement was low.
            The Majors Challenge solves all those problems.
          </p>
        </Col>
        <Col xs="12" md={{size: 1}}>
          <FontAwesome name="quote-right" className="landingJumboQuotes"/>
        </Col>
      </Row>
      {/*
      <Row className="justify-content-center pt-3">
        <span style={{fontFamily: "Roboto", color: "#90a4ae", lineHeight: "20px"}}>Peter Murray</span>
      </Row>
      <Row className="justify-content-center pb-5">
        <span style={{fontSize: "12px", fontFamily: "Roboto", color: "#90a4ae", letterSpacing: "0.3px", lineHeight: "20px"}}>Pittsburgh, PA</span>
      </Row>
      */}
    </Container>
  </div>
)


export default ClubLanding;
