import React from 'react';
import {
  Button, ButtonGroup,
  Card, CardHeader, CardBody,
  Collapse,
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
  Table,
  Alert,
} from 'reactstrap';

import { Link } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import numeral from 'numeral';
import FontAwesome from 'react-fontawesome';

import { CURRENT_SEASON } from 'utils/constants';

import {
  MobileHeader,
  ContentWrapper
} from 'common/CommissionerComponents.js';

import CsvDownloader from 'react-csv-downloader';
import LoadingModal from 'common/LoadingModal.js';

import LeagueBalanceWarning from 'common/LeagueBalanceWarning';
import TeamModal from 'common/TeamModal';
import PickTeamModal from 'pages/commissioner/PickTeamModal.js';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'common/ConfirmAlert.css';

import {
  get_payout_events,
  get_payouts,
  lock_point_pool,
  mark_paid,
  mark_unpaid,
  mark_all_paid,
  mark_all_unpaid,
  include_in_pool,
  exclude_from_pool,
  remove_entry,
} from 'utils/api.js';

import 'pages/commissioner/Payouts.css';

export default class Payouts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dropdownOpen: false,
      dropdownSeasonOpen: false,
      filter: 'owe',
      howToOpen: false,
      season: CURRENT_SEASON,
      eventLoadDone: false,
      pageLoadDone: false,
      teamModalOpen: false,
      teamModalEntry: null,
      teamModalRoster:null,
      loading: false,
      pickTeamModalOpen: false,
    }

    this.fetchEvents = this.fetchEvents.bind(this);
    this.selectEvent = this.selectEvent.bind(this);
    this.selectEventsForSeason = this.selectEventsForSeason.bind(this);
    this.fetchEvent = this.fetchEvent.bind(this);
    this.lockPointPool = this.lockPointPool.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.toggleSeasonDropdown = this.toggleSeasonDropdown.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.toggleHowTo = this.toggleHowTo.bind(this);
    this.markAllPaid = this.markAllPaid.bind(this);
    this.markAllUnpaid = this.markAllUnpaid.bind(this);
    this.toggleTeamModal = this.toggleTeamModal.bind(this);
    this.showTeamDetails = this.showTeamDetails.bind(this);
    this.setLoadingModal = this.setLoadingModal.bind(this);
    this.togglePickTeamModal = this.togglePickTeamModal.bind(this);
  }

  componentDidMount() {
    console.log('eventid :' + this.props.match.params.eventid);
    this.fetchEvents(this.props.activeLeague);
    //console.log('eventid :' + this.props.match.params.eventid);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.activeLeague === nextProps.activeLeague) {
      return
    }

    this.fetchEvents(nextProps.activeLeague);
  }

  setLoadingModal(loading){
    this.setState({loading});
  }

  fetchEvents(league) {
    if (!league)
      return;
    this.setState({loading:true});
    get_payout_events(league,
    ({success, tournaments, error}) => {
      this.setState({loading:false});
      if (success) {
        this.setState({eventLoadDone:true});
        if (!tournaments) {
          this.setState({tournaments});
        } else {
          const eventid = this.props.match.params.eventid?parseInt(this.props.match.params.eventid):0;
          let seasonTournaments = tournaments.filter(tournament => tournament.season === tournaments[0].season);
          let selectedTournament = eventid >0 ? seasonTournaments.filter(tournament => tournament.id === eventid): [];
          this.setState({
            tournaments,
            tournament_index: (selectedTournament && selectedTournament.length > 0? seasonTournaments.indexOf(selectedTournament[0]) : 0),
            season: tournaments[0].season,
            seasonTournaments: seasonTournaments,
            filter: (seasonTournaments.length > 0 && seasonTournaments[0].leagueType === 'CHARITY')? 'all': this.state.filter,
          });
          //this.selectEventsForSeason(this.state.season);

          this.fetchEvent(this.state.seasonTournaments[this.state.tournament_index].id);
        }
      } else {
        console.log(error);
      }
    }, (error) => {
      console.log(error);
    });
  }

  selectEventsForSeason(season) {
    let filterFunc = null;
    filterFunc = (tournament) => tournament.season === season;
    //alert (season);

    let seasonTournaments = null;
    seasonTournaments = this.state.tournaments.filter(filterFunc);

    //this.fetchEvent(seasonTournaments[0].id);

    this.setState({
      season: season,
      seasonTournaments: seasonTournaments,
      tournament_index: 0
    });
    this.selectEvent(0);

  }

  selectEvent(index) {
    let status = this.state.seasonTournaments[index].status;
    let leagueType = this.state.seasonTournaments[index].leagueType;
    this.setState({
      tournament_index: index,
      filter: leagueType === 'CHARITY'? 'all':(status === 'closed'?'owe':'all'),
    });
    this.fetchEvent(this.state.seasonTournaments[index].id);
  }

  fetchEvent(tournament_id) {
    this.setState({loading:true});
    get_payouts(tournament_id,
    ({success, payouts, pointpool, paid, collections, resStateRequired, poolDepositAmount, error}) => {
      if (success) {
        this.setState({
          payouts,
          pointpool,
          paid,
          collections,
          resStateRequired,
          pageLoadDone: true,
          loading: false,
          poolDepositAmount,
        });
      } else {
        console.log(error);
      }
    }, (error) => {
      console.log(error);
    });
  }

  toggleDropdown() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    })
  }

  toggleSeasonDropdown() {
    this.setState({
      dropdownSeasonOpen: !this.state.dropdownSeasonOpen,
    })
  }

  lockPointPool(tournament_id) {
    lock_point_pool(tournament_id,
    ({success, error}) => {
      if (success) {
        let tournaments = this.state.tournaments;
        tournaments[this.state.tournament_index].pool_locked = true

        this.setState({ tournaments });
      } else {
        console.log(error);
      }
    }, (error) => {
      console.log(error);
    });
  }

  markAllPaid() {
    let tournament = this.state.tournaments[this.state.tournament_index];
    const filteredPayouts = filterPayouts(this.state.payouts, this.state.filter, tournament);
    let entries = filteredPayouts.map((payout) => payout.entryId) // List of entryId
    if (entries.length ===0 ) return;

    confirmAlert({
      title: 'Mark All Paid',
      message: 'Mark all teams as Paid. Are you sure?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
                            this.setLoadingModal(true);
                            mark_all_paid(entries,
                                        ({success, error}) => {
                                          if (success) {
                                            this.fetchEvent(this.state.seasonTournaments[this.state.tournament_index].id);
                                          } else {
                                            console.log(error);
                                          }
                                        }, (error) => {
                                          console.log(error);
                                        });

                        }

        },
        {
          label: 'No',
          onClick: () => {  }
        }
      ]
    });

  }

  markAllUnpaid() {
    let tournament = this.state.tournaments[this.state.tournament_index];
    const filteredPayouts = filterPayouts(this.state.payouts, this.state.filter, tournament);
    let entries = filteredPayouts.map((payout) => payout.entryId) // List of entryId
    if (entries.length ===0 ) return;

    confirmAlert({
      title: 'Mark All Unpaid',
      message: 'Mark all teams as Unpaid. Are you sure?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {this.setLoadingModal(true);
                          mark_all_unpaid(entries,
                                      ({success, error}) => {
                                        if (success) {
                                          this.fetchEvent(this.state.seasonTournaments[this.state.tournament_index].id);
                                        } else {
                                          console.log(error);
                                        }
                                      }, (error) => {
                                        console.log(error);
                                      });
                        }
        },
        {
          label: 'No',
          onClick: () => {  }
        }
      ]
    });

  }

  handleFilter(filter) {
    if (this.state.filter === filter) {
      return;
    }

    this.setState({filter});
  }

  toggleHowTo() {
    this.setState({
      howToOpen: !this.state.howToOpen,
    })
  }

  emailCopyConfirmation(cnt){
    confirmAlert({
      title: 'Email',
      message: cnt + ' Emails Copied to Clipboard',
      buttons: [
        {
          label: 'OK',
          onClick: () => { }
        }
      ]
    });
    //alert(cnt + ' Emails Copied to Clipboard');
  }

  toggleTeamModal() {
    this.setState({teamModalOpen: !this.state.teamModalOpen});
  }

  showTeamDetails(entry, roster) {
    this.setState({
      teamModalEntry: entry,
      teamModalRoster:roster,
      teamModalOpen: true
    });
  }

  togglePickTeamModal(){
    if (this.state.pickTeamModalOpen){this.fetchEvent(this.state.seasonTournaments[this.state.tournament_index].id);}
    this.setState({pickTeamModalOpen: !this.state.pickTeamModalOpen});
  }

  render() {

    let dropdownSeasonItems = null;
    let listOfSeasonsMap = [];
    let listOfSeasons = [];
    if (this.state.tournaments !== undefined) {
      this.state.tournaments.map((tournament, index) => {
         if (!listOfSeasonsMap[tournament.season]){
           listOfSeasonsMap[tournament.season] = tournament.season;
           listOfSeasons.push(tournament.season);
         }
      })
      console.log(listOfSeasons);
      dropdownSeasonItems = listOfSeasons.map((season, index) => {
        if (season === this.state.season){
          return (
            <DropdownItem key={season} onClick={()=>this.selectEventsForSeason(season)} active>
              {season}
            </DropdownItem>
          )
        }else{
          return (
            <DropdownItem key={season} onClick={()=>this.selectEventsForSeason(season)}>
              {season}
            </DropdownItem>
          )
        }
      })


    }

    let dropdownItems = null;
    if (!!this.state.seasonTournaments) {
      dropdownItems = this.state.seasonTournaments.map((tournament, index) => {
          if (index=== this.state.tournament_index){
            return (
                <DropdownItem key={tournament.id} onClick={()=>this.selectEvent(index)} active>
                  {tournament.name}
                </DropdownItem>
            )
         }else{
            return (
            <DropdownItem key={tournament.id} onClick={()=>this.selectEvent(index)}>
              {tournament.name}
            </DropdownItem>
          )
        }

        })

      //dropdownItems = renderTournamentsDropdown(this.state.seasonTournaments, this.state.tournaments, this.renderTournamentsDropdown);
    }



    let tournament = null;
    const t_index = this.state.tournament_index;
    if (t_index !== undefined && !!this.state.seasonTournaments) {
      tournament = this.state.seasonTournaments[t_index];
    }

    let pool_factor = '';
    if (!!tournament) {
      pool_factor = tournament.pool === 'variable' ?
                      tournament.pool_factor + 'x' :
                      (tournament.pool === 'seasonLong' ? numeral(tournament.seasonLongFee).format('$0,000[.]00') + '/YR':
                      numeral(tournament.pool_factor).format('$0,000[.]00'));
    }

    let pointpool = ( this.state.pointpool > 0 ?
      <div className="mx-1">
        <div>{numeral(this.state.pointpool).format('0,000[.]00')}</div>
        <div className="subtitle">Purse</div>
      </div> : ''
    )

    let collections = ''
    if (!!tournament) {
      if (tournament.status !== 'closed' && tournament.pool === 'variable') {
        collections = 'N/A';
      } else {
        collections = numeral(this.state.paid).format('0,000[.]00') + ' / ' + numeral(this.state.collections).format('0,000[.]00')
      }
    }

    let collected =
      <div className="mx-1">
        <div>{collections}</div>
        <div className="subtitle">Collected</div>
      </div>

    let status = (
      <div className="mx-1">
        <div>{' '}</div>
        <div className="subtitle"></div>
      </div>
    )

    if (!!tournament && !!this.state.payouts) {
      const filteredPayouts = filterPayouts(this.state.payouts, this.state.filter, tournament);
      let isMarkAllBtnVisible = (filteredPayouts.length >0?true:false);
      let isMarkAllBtnEnabled = true;
      if (tournament.pool === 'variable' && tournament.status !== 'closed'){
        isMarkAllBtnEnabled = false;
      }
      if (['classic','seasonLong'].indexOf(tournament.pool)>-1 && this.state.filter === 'winners'){
        isMarkAllBtnVisible = false;
      }
      if (tournament.leagueType === 'CHARITY'){
        isMarkAllBtnVisible = false;
      }
      if (isMarkAllBtnVisible === true){
        if (this.state.filter === 'owe' || this.state.filter === 'winners'){
          if (isMarkAllBtnEnabled){
            status = <Button className="" color="primary" onClick={()=>this.markAllPaid()}>
                      Mark All Paid
                     </Button>
          }else{
            status = <Button className="" disabled color="secondary">
                      Mark All Paid
                     </Button>
          }
        }else if (this.state.filter === 'paid'){
          if (isMarkAllBtnEnabled){
            status = <Button className="" color="primary" onClick={()=>this.markAllUnpaid()}>
                      Mark All Unpaid
                     </Button>
          }else{
            status = <Button className="" disabled color="secondary">
                      Mark All Unpaid
                     </Button>
          }
        }
      }
      /*
     if (tournament.status === 'closed') {
        status = tournament.pool_locked ?
            <div className="pr-4 mx-1">
              <div>Pool Locked</div>
              <div className="subtitle">Status</div>
            </div>
            :
            <Button className="pr-4 mx-1" color="primary" onClick={()=>this.lockPointPool(tournament.id)}>
              Mark All Paid
            </Button>
      } else {
        status = (
          <div className="pr-4 mx-1">
            <div>{tournament.status === 'open' ? 'Open' : 'Live'}</div>
            <div className="subtitle">Status</div>
          </div>
        )
      }*/
    }

    let csvExprtFileName = this.state.filter.toUpperCase() + " Bookkeeping for " +
      (tournament?tournament.name:'') + " (" + this.state.season + ")";
    let csvExprtDatas = [];
    let csvExprtColumns = [{
        id: 'rank',
        displayName: 'Position'
      }, {
        id: 'owner',
        displayName: 'Name'
      }, {
        id: 'owneremail',
        displayName: 'Email'
      }
    ];
    if (tournament && tournament.leagueType !== 'CHARITY'){
      csvExprtColumns.push({
        id: 'ownervenmo',
        displayName: 'Venmo'
      });
    }
    if (this.state.resStateRequired){
      csvExprtColumns.push({
        id: 'resState',
        displayName: 'Resident State'
      });
    }
    csvExprtColumns.push({
      id: 'team',
      displayName: 'Team Name'
    });
    if (tournament && tournament.leagueType === 'CHARITY'){
      csvExprtColumns.push({
        id: 'poolDepositAmt',
        displayName: 'Donated'
      });
    }else{
      csvExprtColumns.push({
        id: 'winning',
        displayName: 'Owe'
      },{
        id: 'paidDone',
        displayName: 'Paid'
      });
    }
    let filteredEmails = "";
    let filteredEmailsCnt = 0;
    if (!!this.state.payouts) {
      const filteredPayouts = filterPayouts(this.state.payouts, this.state.filter, tournament);

      filteredEmails = filteredPayouts.map((payout) => payout.owneremail) // List of emails
      filteredEmails = [...new Set(filteredEmails)] // Remove duplicates
      filteredEmailsCnt = filteredEmails.length;
      filteredEmails = filteredEmails.join('; ') // join with separator recognized by standard email clients

      csvExprtDatas = [];
      filteredPayouts.map((payout, index) => {
          let data = payout;
          data['paidDone'] = (payout.paid?'Yes':'No');
          data['owneremail'] = (payout.owneremail?payout.owneremail:payout.ownerusername);
          data['poolDepositAmt'] = '$'+payout.poolDepositAmount;
          csvExprtDatas.push(data);

      })
    }

    const copyEmailsButton = filteredEmails === "" ?
      <Button color="secondary" outline disabled size="sm" style={{marginTop: "8px", marginBottom: "18px"}}
        className="text-nowrap">
        <FontAwesome name="envelope" />&nbsp;&nbsp;<FontAwesome name="copy" />
      </Button> :
      <CopyToClipboard text={filteredEmails}>
        <Button color="success" size="sm" style={{marginTop: "8px", marginBottom: "18px"}} className="text-nowrap"
        outline onClick={()=>this.emailCopyConfirmation(filteredEmailsCnt)}>
          <FontAwesome name="envelope" />&nbsp;&nbsp;<FontAwesome name="copy" />
        </Button>
      </CopyToClipboard>

    const filteredPayouts = !!this.state.payouts ? filterPayouts(this.state.payouts, this.state.filter, tournament) : [];

    const filterButton = (currFilter, text) => {
      const active = this.state.filter === currFilter;
      const textWithCount = active ? text + ' (' + filteredPayouts.length + ')' : text;

      return <Button color="primary" outline onClick={() => this.handleFilter(currFilter)} active={active} key={currFilter}>
        <span className="text-uppercase text-nowrap" style={{fontSize: ".7em"}}>{textWithCount}</span>
      </Button>
    }

    const payoutsHeader = (league) => (
      <div className="pb-0 d-flex justify-content-between">
        <div className="d-none d-lg-block mr-auto p-0" style={{fontSize: "1.75em", fontWeight: 300}}>Bookkeeping</div>
        <div className="d-flex justify-content-between pt-2 pr-3">
        <Dropdown isOpen={this.state.dropdownSeasonOpen} toggle={this.toggleSeasonDropdown} className="pr-2">
          <DropdownToggle caret outline color="success" size="sm">
            {this.state.season}
          </DropdownToggle>
          {!!dropdownSeasonItems &&
            <DropdownMenu right>
              {dropdownSeasonItems}
            </DropdownMenu>
          }
        </Dropdown>
        <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown} className="pl-2">
          <DropdownToggle caret outline color="success" size="sm">
            Tournaments
          </DropdownToggle>
          {!!dropdownItems &&
            <DropdownMenu style={{maxHeight:'300px',"overflow":"auto",marginTop:'-17px'}}>
              {dropdownItems}
            </DropdownMenu>
          }
        </Dropdown>

        </div>
        <div className="d-flex justify-content-end pt-0">
        {copyEmailsButton}
        <CsvDownloader
          filename={csvExprtFileName}
          separator=","
          wrapColumnChar='"'
          columns={csvExprtColumns}
          datas={csvExprtDatas}>
          {
            csvExprtDatas.length > 0 ?
            <Button size="sm" color="success" outline className="ml-3 ml-md-none text-nowrap"
              style={{marginTop: "8px", marginBottom: "18px"}}>
              <FontAwesome name="file-excel-o" />&nbsp;&nbsp;<FontAwesome name="download" />
            </Button> :
            <Button size="sm" color="secondary" outline disabled className="ml-3 ml-md-none text-nowrap"
              style={{marginTop: "8px", marginBottom: "18px"}}>
              <FontAwesome name="file-excel-o" />&nbsp;&nbsp;<FontAwesome name="download" />
            </Button>
          }

        </CsvDownloader>
        </div>
      </div>
    )

    return (
      <>
      <div>
        <MobileHeader to="Bookkeeping" leagueid={this.props.activeLeague} />
        <ContentWrapper>

          {this.props.balancewarning && <LeagueBalanceWarning {...this.props} />}
          {(tournament && tournament.leagueType !== 'CHARITY') &&
          <HowToBookkeep howToOpen={this.state.howToOpen} toggle={this.toggleHowTo} />
          }
          <Card>
            <CardHeader className="pb-0 pl-3 pr-3">{payoutsHeader(this.props.activeLeague)}</CardHeader>
            <CardBody className="p-0">
              {!!this.state.tournaments &&
                <div className="d-flex justify-content-between align-items-center flex-wrap px-2 pb-2">
                  {(tournament && tournament.leagueType !== 'CHARITY') &&
                  <ButtonGroup className="pt-2" style={{overflowX: 'auto'}}>
                    {filterButton('owe', 'owe')}
                    {filterButton('paid', 'paid')}
                    {filterButton('winners', 'winners')}
                    {/*filterButton('in pool', 'in pool')}
                    {filterButton('excluded from pool', 'excluded')*/}
                    {filterButton('all', 'all')}
                  </ButtonGroup>
                  }
                  {tournament && tournament.leagueType === 'CHARITY' &&
                  <ButtonGroup className="pt-2" style={{overflowX: 'auto'}}>

                  </ButtonGroup>
                  }
                  <div className="d-flex justify-content-between pt-2" style={{overflowX: 'auto'}}>
                    {tournament && tournament.status !== 'closed' &&
                    <Button size="sm" color="success" outline className="mx-2 ml-md-none text-nowrap" onClick={()=>this.togglePickTeamModal()}
                      style={{}}>
                      Add Team<FontAwesome name="plus" style={{marginLeft: '5px'}} />
                    </Button>
                    }
                    {/*<div>
                      <Button size="sm" color="success" outline>Mark All Paid</Button>
                    </div>
                    <div>
                      <Button className="mx-2" size="sm" color="success" outline>Mark All Unpaid</Button>
                    </div>*/}

                  </div>
                </div>
              }
              {(!!this.state.tournaments && tournament.leagueType !== 'CHARITY' ) &&
                <div className="p-4 tournamentHeader d-flex justify-content-between text-center text-nowrap table-responsive">
                  <div className="mx-1 text-left">
                    <div>{!!tournament && tournament.name}</div>
                    {!!tournament && <div className="subtitle"> {tournament.season} Season &bull; {tournament.dates}</div>}
                  </div>
                  <div className="mx-1">
                    <div>{!!tournament && tournament.pool}</div>
                    <div className="subtitle">Pool Type</div>
                  </div>
                  <div className="mx-1">
                    <div>{pool_factor}</div>
                    <div className="subtitle">{!!tournament && (tournament.pool === 'variable' ? 'Point Multiplier' : 'Entry Fee')}</div>
                  </div>
                  {pointpool}
                  {!!tournament &&tournament.pool !== 'seasonLong' && collected}
                  {status}

                </div>
              }
              {(!!this.state.tournaments && tournament.leagueType === 'CHARITY' ) &&
                <div className="p-4 tournamentHeader d-flex justify-content-between text-center text-nowrap table-responsive">
                  <div className="mx-1 text-left">
                    <div>{!!tournament && tournament.name}</div>
                    {!!tournament && <div className="subtitle"> {tournament.season} Season &bull; {tournament.dates}</div>}
                  </div>
                  <div className="mx-1">
                    <div>{!!tournament && this.state.payouts && this.state.payouts.length}</div>
                    <div className="subtitle">Entries</div>
                  </div>
                  <div className="mx-1">
                    <div>{!!tournament && <span>${numeral(this.state.poolDepositAmount).format('0,0[.]00')}</span>}</div>
                    <div className="subtitle">Amount Raised</div>
                  </div>

                </div>
              }
              {(!this.state.tournaments && this.state.eventLoadDone===true) &&
                <div className="p-2 d-flex justify-content-center text-center text-nowrap table-responsive">
                  <h6>No Tournaments Results Yet.</h6>
                </div>
              }
              {!!tournament &&
                <PayoutsTable {...this.state} filteredPayouts={filteredPayouts} tournament={tournament} filter={this.state.filter}
                fetchEvent={this.fetchEvent} activeLeague={this.props.activeLeague} pageLoadDone={this.state.pageLoadDone}
                showTeamDetails={this.showTeamDetails} setLoadingModal={this.setLoadingModal}/>
              }

            </CardBody>
          </Card>
          <LoadingModal isLoading={this.state.loading} />
          <TeamModal isOpen={this.state.teamModalOpen} toggle={this.toggleTeamModal}
            entry={this.state.teamModalEntry} roster={this.state.teamModalRoster}/>



        </ContentWrapper>
      </div>
      {!!tournament &&
      <PickTeamModal isOpen={this.state.pickTeamModalOpen}
        toggle={this.togglePickTeamModal} leagueid={this.props.activeLeague} eventid={tournament.id}
        selectedLeague={this.props.selectedLeague}/>

      }
      </>
    );
  }
}

const HowToBookkeep = props =>
  <Alert color="success">
    <div className="d-flex justify-content-between py-1 font-weight-bold" onClick={props.toggle}>
      Managing the Books Cheatsheet
      <FontAwesome size="lg" name={props.howToOpen ? "angle-up" : "angle-down"} />
    </div>
    <Collapse isOpen={props.howToOpen}>
      <ul style={{fontSize: ".85em", fontWeight: "200"}} className="mt-2">
        <li className="mb-3">
          Use Bookkeeping to keep track of who entered a tournament, who still owes and who won.
        </li>
        <li className="mb-3">
          You may <b>delete</b> a team. This cannot be undone.
        </li>
        {/*<li className="mb-3">
          Once the tournament starts, teams cannot be deleted. <b>Exclude</b> a team from the point pool instead.
          <br />
          (Excluded teams do not contribute to the point pool total, and thus reduce the total winnings. Useful for deadbeats which you cannot collect from.)
        </li>*/}
        <li className="mb-3">
          Mark a team as <b>paid</b> to track that they have paid their share to the point pool. This action is always reversible.
          <p />
          <dl>
            <dt>Variable point pools</dt>
            <dd>Losses are not known until the event concludes. Wait until then to mark teams paid.</dd>
            <dt>Classic point pools</dt>
            <dd>This action is avaialble at any time, as classic pools have predetermiend fees.</dd>
            <dt>Winners</dt>
            <dd>Track that you have paid out winners here.</dd>
          </dl>
        </li>
        <li className="mb-3">
          For <b>One-Time Season Long Payment</b> leagues, once you mark a member paid, that member will appear as paid in all subsequent tournaments. Only new, unpaid entries appear in the owe section.
        </li>
      </ul>
    </Collapse>
  </Alert>


const filterPayouts = (payouts, filter, tournament) => {
  let filterFunc = () => false;
  let status = (tournament?tournament.status:'');
  let pool = (tournament?tournament.pool:'');

  if (filter === 'all') {
    filterFunc = (payout) => true;
  } else if (filter === 'paid') {
    filterFunc = (payout) => payout.paid && payout.inPool;
  } else if (filter === 'owe') {
    filterFunc = (payout) => !payout.paid && payout.inPool &&
                              ((pool === 'variable' && payout.winning < 0) ||
                               (pool === 'classic' && 1===1) ||
                               (pool === 'seasonLong' && 1===1)) ;
  } else if (filter === 'in pool') {
    filterFunc = (payout) => payout.inPool;
  } else if (filter === 'excluded from pool') {
    filterFunc = (payout) => !payout.inPool;
  } else if (filter === 'winners') {
    filterFunc = (payout) => payout.winner && status === 'closed';
  }

  return payouts.filter(filterFunc);
}

class PayoutsTable extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      payouts: props.filteredPayouts,
      sortFunc: null,
      sortByFld: '',
      sortOrder: 'asc',
    }

    this.sortBy = this.sortBy.bind(this);
  }

  componentDidMount() {
    this.sortBy('place'); // Default Sort Order
  }

  componentWillReceiveProps(nextProps) {
    const payouts = nextProps.filteredPayouts;
    this.setState({payouts});
  }

  sortBy(sortBy) {
    let sortFunc = null;

    if (sortBy === this.state.sortByFld){
      this.setState({sortOrder:(this.state.sortOrder === 'desc'?'asc':'desc')});
    }else{
      this.setState({sortOrder:'asc'});
    }

    const placeOrIdSort = (x, y) => {
      let a = x, b = y;
      if (this.state.sortOrder === 'desc'){
        a = y;
        b = x;
      }
      if (!!a.rank && !!b.rank) {
        return parseInt(a.rank.replace('T',''),10) - parseInt(b.rank.replace('T',''), 10);
      }
      return a.entryId - b.entryId;
    }

    if (sortBy === 'place') {
      sortFunc = placeOrIdSort
    } else if (sortBy === 'owner') {
      sortFunc = (x,y) => {
        let a = x, b = y;
        if (this.state.sortOrder === 'desc'){
          a = y;
          b = x;
        }
        if (a.owner !== b.owner) {
          return a.owner < b.owner ? -1 : 1;
        }
        if (a.ownerid !== b.ownerid) {
          return a.ownerid - b.ownerid;
        }
        return placeOrIdSort(x,y);
      }
    } else if (sortBy === 'paid') {
      sortFunc = (x, y) => {
        let a = x, b = y;
        if (this.state.sortOrder === 'desc'){
          a = y;
          b = x;
        }
        if (a.paid !== b.paid) {
          return a.paid - b.paid;
        }
        return placeOrIdSort(x,y);
      }
    } else if (sortBy === 'inpool') {
      sortFunc = (x, y) => {
        let a = x, b = y;
        if (this.state.sortOrder === 'desc'){
          a = y;
          b = x;
        }
        if (a.inPool !== b.inPool) {
          return a.inPool - b.inPool;
        }
        return placeOrIdSort(x,y);
      }
    } else if (sortBy === 'poolDepositAmount') {
      sortFunc = (x,y) => {
        let a = x, b = y;
        if (this.state.sortOrder === 'desc'){
          a = y;
          b = x;
        }
        if (a.poolDepositAmount !== b.poolDepositAmount) {
          return a.poolDepositAmount < b.poolDepositAmount ? -1 : 1;
        }
        return placeOrIdSort(x,y);
      }
    }
    this.setState({sortByFld:sortBy});
    this.setState({sortFunc});
  }

  render() {
    let rows = null;
    let header = null;
    let sortPosIcon = 'sort';
    let sortOwnerIcon = 'sort';
    let sortPaidIcon = 'sort';
    let sortInPoolIcon = 'sort';

    if (!!this.state.payouts) {

      let sortedPayouts = this.state.payouts;
      if (this.state.sortFunc !== null) {
        sortedPayouts.sort(this.state.sortFunc);
      }

      rows = sortedPayouts.map((payout, index) =>
        <PayoutsRow key={payout.entryId} payout={payout} tournament={this.props.tournament}
          fetchEvent={this.props.fetchEvent} activeLeague={this.props.activeLeague}
          showTeamDetails={this.props.showTeamDetails} setLoadingModal={this.props.setLoadingModal}/>
      );

      sortPosIcon = ((this.state.sortByFld === 'place' && this.state.sortOrder === 'asc')?'sort-up':
                     (this.state.sortByFld === 'place' && this.state.sortOrder === 'desc')?'sort-down':'sort');
      sortOwnerIcon = ((this.state.sortByFld === 'owner' && this.state.sortOrder === 'asc')?'sort-up':
                       (this.state.sortByFld === 'owner' && this.state.sortOrder === 'desc')?'sort-down':'sort');
      sortPaidIcon = ((this.state.sortByFld === 'paid' && this.state.sortOrder === 'asc')?'sort-up':
                      (this.state.sortByFld === 'paid' && this.state.sortOrder === 'desc')?'sort-down':'sort');
      sortInPoolIcon = ((this.state.sortByFld === 'inpool' && this.state.sortOrder === 'asc')?'sort-up':
                        (this.state.sortByFld === 'inpool' && this.state.sortOrder === 'desc')?'sort-down':'sort');

      if (this.props.filter === 'owe' && this.state.payouts.length === 0 && this.props.pageLoadDone){
        header =
          <tr className="payoutsHeader">
            <th className="text-center" style={{color:"#155724"}}>
              {this.props.tournament.status !== 'closed' && this.props.tournament.pool === 'variable' ? '' : 'All Teams Paid Up'}
            </th>
          </tr>
      }else if (this.props.filter === 'winners' && this.props.tournament.status !== 'closed' && this.props.pageLoadDone){
        header =
          <tr className="payoutsHeader">
            <th className="text-center" style={{color:"#155724"}}>
              EVENT IN PROGRESS
            </th>
          </tr>
      }else{
        header =
          <tr className="payoutsHeader">
            <th className="text-center text-nowrap" onClick={() => {this.sortBy('place')}}>
              POS <FontAwesome name={sortPosIcon} />
            </th>
            <th className="text-nowrap" onClick={() => {this.sortBy('owner')}}>
              Team (Owner) <FontAwesome name={sortOwnerIcon} />
            </th>
            <th className="text-center text-nowrap" onClick={() => {this.sortBy('place')}}>
              Behind <FontAwesome name={sortPosIcon} />
            </th>
            {this.props.tournament.leagueType === 'CHARITY' &&
            <th className="text-center text-nowrap" onClick={() => {this.sortBy('poolDepositAmount')}}>
              Donated <FontAwesome name={sortPosIcon} />
            </th>
            }
            {this.props.tournament.leagueType !== 'CHARITY' &&
            <>
            <th className="text-center text-nowrap" onClick={() => {this.sortBy('place')}}>
              Owe <FontAwesome name={sortPosIcon} />
            </th>
            <th className="text-center text-nowrap" onClick={() => {this.sortBy('paid')}}>
              Paid <FontAwesome name={sortPaidIcon} />
            </th>
            <th className="text-center text-nowrap" onClick={() => {this.sortBy('inpool')}}>
              In Point Pool <FontAwesome name={sortInPoolIcon} />
            </th>
            </>
            }
            <th/>
        </tr>
      }
    }

    return (
      <Table responsive>
        <thead>
            {header}
        </thead>
        <tbody>
          {rows}
        </tbody>
      </Table>
    )
  }
}

class PayoutsRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      actionsOpen: false,
    }

    this.toggleActions = this.toggleActions.bind(this);
    this.markPaid = this.markPaid.bind(this);
    this.markUnpaid = this.markUnpaid.bind(this);
    this.includeInPool = this.includeInPool.bind(this);
    this.excludeFromPool = this.excludeFromPool.bind(this);
    this.removeEntry = this.removeEntry.bind(this);

  }

  toggleActions() {
    this.setState({actionsOpen: !this.state.actionsOpen});
  }

  markPaid() {
    this.props.setLoadingModal(true);
    mark_paid(this.props.payout.entryId,
    ({success, error}) => {
      if (success) {
        this.props.fetchEvent(this.props.tournament.id);
      } else {
        console.log(error);
      }
    }, (error) => {
      console.log(error);
    });
  }

  markUnpaid() {
    this.props.setLoadingModal(true);
    mark_unpaid(this.props.payout.entryId,
    ({success, error}) => {
      if (success) {
        this.props.fetchEvent(this.props.tournament.id);
      } else {
        console.log(error);
      }
    }, (error) => {
      console.log(error);
    });
  }

  includeInPool() {
    include_in_pool(this.props.payout.entryId,
    ({success, error}) => {
      if (success) {
        this.props.fetchEvent(this.props.tournament.id);
      } else {
        console.log(error);
      }
    }, (error) => {
      console.log(error);
    });
  }

  excludeFromPool() {
    exclude_from_pool(this.props.payout.entryId,
    ({success, error}) => {
      if (success) {
        this.props.fetchEvent(this.props.tournament.id);
      } else {
        console.log(error);
      }
    }, (error) => {
      console.log(error);
    });
  }

  removeEntry() {
    confirmAlert({
      title: 'Delete Team',
      message: 'Deleted teams cannot be recovered. Are you sure?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {

                          this.props.setLoadingModal(true);
                          remove_entry(this.props.payout.entryId,
                            ({success, error}) => {
                              if (success) {
                                this.props.fetchEvent(this.props.tournament.id);
                              } else {
                                console.log(error);
                              }
                            }, (error) => {
                              console.log(error);
                            });

                    }
        },
        {
          label: 'No',
          onClick: () => {  }
        }
      ]
    });

  }



  render() {
    const winning = (w, p) => {
      let recolor = "gray";
      if (!p) {
        if (w > 0)
          recolor = "#27ae60"
        else if (w < 0)
          recolor = "#e74c3c"
      }
      return  <span style={{color: recolor}}>{ numeral(w).format('0,0[.]00') }</span>
    }

    const rank = (rank, winner) => {
      let classname = "mcLeaderboardTeamRowRank";

      if (winner) {
        classname += " winner"
      }

      return <span className={classname}>
        {rank}
      </span>
    }

    const tournament = this.props.tournament
    const payout = this.props.payout

    const markPaid = (['classic','seasonLong'].indexOf(tournament.pool) > -1 || tournament.status === 'closed') &&
                      !payout.paid &&
                      <DropdownItem onClick={()=>this.markPaid()}>
                        Mark Paid
                      </DropdownItem>

    const markUnpaid = payout.paid &&
                        <DropdownItem onClick={()=>this.markUnpaid()}>Mark Unpaid</DropdownItem>

    const excludePool = //tournament.status === 'closed' && RI#147
                        !tournament.pool_locked &&
                        payout.inPool &&
                        <DropdownItem onClick={()=>this.excludeFromPool()}>Exclude from Point Pool</DropdownItem>

    const includePool = //tournament.status === 'closed' && RI#147
                        !tournament.pool_locked &&
                        !payout.inPool &&
                        <DropdownItem onClick={()=>this.includeInPool()}>Include in Point Pool</DropdownItem>

    /*const removeTeam = tournament.status === 'open' &&
                        <DropdownItem onClick={()=>this.removeEntry()}>Remove Team</DropdownItem>*/

    const removeTeam = <DropdownItem onClick={()=>this.removeEntry()}>Delete</DropdownItem>

    const viewTeam = <DropdownItem onClick={()=>this.props.showTeamDetails(payout, payout.roster)}>View Team</DropdownItem>

    const copyEmail =
      <CopyToClipboard text={payout.owneremail}>
        <DropdownItem size="sm">Copy Email</DropdownItem>
      </CopyToClipboard>

    const sendEmail = <a href={"mailto:" + payout.owneremail} style={{textDecoration: "none"}}><DropdownItem size="sm">Send Email</DropdownItem></a>

    return (
        <tr>
          <td className="text-center">{rank(payout.rank, payout.winner)}</td>
          <td>
            <div style={{whiteSpace: "nowrap", fontSize: ".9em"}}>
              {payout.team}
            </div>

            <Link to={"/commissioner/"+this.props.activeLeague+"/member/"+payout.ownerid}>
              <span style={{whiteSpace: "nowrap", fontSize: ".8em"}}>
                {payout.owner}
              </span>
            </Link>
          </td>
          <td className="text-center">{payout.behind === 0 ? '-' : payout.behind}</td>
          {tournament.leagueType === 'CHARITY' &&
              <td className="text-center">${numeral(payout.poolDepositAmount).format('0,0[.]00')}</td>
          }
          {tournament.leagueType !== 'CHARITY' &&
          <>
          <td className="text-center">{winning(payout.winning, payout.paid)}</td>
          <td className="text-center">{payout.paid ? 'Yes' : 'No'}</td>
          <td className="text-center">{payout.inPool ? 'Yes' : 'Excluded'}</td>
          </>
          }
          <td>
            <Dropdown isOpen={this.state.actionsOpen} toggle={this.toggleActions}>
              <DropdownToggle caret color="secondary" disabled={!(markPaid || markUnpaid || excludePool || includePool || removeTeam || viewTeam)}>
                Actions
              </DropdownToggle>
              <DropdownMenu>
                {viewTeam}
                {removeTeam}
                {tournament.leagueType !== 'CHARITY' && markPaid}
                {tournament.leagueType !== 'CHARITY' && markUnpaid}
                {/*excludePool*/}
                {/*includePool*/}
                {copyEmail}
                {sendEmail}

              </DropdownMenu>
            </Dropdown>
          </td>
        </tr>
    )
  }
}
