import React from 'react';

import {
  Container,
  Row,
  Col,
} from 'reactstrap';

import {
  withRouter
} from 'react-router-dom';

import {OnboardingWrapper} from 'common/LandingWrapper.js';
import CreateClub from 'pages/admin/CreateClub';

import {login, logout} from 'utils/api';



class UnauthedCreateGolfClub extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    };


    this.handleLoginSubmit = this.handleLoginSubmit.bind(this);

  }

  componentDidMount() {
      let postLoginURL = this.props.postLoginURL || '';
      console.log('postLoginURL :' + postLoginURL);
      if (postLoginURL === '/'){
        logout((response) => {
            /*window.FB.logout((response) => {
              console.log(response);
            });*/
            this.props.loggedOutCallback();
          }, (error) => {
            console.log(error);
            console.log("Error occurred trying to logout!");
        });
      }

  }



  handleLoginSubmit(event){
    event.preventDefault();
    event.stopPropagation();

    this.setState({status: false});

    login(this.state.email, this.state.password,
      (response) => {
        if (response.data.success) {
          //this.props.history.push('/');
          //this.props.loggedInCallback(response.data.me, this.props.history);

        } else {
          this.setState({status:null, error: "There was a problem logging you in."});
        }
      }, (error) => {
        this.setState({status:null, error: "Invalid email or password."});
    })
  }


  render() {

    return (
      <OnboardingWrapper {...this.props}>
        <Container fluid className="joinContainer">
          <Row noGutters>
            <Col className="mx-auto">
              <CreateClub {...this.props} {...this.params} authed={false}/>
            </Col>
          </Row>
        </Container>
      </OnboardingWrapper>
    );
  }
}

export default withRouter(UnauthedCreateGolfClub);
