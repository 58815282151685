import React from 'react';

import {
  Row, Col,
  Container,
  ListGroup
} from 'reactstrap';

import {
  Link,
  NavLink,
  Route,
  Redirect,
  Switch
} from 'react-router-dom';

import FontAwesome from 'react-fontawesome';

import Motd from 'pages/commissioner/Motd';
import Members from 'pages/commissioner/Members';
import Settings from 'pages/commissioner/Settings';
import Events from 'pages/commissioner/Events';
import CommissionerBalanceNav from 'pages/commissioner/BalanceNav';
import Payouts from 'pages/commissioner/Payouts';
import MemberProfile from 'pages/commissioner/MemberProfile';
import Cup from 'pages/commissioner/Cup';
import ClubPromotion from 'pages/commissioner/ClubPromotion';
import Invite from 'pages/commissioner/Invite';
import BannerAds from 'pages/commissioner/BannerAds';
import WhiteLabel from 'pages/commissioner/WhiteLabel';
import FoundationAgreement from 'pages/commissioner/FoundationAgreement';
import Toc from 'pages/commissioner/Toc';
import SeasonDraft from 'pages/commissioner/SeasonDraft';
import OneAndDone from 'pages/commissioner/OneAndDone';

import FeedbackModal from 'common/FeedbackModal.js';


export const DesktopCommissioner = (props) => {
  return (
    <Container fluid>
      <div className="p-lg-3">
        <CommissionerHeader leagueTitle={props.leagueTitle} leagueCreated={props.leagueCreated}/>
        <Row>
          <Col lg="3">
            <DesktopCommissionerNav {...props} />
          </Col>
          <Col lg="9">
            <Routes isDesktop={true} {...props} />
          </Col>
        </Row>
      </div>
    </Container>
  )
}

export const MobileCommissioner = (props) => {
  return (
    <Routes isDesktop={false} {...props} />
  )
}

const Routes = (props) => {
  if (props.activeLeague === null) {
    return null;
  }

  const league_charity_status = props.selectedLeague ? props.selectedLeague.league_charity_status : null;

  return (
    <Switch>
      {(props.selectedLeague && props.selectedLeague.whiteLabel) &&
        <Route path="/commissioner/:league/whitelabel" render={(params) => <WhiteLabel  {...props} {...params} /> } />
      }
      {(league_charity_status && ['IN_REVIEW','APPROVED','REJECTED'].indexOf(league_charity_status) > -1) &&
      <Route path="/commissioner/:league/foundationagreement" render={(params) => <FoundationAgreement {...props} {...params}/> } />
      }

      <Route path="/commissioner/:league/settings" render={(params) => <Settings {...props}  {...params} settingsType='GENERAL'/> } />
      <Route path="/commissioner/:league/wklysettings" render={(params) => <Settings {...props}  {...params} settingsType='WEEKLY'/> } />

      {(!league_charity_status || ['APPROVED'].indexOf(league_charity_status) > -1) && <Route path="/commissioner/:league/members" render={() => <Members {...props} /> } /> }
      {(!league_charity_status || ['APPROVED'].indexOf(league_charity_status) > -1) && <Route path="/commissioner/:league/invite" render={() => <Invite {...props} /> } /> }
      {(!league_charity_status || ['APPROVED'].indexOf(league_charity_status) > -1) && <Route path="/commissioner/:league/events" render={(params) => <Events {...props} {...params}/> } /> }
      {(!league_charity_status || ['APPROVED'].indexOf(league_charity_status) > -1) && <Route path="/commissioner/:league/payouts/:eventid" render={(params) => <Payouts {...props} {...params}/> } /> }
      {(!league_charity_status || ['APPROVED'].indexOf(league_charity_status) > -1) && <Route path="/commissioner/:league/payouts" render={(params) => <Payouts {...props} {...params}/> } /> }
      {(!league_charity_status || ['APPROVED'].indexOf(league_charity_status) > -1) && <Route path="/commissioner/:league/balance" render={() => <CommissionerBalanceNav {...props} /> } /> }
      {(!league_charity_status || ['APPROVED'].indexOf(league_charity_status) > -1) && <Route path="/commissioner/:league/bannerads" render={() => <BannerAds {...props} configGrp={'LEAGUE_BANNER'} /> } /> }
      {(!league_charity_status || ['APPROVED'].indexOf(league_charity_status) > -1) && <Route path="/commissioner/:league/promotion" render={() => <ClubPromotion {...props} /> } /> }
      {(!league_charity_status || ['APPROVED'].indexOf(league_charity_status) > -1) && <Route path="/commissioner/:league/cup" render={() => <Cup {...props} /> } /> }
      {(!league_charity_status || ['APPROVED'].indexOf(league_charity_status) > -1) && <Route path="/commissioner/:league/toc" render={() => <Toc {...props} /> } /> }
      {(!league_charity_status || ['APPROVED'].indexOf(league_charity_status) > -1) && <Route path="/commissioner/:league/seasondraft" render={() => <SeasonDraft {...props} /> } /> }
      {(!league_charity_status || ['APPROVED'].indexOf(league_charity_status) > -1) && <Route path="/commissioner/:league/onedone" render={() => <OneAndDone {...props} /> } /> }


      {(!league_charity_status || ['APPROVED'].indexOf(league_charity_status) > -1) && <Route path="/commissioner/:league/member/:member" render={({match}) => <MemberProfile member={match.params.member} {...props} /> } /> }

      {
        props.isDesktop === true ?
        <Redirect to={"/commissioner/"+props.activeLeague+"/settings"} /> :
        <Route path="/commissioner/:league" render={() => <MobileCommissionerMenu {...props} /> } />
      }
    </Switch>
  )
}



const commissionerNavsGrp1 = ({leaguebalance, club, balancewarning, ...props}) => {

  const league_charity_status = (props.selectedLeague && props.selectedLeague.league_charity_status && ['IN_REVIEW','APPROVED','REJECTED'].indexOf(props.selectedLeague.league_charity_status) >-1)?true:false;

  if (props.selectedLeague && props.selectedLeague.league_charity_status && ['IN_REVIEW','REJECTED'].indexOf(props.selectedLeague.league_charity_status) > -1){
    return [
      {to: "/settings", title: "League Settings"},
    ];
  }
  const navItems = [
    {to: "/settings", title: "League Settings"},
    //{to: "/home", title: "Announcements"},
    {to: "/members", title: "Members"},
    {to: "/invite", title: "Invite"},
  ];

  return navItems.filter(n => n);
};

const commissionerNavsGrp2 = ({leaguebalance, club, balancewarning, ...props}) => {

  const navItems = [
    {to: "/wklysettings", title: "Settings"},
    {to: "/events", title: "Weekly Tournaments"},
    {to: "/payouts", title: "Bookkeeping"}
  ];

  return navItems.filter(n => n);
};

const commissionerNavsGrp3 = ({leaguebalance, club, balancewarning, ...props}) => {

  const league_charity_status = (props.selectedLeague && props.selectedLeague.league_charity_status && ['IN_REVIEW','APPROVED','REJECTED'].indexOf(props.selectedLeague.league_charity_status) >-1)?true:false;

  const navItems = [
    {to: "/cup", title: "Majors Challenge Cup"},
    {to: "/toc", title: "Tournament of Champions"},
    {to: "/onedone", title: "One-And-Done"},
    {to: "/seasondraft", title: "Season Draft"},
  ];

  return navItems.filter(n => n);
};

const commissionerNavsGrp4 = ({leaguebalance, club, balancewarning, ...props}) => {

  const league_charity_status = (props.selectedLeague && props.selectedLeague.league_charity_status && ['IN_REVIEW','APPROVED','REJECTED'].indexOf(props.selectedLeague.league_charity_status) >-1)?true:false;
  const club_status = (props.selectedLeague ? props.selectedLeague.club_status : '');
  const navItems = [
    (league_charity_status) ?
        {to: "/foundationagreement", title: "Foundation Agreement"}: null,
    (props.selectedLeague && props.selectedLeague.whiteLabel) ?
        {to: "/whitelabel",title: 'White Label'} : null,
    (!club && !league_charity_status) ?
      {
        to: "/balance",
        title: (<span>Credit Balance {"(" + leaguebalance + ")"} {balancewarning && <FontAwesome name="exclamation-triangle" size="lg" style={{color: "orange"}}/>}</span>)
      } : null,
    (club) ?
      {to: "/bannerads", title: "Banner Ads"} : null,
    (club) ?
      {to: "/promotion", title: "Promotion"} : null,
    (club && !league_charity_status && club_status !== 'promotional') ?
      {to: "/balance",title: "Subscription"} : null,
  ];

  return navItems.filter(n => n);
};

const DesktopCommissionerNav = (props) => {
  const itemsGrp1 = commissionerNavsGrp1(props).map((item, index) => <DesktopCommissionerNavItem
    key={index}
    to={"/commissioner/" + props.activeLeague + item.to}
    title={item.title}
  />);

  const itemsGrp2 = commissionerNavsGrp2(props).map((item, index) => <DesktopCommissionerNavItem
    key={index}
    to={"/commissioner/" + props.activeLeague + item.to}
    title={item.title}
  />);

  const itemsGrp3 = commissionerNavsGrp3(props).map((item, index) => <DesktopCommissionerNavItem
    key={index}
    to={"/commissioner/" + props.activeLeague + item.to}
    title={item.title}
  />);

  const itemsGrp4 = commissionerNavsGrp4(props).map((item, index) => <DesktopCommissionerNavItem
    key={index}
    to={"/commissioner/" + props.activeLeague + item.to}
    title={item.title}
  />);

  const feedback = (
    <div className="list-group-item-action list-group-item">
      Contact Us
    </div>
  )

  return (
    <>
      <div className="commissionerNavGroup"> LEAGUE SETTINGS </div>
      <ListGroup>
        {itemsGrp1}
      </ListGroup>

      <div className="commissionerNavGroup"> WEEKLY TOURNAMENTS </div>
      <ListGroup>
        {itemsGrp2}
      </ListGroup>

      <div className="commissionerNavGroup"> SEASON LONG GAMES </div>
      <ListGroup>
        {itemsGrp3}
      </ListGroup>

      <div className="commissionerNavGroup"> OTHER FEATURES </div>
      <ListGroup>
        {itemsGrp4}
        <FeedbackModal component={feedback} />
      </ListGroup>
    </>
  )
}


const MobileCommissionerNav = (props) => {
  const itemsGrp1 = commissionerNavsGrp1(props).map((item, index) => <MobileCommissionerNavItem
    key={index}
    to={"/commissioner/" + props.activeLeague + item.to}
    title={item.title}
  />);

  const itemsGrp2 = commissionerNavsGrp2(props).map((item, index) => <MobileCommissionerNavItem
    key={index}
    to={"/commissioner/" + props.activeLeague + item.to}
    title={item.title}
  />);

  const itemsGrp3 = commissionerNavsGrp3(props).map((item, index) => <MobileCommissionerNavItem
    key={index}
    to={"/commissioner/" + props.activeLeague + item.to}
    title={item.title}
  />);

  const itemsGrp4 = commissionerNavsGrp4(props).map((item, index) => <MobileCommissionerNavItem
    key={index}
    to={"/commissioner/" + props.activeLeague + item.to}
    title={item.title}
  />);

  const feedback = (
    <div className="list-group-item-action list-group-item">
      <div style={{width: "100%"}} className="d-flex justify-content-between">
        Contact Us
      </div>
    </div>
  )

  return (
    <>
      <div className="commissionerNavGroup"> LEAGUE SETTINGS </div>
      <ListGroup>
        {itemsGrp1}
      </ListGroup>

      <div className="commissionerNavGroup"> WEEKLY TOURNAMENTS </div>
      <ListGroup>
        {itemsGrp2}
      </ListGroup>

      <div className="commissionerNavGroup"> SEASON LONG GAMES </div>
      <ListGroup>
        {itemsGrp3}
      </ListGroup>

      <div className="commissionerNavGroup"> OTHER FEATURES </div>
      <ListGroup>
        {itemsGrp4}
        <FeedbackModal component={feedback} />
      </ListGroup>
    </>
  )
}

const DesktopCommissionerNavItem = (props) => {
  return (
    <NavLink
      to={props.to}
      exact={true}
      className="list-group-item-action list-group-item"
      activeClassName="active">
      <div>{props.title}</div>
    </NavLink>
  )
}


const MobileCommissionerNavItem = (props) => {
  return (
    <Link
      to={props.to}
      className="list-group-item-action list-group-item">
      <div style={{width: "100%"}} className="d-flex justify-content-between">
        {props.title}
        <FontAwesome name="chevron-right" style={{fontSize: "1rem", lineHeight: "22px"}} />
      </div>
    </Link>
  )
}


const MobileCommissionerMenu = (props) => {
  return (
    <Container fluid>
      <div className="p-3">
        <MobileCommissionerNav {...props} />
      </div>
    </Container>
  )
}

const CommissionerHeader = (props) => {
  return (
    <div className="pb-3">
      <div className="commissionerTitle">Commissioner Console</div>
      {!props.leagueTitle ? undefined :
        <h6 style={{color:"#aaa", fontWeight:"300"}}>
          {props.leagueTitle} created on {props.leagueCreated}
        </h6>
      }
    </div>
  )
}
