import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';

import {
  Container
} from 'reactstrap';

import Landing from 'pages/user/Landing';
import DeepURLLanding from 'pages/user/DeepURLLanding';
// import Landing2 from 'pages/user/Landing2';
import ClubLanding from 'pages/user/ClubLanding';
import Signup from 'pages/user/Signup';
import Activation from 'pages/user/Activation';
import LoginFromExternal from 'pages/user/LoginFromExternal';
import PasswordReset from 'pages/user/PasswordReset';
import WelcomeBack from 'pages/user/WelcomeBack';
import AuthedJoinLeague from 'pages/user/AuthedJoinLeague';
import UnauthedJoinLeague from 'pages/user/UnauthedJoinLeague';
import UnauthedJoinClub from 'pages/user/UnauthedJoinClub';
import ClubPage from 'pages/user/ClubPage';
import {SimpleHowToPlay} from 'pages/user/HowToPlay';
import {WhiteLabelHowToPlay} from 'pages/user/HowToPlayWhiteLabel';
import {LandingPrivacy, AuthedPrivacy} from 'pages/user/Privacy';
import {LandingTerms, AuthedTerms} from 'pages/user/Terms';
import {AuthedRules, LandingRules, AuthedWhiteLabelRules} from 'pages/user/Rules';
import {AuthedPerfectTeam, LandingPerfectTeam} from 'pages/user/PerfectTeam';
import Home from 'pages/user/Home';
import Profile from 'pages/user/Profile';
import League from 'pages/user/League';
import Members from 'pages/user/Members';
import EventsContainer from 'pages/user/Events';
import Cup from 'pages/user/Cup';
import CreateLeague from 'pages/user/CreateLeague';
import CreateCharityLeague from 'pages/user/CreateCharityLeague';
import JoinLeague from 'pages/user/JoinLeague';
import JoinAfterLogin from 'pages/user/JoinAfterLogin';
import MoneyLeaderboard from 'pages/user/MoneyLeaderboard';

import Commissioner from 'pages/commissioner/Commissioner';

import Admin from 'pages/admin/Admin';

import UnauthedCreateGolfClub from 'pages/user/UnauthedCreateGolfClub';
import UnauthedCreateCharityLeague from 'pages/user/UnauthedCreateCharityLeague';

import JoinGolfPool from 'pages/user/JoinGolfPool';
import {GamesUnauth, GamesAuth, SeasonDraftGameRuleUnauth, SeasonDraftGameRuleAuth, OneAndDoneGameRuleUnauth, OneAndDoneGameRuleAuth} from 'pages/user/Games';

import PublicEventsContainer from 'pages/user/PublicEventsContainer';

import TopNav from 'common/TopNav';

import {
  is_authed,
  leagueinfoWL,
  clubinfoWL,
} from 'utils/api.js';

import './App.css';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      authed: null,
      me: null,
      activeLeague: null,
      postLoginURL: "/",
      loading: true,
      redirectURL: null,

    };

    this.loggedInCallback = this.loggedInCallback.bind(this);
    this.setPostLoginURL = this.setPostLoginURL.bind(this);
    this.loggedOutCallback = this.loggedOutCallback.bind(this);
    this.updateMe = this.updateMe.bind(this);
    this.updateMeDefaultEvent = this.updateMeDefaultEvent.bind(this);
    this.selectLeague = this.selectLeague.bind(this);
  }

  loggedInCallback(me, history) {
    this.setState({
      authed: true,
      me: me,
    });



    if (history !== undefined) {
      let url = this.state.postLoginURL;
      this.setState({postLoginURL: "/"});
      history.push(url);
    }
  }

  setPostLoginURL(postLoginURL) {
    this.setState({postLoginURL})
  }

  loggedOutCallback() {
    this.setState({
      authed: false,
      me: null,
      activeLeague: null,
      selectedLeague: null,
      redirectURL:'',
    });
  }

  updateMe(me) {
    this.setState({me});
    //console.log('me.fName:' + me.usr.fName + me.usr.lName)
  }

  updateMeDefaultEvent(leagueid, defaultEvent) {
    // TODO this could be made into a higher level function that operates on the me object, but lets other components dictate what changes
    const { me } = this.state;

    me.leagues.forEach((league)=>{
      if (league.leagueid !== leagueid) { return; }

      league.default_event = defaultEvent;
    });

    this.updateMe(me);
  }

  selectLeague(activeLeague) {
    let selectedLeague = null;
    if (activeLeague && this.props.me){
      this.props.me.leagues.forEach((league)=>{
        if (league.leagueid === activeLeague) { selectedLeague = league; }
      });
    }
    this.setState({activeLeague,selectedLeague});
  }

  componentDidMount() {
    //this.whiteLabelRedirect(window.location.pathname);
    is_authed((response) => {
      if (response.data.success) {
        this.loggedInCallback(response.data.me);
      } else {
        const pathUrl = window.location.pathname;
        this.whiteLabelRedirect(pathUrl);
        this.loggedOutCallback();

        /*if (this.state.redirectURL){
          window.location.href = this.state.redirectURL;
          return null;
        }*/


        if ((this.props.location && this.props.location.pathname && this.props.location.pathname.indexOf('/commissioner/') > 0) ||
            (pathUrl && pathUrl.indexOf('createleague') >= 0)

        ){
          //alert('ff');
          this.setPostLoginURL(pathUrl);
          this.props.history.push("/deep");
        }

      }
    }, (error) => {
      console.log("Error occurred trying to check is_authed:");
      console.dir(error);
    })

  }

  whiteLabelRedirect = (pathname) => {
    let params = pathname.split('/');
    if (params.length > 2 && params[0]==='' && ['commissioner','league'].indexOf(params[1]) >-1){
      let leagueid = parseInt(params[2],0);
      leagueinfoWL(leagueid,
      ({league}) => {
        if (league.whiteLabel && league.wlRedirectURL){
          //this.setState({redirectURL:league.wlRedirectURL});
          window.location.href = league.wlRedirectURL;
          return null;
        }else{
          this.setState({redirectURL:''});
        }
      }, (error) => {
        console.log(error);
        this.setState({redirectURL:''});
      });

    }else if (params.length > 2 && params[0]==='' && ['club'].indexOf(params[1]) >-1){
      let clubname = params[2];
      clubinfoWL(clubname,
      ({club}) => {
        if (club.whiteLabel && club.wlRedirectURL){
          //this.setState({redirectURL:club.wlRedirectURL});
          window.location.href = club.wlRedirectURL;
          return null;
        }else{
          this.setState({redirectURL:''});
        }
      }, (error) => {
        console.log(error);
        this.setState({redirectURL:''});
      });

    }else{
      this.setState({redirectURL:''});
    }


    return null;


  }


  render() {
    const authed = this.state.authed;
    const redirectURL = this.state.redirectURL;

    if (authed === null || (authed === false && redirectURL === null)) {
      return null;
    } else  {
      return (
        <Router>
          {
            authed === false ?
              <PublicRoutes loggedInCallback={this.loggedInCallback} setPostLoginURL={this.setPostLoginURL} /> :
              <AuthedRoutes {...this.state} selectLeague={this.selectLeague} loggedOutCallback={this.loggedOutCallback} updateMe={this.updateMe} updateMeDefaultEvent={this.updateMeDefaultEvent} />
          }
        </Router>
      )
    }
  }
}

const PublicRoutes = (props) => {
  return (
    <Switch>
      <Route exact path="/" render={(params) => <Landing {...props} {...params} /> } />
      <Route exact path="/deep" render={(params) => <DeepURLLanding postLoginURL={params.match.url} {...props} {...params} /> } />
      {/* <Route path="/promo" render={(params) => <Landing2 {...props} {...params} /> } /> */}
      <Route path="/signup" render={(params) => <Signup {...props} {...params} /> } />
      <Route path="/public/leaderboard/:leagueslug/:leagueevent" render={(params) => <PublicEventsContainer {...props} {...params} /> } />
      <Route path="/public/leaderboard/:leagueslug" render={(params) => <PublicEventsContainer {...props} {...params} /> } />

      <Route path="/activation/:activation_code" render={(params) => <Activation {...props} {...params} /> } />
      <Route path="/extrnllogin/:application/:token" render={(params) => <LoginFromExternal {...props} {...params} /> } />
      <Route path="/joinleague/:league/:code" render={(params) => <JoinAfterLogin postLoginURL={params.match.url} {...props} {...params}/> } />
      <Route path="/passwordreset/:code" render={(params) => <PasswordReset {...props} {...params} /> } />
      <Route path="/welcomeback/:code" render={(params) => <WelcomeBack {...props} {...params} /> } />
      <Route exact path="/club" render={(params) => <ClubLanding {...props} {...params} /> } />
      <Redirect path="/clubs" to="/club" />
      <Route path="/club/:clubname" render={(params) => <UnauthedJoinClub postLoginURL={params.match.url} {...props} {...params} /> } />
      <Route path="/ileague/:leagueslug" render={(params) => <UnauthedJoinLeague postLoginURL={params.match.url} {...props} {...params} /> } />
      <Route path="/league/:leagueslug" render={(params) => <UnauthedJoinLeague postLoginURL={params.match.url} {...props} {...params} /> } />
      <Route path="/ileague" render={(params) => <UnauthedJoinLeague postLoginURL={params.match.url} {...props} {...params} /> } />
      <Route path="/privacy" render={(params) => <LandingPrivacy {...props} {...params} /> } />
      <Route path="/terms" render={(params) => <LandingTerms {...props} {...params} /> } />
      <Route path="/rules" render={(params) => <LandingRules {...props} {...params} /> } />
      <Route path="/games" render={(params) => <GamesUnauth {...props} {...params} /> } />
      <Route path="/onedonerules" render={(params) => <OneAndDoneGameRuleUnauth {...props} {...params} /> } />
      <Route path="/seasondraftrules" render={(params) => <SeasonDraftGameRuleUnauth {...props} {...params} /> } />
      <Route path="/newgolfclub/:referraluser" render={(params) => <UnauthedCreateGolfClub {...props} {...params} /> } />
      <Route path="/newgolfclub" render={(params) => <UnauthedCreateGolfClub {...props} {...params} /> } />
      <Route path="/newcharity" render={(params) => <UnauthedCreateCharityLeague {...props} {...params} /> } />
      <Route path="/charity" render={(params) => <UnauthedCreateCharityLeague {...props} {...params} /> } />
      <Route path="/perfectteam" render={(params) => <LandingPerfectTeam {...props} {...params} /> } />
      <Redirect path="/fsta" to="/club/fsta" /> {/* THIS IS A ONE-OFF FOR FSTA */}
      <Redirect path="/free" to="/club/free" /> {/* THIS IS A ONE-OFF FOR FSTA */}
      <Route path='/250k' component={() => {
           window.location.href = 'https://mailchi.mp/majorschallenge/250k';
           return null;
      }}/>
      <Route path='/sales' component={() => {
           window.location.href = 'https://mailchi.mp/majorschallenge/sales';
           return null;
      }}/>
      <Route path='/gbn' component={() => {
           window.location.href = 'http://mailchi.mp/majorschallenge/gbn';
           return null;
      }}/>
      <Route path='/raisemoney' component={() => {
           window.location.href = 'https://mailchi.mp/majorschallenge/charity';
           return null;
      }}/>
      <Route path='/dfsarmy' component={(params) => {
           params.history.push('/club/dfsarmy');
           return null;
      }}/>
      <Route path='/mc1' component={() => {
           window.location.href = '/signup';
           return null;
      }}/>
      <Route path='/mc2' component={() => {
           window.location.href = '/newgolfclub';
           return null;
      }}/>
      <Route path='/mc3' component={(params) => {
          window.location.href = '/newgolfclub';
          return null;
      }}/>
      <Route path="/commissioner/:league" render={(params) => <DeepURLLanding postLoginURL={params.match.url} {...props} {...params} /> } />
      {/*<Route path="/createcharityleague" render={(params) => <DeepURLLanding postLoginURL={params.match.url} {...props} {...params} /> }  />*/}
      <Route path='/createcharityleague' component={(params) => {
          window.location.href = '/newcharity';
          return null;
      }}/>

      <Redirect to="/"/>
    </Switch>
  )
}

const AuthedRoutes = (props) => {
  let whiteLabel = false;
  if (props.activeLeague && props.me){
    props.me.leagues.forEach((league)=>{
      if (league.leagueid === props.activeLeague && league.whiteLabel) { whiteLabel = true; return; }
    });
  }

  return (
    <div>
      <TopNav {...props} />
      <Switch>
        <Redirect exact from="/promo" to="/" />
        <Route path="/extrnllogin/:application/:token" render={(params) => <LoginFromExternal {...props} {...params} /> } />
        <Route path="/public/leaderboard/:leagueslug" render={(params) => <PublicEventsContainer {...props} {...params} /> } />
        <Route path="/events/:eventid" render={(params) => { return <EventsContainer {...params} {...props}/> } }/>
        <Route path="/createleague" render={(params) => <CreateLeague {...params} {...props}/> } />
        <Route path="/createcharityleague" render={(params) => <CreateCharityLeague {...params} {...props} authed={true} /> } />
        <Route path="/joinleague/:league/:code" render={(params) => <JoinLeague {...params} {...props}/> } />
        <Route path="/commissioner/:league" render={(params) => <Commissioner {...params} {...props} />} />
        <Route path="/moneyleaders/:league" render={(params) => <MoneyLeaderboard {...params} {...props} showAll={true}/>} />
        <Route path="/club/:clubname" render={(params) => <ClubPage {...props} {...params} /> } />
        <Route path="/joingolfpool" render={(params) => <JoinGolfPool {...params} {...props}/> } />
        <Route path="/admin" render={(params) => <Admin {...params} {...props} />} />
        <Route path="/profile" render={(params) => <Profile {...props} {...params}/>} />
        <Route path="/privacy" component={AuthedPrivacy} />
        <Route path="/terms" component={AuthedTerms} />
        {(whiteLabel) &&
          <Route exact path="/rules" component={AuthedWhiteLabelRules} />
        }
        <Route exact path="/rules" component={AuthedRules} />
        <Route path="/games" render={(params) => <GamesAuth {...props} {...params} /> } />
        <Route path="/onedonerules" render={(params) => <OneAndDoneGameRuleAuth {...props} {...params} /> } />
        <Route path="/seasondraftrules" render={(params) => <SeasonDraftGameRuleAuth {...props} {...params} /> } />
        <Route path="/perfectteam" render={(params) => <AuthedPerfectTeam {...props} {...params} /> } />
        <Route path="/ileague/:leagueslug" render={(params) => <AuthedJoinLeague {...props} {...params} /> } />
        <Route path="/newgolfclub/:referraluser" render={(params) => <UnauthedCreateGolfClub {...props} {...params} /> } />
        <Route path="/newgolfclub" render={(params) => <UnauthedCreateGolfClub {...props} {...params} /> } />
        <Route path="/newcharity" render={(params) => <UnauthedCreateCharityLeague {...props} {...params} /> } />
        <Route path="/charity" render={(params) => <UnauthedCreateCharityLeague {...props} {...params} /> } />
        <Route path='/250k' component={() => {
             window.location.href = 'https://mailchi.mp/majorschallenge/250k';
             return null;
        }}/>
        <Route path='/sales' component={() => {
             window.location.href = 'https://mailchi.mp/majorschallenge/sales';
             return null;
        }}/>
        <Route path='/gbn' component={() => {
             window.location.href = 'http://mailchi.mp/majorschallenge/gbn';
             return null;
        }}/>
        <Route path='/raisemoney' component={() => {
             window.location.href = 'https://mailchi.mp/majorschallenge/charity';
             return null;
        }}/>
        <Route path='/dfsarmy' component={(params) => {
             params.history.push('/club/dfsarmy');
             return null;
        }}/>
        <Route path='/mc1' component={() => {
             window.location.href = '/signup';
             return null;
        }}/>
        <Route path='/mc2' component={() => {
             window.location.href = '/newgolfclub';
             return null;
        }}/>
        <Route path='/mc3' component={(params) => {
            window.location.href = '/newgolfclub';
            return null;
        }}/>
        <Route path="/" render={() => <MiscRoutes {...props}/> } />
      </Switch>
    </div>
  )
}

const MiscRoutes = (props) => {
  let whiteLabel = false;
  if (props.activeLeague && props.me){
    props.me.leagues.forEach((league)=>{
      if (league.leagueid === props.activeLeague && league.whiteLabel) { whiteLabel = true; return; }
    });
  }
  return (
    <Container fluid className="px-0" style={{backgroundColor:"rgba(236, 240, 241, 0.5)", minHeight: "100vh", paddingTop:"6.5rem", paddingBottom:"2rem"}}>
      <Switch>
        <Route exact path="/" render={() => <Home {...props} /> } />
        {(whiteLabel) &&
          <Route path="/league/:league/help" render={(params) => <WhiteLabelHowToPlay {...props} {...params} /> } />
        }
        <Route path="/league/:league/help" render={(params) => <SimpleHowToPlay {...props} {...params} /> } />
        <Route path="/league/:league/contest/event/:event" render={(params) => <League {...params} {...props} showAllLeagueConest={true} showLB={true} /> } />
        <Route path="/league/:league/contest" render={(params) => <League {...params} {...props} showAllLeagueConest={true} /> } />

        <Route path="/league/:league/odcontest/event/:event" render={(params) => <League {...params} {...props} showAllLeagueConest={true} lcContestType={'ONE_AND_DONE'} showLB={true}/> } />
        <Route path="/league/:league/sdcontest/event/:event" render={(params) => <League {...params} {...props} showAllLeagueConest={true} lcContestType={'SEASON_DRAFT'} showLB={true}/> } />
        <Route path="/league/:league/sdcontest" render={(params) => <League {...params} {...props} showAllLeagueConest={true} lcContestType={'SEASON_DRAFT'} /> } />
        <Route path="/league/:league/odcontest" render={(params) => <League {...params} {...props} showAllLeagueConest={true} lcContestType={'ONE_AND_DONE'}/> } />

        <Route path="/league/:league" render={(params) => <League {...params} {...props} showAllLeagueConest={false} lcContestType={''}/> } />
        <Route path="/members/:league" render={(params) => <Members {...params} {...props} /> } />
        <Route path="/cup/:cupid" render={(params) => <Cup {...params} {...props}/> } />
        <Route component={NotFound} />
      </Switch>
    </Container>
  )
}

const NotFound = (props) => (
  <Container fluid style={{backgroundColor: "rgba(236, 240, 241, 0.5)", minHeight: "100vh"}}>
    <center className="p-4">
      <h2>The page you were looking for could not be found.</h2>
    </center>
  </Container>
)

export default App;
